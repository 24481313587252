import React from 'react';
import BasicInfoForm from '../components/basicInfoForm';
import HomePage from '../views/HomePage';

//错误页面
import Error403 from '../views/error/403'
import Error404 from '../views/error/404'
//系统注册、登陆页面
import Forget from '../views/system/Forget';
import Login from '../views/system/Login';
import Register from '../views/system/Register';
import RegisterRules from '../views/system/RegisterRules';
import ScanQRCode from '../views/system/ScanQRCode';
import ParamsSetting from '../views/projectInput/settting/paramSetting'
// 项目分析
import analysis from '../views/projectAnalysis/projectAnalysis';

// 项目输入
import followPollute from '../views/projectInput/followPollute/followPollute';//关注污染物
import exposeRoad from '../views/projectInput/exposeRoad/exposeRoad';//关注污染物

// 项目输出

import conceptModel from '../views/projectOutput/conceptModel/conceptModel';//概念模型
import modelParamsTable from '../views/projectOutput/modelParamsTable/modelParamsTable';//模型及参数表
import generalReport from '../views/projectOutput/generalReport/generalReport';//总报告
import sensitivity from '../views/projectOutput/Sensitivity/sensitivity';//敏感度分析
import getlnitParamsValue from '../views/projectInput/getlnitParamsValue/getlnitParamsValue';//敏感度分析结果
import polluteParamsTable from '../views/projectOutput/polluteParamsTable/polluteParamsTable';//污染物参数表
import exposedQuantityTable from '../views/projectOutput/exposedQuantityTable/exposedQuantityTable';//暴露量计算
import riskCalculation from '../views/projectOutput/riskCalculation/riskCalculation';//风险计算
import ContributionRateCalculation from '../views/projectOutput/ContributionRateCalculation/ContributionRateCalculation';//贡献率计算
import controlValueCalculation from '../views/projectOutput/controlValueCalculation/controlValueCalculation';//控制值计算

// import File from '../components/viewPdf/viewPdf'

export interface IRouteBase {
  // 路由路径
  path: string;
  // 路由组件
  component?: any;
  // 302 跳转
  redirect?: string;
  // 路由信息
  meta: IRouteMeta;
  // 是否校验权限, false 为不校验, 不存在该属性或者为true 为校验, 子路由会继承父路由的 auth 属性
  auth?: boolean;
  exact?: boolean;
  needProductId?:boolean;
}

export interface IRouteMeta {
  title: string;
  icon?: string;
  breadtitle?:string;
  id?:any
}

export interface IRoute extends IRouteBase {
  children?: IRoute[];
}

/**
 * routes 第一级路由负责最外层的路由渲染，比如 userLayout 和 Layout 的区分
 * 所有系统内部存在的页面路由都要在此地申明引入，而菜单栏的控制是支持异步请求控制的
 */

 export const system = [
  {
  path: '/login',
  component: Login,
  auth: false,
  meta: {
    title: '登录',
  },
  exact:true,
},
{
  path: '/register',
  component: Register,
  auth: false,
  meta: {
    title: '新用户注册',
  },
  exact:true,
},
{
  path: '/forget',
  auth: false,
  component: Forget,
  meta: {
    title: '找回密码',
  },
  exact:true,
},
{
  path: '/scan',
  auth: false,
  component: ScanQRCode,
  meta: {
    title: '扫码登陆',
  },
  exact:true,
},
{
  path: '/file',
  auth: false,
  component: File,
  meta:{
    title: "pdf"
  },
  exact:true
},
{
  path: '/project',
  auth: false,
  component: HomePage,
  meta: {
    title: '项目',
  },
  exact:false,
  redirect: '/project/info',
},
{
  path: '/404',
  auth: false,
  component: Error404,
  meta: {
    title: '页面不存在',
  },
},
{
  path: '/403',
  auth: false,
  component: Error403,
  meta: {
    title: '暂无权限',
  },
}
]
export const project = [
      {
      path: '/project/info',
      meta: {
        title: '项目信息',
        breadtitle:'项目信息',
        id:1
      },
      component: BasicInfoForm,
      exact:true,
      needProductId:false
    },
    {
      path: '/project/analysis',
      meta: {
        title: '分析层次',
        breadtitle:'层次分析',
        id:2
      },
      component: analysis,
      exact:true,
      needProductId:true
    },
    {
      path: '/project/followPollute',
      component: followPollute,
      meta: {
        title: '关注污染物',
        breadtitle:'项目输入/关注污染物',
        id:3
      },
      needProductId:true
    },
    {
      path: '/project/exposeRoad',
      component: exposeRoad,
      meta: {
        title: '暴露途径及模型',
        breadtitle:'项目输入/暴露途径及模型',
        id:3
      },
      needProductId:true
    },
    {
      path: '/project/parmas',
      component: ParamsSetting,
      meta:{
        title: "参数设置",
        breadtitle:'项目输入/参数设置',
        id:3
      },
      needProductId:true
    },
    {
      path: '/project/conceptModel',
      component: conceptModel,
      meta:{
        title: "概念模型",
        breadtitle:'项目输出/概念模型',
        id:4
      },
      needProductId:true
    },
  {
    path: '/project/polluteParamsTable',
    component: polluteParamsTable,
    meta:{
      title: "污染物参数表",
      breadtitle:'项目输出/污染物参数表',
      id:4
    },
    needProductId:true
  },
  {
    path: '/project/modelParamsTable',
    component: modelParamsTable,
    meta:{
      title: "模型及参数表",
      breadtitle:'项目输出/模型及参数表',
      id:4
    },
    needProductId:true
  },
  {
    path: '/project/exposedQuantityTable',
    component: exposedQuantityTable,
    meta:{
      title: "暴露量计算",
      breadtitle:'项目输出/暴露量计算',
      id:4
    },
    needProductId:true
  },
  {
    path: '/project/riskCalculation',
    component: riskCalculation,
    meta:{
      title: "风险计算",
      breadtitle:'项目输出/风险计算',
      id:4
    },
    needProductId:true
  },
  {
    path: '/project/ContributionRateCalculation',
    component: ContributionRateCalculation,
    meta:{
      title: "贡献率计算",
      breadtitle:'项目输出/贡献率计算',
      id:4
    },
    needProductId:true
  },
  {
    path: '/project/controlValueCalculation',
    component: controlValueCalculation,
    meta:{
      title: "控制值计算",
      breadtitle:'项目输出/控制值计算',
      id:4
    },
    needProductId:true
  },
  {
    path: '/project/generalReport',
    component: generalReport,
    meta:{
      title: "分析报告",
      breadtitle:'项目输出/总报告',
      id:4
    },
    needProductId:true
  },
  {
    path: '/project/sensitivity',
    component: sensitivity,
    meta:{
      title: "敏感性分析结果",
      breadtitle:'项目输出/敏感性分析结果',
      id:4
    },
    needProductId:true
  },
  {
    path: '/project/getlnitParamsValue',
    component: getlnitParamsValue,
    meta:{
      title: "敏感性分析",
      breadtitle:'项目输出/敏感性分析',
      id:4
    },
    needProductId:true
  },
]