import * as React from "react";
import { Component } from "react";
import { Tabs, Row, Col, Form, Input, Divider, Table, Switch, Space, Spin } from "antd";
import { riskCalculation } from '../../../apis/common'
import { checkProductd ,checkDepthid} from '../../../configs/checkProductId.js'
import "./controlValueCalculation.less";
import {  getSessionLevel } from '../../../utils/token'

const { TabPane } = Tabs;
interface Item {
  value: string
  index: number,
}
export interface Props {
  history: any
}

export interface State {
  project_id: string,
  depth_id:string,
  soilColumns: any,
  soil: any,
  waterColumns: any,
  loading: boolean;
  water: any,
  level: any;
  keySoilFirst: any,
  // keySoilFirst: {
  //   RCVSois: Item
  //   RCVSdcs: Item
  //   RCVSpis: Item
  //   RCVSiov1: Item
  //   RCVSiov2: Item
  //   RCVSiiv1: Item
  // },
  keySoilTwo: any,
  // {
  //   HCVSois: Item
  //   HCVSdcs: Item
  //   HCVSpis: Item
  //   HCVSiov1: Item
  //   HCVSiov2: Item
  //   HCVSiiv1: Item
  // },
  keyWaterFirst:any,
  
  // {
  //   RCVScgw: Item
  //   RCVSiov3: Item
  //   RCVSiiv2: Item
  // },
  keyWaterTwo:any,
  //  {
  //   HCVScgw: Item
  //   HCVSiov3: Item
  //   HCVSiiv2: Item
  // }
}
function expandedData(props: any) {
  const pStyle = {
    fontSize: '14px',
    margin: '10px 0'
  }
  return (
    <div className="controlContent">
      <div className="selectmargin">
        <div className="tableSelect">致癌风险控制值</div>
        <Divider></Divider>
        <Row>
          {

            props.firstsoil.map((value: any, index: any) => {
              return <Col span={8} key={index}>
                <div style={pStyle}>{value}</div>
              </Col>
            })

          }
        </Row>
      </div>
      <div>
        <div className="tableSelect">非致癌风险控制值</div>
        <Divider></Divider>
        <Row>
          {

            props.twosoil.map((value: any, index: any) => {
              return <Col span={8} key={index}>
                <div style={pStyle}>{value}</div>
              </Col>
            })

          }
        </Row>
      </div>
    </div>
  );
}
let soilColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '中文名',
    dataIndex: 'name_cn'
  },
  {
    title: '英文名',
    dataIndex: 'name_en',
  },
  {
    title: 'CAS编号',
    dataIndex: 'cas_code',
  },
  {
    title: '综合致癌控制值',
    dataIndex: 'zhiAiControlValue',
  },
  {
    title: '综合非致癌控制值',
    dataIndex: 'feiZhiAiControlValue',
  },
  {
    title: '风险控制值',
    dataIndex: 'total',
  },
];
let waterColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '中文名',
    dataIndex: 'name_cn'
  },
  {
    title: '英文名',
    dataIndex: 'name_en',
  },
  {
    title: 'CAS编号',
    dataIndex: 'cas_code',
  },
  {
    title: '综合致癌控制值',
    dataIndex: 'zhiAiDXControlValue',
  },
  {
    title: '综合非致癌控制值',
    dataIndex: 'feiZhiAiDXControlValue',
  },
  {
    title: '风险控制值',
    dataIndex: 'total',
  },
];
class modelParamsTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      project_id: '',
      level: '',

      depth_id:'',
      soilColumns: [],
      soil: [],
      waterColumns: [],
      loading: true,
      water: [],
      keySoilFirst: {
        RCVSois: {
          value: '经口摄入:',
          index: 0,
        },
        RCVSdcs: {
          value: '皮肤接触:',
          index: 1,
        },
        RCVSpis: {
          value: '吸入土壤颗粒物:',
          index: 2,
        },
        RCVSiov1: {
          value: '吸入室外空气中来自表层土壤中的气态污染物:',
          index: 3,
        },
        RCVSiov2: {
          value: '吸入室外空气中来自下层土壤中的气态污染物:',
          index: 4,
        },
        RCVSiiv1: {
          value: '吸入室内空气中来自下层土壤中的气态污染物:',
          index: 5,
        },
      },
      keySoilTwo: {
        HCVSois: {
          value: '经口摄入:',
          index: 0,
        },
        HCVSdcs: {
          value: '皮肤接触:',
          index: 1,
        },
        HCVSpis: {
          value: '吸入土壤颗粒物:',
          index: 2,
        },
        HCVSiov1: {
          value: '吸入室外空气中来自表层土壤中的气态污染物:',
          index: 3,
        },
        HCVSiov2: {
          value: '吸入室外空气中来自下层土壤中的气态污染物:',
          index: 4,
        },
        HCVSiiv1: {
          value: '吸入室内空气中来自下层土壤中的气态污染物:',
          index: 5,
        }
      },
      keyWaterFirst: {
        RCVScgw: {
          value: '饮用:',
          index: 0,
        },
        RCVSiov3: {
          value: '室外吸入:',
          index: 1,
        },
        RCVSiiv2: {
          value: '室内吸入:',
          index: 2,
        }
      },
      keyWaterTwo: {
        HCVScgw: {
          value: '饮用:',
          index: 0,
        },
        HCVSiov3: {
          value: '室外吸入:',
          index: 1,
        },
        HCVSiiv2: {
          value: '室内吸入:',
          index: 2,
        },
      }
    };
  }
  componentWillMount() {
    let productId = checkProductd();
    if (productId) {
      this.setState({
        project_id: productId
      })
    }
    let Depthid = checkDepthid();
    console.log("Depthid",Depthid);
    
    if (Depthid) {
      this.setState({
        depth_id: Depthid,
      })
    }
  }
  componentDidMount() {
    this.riskCalculation()
  }
  riskCalculation = async () => {
    console.log("this.state.depth_id",this.state.depth_id);

    let params = {
      project_id: this.state.project_id,
      depth_id: this.state.depth_id,
    }
    try {
      let res = await riskCalculation(params);
      this.setState({
        loading: false
      })
      let risk = res.data.control;
      this.handlesoil(risk)
    } catch (error) {

    }
  }
  handlesoil = (risk: any) => {
    let {
      soil,
      water
    } = risk;
    this.handlesoilFn(soil);
    this.handleWaterFn(water)
  }
  handlesoilFn = (soil: any) => {
    let keySoilFirst = Object.keys(this.state.keySoilFirst);
    let keySoilTwo = Object.keys(this.state.keySoilTwo);
    let firstsoil = new Array()
    let twosoil = new Array()
    let dataSource = new Array()
    let newobject = new Object()
    soil.map((key: any) => {
      Object.assign(newobject, {
        id: key.id,
        name_cn: key.name_cn,
        name_en: key.name_en,
        cas_code: key.cas_code,
        value: key.value,
        zhiAiControlValue: (key.zhiAiControlValue || key.zhiAiControlValue == "0") ? key.zhiAiControlValue.toExponential(2) : '-',
        feiZhiAiControlValue: (key.feiZhiAiControlValue || key.feiZhiAiControlValue == "0") ? key.feiZhiAiControlValue.toExponential(2) : '-',
        total: (key.total || key.total == "0") ? key.total.toExponential(2) : '-'
      })
      keySoilFirst.forEach((sta: any) => {
        Object.keys(key).forEach((id: any) => {
          if (sta == id && (key[sta] || key[sta] == '0')) {
            firstsoil.splice(this.state.keySoilFirst[sta].index, 0, this.state.keySoilFirst[sta].value + (key[sta].toExponential(2)))
            Object.assign(newobject, {
              firstsoil
            })
          } else if (sta == id && key[sta] == '') {
            firstsoil.splice(this.state.keySoilFirst[sta].index, 0, this.state.keySoilFirst[sta].value + '/');
            Object.assign(newobject, {
              firstsoil
            })
          }
        })
      });
      keySoilTwo.forEach((sta: any) => {
        Object.keys(key).forEach((id: any) => {
          if (sta == id && (key[sta] || key[sta] == '0')) {
            twosoil.splice(this.state.keySoilTwo[sta].index, 0, this.state.keySoilTwo[sta].value + (key[sta].toExponential(2)))
            Object.assign(newobject, {
              twosoil
            })
          } else if (sta == id && key[sta] == '') {
            twosoil.splice(this.state.keySoilTwo[sta].index, 0, this.state.keySoilTwo[sta].value + '/');
            Object.assign(newobject, {
              twosoil
            })
          }
        })
      });
      dataSource.push(newobject)
      firstsoil = []
      twosoil = []
      newobject = {}
    });
    console.log(dataSource)
    this.setState({
      soilColumns,
      soil: dataSource
    })
  }
  handleWaterFn = (water: any) => {
    let keyWaterFirst = Object.keys(this.state.keyWaterFirst);
    let keyWaterTwo = Object.keys(this.state.keyWaterTwo);
    let firstsoil = new Array()
    let twosoil = new Array()
    let dataSource = new Array()
    let newobject = new Object()
    water.map((key: any) => {
      Object.assign(newobject, {
        id: key.id,
        name_cn: key.name_cn,
        name_en: key.name_en,
        cas_code: key.cas_code,
        value: key.value,
        zhiAiDXControlValue: (key.zhiAiDXControlValue || key.zhiAiDXControlValue == "0") ? key.zhiAiDXControlValue.toExponential(2) : '-',
        feiZhiAiDXControlValue: (key.feiZhiAiDXControlValue || key.feiZhiAiDXControlValue == "0") ? key.feiZhiAiDXControlValue.toExponential(2) : '-',
        total: (key.total || key.total == "0") ? key.total.toExponential(2) : '-'
      })
      keyWaterFirst.forEach((sta: any) => {
        Object.keys(key).forEach((id: any) => {
          if (sta == id && (key[sta] || key[sta] == '0')) {
            firstsoil.splice(this.state.keyWaterFirst[sta].index, 0, this.state.keyWaterFirst[sta].value + (key[sta].toExponential(2)))
            Object.assign(newobject, {
              firstsoil
            })
          } else if (sta == id && key[sta] == '') {
            firstsoil.splice(this.state.keyWaterFirst[sta].index, 0, this.state.keyWaterFirst[sta].value + '/')
            Object.assign(newobject, {
              firstsoil
            })
          }
        })
      });
      keyWaterTwo.forEach((sta: any) => {
        Object.keys(key).forEach((id: any) => {
          if (sta == id && (key[sta] || key[sta] == '0')) {
            twosoil.splice(this.state.keyWaterTwo[sta].index, 0, this.state.keyWaterTwo[sta].value + (key[sta].toExponential(2)))
            Object.assign(newobject, {
              twosoil
            })
          } else if (sta == id && key[sta] == '') {
            twosoil.splice(this.state.keyWaterTwo[sta].index, 0, this.state.keyWaterTwo[sta].value + '/');
            Object.assign(newobject, {
              twosoil
            })
          }
        })
      });
      dataSource.push(newobject)
      firstsoil = []
      twosoil = []
      newobject = {}
    })
    this.setState({
      waterColumns,
      water: dataSource
    })
  }
  gotoTap = (step: string) => {
    const level = getSessionLevel()

    if (step == 'back') {
      if (level) {
        this.props.history.push({
          pathname: "/project/ContributionRateCalculation",
        })
      } else {
        this.props.history.push({
          pathname: "/project/ContributionRateCalculation",
        })
      }
    }
    if (step == 'next') {
      if (level) {

        if (level == '1') {
          this.props.history.push({
            pathname: "/project/generalReport"
          })
        } else if (level == '2') {
          this.props.history.push({
            pathname: "/project/getlnitParamsValue"


          })
        }
      } else {
        this.props.history.push({
          pathname: "/project/getlnitParamsValue",
          adjustment:true

        })

      }
   
    }
  }
  render() {
    return (
      <div className="ps-content">
        <Spin spinning={this.state.loading}>
          
          <Tabs
            type="card"
            defaultActiveKey="1"
            onChange={(key) => {
              console.log(key);
            }}
          >
            <TabPane tab="土壤" key="1">
              <Table
                columns={this.state.soilColumns}
                dataSource={this.state.soil}
                rowKey={record => record.id}
                bordered
                expandable={{
                  expandedRowRender: record => expandedData(record)
                }}>
              </Table>
            </TabPane>
            <TabPane tab="地下水" key="2">
              <Table
                columns={this.state.waterColumns}
                dataSource={this.state.water}
                rowKey={record => record.id}
                bordered
                expandable={{
                  expandedRowRender: record => expandedData(record)
                }}>
              </Table>
            </TabPane>
          </Tabs>
          <div className="exposebtn">
            <button className="formbtnleft" onClick={this.gotoTap.bind(this, 'back')}>上一步</button>
            <button className="formbtnright" onClick={this.gotoTap.bind(this, 'next')}>下一步</button>
          </div>
        </Spin>
      </div>
    );
  }
}

export default modelParamsTable;
