import LocalStore from './store';
import SessionStore from './sessionstore';
import config from '../configs/index';

export const setToken = (token: string) => LocalStore.setValue(config.TOKEN_KEY, token);

export const getToken: () => string = () => LocalStore.getValue(config.TOKEN_KEY) || '';

export const removeToken = () => LocalStore.removeValue(config.TOKEN_KEY);


export const setDepthid = (Depthid: string) => SessionStore.setSessionValue(config.DEPTH_ID, Depthid);

export const getDepthid: () => string = () => SessionStore.getSessionValue(config.DEPTH_ID) || '';

export const removeDepthid = () => SessionStore.removeSessionValue(config.DEPTH_ID);



export const setSessionProductId = (productId: string) => SessionStore.setSessionValue(config.PRODUCT_ID, productId);

export const getSessionProductId: () => string = () => SessionStore.getSessionValue(config.PRODUCT_ID) || '';

export const removeSessionProductId = () => SessionStore.removeSessionValue(config.PRODUCT_ID);


export const setUser = (userInfo: string) => SessionStore.setSessionValue(config.USER, userInfo);

export const getUser: () => string = () => SessionStore.getSessionValue(config.USER) || '';

export const removeUser = () => SessionStore.removeSessionValue(config.USER);


export const setSessionLevel = (level: string) => SessionStore.setSessionValue(config.LEVEL, level);

export const getSessionLevel: () => string = () => SessionStore.getSessionValue(config.LEVEL) || '';

export const removeSessionLevel= () => SessionStore.removeSessionValue(config.LEVEL);

export const setSessionNumberPlies = (NumberPlies: string) => SessionStore.setSessionValue(config.NUMBERPLIES, NumberPlies);

export const getSessionNumberPlies: () => string = () => SessionStore.getSessionValue(config.NUMBERPLIES) || '';

export const removeSessionNumberPlies= () => SessionStore.removeSessionValue(config.NUMBERPLIES);

export const setSelectedKey = (SelectedKey: string) => SessionStore.setSessionValue(config.SELECTEDKEY, SelectedKey);

export const getSelectedKey: () => string = () => SessionStore.getSessionValue(config.SELECTEDKEY) || '';

export const removeSelectedKey= () => SessionStore.removeSessionValue(config.SELECTEDKEY);


export const setTreeData = (TreeData: string) => SessionStore.setSessionValue(config.TREEDATA, TreeData);

export const getTreeData: () => string = () => SessionStore.getSessionValue(config.TREEDATA) || '';

export const removeTreeData= () => SessionStore.removeSessionValue(config.TREEDATA);