import * as React from "react";
import { withRouter } from "react-router-dom";
import RegistrationForm from "../../components/registerForm"
import "../../assets/styles/register.less";
// import { usersinfoAction} from '../reducers/calculate';
import { connect } from 'react-redux';
import { maximizeWindow, closeWindow, minWindow } from '../../configs/electronHandle'
interface IProps {
  login: any;
  register: any;
  history: any;
  retrieve: any;
}

interface IState {
  registerForm: {
    userName: any,
    phoneNumber: number,
    verifyNumber: number,
    setPassword: number,
    confirmPassword: number,
    checked: boolean
  },
}

interface Props {
  userInfo: any,
  userinfoFun: () => any,
  history: any
}

const mapStateToProps = (state: any) => {
  return {
    userInfo: state.userinfo
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  userinfoFun: () => dispatch({ type: 'USERINFO', plalod: { aaa: 1 } })
});
@(connect(mapStateToProps, mapDispatchToProps,) as any)
class Register extends React.Component<Props, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      registerForm: {
        userName: "",
        phoneNumber: 0,
        verifyNumber: 0,
        setPassword: 0,
        confirmPassword: 0,
        checked: false
      },
    };
  }


  handleLogin = () => {
    const { history } = this.props
    history.push("/login")
  }

  render() {
    return (
      <div className="register-container win-drag">
        <div className="register-container-box">
          <div className="register-container_fl fl">
            <div className="register-container_fl_top_img">
              <img
                src={require("../../assets/images/login/login-fl.png").default}
                alt=""
              />

            </div>
            <div className="register-container_fl_box">


              <div className="register-container_fl_box_center">
                <h3>污染场地风险评估系统</h3>
                <div className="register-container_fl_center_img">
                  <img
                   src={require("../../assets/images/login/蒙版组 10@2x.png").default}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="register-container_fr fr">
            {/* <div className="register-header-button-container">
              <img className="win-no-drag" src={require("../../assets/images/login/min_button.png").default} alt="" onClick={minWindow} />
              <img className="win-no-drag" src={require("../../assets/images/login/close_button.png").default} alt="" onClick={closeWindow} />
            </div> */}

            <div className="register-form">
              <div className="register-header">
                <div className="register-header-title">新用户注册</div>
                <div className="register-header-sub-title win-no-drag">已经有账号?<span onClick={this.handleLogin} style={{ cursor: "pointer", color: "#3F59AE" }}>直接登录</span></div>
              </div>
              <div className="register-space"></div>
              <RegistrationForm props={this} />
              <div className="register-space"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Register);
