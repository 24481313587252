import * as React from "react";
import { Component } from "react";
import { Tabs, Row, Col, Form, Input, Divider, Table, Switch, Space, Spin } from "antd";
import { riskCalculation } from '../../../apis/common'
import { checkProductd, checkDepthid } from '../../../configs/checkProductId.js'
import "./riskCalculation.less";
import { getSessionLevel } from '../../../utils/token'

const { TabPane } = Tabs;
interface Item {
  value: string
  index: number,
}
export interface Props {
  history: any
}

export interface State {
  project_id: string,
  depth_id: string,
  dataSource: any;
  waterCancerColumns: any;
  waterCancer: any;
  waterNotCancerColumns: any,
  waterNotCancer: any,
  soilCancerColumns: any,
  soilCancer: any,
  soilNotCancerColumns: any,
  soilNotCancer: any,
  loading: boolean,
  keySoilCancer: any,
  keySoilNotCancer: any,

  // keySoilCancer: {
  //   CRois: Item
  //   CRdcs: Item
  //   CRpis: Item
  //   CRiov1: Item
  //   CRiov2: Item
  //   CRiiv1: Item
  // },
  // keySoilNotCancer: {
  //   HQois: Item
  //   HQdcs: Item
  //   HQpis: Item
  //   HQiov1: Item
  //   HQiov2: Item
  //   HQiiv1: Item
  // }
}

function expandedData(props: any) {
  const pStyle = {
    fontSize: '14px',
    margin: '10px 0'
  }
  return (
    <Row>
      {
        props.content.map((value: any) => {
          return <Col span={8}>
            <div style={pStyle}>{value}</div>
          </Col>
        })
      }
    </Row>
  );
}
let waterCancerColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '中文名',
    dataIndex: 'name_cn'
  },
  {
    title: '英文名',
    dataIndex: 'name_en',
  },
  {
    title: 'CAS编号',
    dataIndex: 'cas_code',
  },
  {
    title: '暴露浓度',
    dataIndex: 'value',
  },
  {
    title: '饮用',
    dataIndex: 'CRcgw',
  },
  {
    title: '室外吸入',
    dataIndex: 'CRiov3',
  },
  {
    title: '室内吸入',
    dataIndex: 'CRiiv2',
  },
  {
    title: '总风险水平',
    dataIndex: 'zhiAiDXValue',
  },
];
let waterNotCancerColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '中文名',
    dataIndex: 'name_cn'
  },
  {
    title: '英文名',
    dataIndex: 'name_en',
  },
  {
    title: 'CAS编号',
    dataIndex: 'cas_code',
  },
  {
    title: '暴露浓度',
    dataIndex: 'value',
  },
  {
    title: '饮用',
    dataIndex: 'HQcgw',
  },
  {
    title: '室外吸入',
    dataIndex: 'HQiov3',
  },
  {
    title: '室内吸入',
    dataIndex: 'HQiiv2',
  },
  {
    title: '总危害指数',
    dataIndex: 'feiZhiAiDXValue',
  },
];
let soilCancerColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '中文名',
    dataIndex: 'name_cn'
  },
  {
    title: '英文名',
    dataIndex: 'name_en',
  },
  {
    title: 'CAS编号',
    dataIndex: 'cas_code',
  },
  {
    title: '暴露含量',
    dataIndex: 'value',
  },
  {
    title: '总风险水平',
    dataIndex: 'zhiAiValue',
  },
];
let soilNotCancerColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '中文名',
    dataIndex: 'name_cn'
  },
  {
    title: '英文名',
    dataIndex: 'name_en',
  },
  {
    title: 'CAS编号',
    dataIndex: 'cas_code',
  },
  {
    title: '暴露含量',
    dataIndex: 'value',
  },
  {
    title: '总危害指数',
    dataIndex: 'feiZhiAiValue',
  },
];
class modelParamsTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dataSource: [
        {
          key: 1,
          NUMBER: 1,
          NAME_CN: 'John Brown sr.',
          NAME_EN: 60,
          CAS_CODE: 'New York No. 1 Lake Park',
          content: ['中文名：四氯甲烷', '英文名：Tetrachloroethane,1,1', 'CAS编号：630-20-6', '亨利常数数据来源：国家标准', 'Da(cm²/s)：85']
        }
      ],
      project_id: '',
      depth_id: '',
      waterCancerColumns: [],
      waterCancer: [],
      waterNotCancerColumns: [],
      waterNotCancer: [],
      soilCancerColumns: [],
      soilCancer: [],
      soilNotCancerColumns: [],
      soilNotCancer: [],
      loading: true,
      keySoilCancer: {
        CRois: {
          value: '经口摄入:',
          index: 0,
        },
        CRdcs: {
          value: '皮肤接触:',
          index: 1,
        },
        CRpis: {
          value: '吸入土壤颗粒物:',
          index: 2,
        },
        CRiov1: {
          value: '吸入室外空气中来自表层土壤中的气态污染物:',
          index: 3,
        },
        CRiov2: {
          value: '吸入室外空气中来自下层土壤中的气态污染物:',
          index: 4,
        },
        CRiiv1: {
          value: '吸入室内空气中来自下层土壤中的气态污染物:',
          index: 5,
        }
      },
      keySoilNotCancer: {
        HQois: {
          value: '经口摄入:',
          index: 0,
        },
        HQdcs: {
          value: '皮肤接触:',
          index: 1,
        },
        HQpis: {
          value: '吸入土壤颗粒物:',
          index: 2,
        },
        HQiov1: {
          value: '吸入室外空气中来自表层土壤中的气态污染物:',
          index: 3,
        },
        HQiov2: {
          value: '吸入室外空气中来自下层土壤中的气态污染物:',
          index: 4,
        },
        HQiiv1: {
          value: '吸入室内空气中来自下层土壤中的气态污染物:',
          index: 5,
        }
      }
    };
  }
  componentWillMount() {
    let productId = checkProductd();
    if (productId) {
      this.setState({
        project_id: productId
      })
    }
    let Depthid = checkDepthid();
    if (Depthid) {
      this.setState({
        depth_id: Depthid,
      })
    }

  }
  componentDidMount() {
    this.riskCalculation()
  }
  riskCalculation = async () => {

    let params = {
      project_id: this.state.project_id,
      depth_id: this.state.depth_id,
    }
    try {
      let res = await riskCalculation(params);
      let risk = res.data.risk;
      this.setState({
        loading: false
      })
      this.handlewater(risk)
      this.handlesoil(risk)
    } catch (error) {
      console.log("this.state.depth_id", this.state.depth_id);

      console.log(error)
    }
  }
  handlewater = (risk: any) => {
    let {
      water: {
        cancer: waterCancer,
        not_cancer: waterNotCancer
      }
    } = risk;
    this.handlewaterCancer(waterCancer)
    this.handlewaterNotCancer(waterNotCancer)
  }
  handlewaterCancer = (waterCancer: any) => {
    let dataSource = new Array()
    let newobject = new Object()
    waterCancer.map((key: any) => {
      console.log(key.CRiiv2 == 0)
      Object.assign(newobject, {

        id: key.id,
        name_cn: key.name_cn,
        name_en: key.name_en,
        cas_code: key.cas_code,
        value: key.value,
        CRcgw: (key.CRcgw || key.CRcgw == "0") ? key.CRcgw.toExponential(2) : '-',
        CRiiv2: (key.CRiiv2 || key.CRiiv2 == "0") ? key.CRiiv2.toExponential(2) : '-',
        CRiov3: (key.CRiov3 || key.CRiov3 == "0") ? key.CRiov3.toExponential(2) : '-',
        zhiAiDXValue: (key.zhiAiDXValue || key.zhiAiDXValue == "0") ? key.zhiAiDXValue.toExponential(2) : '-'
      })
      dataSource.push(newobject)
      newobject = {}
    })
    this.setState({
      waterCancerColumns,
      waterCancer: dataSource
    })
  }
  handlewaterNotCancer = (waterNotCancer: any) => {
    let dataSource = new Array()
    let newobject = new Object()
    waterNotCancer.map((key: any) => {
      Object.assign(newobject, {
        id: key.id,
        name_cn: key.name_cn,
        name_en: key.name_en,
        cas_code: key.cas_code,
        value: key.value,
        HQcgw: (key.HQcgw || key.HQcgw == "0") ? key.HQcgw.toExponential(2) : '-',
        HQiiv2: (key.HQiiv2 || key.HQiiv2 == "0") ? key.HQiiv2.toExponential(2) : '-',
        HQiov3: (key.HQiov3 || key.HQiov3 == "0") ? key.HQiov3.toExponential(2) : '-',
        feiZhiAiDXValue: (key.feiZhiAiDXValue || key.feiZhiAiDXValue == "0") ? key.feiZhiAiDXValue.toExponential(2) : '-'
      })

      dataSource.push(newobject)
      newobject = {}
    })
    this.setState({
      waterNotCancerColumns,
      waterNotCancer: dataSource,
    })
  }
  handlesoil = (risk: any) => {
    let {
      soil: {
        cancer: soilCancer,
        not_cancer: soilNotCancer
      }
    } = risk;
    this.handleSoilCancer(soilCancer)
    this.handleSoilNotCancer(soilNotCancer)
  }
  handleSoilCancer = (soilCancer: any) => {
    let standard = Object.keys(this.state.keySoilCancer);
    let content = new Array()
    let dataSource = new Array()
    let newobject = new Object()
    soilCancer.map((key: any) => {
      Object.assign(newobject, {
        id: key.id,
        name_cn: key.name_cn,
        name_en: key.name_en,
        cas_code: key.cas_code,
        value: key.value,
        zhiAiValue: (key.zhiAiValue || key.zhiAiValue == "0") ? key.zhiAiValue.toExponential(2) : '-'
      })
      standard.forEach((sta: any) => {
        Object.keys(key).forEach((id: any) => {
          if (sta == id && (key[sta] || key[sta] == '0')) {
            content.splice(this.state.keySoilCancer[sta].index, 0, this.state.keySoilCancer[sta].value + (key[sta].toExponential(2)))
            Object.assign(newobject, {
              content
            })
          } else if (sta == id && key[sta] == "") {
            content.splice(this.state.keySoilCancer[sta].index, 0, this.state.keySoilCancer[sta].value + "/")
            Object.assign(newobject, {
              content
            })
          }
        })
      });
      dataSource.push(newobject);
      content = []
      newobject = {}
    })
    this.setState({
      soilCancerColumns,
      soilCancer: dataSource
    })
  }
  handleSoilNotCancer = (soilNotCancer: any) => {
    let standard = Object.keys(this.state.keySoilNotCancer);
    let content = new Array()
    let dataSource = new Array()
    let newobject = new Object()
    soilNotCancer.map((key: any) => {
      Object.assign(newobject, {
        id: key.id,
        name_cn: key.name_cn,
        name_en: key.name_en,
        cas_code: key.cas_code,
        value: key.value,
        feiZhiAiValue: (key.feiZhiAiValue || key.feiZhiAiValue == "0") ? key.feiZhiAiValue.toExponential(2) : '-'
      })
      standard.forEach((sta: any) => {
        Object.keys(key).forEach((id: any) => {
          if (sta == id && (key[sta] || key[sta] == '0')) {
            content.splice(this.state.keySoilNotCancer[sta].index, 0, this.state.keySoilNotCancer[sta].value + (key[sta].toExponential(2)))
            Object.assign(newobject, {
              content
            })
          } else if (sta == id && key[sta] == "") {
            content.splice(this.state.keySoilNotCancer[sta].index, 0, this.state.keySoilNotCancer[sta].value + "/")
            Object.assign(newobject, {
              content
            })
          }
        })
      });
      dataSource.push(newobject)
      content = []
      newobject = {}
    })
    this.setState({
      soilNotCancerColumns,
      soilNotCancer: dataSource,
    })
  }
  gotoTap = (step: string) => {
    let level = getSessionLevel()

    if (step == 'back') {
      if (level) {
        this.props.history.push({
          pathname: "/project/exposedQuantityTable",
        })
        return
      } else {
        this.props.history.push({
          pathname: "/project/exposedQuantityTable",
        })
      }
    }
    if (step == 'next') {
      this.props.history.push({
        pathname: "/project/ContributionRateCalculation"
      })
    }
  }
  render() {
    const columns = [
      {
        title: '序号',
        dataIndex: 'NUMBER',
        key: 'NUMBER',
      },
      {
        title: '中文名',
        dataIndex: 'NAME_CN'
      },
      {
        title: '英文名',
        dataIndex: 'NAME_EN',
      },
      {
        title: 'CAS编号',
        dataIndex: 'CAS_CODE',
      },
    ];
    return (
      <div className="ps-content">
        <Spin spinning={this.state.loading}>
          <Tabs
            type="card"
            defaultActiveKey="1"
            onChange={(key) => {
              console.log(key);
            }}
          >
            <TabPane tab="土壤" key="1">
              <div className="setting">
                <div className="modeliconleft">致癌风险水平</div>
              </div>
              <Divider></Divider>
              <Table
                className="Cancer_Render"
                columns={this.state.soilCancerColumns}//表格列的配置描述，具体项见下表
                dataSource={this.state.soilCancer}//数据数组
                rowKey={record => record.id}	//表格行 key 的取值，可以是字符串或一个函数
                bordered
                expandable={{//配置展开属性
                  expandedRowRender: record => expandedData(record)
                }}>
              </Table>
              <div className="setting">
                <div className="modeliconleft">非致癌危害指数</div>
              </div>
              <Divider></Divider>
              <Table
                className="Cancer_Render"
                columns={this.state.soilNotCancerColumns}
                dataSource={this.state.soilNotCancer}
                rowKey={record => record.id}
                bordered
                expandable={{
                  expandedRowRender: record => expandedData(record)
                }}>
              </Table>
            </TabPane>
            <TabPane tab="地下水" key="2">
              <div className="setting">
                <div className="modeliconleft">致癌风险水平</div>
              </div>
              <Divider></Divider>
              <Table
                columns={this.state.waterCancerColumns}
                dataSource={this.state.waterCancer}
                rowKey={record => record.id}
                bordered
              >
              </Table>
              <div className="setting">
                <div className="modeliconleft">非致癌危害指数</div>
              </div>
              <Divider></Divider>
              <Table
                columns={this.state.waterNotCancerColumns}
                dataSource={this.state.waterNotCancer}
                rowKey={record => record.id}
                bordered
              >
              </Table>
            </TabPane>
          </Tabs>
          <div className="exposebtn">
            <button className="formbtnleft" onClick={this.gotoTap.bind(this, 'back')}>上一步</button>
            <button className="formbtnright" onClick={this.gotoTap.bind(this, 'next')}>下一步</button>
          </div>
        </Spin>
      </div>
    );
  }
}

export default modelParamsTable;
