import * as React from "react";
import { message,Spin,Divider} from "antd";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import watermark from 'watermark-dom';
// import * as utils from '../../../../public/watermark.js';
import "./generalReport.less";
import  ReportTable from '../../../components/reportTable/reportTable'
import {date} from '../../../configs/filterTime'
import {levelAnalysis} from '../../../apis/common'
import {setSessionLevel} from '../../../utils/token'
import eventBus from '../../../configs/events';
export interface Props {
  props:any
  base_info:any;
  // data_list:any;
  soil_pollutant:any,
  water_pollutant:any,
  project_id:any;
}

export interface State {
  pdfs:any;
}

class firstLevel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      pdfs:React.createRef(),
    };
  }
  componentDidMount(){
  //   watermark.init({
  //     watermark_id: 'wm_div_id',          //水印总体的id
  //     watermark_prefix: 'mask_div_id',    //小水印的id前缀
  //     watermark_txt:"CRISK",             //水印的内容
  //     watermark_x:250,                     //水印起始位置x轴坐标
  //     watermark_y:20,                     //水印起始位置Y轴坐标
  //     watermark_rows:0,                   //水印行数
  //     watermark_cols:0,                   //水印列数
  //     watermark_x_space:10,              //水印x轴间隔
  //     watermark_y_space:10,               //水印y轴间隔
  //     watermark_font:'微软雅黑',           //水印字体
  //     watermark_color:'#246bba',            //水印字体颜色
  //     watermark_fontsize:'18px',          //水印字体大小
  //     watermark_alpha:0.12,               //水印透明度，要求设置在大于等于0.005
  //     watermark_width:100,                //水印宽度
  //     watermark_height:100,               //水印长度
  //     watermark_angle:15,                 //水印倾斜度数
  //     watermark_parent_width:0,      //水印的总体宽度（默认值：body的scrollWidth和clientWidth的较大值）
  //     watermark_parent_height:0,     //水印的总体高度（默认值：body的scrollHeight和clientHeight的较大值）
  //     watermark_parent_node:"div#test"  //水印插件挂载的父元素element,不输入则默认挂在body上
  //  })
  // watermark.load({ watermark_parent_node: this.state.pdfs, watermark_txt: "saucx" });

  }
  componentWillUnmount(){
    // watermark.remove();
  }
  filtterType = (type:any) => {
    if(type=='1'){
      return '第一类用地'
    }else if(type=='2'){
      return '第二类用地'
    }else{
      return ''
    }
  }
  nextStep = async(value:any)=>{
    let mycheck={
      clicktwoActive:true,
      clickoneActive:false
     }
    switch (value) {
      case 'previous':
        this.props.props.history.goBack()
        break;
      case 'analysis':
        await levelAnalysis({    
          level:2,
          project_id:this.props.project_id
        }).then((res:any)=>{
          setSessionLevel('2')
          eventBus.emit('changeTabSelect',mycheck);
          setTimeout(()=>{
            this.props.props.history.push({
              pathname:"/project/exposeRoad"
            })
          },200)
        }).catch((err:any)=>{
          message.warn('服务器异常，请稍后再试')
        })
        break;
      case 'pdf':
        this.getPdf()
        // watermark.load({ watermark_parent_node: ".reportPdfContent", watermark_txt: "saucxs，1021002301，测试水印，100101010111101" });
        break;
      case 'word':
        
        break;
      default:
        break;
    }
  }
  getPdf(){
    html2canvas(this.state.pdfs.current).then(function (canvas) {
      const contentWidth = canvas.width;
      const contentHeight = canvas.height;

      const pageData = canvas.toDataURL('image/jpeg', 1.0);

      let pdfX = Number();
      let pdfY=0
      if(contentHeight<=1200){
       pdfY = 1200;// 500为底部留白
       pdfX=contentWidth*0.75
      }else{
       pdfY = contentHeight;
       pdfX = contentWidth;

      }
      const imgX = pdfX;
      const imgY = pdfY; //内容图片这里不需要留白的距离
      const PDF = new jsPDF('p', 'pt', [pdfX, pdfY]);

      PDF.addImage(pageData, 'jpeg', 0, 0, imgX, imgY);
      // var totalPages = PDF.getNumberOfPages();
      // for (let i = 0; i <= totalPages; i++) {
      //   PDF.setPage(i);
      //   PDF.setFontSize(20)
      //   PDF.setTextColor(150);
      //   PDF.text('50', 80,30);
      // }
      PDF.save('report.pdf');
    });
  }
  render() {
    const soil_columns = [
      {
        title: '序号',
        dataIndex: 'no',
        className:'column-money'
      },
      {
        title: '污染物名称',
        dataIndex: 'name_cn',
        className:'column-money'
      },
      {
        title: 'CAS编号',
        dataIndex: 'cas_code',
        className:'column-money'
      },
      {
        title: '最大暴露含量(mg.kg-1)',
        dataIndex: 'concentration',
        className:'column-money'
      },
      {
        title: '背景值',
        className: 'column-money',
        dataIndex: 'background_value'
      },
      {
        title: '是否标准库',
        dataIndex: 'is_standard',
        className:'column-money'
      },
      {
        title: '筛选值(mg.kg-1)',
        dataIndex: 'screen',
        className:'column-money'
      },
      {
        title: '管制值(mg.kg-1)',
        dataIndex: 'control',
        className:'column-money'
      },
      {
        title: '筛选值来源',
        dataIndex: 'source',
        className:'column-money'
      },
      {
        title: '筛选结果',
        dataIndex: 'conclusion',
        className:'column-money',
        isColor:true
      },
      {
        title: '是否列为关注污染物',
        dataIndex: 'attention',
        className:'column-money',
      }
    ];
    const water_columns = [
      {
        title: '序号',
        dataIndex: 'no',
        className:'column-money'
      },
      {
        title: '污染物名称',
        dataIndex: 'name_cn',
        className:'column-money'
      },
      {
        title: 'CAS编号',
        dataIndex: 'cas_code',
        className:'column-money'
      },
      {
        title: '最大暴露浓度(mg.kg-1)',
        dataIndex: 'concentration',
        className:'column-money'
      },
      {
        title: '背景值',
        className: 'column-money',
        dataIndex: 'background_value'
      },
      {
        title: '是否标准库',
        dataIndex: 'is_standard',
        className:'column-money'
      },
      {
        title: 'III类标准值(mg.kg-1)',
        dataIndex: 'three',
        className:'column-money'
      },
      {
        title: 'IV类标准值(mg.kg-1)',
        dataIndex: 'four',
        className:'column-money'
      },
      {
        title: '筛选值来源',
        dataIndex: 'source',
        className:'column-money'
      },
      {
        title: '超出标准限值',
        dataIndex: 'conclusion',
        className:'column-money',
        isColor:true
      },
      {
        title: '是否列为关注污染物',
        dataIndex: 'attention',
        className:'column-money',
      }
    ];
    return (
      <div className="report">
        <Spin spinning={!this.props.base_info.report_time}>
        <div ref={this.state.pdfs}>
          <div className="reportPdfContent">
            <div className="reportMarign">
              <img src={require('../../../assets/images/report/logo.png').default} className="report-img"></img>
              <Divider></Divider>
            </div>
            <div className="report-title">第一层次项目分析报告</div>
            <div className="report-time">生成报告日期：{date("Y-m-d H:i:s",parseInt(this.props.base_info.report_time))}</div>
            <div className="report-content">
              <div className="report-area">
                <div className="area-title">
                  <div className="area-top">项目基本信息</div>
                  <div className="area-btm">ESSENTIAL INFORMATION</div>
                </div>
                <table id="tfhover" className="tfhead">
                  <thead>
                    <tr>
                      <th>项目名称</th>
                      <td>{this.props.base_info.project_name}</td>
                      <th>评估时间</th>
                      <td>{date("Y-m-d",parseInt(this.props.base_info.project_time))}</td>
                    </tr>
                  </thead>  
                  <tbody>
                    <tr>
                      <th>用地方式</th>
                      <td>{this.filtterType(this.props.base_info.project_type)}</td>
                      <th>项目地址</th>
                      <td>{this.props.base_info.project_address}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
             </div>
            <div className="report-content">
            <div className="report-area">
              <div className="area-title">
                <div className="area-top">项目污染物信息(土壤)</div>
                <div className="area-btm">ENVIRONMENTAL PROJECT POLLUTANT INFORMATION(SOIL)</div>
              </div>
              <ReportTable 
                columns={soil_columns}
                dataSource={this.props.soil_pollutant}
              ></ReportTable>
            </div>
          </div>  
          <div className="report-content">
            <div className="report-area">
              <div className="area-title">
                <div className="area-top">项目污染物信息(地下水)</div>
                <div className="area-btm">ENVIRONMENTAL PROJECT POLLUTANT INFORMATION(GROUNDWATER)</div>
              </div>
              <ReportTable 
                columns={water_columns}
                dataSource={this.props.water_pollutant}
              ></ReportTable>
            </div>
          </div> 
          </div>
        </div>
        <div className="report-btn">
          <div className="laststep" onClick={this.nextStep.bind(this,'previous')}>
            上一步
          </div>
          <div className="twolevel" onClick={this.nextStep.bind(this,'analysis')}>
            <img src={require('../../../assets/images/report/dier.png').default}></img>
            <span>第二层次分析</span>
          </div>
          <div className="pdf" onClick={this.nextStep.bind(this,'pdf')}>
            <img src={require('../../../assets/images/report/report_pdf.png').default}></img>
            <span>导出PDF</span>
          </div>
          {/* <div className="word" onClick={this.getPdf.bind(this)}>
            <img src={require('../../../assets/images/report/word.png').default}></img>
            <span>导出WORD</span>
          </div> */}
        </div>
        </Spin>
      </div>
    );
  }
}

export default firstLevel;
