import { AnyAction } from 'redux';
import { getToken, setToken, removeToken,setUser,removeUser,getUser } from '../utils/token';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_USER_LOGOUT = 'SET_USER_LOGOUT';

export const SELECT_ROWSIDS = 'SELECT_ROWSIDS';

export interface UserState {
    token: string;
    user: any
}

const initialState = {
    token: getToken(),
    user:getUser()
}
export const setUserInfo =(state: UserState = initialState, action: AnyAction)=>{
    const { type, plalod } = action;
    switch (type) {
        case SET_USER_INFO:
            setToken(plalod.token);
            setUser(plalod.user);  
            return plalod
        case SET_USER_LOGOUT:
            removeToken();
            removeUser();  
            return initialState
        default:
          return state
            
    }
}

