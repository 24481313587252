import * as React from "react";
import { Table, Spin, Divider, Button, Modal, Input } from "antd";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import watermark from 'watermark-dom';
import "./generalReport.less";
import ReportTable from '../../../components/reportTable/reportTable'
import ReportTableCheck from '../../../components/reportTableCheck/reportTableCheck'
import Model from '../../../components/controlModel/controlModel'
// import LineChart from '../../../components/lineChart/lineChart'
import LineImg from '../../../components/lineImg/lineImg'
import { date } from '../../../configs/filterTime'
import { setaddParam, saveExpertConsultation } from '../../../apis/common'
import { checkProductd } from '../../../configs/checkProductId.js'

const { Column, ColumnGroup } = Table;
const { TextArea } = Input;
export interface Props {
  props: any
  base_info: any;
  soil_pollutant: any;
  water_pollutant: any;
  all_param_value: any;
  physical: any,
  project_model: any,
  toxicity: any,
  soilControl: any,
  waterControl: any,
  soilCancer: any,
  soilNotCancer: any,
  exsoilCancer: any,
  exsoilNotCancer: any,
  exwaterCancer: any,
  exwaterNotCancer: any,
  contributionsoil: any,
  contributionsoilNot: any,
  contributionwater: any,
  contributionwaterNot: any,
  graphicArr: any,
  report_water: any,
  project_id: any,
  token: string,
  SessionProductId: string,
  User: any,

  soil_cancer_sensitivity: any,
  soil_not_cancer_sensitivity: any,
  water_cancer_sensitivity: any,
  water_not_cancer_sensitivity: any,
  soil_cancer_sensitivityColumns: any,
  soil_not_cancer_sensitivityColumns: any,
  water_cancer_sensitivityColumns: any,
  water_not_cancer_sensitivityColumns: any,
}

export interface State {
  loading: any,
  pdfs: any;
  consultData: any,
  project_id: string,
  content: string,
  phone: string,

}

class twoLevel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      pdfs: React.createRef(),
      consultData: false,
      project_id: '',
      content: '',
      phone: '',

    };
  }
  filtterType = (type: any) => {
    if (type == '1') {
      return '第一类用地'
    } else if (type == '2') {
      return '第二类用地'
    } else {
      return ''
    }
  }
  nextStep = (value: any) => {
    switch (value) {
      case 'previous':
        this.props.props.history.goBack()
        break;
      case 'analysis':

        break;
      case 'pdf':
        this.getPdf()
        break;
      case 'charge':
        this.gitCharge()
        break;
      case 'consult':
        this.getConsult()
        break;
      case 'word':

        break;
      default:
        break;
    }
  }
  componentWillMount() {
    let productId = checkProductd();
    if (productId) {
      this.setState({
        project_id: productId
      })
    }
  }
  getConsult() {
    this.usercenter(true)

  }
  getPdf() {
    html2canvas(this.state.pdfs.current).then(function (canvas) {
      const contentWidth = canvas.width;
      const contentHeight = canvas.height;
      console.log(contentWidth, contentHeight, '-===')
      const pageData = canvas.toDataURL('image/jpeg', 1.0);
      console.log("少时诵诗书少时诵诗书是撒是撒是撒是撒是撒是撒是撒", contentWidth, contentHeight, pageData);
     
      // const pdfX = (contentWidth + 0) / 2 * 0.75;
      // const pdfY = (contentHeight + 700) / 2 * 0.75 ;// 500为底部留白
      // const imgX = pdfX;
      // const imgY = (contentHeight / 2 * 0.75); //内容图片这里不需要留白的距离

      // const pdfX = (contentWidth)/2*0.75;
      // const pdfY = (contentHeight ) /2*0.75;// 500为底部留白
      // const imgX = pdfX;
      // const imgY =  (contentHeight / 2 * 0.75); //内容图片这里不需要留白的距离

      const pdfX = (contentWidth) / 2 * 0.75;
      const pdfY = (contentHeight) / 2 * 0.75;// 500为底部留白
      const imgX = pdfX;
      const imgY = (contentHeight / 2 * 0.75); //内容图片这里不需要留白的距离
      // const pdfX = contentWidth;
      // const pdfY = contentHeight;// 500为底部留白
      // const imgX = pdfX;
      // const imgY = pdfY; //内容图片这里不需要留白的距离
      

      const PDF = new jsPDF('p', 'pt', [pdfX, pdfY]);
      PDF.addImage(pageData, 'jpeg', 0, 0, imgX, imgY);
      // var totalPages = PDF.getNumberOfPages();
      // for (let i = 0; i <= totalPages; i++) {
      //   PDF.setPage(i);
      //   PDF.setFontSize(20)
      //   PDF.setTextColor(150);
      //   PDF.text('50', 80,30);
      // }
      PDF.save('report.pdf');
    });
  }
  gitCharge() {
    window.open("http://www.dsenv.cn/?userToken=" + this.props.token + '&id=' + JSON.stringify(this.props.SessionProductId) + '&acc=' + this.props.User.account + '#/MyOrder');

  }
  confirmSubmit = async () => {
    console.log("this.props", this.props);

    await saveExpertConsultation({
      token: this.props.token,
      question: this.state.content,
      phone: this.state.phone,
      project_id: this.state.project_id,
      user_id: 5
    }).then((res: any) => {
      this.usercenter(false)

    }).catch((err: any) => {

    })

  }
  onChangeArea = (e: any) => {
    let value = e.target.value;

    this.setState({
      content: value
    })
  };
  onChangephone = (e: any) => {
    let value = e.target.value;

    this.setState({
      phone: value
    })
  };
  usercenter = (value: boolean) => {

    this.setState({
      consultData: value
    })
  }
  render() {
    const soil_columns = [
      {
        title: '序号',
        dataIndex: 'no',
        className: 'column-money'
      },
      {
        title: '污染物名称',
        dataIndex: 'name_cn',
        className: 'column-money'
      },
      {
        title: 'CAS编号',
        dataIndex: 'cas_code',
        className: 'column-money'
      },
      {
        title: '最大暴露含量(mg.kg-1)',
        dataIndex: 'concentration',
        className: 'column-money'
      },
      {
        title: '背景值',
        className: 'column-money',
        dataIndex: 'background_value'
      },
      {
        title: '是否标准库',
        dataIndex: 'is_standard',
        className: 'column-money'
      },
      {
        title: '筛选值(mg.kg-1)',
        dataIndex: 'screen',
        className: 'column-money'
      },
      {
        title: '管制值(mg.kg-1)',
        dataIndex: 'control',
        className: 'column-money'
      },
      {
        title: '筛选值来源',
        dataIndex: 'source',
        className: 'column-money'
      },
      {
        title: '筛选结果',
        dataIndex: 'conclusion',
        className: 'column-money',
        isColor: true
      },
      {
        title: '是否列为关注污染物',
        dataIndex: 'attention',
        className: 'column-money',
      }
    ];
    const water_columns = [
      {
        title: '序号',
        dataIndex: 'no',
        className: 'column-money'
      },
      {
        title: '污染物名称',
        dataIndex: 'name_cn',
        className: 'column-money'
      },
      {
        title: 'CAS编号',
        dataIndex: 'cas_code',
        className: 'column-money'
      },
      {
        title: '最大暴露浓度(mg.kg-1)',
        dataIndex: 'concentration',
        className: 'column-money'
      },
      {
        title: '背景值',
        className: 'column-money',
        dataIndex: 'background_value'
      },
      {
        title: '是否标准库',
        dataIndex: 'is_standard',
        className: 'column-money'
      },
      {
        title: 'III类标准值(mg.kg-1)',
        dataIndex: 'three',
        className: 'column-money'
      },
      {
        title: 'IV类标准值(mg.kg-1)',
        dataIndex: 'four',
        className: 'column-money'
      },
      {
        title: '筛选值来源',
        dataIndex: 'source',
        className: 'column-money'
      },
      {
        title: '超出标准限值',
        dataIndex: 'conclusion',
        className: 'column-money',
        isColor: true
      },
      {
        title: '是否列为关注污染物',
        dataIndex: 'attention',
        className: 'column-money',
      }
    ];
    const parameter = [
      {
        title: '序号',
        dataIndex: 'id',
        className: 'column-money'
      },
      {
        title: '参数符号',
        dataIndex: 'symbol',
        className: 'column-money'
      },
      {
        title: '参数名称',
        dataIndex: 'meaning',
        className: 'column-money'
      },
      {
        title: '实际取值',
        dataIndex: 'real',
        className: 'column-money',
        isColor: true
      },
      {
        title: '推荐值',
        dataIndex: 'default',
        className: 'column-money'
      }
    ];
    const toxicity = [
      {
        title: '序号',
        dataIndex: 'ID',
        className: 'column-money'
      },
      {
        title: '中文名',
        dataIndex: 'NAME_CN',
        className: 'column-money'
      },
      {
        title: '英文名',
        dataIndex: 'NAME_EN',
        className: 'column-money'
      },
      {
        title: 'CAS编号',
        dataIndex: 'CAS_CODE',
        className: 'column-money'
      },
      {
        title: 'Sfo(mg/kg-d)-1',
        dataIndex: 'SFO',
        className: 'column-money'
      },
      {
        title: 'IUR(mg/m3)-1',
        dataIndex: 'IUR',
        className: 'column-money'
      },
      {
        title: 'RfDo(mg/kg-d)',
        dataIndex: 'RFDO',
        className: 'column-money'
      },
      {
        title: 'RfC(mg/m3)',
        dataIndex: 'RFC',
        className: 'column-money'
      },
      {
        title: 'ABSd(无量纲)',
        dataIndex: 'ABSD',
        className: 'column-money'
      },
      {
        title: 'ABSgi(无量纲)',
        dataIndex: 'ABSGI',
        className: 'column-money'
      }
    ];
    const physical = [
      {
        title: '序号',
        dataIndex: 'ID',
        className: 'column-money'
      },
      {
        title: '中文名',
        dataIndex: 'NAME_CN',
        className: 'column-money'
      },
      {
        title: '英文名',
        dataIndex: 'NAME_EN',
        className: 'column-money'
      },
      {
        title: 'CAS编号',
        dataIndex: 'CAS_CODE',
        className: 'column-money'
      },
      {
        title: 'Hstrike',
        dataIndex: 'HSTRIKE',
        className: 'column-money'
      },
      {
        title: 'Da(cm2/s)',
        dataIndex: 'DA',
        className: 'column-money'
      },
      {
        title: 'Dw(cm2/s)',
        dataIndex: 'DW',
        className: 'column-money'
      },
      {
        title: 'Koc(cm3/g)',
        dataIndex: 'KOC',
        className: 'column-money'
      },
      {
        title: 'Kd',
        dataIndex: 'KD',
        className: 'column-money'
      },
      {
        title: 'S(mg / L)',
        dataIndex: 'S',
        className: 'column-money'
      }
    ]
    const cancerRiskSoil = [
      {
        title: '序号',
        dataIndex: 'id',
        className: 'column-money'
      },
      {
        title: '中文名',
        dataIndex: 'name_cn',
        className: 'column-money'
      },
      {
        title: '英文名',
        dataIndex: 'name_en',
        className: 'column-money'
      },
      {
        title: 'CAS编号',
        dataIndex: 'cas_code',
        className: 'column-money'
      },
      {
        title: '暴露含量',
        dataIndex: 'value',
        className: 'column-money'
      },
      {
        title: '致癌经口摄入',
        dataIndex: 'CRois',
        className: 'column-money'
      },
      {
        title: '致癌皮肤接触',
        dataIndex: 'CRdcs',
        className: 'column-money'
      },
      {
        title: '致癌吸入土壤颗粒物',
        dataIndex: 'CRpis',
        className: 'column-money'
      },
      {
        title: '致癌吸入室外空气中来自表层土壤中的气态污染物',
        dataIndex: 'CRiov1',
        className: 'column-money'
      },
      {
        title: '致癌吸入室外空气中来自下层土壤中的气态污染物',
        dataIndex: 'CRiov2',
        className: 'column-money'
      },
      {
        title: '致癌吸入室内空气中来自下层土壤中的气态',
        dataIndex: 'CRiiv1',
        className: 'column-money'
      },
      {
        title: '总风险水平',
        dataIndex: 'zhiAiValue',
        className: 'column-money'
      }
    ]
    const soilNotCancer = [
      {
        title: '序号',
        dataIndex: 'id',
        className: 'column-money'
      },
      {
        title: '中文名',
        dataIndex: 'name_cn',
        className: 'column-money'
      },
      {
        title: '英文名',
        dataIndex: 'name_en',
        className: 'column-money'
      },
      {
        title: 'CAS编号',
        dataIndex: 'cas_code',
        className: 'column-money'
      },
      {
        title: '暴露含量',
        dataIndex: 'value',
        className: 'column-money'
      },
      {
        title: '非致癌经口摄入',
        dataIndex: 'HQois',
        className: 'column-money'
      },
      {
        title: '非致癌皮肤接触',
        dataIndex: 'HQdcs',
        className: 'column-money'
      },
      {
        title: '非致癌吸入土壤颗粒物',
        dataIndex: 'HQpis',
        className: 'column-money'
      },
      {
        title: '非致癌吸入室外空气中来自表层土壤中的气态污染物',
        dataIndex: 'HQiov1',
        className: 'column-money'
      },
      {
        title: '非致癌吸入室外空气中来自下层土壤中的气态污染物',
        dataIndex: 'HQiov2',
        className: 'column-money'
      },
      {
        title: '非致癌吸入室内空气中来自下层土壤中的气态',
        dataIndex: 'HQiiv1',
        className: 'column-money'
      },
      {
        title: '总危害指数',
        dataIndex: 'feiZhiAiValue',
        className: 'column-money'
      }
    ]
    const report_water = [
      {
        title: '序号',
        dataIndex: 'id',
        className: 'column-money'
      },
      {
        title: '中文名',
        dataIndex: 'name_cn',
        className: 'column-money'
      },
      {
        title: '英文名',
        dataIndex: 'name_en',
        className: 'column-money'
      },
      {
        title: 'CAS编号',
        dataIndex: 'cas_code',
        className: 'column-money'
      },
      {
        title: '暴露浓度',
        dataIndex: 'value',
        className: 'column-money'
      },
      {
        title: '致癌饮用',
        dataIndex: 'CRcgw',
        className: 'column-money'
      },
      {
        title: '致癌室内吸入',
        dataIndex: 'CRiiv2',
        className: 'column-money'
      },
      {
        title: '致癌室外吸入',
        dataIndex: 'CRiov3',
        className: 'column-money'
      },
      {
        title: '总风险水平',
        dataIndex: 'zhiAiDXValue',
        className: 'column-money'
      },
      {
        title: '非致癌饮用',
        dataIndex: 'HQcgw',
        className: 'column-money'
      },
      {
        title: '非致癌室内吸入',
        dataIndex: 'HQiiv2',
        className: 'column-money'
      },
      {
        title: '非致癌室外吸入',
        dataIndex: 'HQiov3',
        className: 'column-money'
      },
      {
        title: '总危害指数',
        dataIndex: 'feiZhiAiDXValue',
        className: 'column-money'
      },
    ]
    const waterControl = [
      {
        title: '序号',
        dataIndex: 'id',
        className: 'column-money'
      },
      {
        title: '中文名',
        dataIndex: 'name_cn',
        className: 'column-money'
      },
      {
        title: '英文名',
        dataIndex: 'name_en',
        className: 'column-money'
      },
      {
        title: 'CAS编号',
        dataIndex: 'cas_code',
        className: 'column-money'
      },
      {
        title: '致癌饮用',
        dataIndex: 'RCVScgw',
        className: 'column-money'
      },
      {
        title: '致癌室内吸入',
        dataIndex: 'RCVSiiv2',
        className: 'column-money'
      },
      {
        title: '致癌室外吸入',
        dataIndex: 'RCVSiov3',
        className: 'column-money'
      },
      {
        title: '综合致癌控制值',
        dataIndex: 'zhiAiDXControlValue',
        className: 'column-money'
      },
      {
        title: '非致癌饮用',
        dataIndex: 'HCVScgw',
        className: 'column-money'
      },
      {
        title: '非致癌室内吸入',
        dataIndex: 'HCVSiiv2',
        className: 'column-money'
      },
      {
        title: '非致癌室外吸入',
        dataIndex: 'HCVSiov3',
        className: 'column-money'
      },
      {
        title: '综合非致癌控制值',
        dataIndex: 'feiZhiAiDXControlValue',
        className: 'column-money'
      },
      {
        title: '风险控制值',
        dataIndex: 'total',
        className: 'column-money'
      }
    ]

    const soilCancerControl = [
      {
        title: '序号',
        dataIndex: 'id',
        className: 'column-money'
      },
      {
        title: '中文名',
        dataIndex: 'name_cn',
        className: 'column-money'
      },
      {
        title: '英文名',
        dataIndex: 'name_en',
        className: 'column-money'
      },
      {
        title: 'CAS编号',
        dataIndex: 'cas_code',
        className: 'column-money'
      },
      {
        title: '致癌经口摄入',
        dataIndex: 'RCVSois',
        className: 'column-money'
      },
      {
        title: '致癌皮肤接触',
        dataIndex: 'RCVSdcs',
        className: 'column-money'
      },
      {
        title: '致癌吸入土壤颗粒物',
        dataIndex: 'RCVSpis',
        className: 'column-money'
      },
      {
        title: '致癌吸入室外空气中来自表层土壤中的气态污染物',
        dataIndex: 'RCVSiov1',
        className: 'column-money'
      },
      {
        title: '致癌吸入室外空气中来自下层土壤中的气态污染物',
        dataIndex: 'RCVSiov2',
        className: 'column-money'
      },
      {
        title: '致癌吸入室内空气中来自下层土壤中的气态',
        dataIndex: 'RCVSiiv1',
        className: 'column-money'
      },
      {
        title: '综合致癌控制值',
        dataIndex: 'zhiAiControlValue',
        className: 'column-money'
      }
    ]
    const soilNotCancerControl = [
      {
        title: '序号',
        dataIndex: 'id',
        className: 'column-money'
      },
      {
        title: '中文名',
        dataIndex: 'name_cn',
        className: 'column-money'
      },
      {
        title: '英文名',
        dataIndex: 'name_en',
        className: 'column-money'
      },
      {
        title: 'CAS编号',
        dataIndex: 'cas_code',
        className: 'column-money'
      },
      {
        title: '非致癌经口摄入',
        dataIndex: 'HCVSois',
        className: 'column-money'
      },
      {
        title: '非致癌皮肤接触',
        dataIndex: 'HCVSdcs',
        className: 'column-money'
      },
      {
        title: '非致癌吸入土壤颗粒物',
        dataIndex: 'HCVSpis',
        className: 'column-money'
      },
      {
        title: '非致癌吸入室外空气中来自表层土壤中的气态污染物',
        dataIndex: 'HCVSiov1',
        className: 'column-money'
      },
      {
        title: '非致癌吸入室外空气中来自下层土壤中的气态污染物',
        dataIndex: 'HCVSiov2',
        className: 'column-money'
      },
      {
        title: '非致癌吸入室内空气中来自下层土壤中的气态',
        dataIndex: 'HCVSiiv1',
        className: 'column-money'
      },
      {
        title: '综合非致癌控制值',
        dataIndex: 'feiZhiAiControlValue',
        className: 'column-money'
      },
      {
        title: '风险控制值',
        dataIndex: 'total',
        className: 'column-money'
      }
    ]
    const soilCancerexposure = [
      {
        title: '序号',
        dataIndex: 'id',
        className: 'column-money'
      },
      {
        title: '中文名',
        dataIndex: 'name_cn',
        className: 'column-money'
      },
      {
        title: '英文名',
        dataIndex: 'name_en',
        width: 200,
        className: 'column-money'
      },
      {
        title: 'CAS编号',
        dataIndex: 'cas_code',
      },
      {
        title: '经口摄入土壤',
        dataIndex: 'OISERca',
        key: 'OISERca',

      },
      {
        title: '皮肤接触土壤',
        dataIndex: 'DCSERca',

      },
      {
        title: '吸入土壤颗粒物',
        dataIndex: 'PIERca',

      },
      {
        title: '吸入室外空气中来自表层土壤的气态污染物',
        dataIndex: 'IOVERca1',

      },
      {
        title: '吸入室外空气中来自下层土壤的气态污染物',
        dataIndex: 'IOVERca2',

      },
      {
        title: '吸入室内空气中来自下层土壤的气态污染物',
        dataIndex: 'IIVERca1',

      },
    ]
    const soilNotCancerexposure = [
      {
        title: '序号',
        dataIndex: 'id',
        className: 'column-money'
      },
      {
        title: '中文名',
        dataIndex: 'name_cn',
        className: 'column-money'
      },
      {
        title: '英文名',
        dataIndex: 'name_en',
        className: 'column-money'
      },
      {
        title: 'CAS编号',
        dataIndex: 'cas_code',
        className: 'column-money'
      },
      {
        title: '经口摄入土壤',
        dataIndex: 'OISERnc',
        className: 'column-money'
      },
      {
        title: '皮肤接触土壤',
        dataIndex: 'DCSERnc',
        className: 'column-money'
      },
      {
        title: '吸入土壤颗粒物',
        dataIndex: 'PIERnc',
        className: 'column-money'
      },
      {
        title: '吸入室外空气中来自表层土壤的气态污染物',
        dataIndex: 'IOVERnc1',
        className: 'column-money'
      },
      {
        title: '吸入室外空气中来自下层土壤的气态污染物',
        dataIndex: 'IOVERnc2',
        className: 'column-money'
      },
      {
        title: '吸入室内空气中来自下层土壤的气态污染物',
        dataIndex: 'IIVERnc1',
        className: 'column-money'
      },

    ]
    const waterCancerexposure = [
      {
        title: '序号',
        dataIndex: 'id',
        className: 'column-money'
      },
      {
        title: '中文名',
        dataIndex: 'name_cn',
        className: 'column-money'
      },
      {
        title: '英文名',
        dataIndex: 'name_en',
        width: 200,
        className: 'column-money'
      },
      {
        title: 'CAS编号',
        dataIndex: 'cas_code',
      },
      {
        title: '吸入室外空气中来自地下水的气态污染物',
        dataIndex: 'IOVERca3',
        key: 'IOVERca3',

      },
      {
        title: '吸入室内空气中来自地下水的气态污染物',
        dataIndex: 'IIVERca2',

      },
      {
        title: '饮用地下水',
        dataIndex: 'CGWERca',

      },
    ]
    const waterNotCancerexposure = [
      {
        title: '序号',
        dataIndex: 'id',
        className: 'column-money'
      },
      {
        title: '中文名',
        dataIndex: 'name_cn',
        className: 'column-money'
      },
      {
        title: '英文名',
        dataIndex: 'name_en',
        className: 'column-money'
      },
      {
        title: 'CAS编号',
        dataIndex: 'cas_code',
        className: 'column-money'
      },
      {
        title: '吸入室外空气中来自地下水的气态污染物',
        dataIndex: 'IOVERnc3',
        className: 'column-money'
      },
      {
        title: '吸入室内空气中来自地下水的气态污染物',
        dataIndex: 'IIVERnc2',
        className: 'column-money'
      },
      {
        title: '饮用地下水',
        dataIndex: 'CGWERnc',
        className: 'column-money'
      },

    ]
    const contributionsoil = [
      {
        title: '序号',
        dataIndex: 'id',
        className: 'column-money'
      },
      {
        title: '中文名',
        dataIndex: 'name_cn',
        className: 'column-money'
      },
      {
        title: '英文名',
        dataIndex: 'name_en',
        width: 200,
        className: 'column-money'
      },
      {
        title: 'CAS编号',
        dataIndex: 'cas_code',
      },
      {
        title: '经口摄入',
        dataIndex: 'OIS',
        className: 'column-money'
      },
      {
        title: '皮肤接触',
        dataIndex: 'DCS',
        className: 'column-money'
      },
      {
        title: '吸入土壤颗粒物',
        dataIndex: 'PIS',
        className: 'column-money'
      },
      {
        title: '吸入室外空气中来自表层土壤中的气态污染物',
        dataIndex: 'IOV1',
        className: 'column-money'
      },
      {
        title: '吸入室外空中来自下层土壤中的气态污染物',
        dataIndex: 'IOV2',
        className: 'column-money'
      },
      {
        title: '吸入室内空气中来自下层土壤中的气态',
        dataIndex: 'IIV1',
        className: 'column-money'
      },
      {
        title: '合计',
        dataIndex: 'total',
        className: 'column-money'
      },
    ]
    const contributionsoilNot = [
      {
        title: '序号',
        dataIndex: 'id',
        className: 'column-money'
      },
      {
        title: '中文名',
        dataIndex: 'name_cn',
        className: 'column-money'
      },
      {
        title: '英文名',
        dataIndex: 'name_en',
        width: 200,
        className: 'column-money'
      },
      {
        title: 'CAS编号',
        dataIndex: 'cas_code',
      },
      {
        title: '经口摄入',
        dataIndex: 'OIS',
        className: 'column-money'
      },
      {
        title: '皮肤接触',
        dataIndex: 'DCS',
        className: 'column-money'
      },
      {
        title: '吸入土壤颗粒物',
        dataIndex: 'PIS',
        className: 'column-money'
      },
      {
        title: '吸入室外空气中来自表层土壤中的气态污染物',
        dataIndex: 'IOV1',
        className: 'column-money'
      },
      {
        title: '吸入室外空中来自下层土壤中的气态污染物',
        dataIndex: 'IOV2',
        className: 'column-money'
      },
      {
        title: '吸入室内空气中来自下层土壤中的气态',
        dataIndex: 'IIV1',
        className: 'column-money'
      },
      {
        title: '合计',
        dataIndex: 'total',
        className: 'column-money'
      },
    ]
    const contributionwater = [
      {
        title: '序号',
        dataIndex: 'id',
        className: 'column-money'
      },
      {
        title: '中文名',
        dataIndex: 'name_cn',
        className: 'column-money'
      },
      {
        title: '英文名',
        dataIndex: 'name_en',
        width: 200,
        className: 'column-money'
      },
      {
        title: 'CAS编号',
        dataIndex: 'cas_code',
      },
      {
        title: '吸入室外地下水蒸气',
        dataIndex: 'IOV3',
        className: 'column-money'
      },
      {
        title: '吸入室内地下水蒸气',
        dataIndex: 'IIV2',
        className: 'column-money'
      },
      {
        title: '饮用地下水',
        dataIndex: 'CGW',
        className: 'column-money'
      },
      {
        title: '合计',
        dataIndex: 'total',
        className: 'column-money'
      },
    ]
    const contributionwaterNot = [
      {
        title: '序号',
        dataIndex: 'id',
        className: 'column-money'
      },
      {
        title: '中文名',
        dataIndex: 'name_cn',
        className: 'column-money'
      },
      {
        title: '英文名',
        dataIndex: 'name_en',
        width: 200,
        className: 'column-money'
      },
      {
        title: 'CAS编号',
        dataIndex: 'cas_code',
      },
      {
        title: '吸入室外地下水蒸气',
        dataIndex: 'IOV3',
        className: 'column-money'
      },
      {
        title: '吸入室内地下水蒸气',
        dataIndex: 'IIV2',
        className: 'column-money'
      },
      {
        title: '饮用地下水',
        dataIndex: 'CGW',
        className: 'column-money'
      },
      {
        title: '合计',
        dataIndex: 'total',
        className: 'column-money'
      },
    ]
    const graphicArr_columns = [
      {
        title: '序号',
        dataIndex: 'id',
        className: 'column-money'
      },
      {
        title: '暴露途径',
        dataIndex: 'cn',
        className: 'column-money'
      },
      {
        title: `${this.filtterType(this.props.base_info.project_type)}`,
        dataIndex: 'url',
        className: 'column-money'
      },

    ]
    return (
      <div className="report">
        <Spin spinning={!this.props.base_info.report_time}>
          <div ref={this.state.pdfs}>
            <div className="reportPdfContent">
              <div className="reportMarign">
                <img src={require('../../../assets/images/report/logo.png').default} className="report-img"></img>
                <Divider></Divider>
              </div>
              <div className="report-title">第二层次项目分析报告</div>
              <div className="report-time">生成报告日期：{date("Y-m-d H:i:s", parseInt(this.props.base_info.report_time))}</div>
              <div className="report-content">
                <div className="report-area">
                  <div className="area-title">
                    <div className="area-top">项目基本信息</div>
                    <div className="area-btm">ESSENTIAL INFORMATION</div>
                  </div>
                  <table id="tfhover" className="tfhead">
                    <thead>
                      <tr>
                        <th>项目名称</th>
                        <td>{this.props.base_info.project_name}</td>
                        <th>评估时间</th>
                        <td>{date("Y-m-d", parseInt(this.props.base_info.project_time))}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>用地方式</th>
                        <td>{this.filtterType(this.props.base_info.project_type)}</td>
                        <th>项目地址</th>
                        <td>{this.props.base_info.project_address}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>


              {this.props.soil_pollutant.length == 0 ? (
                <div></div>
              ) : (
                <div> <div className="report-content">
                  <div className="report-area">
                    <div className="area-title">
                      <div className="area-top">项目污染物信息(土壤)</div>
                      <div className="area-btm">ENVIRONMENTAL PROJECT POLLUTANT INFORMATION(SOIL)</div>
                    </div>
                    <ReportTable
                      columns={soil_columns}
                      dataSource={this.props.soil_pollutant}
                    ></ReportTable>
                  </div>
                </div></div>
              )}

              {this.props.water_pollutant.length == 0 ? (
                <div></div>
              ) : (
                <div> <div className="report-content">
                  <div className="report-area">
                    <div className="area-title">
                      <div className="area-top">项目污染物信息(地下水)</div>
                      <div className="area-btm">ENVIRONMENTAL PROJECT POLLUTANT INFORMATION(GROUNDWATER)</div>
                    </div>
                    <ReportTable
                      columns={water_columns}
                      dataSource={this.props.water_pollutant}
                    ></ReportTable>
                  </div>
                </div></div>
              )}
              {this.props.graphicArr.length == 0 ? (
                <div></div>
              ) : (
                <div><div className="report-content">
                  <div className="report-area dr">
                    <div className="area-title">                   <div className="area-top">{this.filtterType(this.props.base_info.project_type)}方式下暴露途径</div>
                      <div className="area-btm">Exposure routes under the second type of land use</div>
                    </div>
                    <ReportTableCheck
                      columns={graphicArr_columns}
                      dataSource={this.props.graphicArr}
                    ></ReportTableCheck>
                  </div>
                </div></div>

              )}

              {this.props.all_param_value.length == 0 ? (
                <div></div>
              ) : (
                <div> <div className="report-content">
                  <div className="report-area">
                    <div className="area-title">
                      <div className="area-top">全部参数取值</div>
                      <div className="area-btm">All parameter values</div>
                    </div>
                    <ReportTable
                      columns={parameter}
                      dataSource={this.props.all_param_value}
                    ></ReportTable>
                  </div>
                </div></div>

              )}


              <div className="LineChart-content">
                <div className="report-area">
                  <div className="LineChart-title">
                    <div className="area-top">概念模型-流程图</div>
                    <div className="area-btm">conceptual model-flow chart</div>
                  </div>
                  <LineImg></LineImg>
                </div>
              </div>

              <div className="report-content">
                <div className="report-area">
                  <div className="area-title">
                    <div className="area-top">概念模型-示意图</div>
                    <div className="area-btm">conceptual model-Sketch Map</div>
                  </div>
                  <Model></Model>
                </div>
              </div>
              {this.props.toxicity.length == 0 ? (
                <div></div>
              ) : (
                <div><div className="report-content">
                  <div className="report-area">
                    <div className="area-title">
                      <div className="area-top">污染物毒性参数表</div>
                      <div className="area-btm">Table of toxicity parameters of pollutants</div>
                    </div>
                    <ReportTable
                      columns={toxicity}
                      dataSource={this.props.toxicity}
                    ></ReportTable>
                  </div>
                </div></div>

              )}

              {this.props.physical.length == 0 ? (
                <div></div>
              ) : (
                <div><div className="report-content">
                  <div className="report-area">
                    <div className="area-title">
                      <div className="area-top">污染物理化参数表</div>
                      <div className="area-btm">Pollution physicochemical parameter table</div>
                    </div>
                    <ReportTable
                      columns={physical}
                      dataSource={this.props.physical}
                    ></ReportTable>
                  </div>
                </div></div>

              )}

              {this.props.exsoilCancer.length == 0 ? (
                <div></div>
              ) : (
                <div><div className="report-content">
                  <div className="report-area">
                    <div className="area-title">
                      <div className="area-top">土壤不同暴露途径暴露量（致癌）</div>
                      <div className="area-btm">Soil exposure by different exposure routes (carcinogenic)</div>
                    </div>
                    <ReportTable
                      columns={soilCancerexposure}
                      dataSource={this.props.exsoilCancer}
                    ></ReportTable>
                  </div>
                </div></div>

              )}

              {this.props.exsoilNotCancer.length == 0 ? (
                <div></div>
              ) : (
                <div> <div className="report-content">
                  <div className="report-area">
                    <div className="area-title">
                      <div className="area-top">土壤不同暴露途径暴露量（非致癌）</div>
                      <div className="area-btm">Soil exposure by different exposure routes (non-carcinogenic)</div>
                    </div>
                    <ReportTable
                      columns={soilNotCancerexposure}
                      dataSource={this.props.exsoilNotCancer}
                    ></ReportTable>
                  </div>
                </div></div>

              )}

              {this.props.exwaterCancer.length == 0 ? (
                <div></div>
              ) : (
                <div> <div className="report-content">
                  <div className="report-area">
                    <div className="area-title">
                      <div className="area-top">地下水不同暴露途径暴露量（致癌）</div>
                      <div className="area-btm">Groundwater exposure by different routes (carcinogenic)</div>
                    </div>
                    <ReportTable
                      columns={waterCancerexposure}
                      dataSource={this.props.exwaterCancer}
                    ></ReportTable>
                  </div>
                </div></div>

              )}

              {this.props.exwaterNotCancer.length == 0 ? (
                <div></div>
              ) : (
                <div> <div className="report-content">
                  <div className="report-area">
                    <div className="area-title">
                      <div className="area-top">地下水不同暴露途径暴露量（非致癌）</div>
                      <div className="area-btm">Groundwater exposure by different routes (non-carcinogenic)</div>
                    </div>
                    <ReportTable
                      columns={waterNotCancerexposure}
                      dataSource={this.props.exwaterNotCancer}
                    ></ReportTable>
                  </div>
                </div></div>

              )}

              {this.props.soilCancer.length == 0 ? (
                <div></div>
              ) : (
                <div><div className="report-content">
                  <div className="report-area">
                    <div className="area-title">
                      <div className="area-top">致癌风险水平（土壤）</div>
                      <div className="area-btm">Carcinogenic risk level (soil)</div>
                    </div>
                    <ReportTable
                      columns={cancerRiskSoil}
                      dataSource={this.props.soilCancer}
                    ></ReportTable>
                  </div>
                </div></div>

              )}

              {this.props.soilNotCancer.length == 0 ? (
                <div></div>
              ) : (
                <div> <div className="report-content">
                  <div className="report-area">
                    <div className="area-title">
                      <div className="area-top">非致癌危害指数（土壤）</div>
                      <div className="area-btm">Non carcinogenic hazard index (soil)</div>
                    </div>
                    <ReportTable
                      columns={soilNotCancer}
                      dataSource={this.props.soilNotCancer}
                    ></ReportTable>
                  </div>
                </div></div>

              )}

              {this.props.report_water.length == 0 ? (
                <div></div>
              ) : (
                <div> <div className="report-content">
                  <div className="report-area">
                    <div className="area-title">
                      <div className="area-top">污染物风险(地下水)</div>
                      <div className="area-btm">Pollutant risk (groundwater)</div>
                    </div>
                    <ReportTable
                      columns={report_water}
                      dataSource={this.props.report_water}
                    ></ReportTable>
                  </div>
                </div></div>

              )}

              {this.props.contributionsoil.length == 0 ? (
                <div></div>
              ) : (
                <div> <div className="report-content">
                  <div className="report-area">
                    <div className="area-title">
                      <div className="area-top">不同暴露途径的致癌风险贡献率（土壤）</div>
                      <div className="area-btm">Contribution to carcinogenic risk by different exposure pathways (soil)</div>
                    </div>
                    <ReportTable
                      columns={contributionsoil}
                      dataSource={this.props.contributionsoil}
                    ></ReportTable>
                  </div>
                </div></div>

              )}

              {this.props.contributionsoilNot.length == 0 ? (
                <div></div>
              ) : (
                <div> <div className="report-content">
                  <div className="report-area">
                    <div className="area-title">
                      <div className="area-top">不同暴露途径的危害商贡献率（土壤）</div>
                      <div className="area-btm">Contribution rate of hazard quotient of different exposure pathways (soil)</div>
                    </div>
                    <ReportTable
                      columns={contributionsoilNot}
                      dataSource={this.props.contributionsoilNot}
                    ></ReportTable>
                  </div>
                </div></div>

              )}

              {this.props.contributionwater.length == 0 ? (
                <div></div>
              ) : (
                <div> <div className="report-content">
                  <div className="report-area">
                    <div className="area-title">
                      <div className="area-top">不同暴露途径的致癌风险贡献率（地下水）</div>
                      <div className="area-btm">Contribution rates of carcinogenic risk by different exposure pathways (groundwater)</div>
                    </div>
                    <ReportTable
                      columns={contributionwater}
                      dataSource={this.props.contributionwater}
                    ></ReportTable>
                  </div>
                </div></div>

              )}


              {this.props.contributionwaterNot.length == 0 ? (
                <div></div>
              ) : (
                <div> <div className="report-content">
                  <div className="report-area">
                    <div className="area-title">
                      <div className="area-top">不同暴露途径的危害商贡献率（地下水）</div>
                      <div className="area-btm">Contribution rate of hazard quotient of different exposure pathways (groundwater)</div>
                    </div>
                    <ReportTable
                      columns={contributionwaterNot}
                      dataSource={this.props.contributionwaterNot}
                    ></ReportTable>
                  </div>
                </div></div>

              )}

              {this.props.soilControl.length == 0 ? (
                <div></div>
              ) : (
                <div> <div className="report-content">
                  <div className="report-area">
                    <div className="area-title">
                      <div className="area-top">致癌风险控制值(土壤)</div>
                      <div className="area-btm">Carcinogenic risk control value (soil)</div>
                    </div>
                    <ReportTable
                      columns={soilCancerControl}
                      dataSource={this.props.soilControl}
                    ></ReportTable>
                  </div>
                </div></div>

              )}


              {this.props.soilControl.length == 0 ? (
                <div></div>
              ) : (
                <div> <div className="report-content">
                  <div className="report-area">
                    <div className="area-title">
                      <div className="area-top">非致癌风险控制值(土壤)</div>
                      <div className="area-btm">Non carcinogenic risk control value (soil)</div>
                    </div>
                    <ReportTable
                      columns={soilNotCancerControl}
                      dataSource={this.props.soilControl}
                    ></ReportTable>
                  </div>
                </div></div>

              )}

              {this.props.waterControl.length == 0 ? (
                <div></div>
              ) : (
                <div> <div className="report-content">
                  <div className="report-area">
                    <div className="area-title">
                      <div className="area-top">污染物风险控制值(地下水)</div>
                      <div className="area-btm">Pollutant risk control value (groundwater)</div>
                    </div>
                    <ReportTable
                      columns={waterControl}
                      dataSource={this.props.waterControl}
                    ></ReportTable>
                  </div>
                </div></div>

              )}

              {this.props.soil_cancer_sensitivity.length == 0 ? (
                <div></div>
              ) : (
                <div> <div className="report-content">
                  <div className="report-area">
                    <div className="area-title">
                      <div className="area-top">土壤参数敏感性比例（致癌）</div>
                      <div className="area-btm">Soil Parameter sensitivity ratio (carcinogenic)</div>
                    </div>

                    {!Array.isArray(this.props.soil_cancer_sensitivityColumns[0]) ? (
                      <ReportTable
                        columns={this.props.soil_cancer_sensitivityColumns}
                        dataSource={this.props.soil_cancer_sensitivity}
                      ></ReportTable>
                    ) : (
                      <div className="ReportTable_marght">
                        {this.props.soil_cancer_sensitivityColumns.length > 0 ? (
                          <ReportTable
                            columns={this.props.soil_cancer_sensitivityColumns[0]}
                            dataSource={this.props.soil_cancer_sensitivity}
                          ></ReportTable>
                        ) : (
                          <div></div>

                        )}

                        {this.props.soil_cancer_sensitivityColumns.length > 1 ? (
                          <ReportTable
                            columns={this.props.soil_cancer_sensitivityColumns[1]}
                            dataSource={this.props.soil_cancer_sensitivity}
                          ></ReportTable>
                        ) : (
                          <div></div>

                        )}
                        {this.props.soil_cancer_sensitivityColumns.length > 2 ? (
                          <ReportTable
                            columns={this.props.soil_cancer_sensitivityColumns[2]}
                            dataSource={this.props.soil_cancer_sensitivity}
                          ></ReportTable>
                        ) : (
                          <div></div>

                        )}
                        {this.props.soil_cancer_sensitivityColumns.length > 3 ? (
                          <ReportTable
                            columns={this.props.soil_cancer_sensitivityColumns[3]}
                            dataSource={this.props.soil_cancer_sensitivity}
                          ></ReportTable>
                        ) : (
                          <div></div>

                        )}

                        {this.props.soil_cancer_sensitivityColumns.length > 4 ? (
                          <ReportTable
                            columns={this.props.soil_cancer_sensitivityColumns[4]}
                            dataSource={this.props.soil_cancer_sensitivity}
                          ></ReportTable>
                        ) : (
                          <div></div>
                        )}
                        {this.props.soil_cancer_sensitivityColumns.length > 5 ? (
                          <ReportTable
                            columns={this.props.soil_cancer_sensitivityColumns[5]}
                            dataSource={this.props.soil_cancer_sensitivity}
                          ></ReportTable>
                        ) : (
                          <div></div>
                        )}

                      </div>

                    )}

                  </div>
                </div></div>
              )}

              {this.props.soil_not_cancer_sensitivity.length == 0 ? (
                <div></div>
              ) : (
                <div> <div className="report-content">
                  <div className="report-area">
                    <div className="area-title">
                      <div className="area-top">土壤参数敏感性比例（非致癌）</div>
                      <div className="area-btm">Soil Parameter sensitivity ratio (noncarcinogenic)</div>
                    </div>
                    {!Array.isArray(this.props.soil_not_cancer_sensitivityColumns[0]) ? (
                      <ReportTable
                        columns={this.props.soil_not_cancer_sensitivityColumns}
                        dataSource={this.props.soil_not_cancer_sensitivity}
                      ></ReportTable>
                    ) : (
                      <div className="ReportTable_marght">
                        {this.props.soil_not_cancer_sensitivityColumns.length > 0 ? (
                          <ReportTable
                            columns={this.props.soil_not_cancer_sensitivityColumns[0]}
                            dataSource={this.props.soil_not_cancer_sensitivity}
                          ></ReportTable>
                        ) : (
                          <div></div>

                        )}

                        {this.props.soil_not_cancer_sensitivityColumns.length > 1 ? (
                          <ReportTable
                            columns={this.props.soil_not_cancer_sensitivityColumns[1]}
                            dataSource={this.props.soil_not_cancer_sensitivity}
                          ></ReportTable>
                        ) : (
                          <div></div>

                        )}
                        {this.props.soil_not_cancer_sensitivityColumns.length > 2 ? (
                          <ReportTable
                            columns={this.props.soil_not_cancer_sensitivityColumns[2]}
                            dataSource={this.props.soil_not_cancer_sensitivity}
                          ></ReportTable>
                        ) : (
                          <div></div>

                        )}
                        {this.props.soil_not_cancer_sensitivityColumns.length > 3 ? (
                          <ReportTable
                            columns={this.props.soil_not_cancer_sensitivityColumns[3]}
                            dataSource={this.props.soil_not_cancer_sensitivity}
                          ></ReportTable>
                        ) : (
                          <div></div>

                        )}

                        {this.props.soil_not_cancer_sensitivityColumns.length > 4 ? (
                          <ReportTable
                            columns={this.props.soil_not_cancer_sensitivityColumns[4]}
                            dataSource={this.props.soil_not_cancer_sensitivity}
                          ></ReportTable>
                        ) : (
                          <div></div>
                        )}
                        {this.props.soil_not_cancer_sensitivityColumns.length > 5 ? (
                          <ReportTable
                            columns={this.props.soil_not_cancer_sensitivityColumns[5]}
                            dataSource={this.props.soil_not_cancer_sensitivity}
                          ></ReportTable>
                        ) : (
                          <div></div>
                        )}

                      </div>

                    )}

                  </div>
                </div></div>
              )}

              {this.props.water_cancer_sensitivity.length == 0 ? (
                <div></div>
              ) : (
                <div>
                  <div className="report-content">
                    <div className="report-area">
                      <div className="area-title">
                        <div className="area-top">地下水参数敏感性比例（致癌）</div>
                        <div className="area-btm">water Parameter sensitivity ratio (carcinogenic)</div>
                      </div>



                      {!Array.isArray(this.props.water_cancer_sensitivityColumns[0]) ? (
                        <ReportTable
                          columns={this.props.water_cancer_sensitivityColumns}
                          dataSource={this.props.water_cancer_sensitivity}
                        ></ReportTable>
                      ) : (
                        <div className="ReportTable_marght">
                          {this.props.water_cancer_sensitivityColumns.length > 0 ? (
                            <ReportTable
                              columns={this.props.water_cancer_sensitivityColumns[0]}
                              dataSource={this.props.water_cancer_sensitivity}
                            ></ReportTable>
                          ) : (
                            <div></div>

                          )}

                          {this.props.water_cancer_sensitivityColumns.length > 1 ? (
                            <ReportTable
                              columns={this.props.water_cancer_sensitivityColumns[1]}
                              dataSource={this.props.water_cancer_sensitivity}
                            ></ReportTable>
                          ) : (
                            <div></div>

                          )}
                          {this.props.water_cancer_sensitivityColumns.length > 2 ? (
                            <ReportTable
                              columns={this.props.water_cancer_sensitivityColumns[2]}
                              dataSource={this.props.water_cancer_sensitivity}
                            ></ReportTable>
                          ) : (
                            <div></div>

                          )}
                          {this.props.water_cancer_sensitivityColumns.length > 3 ? (
                            <ReportTable
                              columns={this.props.water_cancer_sensitivityColumns[3]}
                              dataSource={this.props.water_cancer_sensitivity}
                            ></ReportTable>
                          ) : (
                            <div></div>

                          )}

                          {this.props.water_cancer_sensitivityColumns.length > 4 ? (
                            <ReportTable
                              columns={this.props.water_cancer_sensitivityColumns[4]}
                              dataSource={this.props.water_cancer_sensitivity}
                            ></ReportTable>
                          ) : (
                            <div></div>
                          )}
                          {this.props.water_cancer_sensitivityColumns.length > 5 ? (
                            <ReportTable
                              columns={this.props.water_cancer_sensitivityColumns[5]}
                              dataSource={this.props.water_cancer_sensitivity}
                            ></ReportTable>
                          ) : (
                            <div></div>
                          )}

                        </div>

                      )}


                    </div>
                  </div>
                </div>
              )}



              {this.props.water_not_cancer_sensitivity.length == 0 ? (
                <div></div>
              ) : (
                <div> <div className="report-content">
                  <div className="report-area">
                    <div className="area-title">
                      <div className="area-top">地下水参数敏感性比例（非致癌）</div>
                      <div className="area-btm">water Parameter sensitivity ratio (noncarcinogenic)</div>
                    </div>
                    {!Array.isArray(this.props.water_not_cancer_sensitivityColumns[0]) ? (
                      <ReportTable
                        columns={this.props.water_not_cancer_sensitivityColumns}
                        dataSource={this.props.water_not_cancer_sensitivity}
                      ></ReportTable>
                    ) : (
                      <div className="ReportTable_marght">
                        {this.props.water_not_cancer_sensitivityColumns.length > 0 ? (
                          <ReportTable
                            columns={this.props.water_not_cancer_sensitivityColumns[0]}
                            dataSource={this.props.water_not_cancer_sensitivity}
                          ></ReportTable>
                        ) : (
                          <div></div>

                        )}

                        {this.props.water_not_cancer_sensitivityColumns.length > 1 ? (
                          <ReportTable
                            columns={this.props.water_not_cancer_sensitivityColumns[1]}
                            dataSource={this.props.water_not_cancer_sensitivity}
                          ></ReportTable>
                        ) : (
                          <div></div>

                        )}
                        {this.props.water_not_cancer_sensitivityColumns.length > 2 ? (
                          <ReportTable
                            columns={this.props.water_not_cancer_sensitivityColumns[2]}
                            dataSource={this.props.water_not_cancer_sensitivity}
                          ></ReportTable>
                        ) : (
                          <div></div>

                        )}
                        {this.props.water_not_cancer_sensitivityColumns.length > 3 ? (
                          <ReportTable
                            columns={this.props.water_not_cancer_sensitivityColumns[3]}
                            dataSource={this.props.water_not_cancer_sensitivity}
                          ></ReportTable>
                        ) : (
                          <div></div>

                        )}

                        {this.props.water_not_cancer_sensitivityColumns.length > 4 ? (
                          <ReportTable
                            columns={this.props.water_not_cancer_sensitivityColumns[4]}
                            dataSource={this.props.water_not_cancer_sensitivity}
                          ></ReportTable>
                        ) : (
                          <div></div>
                        )}
                        {this.props.water_not_cancer_sensitivityColumns.length > 5 ? (
                          <ReportTable
                            columns={this.props.water_not_cancer_sensitivityColumns[5]}
                            dataSource={this.props.water_not_cancer_sensitivity}
                          ></ReportTable>
                        ) : (
                          <div></div>
                        )}

                      </div>

                    )}

                  </div>
                </div></div>
              )}




            </div>
          </div>
          <Modal
            title="专家咨询"
            centered
            visible={this.state.consultData}
            onOk={() => this.confirmSubmit()}
            onCancel={() => this.usercenter(false)}
            width={800}
            okText="提交"
            cancelText="关闭"
            destroyOnClose={true}
          >
            <p className="consultP ">如果您对报告产生任何疑问，可以留下您的问题和联系方式，我们将派专家跟您联系！</p>
            <p className="consultP consultP_txt">请输入您想咨询的问题</p>
            <TextArea showCount maxLength={300} allowClear onChange={this.onChangeArea} style={{ width: '100%', resize: 'none' }} rows={5} />
            <p className="consultP consultP_txt">请输入您的联系方式</p>
            <Input onChange={this.onChangephone} />
          </Modal>
          <div className="report-btn">
            <div className="laststep" onClick={this.nextStep.bind(this, 'previous')}>
              上一步
            </div>
            <div className="pdf" onClick={this.nextStep.bind(this, 'pdf')}>
              <img src={require('../../../assets/images/report/report_pdf.png').default}></img>
              <span>导出PDF</span>
            </div>
            <div className="consult" onClick={this.nextStep.bind(this, 'consult')}>
              <img src={require('../../../assets/images/report/consult.png').default}></img>
              <span>专家咨询</span>
            </div>
            <div className="charge" onClick={this.nextStep.bind(this, 'charge')}>
              <img src={require('../../../assets/images/report/report_word.png').default}></img>
              <span>生成正式报告</span>
              <div className="pay">付费</div>
            </div>


            {/* <div className="word" onClick={this.nextStep.bind(this,'word')}>
            <img src={require('../../../assets/images/report/word.png').default}></img>
            <span>导出WORD</span>
          </div> */}
          </div>

        </Spin>
      </div>
    );
  }
}

export default twoLevel;
