import * as React from "react";
import { Component } from "react";
import { Tabs, Row, Col,Table ,Spin} from "antd";
import {checkProductd,checkDepthid} from '../../../configs/checkProductId'
// import "./polluteParamsTable.less";
import {listPollutant} from '../../../apis/common' 
import { getSessionLevel } from '../../../utils/token'

const { TabPane } = Tabs;
interface Item {
  value:string
  index:number,
}
export interface ParamsProps {
  history:any
}

export interface ParamsState {
  dataSource: any;
  project_id:any;
  depth_id:any;
  loading:boolean;
  keyValue:any;
  // keyValue:{
  //   ABSD: Item
  //   ABSD_SOURCE: Item
  //   ABSGI:Item,
  //   ABSGI_SOURCE: Item
  //   CARBON: Item
  //   CAS_CODE: Item
  //   DA: Item
  //   DA_SOURCE:Item
  //   DW:Item
  //   DW_SOURCE: Item
  //   HSTRIKE: Item
  //   HSTRIKE_SOURCE: Item
  //   HYDROGEN: Item
  //   ID:Item
  //   ISKOC: Item
  //   ISKOC_SOURCE:Item
  //   ISVOC: Item
  //   IUR: Item
  //   IUR_SOURCE: Item
  //   KD: Item,
  //   KOBS: Item
  //   KOC:Item
  //   NAME_CN: Item
  //   NAME_EN: Item
  //   OXYGEN:Item
  //   RFC:Item
  //   RFC_SOURCE: Item
  //   RFDO:Item
  //   RFDO_SOURCE: Item
  //   S: Item
  //   SFO: Item
  //   SFO_SOURCE: Item
  //   STOICHIOMETRIC: Item
  //   S_SOURCE: Item,
    

  // }
}
function expandedData(props:any) {
  const pStyle = {
    fontSize: '14px',
    margin: '10px 0'
  }
  return (
    <Row>
      {
        props.content.map((value:any)=>{
        return <Col span={6}>
                <div style={pStyle}>{value}</div>    
              </Col>
        })
      }
    </Row>
  );
}
class polluteParamsTable extends React.Component<ParamsProps, ParamsState> {
  constructor(props: ParamsProps) {
    super(props);
    this.state = {
      project_id:'',
      dataSource:[],
      loading:true,
      depth_id:'',
      keyValue:{
        NAME_CN:{
          value:'中文名:',
          index:0,
        },
        NAME_EN:{
          value:'英文名:',
          index:1,
        },
        CAS_CODE:{
          value:'CAS编号:',
          index:2,
        },
        HSTRIKE:{
          value:'亨利常数(无量纲):',
          index:3,
        },
        HSTRIKE_SOURCE:{
          value:'亨利常数数据来源:',
          index:4,
        },
        DA:{
          value:'Da(cm²/s):',
          index:5,
        },
        DA_SOURCE:{
          value:'Da数据来源:',
          index:6,
        },
        DW:{
          value:'Dw(cm²/s):',
          index:8,
        },
        DW_SOURCE:{
          value:'Dw数据来源:',
          index:9,
        },
        ISKOC_SOURCE:{
          value:'Koc/Kd数据来源:',
          index:10,
        },
        S:{
          value:'溶解度(mg/L):',
          index:11,
        },
        S_SOURCE:{
          value:'溶解度数据来源:',
          index:12,
        },
        SFO:{
          value:'Sfo(mg/kg-d)-1:',
          index:13,
        },
        SFO_SOURCE:{
          value:'Sfo数据来源:',
          index:14,
        },
        IUR:{
          value:'IUR(mg/m³)-1:',
          index:15,
        },
        IUR_SOURCE:{
          value:'IUR数据来源:',
          index:16,
        },
        RFDO:{
          value:'RfDo(mg/kg-d):',
          index:18,
        },
        RFDO_SOURCE:{
          value:'RfDo数据来源:',
          index:19,
        },
        RFC:{
          value:'RfC(mg/m³)',
          index:20,
        },
        RFC_SOURCE:{
          value:'RfC数据来源:',
          index:21,
        },

        ABSGI:{
          value:'ABSgi(无量纲):',
          index:22,
        },
        ABSGI_SOURCE:{
          value:'ABSgi(无量纲)数据来源:',
          index:23,
        },
        ABSD: {
          value:'ABSd(无量纲):',
          index:24,
        },

        ABSD_SOURCE:{
          value:'ABSd(无量纲)数据来源:',
          index:25,
        }, 

        CARBON:{
          value:'Carbon atoms per molecule(n)',
          index:26,
        },
        HYDROGEN:{
          value:'Hydrogen atoms per molecule(m):',
          index:27,
        },
        OXYGEN:{
          value:'Oxygen atoms per molecule(m):',
          index:28,
        },
      
        STOICHIOMETRIC:{
          value:'Stoichiometric factor δ [g/g]:',
          index:29,
        },
        KOBS:{
          value:'反应常数:',
          index:30,
        },
        ISVOC:{
          value:'是否VOC:',
          index:31,
        },
        KD:{
          value:'Kd(cm³/g):',
          index:32,
        },
        KOC:{
          value:'Koc(cm³/g):',
          index:33,
        },
        ISKOC:{
          value:'是否基于Koc计算Kd:',
          index:34,
        },
        ID:{
          value:'编号:',
          index:35,
        },
      }
    };
  }
  async componentDidMount(){
    let project_id = checkProductd();
    if(!project_id){
      return
    }
    let Depthid = checkDepthid();
    console.log("Depthid",Depthid);
    
    if (Depthid) {
      this.setState({
        depth_id: Depthid,
      })
    }

    let params= {
      project_id:project_id,
      depth_id: Depthid,
    };
    let res = await listPollutant(params);
    if(res.code == 0){
      let data =res.data;
      let standard=Object.keys(this.state.keyValue);
      let content =new Array()
      let dataSource =new Array()
      let newobject =new Object()
       data.map((key:any)=>{
        Object.assign(newobject,{
          key: key.ID,
          NUMBER:key.ID,
          NAME_CN:key.NAME_CN,
          NAME_EN: key.NAME_EN,
          CAS_CODE:key.CAS_CODE
        })
        standard.forEach((sta:any) => { 
          Object.keys(key).forEach((id:any)=>{
            if(sta==id){
              content.splice(this.state.keyValue[sta].index,0,this.state.keyValue[sta].value+(key[sta]||'无'))
              Object.assign(newobject,{
                content
              })
            }
          })
        });
        dataSource.push(newobject)
        content =[]
        newobject={}
      })
      this.setState({
        loading:false,
        dataSource
      })
    }
  }
  filtter =()=>{

  }
  gotoTap= (step:string)=>{
    let level = getSessionLevel()

    if(step == 'back'){
      if (level) {
        this.props.history.push({
          pathname: "/project/conceptModel",
        })
        return
      } else {
        this.props.history.push({
          pathname: "/project/parmas",
        })
      }
    }
    if(step == 'next'){
      this.props.history.push({
        pathname:"/project/modelParamsTable"
      })
    }
  }
  render() {
    const columns = [
      {
        title: '序号',
        dataIndex: 'NUMBER',
        key: 'NUMBER',
      },
      {
        title: '中文名',
        dataIndex: 'NAME_CN'
      },
      {
        title: '英文名',
        dataIndex: 'NAME_EN',
      },
      {
        title: 'CAS编号',
        dataIndex: 'CAS_CODE',
      },
    ];
    return (
      <div className="ps-content">
        <Spin spinning={this.state.loading}>
          <Table
          columns={columns}
          dataSource={this.state.dataSource}
          bordered

          expandable={{
            expandedRowRender: record => expandedData(record)
          }}
        />

        <div className="exposebtn">
            <button className="formbtnleft" onClick={this.gotoTap.bind(this,'back')}>上一步</button>
            <button className="formbtnright" onClick={this.gotoTap.bind(this,'next')}>下一步</button>
        </div>
        </Spin>
      </div>
    );
  }
}

export default polluteParamsTable;
