import React, { Component } from 'react';
import { Tabs, Pagination, Row, Col, Card, Form, Input, Divider, Table, Switch, Space, Spin } from "antd";
import { contribute } from '../../../apis/common'
import { checkProductd,checkDepthid } from '../../../configs/checkProductId.js'
import "./ContributionRateCalculation.css"
import { getSessionLevel } from '../../../utils/token'


import Pie from '../../../components/echarts/pie'
import PieNot from '../../../components/echarts/piesoilNot'

// import echarts from 'echarts'
//按需导入
// import echartTheme from '../echartTheme'
import echarts from 'echarts/lib/echarts'
//导入饼图
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/markPoint'
import ReactEcharts from 'echarts-for-react'
//引入样式





const { TabPane } = Tabs;

class ContributionRateCalculation extends Component {

  state = {
    project_id: '',
    depth_id: '',
    waterCancer: [],
    waterNotCancer: [],
    soilCancer: [],
    soilNotCancer: [],
    soilgraphic: [],
    watergraphic: [],
    loading: true,
    pageSize: 6,
    pageNum: 1,
    soilMinValue: 0,
    soilMaxValue: 6,
    soilNotMinValue: 0,
    soilNotMaxValue: 6,
    waterMinValue: 0,
    waterMaxValue: 6,
    waterNotMinValue: 0,
    waterNotMaxValue: 6,
    soildata: [
      { name: '经口摄入土壤 (OIS)', en: 'OIS' },
      { name: '皮肤接触土壤 (DCS)', en: 'DCS' },
      { name: '吸入土壤颗粒物 (PIS)', en: 'PIS' },
      { name: '吸入室外空气中来自表层土壤的气态污染物 (IOV1)', en: 'IOV1' },
      { name: '吸入室外空气中来自下层土壤的气态污染物 (IOV2)', en: 'IOV2' },
      { name: '吸入室内空气中来自下层土壤的气态污染物 (IIV1)', en: 'IIV1' },
    ],
    waterdata: [
      { name: '饮用地下水 (CGW)', en: 'CGW' },
      { name: '吸入室外空气中来自地下水的气态污染物 (IOV3)', en: 'IOV3' },
      { name: '吸入室内空气中来自地下水的气态污染物 (IIV2)', en: 'IIV2' },
      
    ],
    soilCancerOption: {
      color: ['#5470C6', '#91CC75', '#FAC858', '#EE6666', '#73C0DE', '#3BA272'],
      title: [
        {
          subtext: '',
          left: '50%',
          top: '270px',
          textAlign: 'center',
          subtextStyle: {
            fontSize: 14,
            // fontWeight: "bold",
            fontFamily: "PingFang SC",
            color: " rgba(0, 0, 0, 0.85)"
          }
        },
      ],
      series: [
        {
          type: 'pie',
          name: '',
          radius: ['40%', '65%'],
          center: ['50%', '50%'],
          hoverAnimation: true,
          itemStyle: {
            borderRadius: 5,
            borderColor: '#fff',
            borderWidth: 1
          },
          label: {

            normal: {
              show: true,
              formatter: '{b} {d}%',
            },
            emphasis: {
              show: true,
              label: {
                show: true,
                fontSize: '14',
                fontWeight: 'bold'
              }
            },
          },
          labelLine: {
            length: 15,
            length2: 20,
          },

        },
      ]
    },
    
    waterCancerOption: {
      color: ['#35759C', '#FA9958', '#5CCFBC'],
      title: [
        {
          subtext: 'alignTo: "none" (default)',
          left: '50%',
          top: '270px',
          textAlign: 'center',
          subtextStyle: {
            fontSize: 14,
            // fontWeight: "bold",
            fontFamily: "PingFang SC",
            color: " rgba(0, 0, 0, 0.85)"
          }
        },
      ],
      series: [
        {
          type: 'pie',
          name: '',
          radius: ['40%', '65%'],
          center: ['50%', '50%'],
          hoverAnimation: true,
          itemStyle: {
            borderRadius: 5,
            borderColor: '#fff',
            borderWidth: 1
          },
          label: {

            normal: {
              show: true,
              formatter: '{b} {d}%',
            },
            emphasis: {
              show: true,
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
          },
          labelLine: {
            length: 15,
            length2: 20,
          },

        },
      ]
    },

  }
  soilOption = () => {
    let option = {
      color: ['#5470C6', '#91CC75', '#FAC858', '#EE6666', '#73C0DE', '#3BA272'],
      legend: {
        top: '5%',
        left: 'center',
        textStyle: {
          fontSize: 14,
          fontWeight: "400",
          fontFamily: "PingFang SC",
          // width: 110,
          // overflow: "break"
        }
      },
      series: [
        {

          type: 'pie',
          data: this.state.soilgraphic

        }

      ]

    }
    return option;

  }

  waterOption = () => {
    let option = {
      color:['#35759C', '#FA9958', '#5CCFBC'],
      legend: {
        top: '5%',
        left: 'center',
        textStyle: {
          fontSize: 14,
          fontWeight: "400",
          fontFamily: "PingFang SC",
          // width: 110,
          // overflow: "break"
        }
      },
      series: [
        {

          type: 'pie',
          data: this.state.watergraphic
        }
      ]
    }
    return option;
  }
  componentWillMount() {
    let productId = checkProductd();
    if (productId) {
      this.setState({
        project_id: productId
      })
    }
    let Depthid = checkDepthid();
    if (Depthid) {
      this.setState({
        depth_id: Depthid,
      })
    }
  }
  componentDidMount() {
    this.contribute()
  }
  contribute = async () => {
    let params = {
      project_id: this.state.project_id,
      depth_id:this.state.depth_id
    }
    try {
      let res = await contribute(params);
      let contribution = res.data.contribution;

      this.setState({
        loading: false
      })
      this.handlewater(contribution)
      this.handlesoil(contribution)
      this.handlegraphic(contribution)
    } catch (error) {
      console.log(error);
    }
  }
  handlegraphic = (contribution) => {
    let {
      graphic: {
        soil: soilgraphic,
        water: watergraphic
      }
    } = contribution;
    this.handleSoilgraphic(soilgraphic)
    this.handleWatergraphic(watergraphic)
  }
  handleSoilgraphic = (soilgraphic) => {
    let SoilgraphicData = []
    for (const i in soilgraphic) {
      SoilgraphicData.push({ [i]: soilgraphic[i] })
    }
    SoilgraphicData.map((gac, i) => {
      for (const key in gac) {
        this.state.soildata.map((item, index) => {
          if (key == item.en) {
             Object.assign(item,  {cn:gac[key]} );
            if (item.cn==false) {
              this.state.soildata.splice(index,1)
            }
          }
          
        })
      }

    })
    soilgraphic=this.state.soildata
    this.setState({
      soilgraphic
    })
  }
  handleWatergraphic = (watergraphic) => {
    let WatergraphicData = []
    for (const i in watergraphic) {
      WatergraphicData.push({ [i]: watergraphic[i] })
    }
    WatergraphicData.map((gac, i) => {
      for (const key in gac) {
        this.state.waterdata.map((item, index) => {
          if (key == item.en) {
             Object.assign(item,  {cn:gac[key]} );
            if (item.cn==false) {
              this.state.waterdata.splice(index,1)
            }
          }
          
        })
      }

    })
    watergraphic=this.state.waterdata
    this.setState({
      watergraphic
    })
  }
  handlewater = (contribution) => {
    let {
      water: {
        cancer: waterCancer,
        not_cancer: waterNotCancer
      }
    } = contribution;
    this.handleWaterCancer(waterCancer)
    this.handleWaterNotCancer(waterNotCancer)
  }
  handleWaterCancer = (waterCancer) => {
    for (let i = 0; i < waterCancer.length; i++) {
      let arr = Object.keys(waterCancer[i].data);
      if (arr.length == 0) {
        waterCancer.splice(i--, 1)
      }
    }

    this.setState({
      waterCancer
    })

  }
  handleWaterNotCancer = (waterNotCancer) => {
    for (let i = 0; i < waterNotCancer.length; i++) {
      let arr = Object.keys(waterNotCancer[i].data);
      if (arr.length == 0) {
        waterNotCancer.splice(i--, 1)
      }
    }
    this.setState({
      waterNotCancer,
    })
  }
  handlesoil = (contribution) => {
    let {
      soil: {
        cancer: soilCancer,
        not_cancer: soilNotCancer
      }
    } = contribution;
    this.handleSoilCancer(soilCancer)
    this.handleSoilNotCancer(soilNotCancer)
  }
  handleSoilCancer = (soilCancer) => {
    for (let i = 0; i < soilCancer.length; i++) {
      let arr = Object.keys(soilCancer[i].data);
      if (arr.length == 0) {
        soilCancer.splice(i--, 1)
      }
    }
    this.setState({
      soilCancer,

    })

  }
  handleSoilNotCancer = (soilNotCancer) => {
    for (let i = 0; i < soilNotCancer.length; i++) {
      let arr = Object.keys(soilNotCancer[i].data);
      if (arr.length == 0) {
        soilNotCancer.splice(i--, 1)
      }
    }

    this.setState({
      soilNotCancer,
    })
  }

  gotoTap = (step) => {
    let level = getSessionLevel()
    if (step == 'back') {
      if (level) {
        this.props.history.push({
          pathname: "/project/riskCalculation",
        })
        return
      } else {
        this.props.history.push({
          pathname: "/project/riskCalculation",
        })
      }
    }
    if (step == 'next') {
      this.props.history.push({
        pathname: "/project/controlValueCalculation"
      })
    }
  }
  //页码点击事件(分页) (重点)
  SoilChange = value => {
    if (value <= 1) {
      this.setState({
        soilMinValue: 0,
        soilMaxValue: 6
      });
    } else {
      this.setState({
        soilMinValue: (value - 1) * 6,
        soilMaxValue: (value - 1) * 6 + 6
      });
    }
  };
  SoilNotChange = value => {
    if (value <= 1) {
      this.setState({
        soilNotMinValue: 0,
        soilNotMaxValue: 6
      });
    } else {
      this.setState({
        soilNotMinValue: (value - 1) * 6,
        soilNotMaxValue: (value - 1) * 6 + 6
      });
    }
  };
  WaterChange = value => {
    if (value <= 1) {
      this.setState({
        waterMinValue: 0,
        waterMaxValue: 6
      });
    } else {
      this.setState({
        waterMinValue: (value - 1) * 6,
        waterMaxValue: (value - 1) * 6 + 6
      });
    }
  };
  WaterNotChange = value => {
    if (value <= 1) {
      this.setState({
        waterNotMinValue: 0,
        waterNotMaxValue: 6
      });
    } else {
      this.setState({
        waterNotMinValue: (value - 1) * 6,
        waterNotMaxValue: (value - 1) * 6 + 6
      });
    }
  };

  render() {

    return (
      <div className="ps-content">
        <Spin spinning={this.state.loading}>
          <Tabs
            type="card"
            defaultActiveKey='1'
            onChange={(key) => {
              console.log(key);
            }}
          >

            <TabPane tab="土壤" key="1">
              <div className="setting">
                <div className="modeliconleft">不同暴露途径的致癌风险贡献率</div>
              </div>
              <Divider></Divider>
              <ReactEcharts className='legendHei' option={this.soilOption()} />
              <div className='flex'>
                {
                  this.state.soilCancer.slice(this.state.soilMinValue, this.state.soilMaxValue).map((item, index) => {
                    return <Pie key={index} data={this.state.soilCancer.slice(this.state.soilMinValue, this.state.soilMaxValue)} index={index} dataOption={this.state.soilCancerOption}></Pie>
                  })
                }
              </div>
              <Pagination
                className='page'
                defaultCurrent={1}//	默认的当前页数
                total={this.state.soilCancer.length}//数据总数
                defaultPageSize={this.state.pageSize}//默认的每页条数
                onChange={this.SoilChange}
              />
              <div className="setting">
                <div className="modeliconleft">不同暴露途径的危害商贡献率</div>
              </div>
              <Divider></Divider>
              <ReactEcharts className='legendHei' option={this.soilOption()} />
              <div className='flex'>
                {
                  this.state.soilNotCancer.slice(this.state.soilNotMinValue, this.state.soilNotMaxValue).map((item, index) => {
                    return <PieNot key={index} data={this.state.soilNotCancer.slice(this.state.soilNotMinValue, this.state.soilNotMaxValue)} index={index} dataOption={this.state.soilCancerOption}></PieNot>
                  })
                }
              </div>
              <Pagination
                className='page'
                defaultCurrent={1}//	默认的当前页数
                total={this.state.soilNotCancer.length}//数据总数
                defaultPageSize={this.state.pageSize}//默认的每页条数
                onChange={this.SoilNotChange}
              />
            </TabPane>

            <TabPane tab="地下水" key="2">
              <div className="setting">
                <div className="modeliconleft">不同暴露途径的致癌风险贡献率</div>
              </div>
              <Divider></Divider>
              <ReactEcharts className='legendHei' option={this.waterOption()} />
              <div className='flex'>
                {
                  this.state.waterCancer.slice(this.state.waterMinValue, this.state.waterMaxValue).map((item, index) => {
                    return <Pie key={index} data={this.state.waterCancer.slice(this.state.waterMinValue, this.state.waterMaxValue)} index={index} dataOption={this.state.waterCancerOption}></Pie>
                  })
                }

              </div>
              <Pagination

                className='page'
                defaultCurrent={1}//	默认的当前页数
                total={this.state.waterCancer.length}//数据总数
                defaultPageSize={this.state.pageSize}//默认的每页条数
                onChange={this.WaterChange}
              />
              <div className="setting">
                <div className="modeliconleft">不同暴露途径的致癌风险贡献率</div>
              </div>
              <Divider></Divider>
              <ReactEcharts className='legendHei' option={this.waterOption()} />
              <div className='flex'>
                {
                  this.state.waterNotCancer.slice(this.state.waterNotMinValue, this.state.waterNotMaxValue).map((item, index) => {
                    return <Pie key={index} data={this.state.waterNotCancer.slice(this.state.waterNotMinValue, this.state.waterNotMaxValue)} index={index} dataOption={this.state.waterCancerOption}></Pie>
                  })
                }

              </div>
              <Pagination
                className='page'
                defaultCurrent={1}//	默认的当前页数
                total={this.state.waterNotCancer.length}//数据总数
                defaultPageSize={this.state.pageSize}//默认的每页条数
                onChange={this.WaterNotChange}
              />
            </TabPane>






          </Tabs>
          <div className="exposebtn">
            <button className="formbtnleft" onClick={this.gotoTap.bind(this, 'back')}>上一步</button>
            <button className="formbtnright" onClick={this.gotoTap.bind(this, 'next')}>下一步</button>
          </div>
        </Spin>
      </div>
    );
  }
}

export default ContributionRateCalculation;
