import { Select } from 'antd';
import React from 'react'
import provinces from '../../assets/database/provinces.json'
import cities from '../../assets/database/province-city.json'

const { Option } = Select;
const provinceData = provinces;
const cityData = cities;

const ProvinceAndCityCascader = () => {

  const [cities, setCities] = React.useState(cityData[provinceData[0]["name"]]);
  const [secondCity, setSecondCity] = React.useState(cityData[provinceData[0]["name"]][0]);

  const handleProvinceChange = value => {
    console.log(value)
    setCities(cityData[value]);
    setSecondCity(cityData[value][0]);
  };

  const onSecondCityChange = value => {
    setSecondCity(value);
  };

  return (
    <div>
      <Select defaultValue={provinceData[0]["name"]} style={{ width: 120 }} onChange={handleProvinceChange}>
        {provinceData.map(province => (
          <Option key={province["name"]}>{province["name"]}</Option>
        ))}
      </Select>
      <Select style={{ width: 120 }} value={secondCity} onChange={onSecondCityChange}>
        {cities.map(city => (
          <Option key={city}>{city}</Option>
        ))}
      </Select>
    </div>
  );
};


export default ProvinceAndCityCascader;