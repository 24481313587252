import * as React from "react";
import { Select, Tabs, Row, Col, Form, message, Divider, Spin, Input, Modal } from "antd";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import FirstLevel from "./firstLevel"
import TwoLevel from "./twoLevel"
import ThreeLevel from "./threeLevel"
import { checkProductd, checkDepthid } from '../../../configs/checkProductId.js'
import { getUser, setUser, getSessionProductId, getToken, getSessionLevel } from '../../../utils/token'
import "./generalReport.less";
import { CheckOutlined } from '@ant-design/icons';
import { date } from '../../../configs/filterTime'
import { firstReport, secondReport, reportPath, saveExpertConsultation } from '../../../apis/common'
import watermark from 'watermark-dom';
import ReportTable from '../../../components/reportTable/reportTable'
import ReportTableCheck from '../../../components/reportTableCheck/reportTableCheck'
import LineImg from '../../../components/lineImg/lineImg'
import Model from '../../../components/controlModel/controlModel'

const { TextArea } = Input;

export interface Props {
  history: any

}

export interface State {
  loading: any,
  base_info: any,
  soil_pollutant: any,
  water_pollutant: any,
  project_id: string,
  depth_id: string,
  level: string,
  GetSessionLevel: string,
  all_param_value: any,
  physical: any,
  project_model: any,
  toxicity: any,
  toxicityItem: any,
  soilControl: any,
  waterControl: any,
  soilCancer: any,
  soilNotCancer: any,
  exsoilCancer: any,
  exsoilNotCancer: any,
  exwaterCancer: any,
  exwaterNotCancer: any,
  contributionsoil: any,
  contributionsoilNot: any,
  contributionwater: any,
  contributionwaterNot: any,
  soilgraphic: any,
  watergraphic: any,
  graphicArr: any,
  report_water: any,
  token: string,
  SessionProductId: string,
  User: any,
  resDataList: any,
  pdfs: any;
  content: string,
  phone: string,
  consultData: any,
  index: string,

  soil_columns: any,
  water_columns: any,
  parameter: any,
  toxicity_columns: any,
  physical_columns: any,
  cancerRiskSoil: any,
  soilNotCancer_columns: any,
  report_water_columns: any,
  waterControl_columns: any,
  soilCancerControl: any,
  soilNotCancerControl: any,
  soilCancerexposure: any,
  soilNotCancerexposure: any,
  waterCancerexposure: any,
  waterNotCancerexposure: any,
  contributionsoil_columns: any,
  contributionsoilNot_columns: any,
  contributionwater_columns: any,
  contributionwaterNot_columns: any,
  graphicArr_columns: any,

  soil_cancer_sensitivity: any,
  soil_not_cancer_sensitivity: any,
  water_cancer_sensitivity: any,
  water_not_cancer_sensitivity: any,
  soil_cancer_sensitivityColumns: any,
  soil_not_cancer_sensitivityColumns: any,
  water_cancer_sensitivityColumns: any,
  water_not_cancer_sensitivityColumns: any,

}

class generalReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      base_info: [],
      soil_pollutant: [],
      water_pollutant: [],
      all_param_value: [],
      physical: [],
      loading: true,
      project_model: {},
      toxicity: [],
      toxicityItem: [],
      soilControl: [],
      waterControl: [],
      soilCancer: [],
      soilNotCancer: [],
      exsoilCancer: [],
      exsoilNotCancer: [],
      exwaterCancer: [],
      exwaterNotCancer: [],
      contributionsoil: [],
      contributionsoilNot: [],
      contributionwater: [],
      contributionwaterNot: [],
      report_water: [],
      soilgraphic: [],
      watergraphic: [],
      graphicArr: [],
      project_id: '',
      depth_id: "",
      level: '',
      GetSessionLevel: '',
      token: '',
      index: '',
      SessionProductId: '',
      User: [],
      resDataList: [],
      pdfs: React.createRef(),
      content: '',
      phone: '',
      consultData: false,

      soil_cancer_sensitivity: [],
      soil_not_cancer_sensitivity: [],
      water_cancer_sensitivity: [],
      water_not_cancer_sensitivity: [],
      soil_cancer_sensitivityColumns: [],
      soil_not_cancer_sensitivityColumns: [],
      water_cancer_sensitivityColumns: [],
      water_not_cancer_sensitivityColumns: [],
      soil_columns: [
        {
          title: '序号',
          dataIndex: 'no',
          className: 'column-money'
        },
        {
          title: '污染物名称',
          dataIndex: 'name_cn',
          className: 'column-money'
        },
        {
          title: 'CAS编号',
          dataIndex: 'cas_code',
          className: 'column-money'
        },
        {
          title: '最大暴露含量(mg.kg-1)',
          dataIndex: 'concentration',
          className: 'column-money'
        },
        {
          title: '背景值',
          className: 'column-money',
          dataIndex: 'background_value'
        },
        {
          title: '是否标准库',
          dataIndex: 'is_standard',
          className: 'column-money'
        },
        {
          title: '筛选值(mg.kg-1)',
          dataIndex: 'screen',
          className: 'column-money'
        },
        {
          title: '管制值(mg.kg-1)',
          dataIndex: 'control',
          className: 'column-money'
        },
        {
          title: '筛选值来源',
          dataIndex: 'source',
          className: 'column-money'
        },
        {
          title: '筛选结果',
          dataIndex: 'conclusion',
          className: 'column-money',
          isColor: true
        },
        {
          title: '是否列为关注污染物',
          dataIndex: 'attention',
          className: 'column-money',
        }
      ],
      water_columns: [
        {
          title: '序号',
          dataIndex: 'no',
          className: 'column-money'
        },
        {
          title: '污染物名称',
          dataIndex: 'name_cn',
          className: 'column-money'
        },
        {
          title: 'CAS编号',
          dataIndex: 'cas_code',
          className: 'column-money'
        },
        {
          title: '最大暴露浓度(mg.kg-1)',
          dataIndex: 'concentration',
          className: 'column-money'
        },
        {
          title: '背景值',
          className: 'column-money',
          dataIndex: 'background_value'
        },
        {
          title: '是否标准库',
          dataIndex: 'is_standard',
          className: 'column-money'
        },
        {
          title: 'III类标准值(mg.kg-1)',
          dataIndex: 'three',
          className: 'column-money'
        },
        {
          title: 'IV类标准值(mg.kg-1)',
          dataIndex: 'four',
          className: 'column-money'
        },
        {
          title: '筛选值来源',
          dataIndex: 'source',
          className: 'column-money'
        },
        {
          title: '超出标准限值',
          dataIndex: 'conclusion',
          className: 'column-money',
          isColor: true
        },
        {
          title: '是否列为关注污染物',
          dataIndex: 'attention',
          className: 'column-money',
        }
      ],
      parameter: [
        {
          title: '序号',
          dataIndex: 'id',
          className: 'column-money'
        },
        {
          title: '参数符号',
          dataIndex: 'symbol',
          className: 'column-money'
        },
        {
          title: '参数名称',
          dataIndex: 'meaning',
          className: 'column-money'
        },
        {
          title: '实际取值',
          dataIndex: 'real',
          className: 'column-money',
          isColor: true
        },
        {
          title: '推荐值',
          dataIndex: 'default',
          className: 'column-money'
        }
      ],
      toxicity_columns: [
        {
          title: '序号',
          dataIndex: 'ID',
          className: 'column-money'
        },
        {
          title: '中文名',
          dataIndex: 'NAME_CN',
          className: 'column-money'
        },
        {
          title: '英文名',
          dataIndex: 'NAME_EN',
          className: 'column-money'
        },
        {
          title: 'CAS编号',
          dataIndex: 'CAS_CODE',
          className: 'column-money'
        },
        {
          title: 'Sfo(mg/kg-d)-1',
          dataIndex: 'SFO',
          className: 'column-money'
        },
        {
          title: 'IUR(mg/m3)-1',
          dataIndex: 'IUR',
          className: 'column-money'
        },
        {
          title: 'RfDo(mg/kg-d)',
          dataIndex: 'RFDO',
          className: 'column-money'
        },
        {
          title: 'RfC(mg/m3)',
          dataIndex: 'RFC',
          className: 'column-money'
        },
        {
          title: 'ABSd(无量纲)',
          dataIndex: 'ABSD',
          className: 'column-money'
        },
        {
          title: 'ABSgi(无量纲)',
          dataIndex: 'ABSGI',
          className: 'column-money'
        }
      ],
      physical_columns: [
        {
          title: '序号',
          dataIndex: 'ID',
          className: 'column-money'
        },
        {
          title: '中文名',
          dataIndex: 'NAME_CN',
          className: 'column-money'
        },
        {
          title: '英文名',
          dataIndex: 'NAME_EN',
          className: 'column-money'
        },
        {
          title: 'CAS编号',
          dataIndex: 'CAS_CODE',
          className: 'column-money'
        },
        {
          title: 'Hstrike',
          dataIndex: 'HSTRIKE',
          className: 'column-money'
        },
        {
          title: 'Da(cm2/s)',
          dataIndex: 'DA',
          className: 'column-money'
        },
        {
          title: 'Dw(cm2/s)',
          dataIndex: 'DW',
          className: 'column-money'
        },
        {
          title: 'Koc(cm3/g)',
          dataIndex: 'KOC',
          className: 'column-money'
        },
        {
          title: 'Kd',
          dataIndex: 'KD',
          className: 'column-money'
        },
        {
          title: 'S(mg / L)',
          dataIndex: 'S',
          className: 'column-money'
        }
      ],
      cancerRiskSoil: [
        {
          title: '序号',
          dataIndex: 'id',
          className: 'column-money'
        },
        {
          title: '中文名',
          dataIndex: 'name_cn',
          className: 'column-money'
        },
        {
          title: '英文名',
          dataIndex: 'name_en',
          className: 'column-money'
        },
        {
          title: 'CAS编号',
          dataIndex: 'cas_code',
          className: 'column-money'
        },
        {
          title: '暴露含量',
          dataIndex: 'value',
          className: 'column-money'
        },
        {
          title: '致癌经口摄入',
          dataIndex: 'CRois',
          className: 'column-money'
        },
        {
          title: '致癌皮肤接触',
          dataIndex: 'CRdcs',
          className: 'column-money'
        },
        {
          title: '致癌吸入土壤颗粒物',
          dataIndex: 'CRpis',
          className: 'column-money'
        },
        {
          title: '致癌吸入室外空气中来自表层土壤中的气态污染物',
          dataIndex: 'CRiov1',
          className: 'column-money'
        },
        {
          title: '致癌吸入室外空气中来自下层土壤中的气态污染物',
          dataIndex: 'CRiov2',
          className: 'column-money'
        },
        {
          title: '致癌吸入室内空气中来自下层土壤中的气态',
          dataIndex: 'CRiiv1',
          className: 'column-money'
        },
        {
          title: '总风险水平',
          dataIndex: 'zhiAiValue',
          className: 'column-money'
        }
      ],
      soilNotCancer_columns: [
        {
          title: '序号',
          dataIndex: 'id',
          className: 'column-money'
        },
        {
          title: '中文名',
          dataIndex: 'name_cn',
          className: 'column-money'
        },
        {
          title: '英文名',
          dataIndex: 'name_en',
          className: 'column-money'
        },
        {
          title: 'CAS编号',
          dataIndex: 'cas_code',
          className: 'column-money'
        },
        {
          title: '暴露含量',
          dataIndex: 'value',
          className: 'column-money'
        },
        {
          title: '非致癌经口摄入',
          dataIndex: 'HQois',
          className: 'column-money'
        },
        {
          title: '非致癌皮肤接触',
          dataIndex: 'HQdcs',
          className: 'column-money'
        },
        {
          title: '非致癌吸入土壤颗粒物',
          dataIndex: 'HQpis',
          className: 'column-money'
        },
        {
          title: '非致癌吸入室外空气中来自表层土壤中的气态污染物',
          dataIndex: 'HQiov1',
          className: 'column-money'
        },
        {
          title: '非致癌吸入室外空气中来自下层土壤中的气态污染物',
          dataIndex: 'HQiov2',
          className: 'column-money'
        },
        {
          title: '非致癌吸入室内空气中来自下层土壤中的气态',
          dataIndex: 'HQiiv1',
          className: 'column-money'
        },
        {
          title: '总危害指数',
          dataIndex: 'feiZhiAiValue',
          className: 'column-money'
        }
      ],
      report_water_columns: [
        {
          title: '序号',
          dataIndex: 'id',
          className: 'column-money'
        },
        {
          title: '中文名',
          dataIndex: 'name_cn',
          className: 'column-money'
        },
        {
          title: '英文名',
          dataIndex: 'name_en',
          className: 'column-money'
        },
        {
          title: 'CAS编号',
          dataIndex: 'cas_code',
          className: 'column-money'
        },
        {
          title: '暴露浓度',
          dataIndex: 'value',
          className: 'column-money'
        },
        {
          title: '致癌饮用',
          dataIndex: 'CRcgw',
          className: 'column-money'
        },
        {
          title: '致癌室内吸入',
          dataIndex: 'CRiiv2',
          className: 'column-money'
        },
        {
          title: '致癌室外吸入',
          dataIndex: 'CRiov3',
          className: 'column-money'
        },
        {
          title: '总风险水平',
          dataIndex: 'zhiAiDXValue',
          className: 'column-money'
        },
        {
          title: '非致癌饮用',
          dataIndex: 'HQcgw',
          className: 'column-money'
        },
        {
          title: '非致癌室内吸入',
          dataIndex: 'HQiiv2',
          className: 'column-money'
        },
        {
          title: '非致癌室外吸入',
          dataIndex: 'HQiov3',
          className: 'column-money'
        },
        {
          title: '总危害指数',
          dataIndex: 'feiZhiAiDXValue',
          className: 'column-money'
        },
      ],
      waterControl_columns: [
        {
          title: '序号',
          dataIndex: 'id',
          className: 'column-money'
        },
        {
          title: '中文名',
          dataIndex: 'name_cn',
          className: 'column-money'
        },
        {
          title: '英文名',
          dataIndex: 'name_en',
          className: 'column-money'
        },
        {
          title: 'CAS编号',
          dataIndex: 'cas_code',
          className: 'column-money'
        },
        {
          title: '致癌饮用',
          dataIndex: 'RCVScgw',
          className: 'column-money'
        },
        {
          title: '致癌室内吸入',
          dataIndex: 'RCVSiiv2',
          className: 'column-money'
        },
        {
          title: '致癌室外吸入',
          dataIndex: 'RCVSiov3',
          className: 'column-money'
        },
        {
          title: '综合致癌控制值',
          dataIndex: 'zhiAiDXControlValue',
          className: 'column-money'
        },
        {
          title: '非致癌饮用',
          dataIndex: 'HCVScgw',
          className: 'column-money'
        },
        {
          title: '非致癌室内吸入',
          dataIndex: 'HCVSiiv2',
          className: 'column-money'
        },
        {
          title: '非致癌室外吸入',
          dataIndex: 'HCVSiov3',
          className: 'column-money'
        },
        {
          title: '综合非致癌控制值',
          dataIndex: 'feiZhiAiDXControlValue',
          className: 'column-money'
        },
        {
          title: '风险控制值',
          dataIndex: 'total',
          className: 'column-money'
        }
      ],
      soilCancerControl: [
        {
          title: '序号',
          dataIndex: 'id',
          className: 'column-money'
        },
        {
          title: '中文名',
          dataIndex: 'name_cn',
          className: 'column-money'
        },
        {
          title: '英文名',
          dataIndex: 'name_en',
          className: 'column-money'
        },
        {
          title: 'CAS编号',
          dataIndex: 'cas_code',
          className: 'column-money'
        },
        {
          title: '致癌经口摄入',
          dataIndex: 'RCVSois',
          className: 'column-money'
        },
        {
          title: '致癌皮肤接触',
          dataIndex: 'RCVSdcs',
          className: 'column-money'
        },
        {
          title: '致癌吸入土壤颗粒物',
          dataIndex: 'RCVSpis',
          className: 'column-money'
        },
        {
          title: '致癌吸入室外空气中来自表层土壤中的气态污染物',
          dataIndex: 'RCVSiov1',
          className: 'column-money'
        },
        {
          title: '致癌吸入室外空气中来自下层土壤中的气态污染物',
          dataIndex: 'RCVSiov2',
          className: 'column-money'
        },
        {
          title: '致癌吸入室内空气中来自下层土壤中的气态',
          dataIndex: 'RCVSiiv1',
          className: 'column-money'
        },
        {
          title: '综合致癌控制值',
          dataIndex: 'zhiAiControlValue',
          className: 'column-money'
        }
      ],
      soilNotCancerControl: [
        {
          title: '序号',
          dataIndex: 'id',
          className: 'column-money'
        },
        {
          title: '中文名',
          dataIndex: 'name_cn',
          className: 'column-money'
        },
        {
          title: '英文名',
          dataIndex: 'name_en',
          className: 'column-money'
        },
        {
          title: 'CAS编号',
          dataIndex: 'cas_code',
          className: 'column-money'
        },
        {
          title: '非致癌经口摄入',
          dataIndex: 'HCVSois',
          className: 'column-money'
        },
        {
          title: '非致癌皮肤接触',
          dataIndex: 'HCVSdcs',
          className: 'column-money'
        },
        {
          title: '非致癌吸入土壤颗粒物',
          dataIndex: 'HCVSpis',
          className: 'column-money'
        },
        {
          title: '非致癌吸入室外空气中来自表层土壤中的气态污染物',
          dataIndex: 'HCVSiov1',
          className: 'column-money'
        },
        {
          title: '非致癌吸入室外空气中来自下层土壤中的气态污染物',
          dataIndex: 'HCVSiov2',
          className: 'column-money'
        },
        {
          title: '非致癌吸入室内空气中来自下层土壤中的气态',
          dataIndex: 'HCVSiiv1',
          className: 'column-money'
        },
        {
          title: '综合非致癌控制值',
          dataIndex: 'feiZhiAiControlValue',
          className: 'column-money'
        },
        {
          title: '风险控制值',
          dataIndex: 'total',
          className: 'column-money'
        }
      ],
      soilCancerexposure: [
        {
          title: '序号',
          dataIndex: 'id',
          className: 'column-money'
        },
        {
          title: '中文名',
          dataIndex: 'name_cn',
          className: 'column-money'
        },
        {
          title: '英文名',
          dataIndex: 'name_en',
          width: 200,
          className: 'column-money'
        },
        {
          title: 'CAS编号',
          dataIndex: 'cas_code',
        },
        {
          title: '经口摄入土壤',
          dataIndex: 'OISERca',
          key: 'OISERca',

        },
        {
          title: '皮肤接触土壤',
          dataIndex: 'DCSERca',

        },
        {
          title: '吸入土壤颗粒物',
          dataIndex: 'PIERca',

        },
        {
          title: '吸入室外空气中来自表层土壤的气态污染物',
          dataIndex: 'IOVERca1',

        },
        {
          title: '吸入室外空气中来自下层土壤的气态污染物',
          dataIndex: 'IOVERca2',

        },
        {
          title: '吸入室内空气中来自下层土壤的气态污染物',
          dataIndex: 'IIVERca1',

        },
      ],
      soilNotCancerexposure: [
        {
          title: '序号',
          dataIndex: 'id',
          className: 'column-money'
        },
        {
          title: '中文名',
          dataIndex: 'name_cn',
          className: 'column-money'
        },
        {
          title: '英文名',
          dataIndex: 'name_en',
          className: 'column-money'
        },
        {
          title: 'CAS编号',
          dataIndex: 'cas_code',
          className: 'column-money'
        },
        {
          title: '经口摄入土壤',
          dataIndex: 'OISERnc',
          className: 'column-money'
        },
        {
          title: '皮肤接触土壤',
          dataIndex: 'DCSERnc',
          className: 'column-money'
        },
        {
          title: '吸入土壤颗粒物',
          dataIndex: 'PIERnc',
          className: 'column-money'
        },
        {
          title: '吸入室外空气中来自表层土壤的气态污染物',
          dataIndex: 'IOVERnc1',
          className: 'column-money'
        },
        {
          title: '吸入室外空气中来自下层土壤的气态污染物',
          dataIndex: 'IOVERnc2',
          className: 'column-money'
        },
        {
          title: '吸入室内空气中来自下层土壤的气态污染物',
          dataIndex: 'IIVERnc1',
          className: 'column-money'
        },

      ],
      waterCancerexposure: [
        {
          title: '序号',
          dataIndex: 'id',
          className: 'column-money'
        },
        {
          title: '中文名',
          dataIndex: 'name_cn',
          className: 'column-money'
        },
        {
          title: '英文名',
          dataIndex: 'name_en',
          width: 200,
          className: 'column-money'
        },
        {
          title: 'CAS编号',
          dataIndex: 'cas_code',
        },
        {
          title: '吸入室外空气中来自地下水的气态污染物',
          dataIndex: 'IOVERca3',
          key: 'IOVERca3',

        },
        {
          title: '吸入室内空气中来自地下水的气态污染物',
          dataIndex: 'IIVERca2',

        },
        {
          title: '饮用地下水',
          dataIndex: 'CGWERca',

        },
      ],
      waterNotCancerexposure: [
        {
          title: '序号',
          dataIndex: 'id',
          className: 'column-money'
        },
        {
          title: '中文名',
          dataIndex: 'name_cn',
          className: 'column-money'
        },
        {
          title: '英文名',
          dataIndex: 'name_en',
          className: 'column-money'
        },
        {
          title: 'CAS编号',
          dataIndex: 'cas_code',
          className: 'column-money'
        },
        {
          title: '吸入室外空气中来自地下水的气态污染物',
          dataIndex: 'IOVERnc3',
          className: 'column-money'
        },
        {
          title: '吸入室内空气中来自地下水的气态污染物',
          dataIndex: 'IIVERnc2',
          className: 'column-money'
        },
        {
          title: '饮用地下水',
          dataIndex: 'CGWERnc',
          className: 'column-money'
        },

      ],
      contributionsoil_columns: [
        {
          title: '序号',
          dataIndex: 'id',
          className: 'column-money'
        },
        {
          title: '中文名',
          dataIndex: 'name_cn',
          className: 'column-money'
        },
        {
          title: '英文名',
          dataIndex: 'name_en',
          width: 200,
          className: 'column-money'
        },
        {
          title: 'CAS编号',
          dataIndex: 'cas_code',
        },
        {
          title: '经口摄入',
          dataIndex: 'OIS',
          className: 'column-money'
        },
        {
          title: '皮肤接触',
          dataIndex: 'DCS',
          className: 'column-money'
        },
        {
          title: '吸入土壤颗粒物',
          dataIndex: 'PIS',
          className: 'column-money'
        },
        {
          title: '吸入室外空气中来自表层土壤中的气态污染物',
          dataIndex: 'IOV1',
          className: 'column-money'
        },
        {
          title: '吸入室外空中来自下层土壤中的气态污染物',
          dataIndex: 'IOV2',
          className: 'column-money'
        },
        {
          title: '吸入室内空气中来自下层土壤中的气态',
          dataIndex: 'IIV1',
          className: 'column-money'
        },
        {
          title: '合计',
          dataIndex: 'total',
          className: 'column-money'
        },
      ],
      contributionsoilNot_columns: [
        {
          title: '序号',
          dataIndex: 'id',
          className: 'column-money'
        },
        {
          title: '中文名',
          dataIndex: 'name_cn',
          className: 'column-money'
        },
        {
          title: '英文名',
          dataIndex: 'name_en',
          width: 200,
          className: 'column-money'
        },
        {
          title: 'CAS编号',
          dataIndex: 'cas_code',
        },
        {
          title: '经口摄入',
          dataIndex: 'OIS',
          className: 'column-money'
        },
        {
          title: '皮肤接触',
          dataIndex: 'DCS',
          className: 'column-money'
        },
        {
          title: '吸入土壤颗粒物',
          dataIndex: 'PIS',
          className: 'column-money'
        },
        {
          title: '吸入室外空气中来自表层土壤中的气态污染物',
          dataIndex: 'IOV1',
          className: 'column-money'
        },
        {
          title: '吸入室外空中来自下层土壤中的气态污染物',
          dataIndex: 'IOV2',
          className: 'column-money'
        },
        {
          title: '吸入室内空气中来自下层土壤中的气态',
          dataIndex: 'IIV1',
          className: 'column-money'
        },
        {
          title: '合计',
          dataIndex: 'total',
          className: 'column-money'
        },
      ],
      contributionwater_columns: [
        {
          title: '序号',
          dataIndex: 'id',
          className: 'column-money'
        },
        {
          title: '中文名',
          dataIndex: 'name_cn',
          className: 'column-money'
        },
        {
          title: '英文名',
          dataIndex: 'name_en',
          width: 200,
          className: 'column-money'
        },
        {
          title: 'CAS编号',
          dataIndex: 'cas_code',
        },
        {
          title: '吸入室外地下水蒸气',
          dataIndex: 'IOV3',
          className: 'column-money'
        },
        {
          title: '吸入室内地下水蒸气',
          dataIndex: 'IIV2',
          className: 'column-money'
        },
        {
          title: '饮用地下水',
          dataIndex: 'CGW',
          className: 'column-money'
        },
        {
          title: '合计',
          dataIndex: 'total',
          className: 'column-money'
        },
      ],
      contributionwaterNot_columns: [
        {
          title: '序号',
          dataIndex: 'id',
          className: 'column-money'
        },
        {
          title: '中文名',
          dataIndex: 'name_cn',
          className: 'column-money'
        },
        {
          title: '英文名',
          dataIndex: 'name_en',
          width: 200,
          className: 'column-money'
        },
        {
          title: 'CAS编号',
          dataIndex: 'cas_code',
        },
        {
          title: '吸入室外地下水蒸气',
          dataIndex: 'IOV3',
          className: 'column-money'
        },
        {
          title: '吸入室内地下水蒸气',
          dataIndex: 'IIV2',
          className: 'column-money'
        },
        {
          title: '饮用地下水',
          dataIndex: 'CGW',
          className: 'column-money'
        },
        {
          title: '合计',
          dataIndex: 'total',
          className: 'column-money'
        },
      ],
      graphicArr_columns: [
        // {
        //   title: '序号',
        //   dataIndex: 'id',
        //   className: 'column-money'
        // },
        // {
        //   title: '暴露途径',
        //   dataIndex: 'cn',
        //   className: 'column-money'
        // },
        // {
        //   title: `${this.filtterType(2)}`,
        //   dataIndex: 'url',
        //   className: 'column-money'
        // },

      ]
    };
  }
  componentWillMount() {
    let productId = checkProductd();
    if (productId) {
      this.setState({
        project_id: productId
      })
      this.getLevel(productId)
    }
    let Depthid = checkDepthid();
    if (Depthid) {
      this.setState({
        depth_id: Depthid,
      })
    }
    let SessionLevel = getSessionLevel()
    console.log("SessionLevel", SessionLevel);

    if (SessionLevel) {
      this.setState({
        GetSessionLevel: SessionLevel,
      })

    }
    console.log("SessionLevel", SessionLevel, this.state.GetSessionLevel);

  }
  getLevel = async (productId: any) => {
    let res = await reportPath({
      project_id: productId
    })
    console.log('res.data.toString()', res.data);

    this.setState({
      level: res.data.toString()
    })
    if (res.data == '1') {
      this.getfirstReport(productId)
    }
    if (res.data == '2') {
      this.getSecondReport(productId)
    }
  }
  async getfirstReport(project_id: any) {
    await firstReport({
      project_id
    }).then((res: any) => {
      let {
        base_info,
        soil_pollutant,
        water_pollutant
      } = res.data;
      this.setState({
        base_info,
        soil_pollutant,
        water_pollutant,
        loading: false

      })
    }).catch(() => {

    })
  }
  async getSecondReport(project_id: any) {
    let dataObj = new Object()
    let graphicdataArr = new Array()
    let soil_cancer_sensitivity = new Array()
    let soil_not_cancer_sensitivity = new Array()
    let water_cancer_sensitivity = new Array()
    let water_not_cancer_sensitivity = new Array()
    let soil_cancer_sensitivityColumns = new Array()
    let soil_not_cancer_sensitivityColumns = new Array()
    let water_cancer_sensitivityColumns = new Array()
    let water_not_cancer_sensitivityColumns = new Array()
    await secondReport({
      project_id
    }).then((res: any) => {
      console.log("res", res.data);

      res.data.map((item: any, index: any) => {
        let {

          contribution: {

            graphic: {
              soil: soilgraphic,
              water: watergraphic
            },
          },
        } = item.risk;
        graphicdataArr = Object.assign(soilgraphic, watergraphic);

        item.graphicdataArr = graphicdataArr

        item.sensitivity.risk.soil.soil_cancer_sensitivity = soil_cancer_sensitivity
        item.sensitivity.risk.soil.soil_not_cancer_sensitivity = soil_not_cancer_sensitivity
        item.sensitivity.risk.water.water_cancer_sensitivity = water_cancer_sensitivity
        item.sensitivity.risk.water.water_not_cancer_sensitivity = water_not_cancer_sensitivity

        item.sensitivity.risk.soil.soil_cancer_sensitivityColumns = soil_cancer_sensitivityColumns
        item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns = soil_not_cancer_sensitivityColumns
        item.sensitivity.risk.water.water_cancer_sensitivityColumns = water_cancer_sensitivityColumns
        item.sensitivity.risk.water.water_not_cancer_sensitivityColumns = water_not_cancer_sensitivityColumns
        this.multilevelRisk(res.data[index], index)
      })

      this.setState({
        resDataList: res.data,
        loading: false
      })

    }).catch(() => {

    })



  }
  multilevelRisk = (data: any, index: any) => {
    let {
      base_info,
      soil_pollutant,
      water_pollutant,
      all_param_value,
      physical,
      project_model,
      toxicity,
      risk,
      graphicdataArr,
      sensitivity
    } = data;
    console.log("111111111111toxicity", toxicity);

    let {
      risk: {
        soil: {
          cancer: soilCancer_sensitivity,
          not_cancer: soilNotCancer_sensitivity
        },
        water: {
          cancer: waterCancer_sensitivity,
          not_cancer: waterNotCancer_sensitivity
        }
      },

    } = sensitivity;
    console.log("sensitivity", sensitivity);

    this.handleRisk(risk, 1)
    this.handleWaterCancer(sensitivity.risk.water)
    this.handleWaterNotCancer(sensitivity.risk.water)
    this.handleSoilCancer(sensitivity.risk.soil)
    this.handleSoilNotCancer(sensitivity.risk.soil)


    let toxicityArr = [
      'ABSD',
      'ABSGI',
      'CAS_CODE',
      'IUR',
      'NAME_CN',
      'NAME_EN',
      'RFC',
      'RFDO',
      'SFO',
      'HSTRIKE',
    ]
    let physicalArr = [
      'CAS_CODE',
      'DA',
      'DW',
      'HSTRIKE',
      'KD',
      'KOC',
      'NAME_CN',
      'NAME_EN',
      'S',
    ]
    let graphicArrcolumns = [
      { id: 1, cn: '径口摄入土壤', en: 'OIS' },
      { id: 2, cn: '皮肤接触土壤颗粒物', en: 'DCS' },
      { id: 3, cn: '吸入土壤颗粒物', en: 'PIS' },
      { id: 4, cn: '吸入室外表层挥发性污染物', en: 'IOV1' },
      { id: 5, cn: '吸入室外下层挥发性污染物', en: 'IOV2' },
      { id: 6, cn: '吸入室内下层挥发性污染物', en: 'IIV1' },
      { id: 7, cn: '饮用地下水', en: 'CGW' },
      { id: 8, cn: '吸入室外地下水挥发性污染物蒸气', en: 'IOV3' },
      { id: 9, cn: '吸入室内地下水挥发性污染物蒸气', en: 'IIV2' },
    ]

    graphicArrcolumns.map((item: any, index: any) => {

      for (const i in graphicdataArr) {


        if (item.en == i) {
          return Object.assign(item, { url: graphicdataArr[i] });
        }


      }
    })
    data.graphicdataArr = graphicArrcolumns


    toxicity.map((columns: any, index: any) => {
      toxicityArr.map((str: any, index: any) => {
        if (!columns[str] || columns[str] == null) {

          return columns[str] = '-'
        } else {

        }
      })
    })

    physical.map((columns: any, index: any) => {
      physicalArr.map((str: any, index: any) => {
        if (!columns[str]) {
          return columns[str] = '-'
        }
      })
    })

    this.setState({
      base_info,
      soil_pollutant,
      water_pollutant,
      all_param_value,
      physical,
      project_model,
      toxicity,
      graphicArr: graphicArrcolumns
    })
    console.log("water_pollutant", this.state.water_pollutant);

  }

  handleWaterCancer = (waterCancer: any) => {
    console.log("waterCancer", waterCancer);

    let Columns = [

      {
        title: '参数名称',
        dataIndex: 'name_en',
        key: 'name_en',
        fixed: 'left',
        width: 100,
      },
      {
        title: '参数含义',
        dataIndex: 'name_cn',
        key: 'name_cn',
        fixed: 'left',
        width: 100,
      },
    ]
    let dataSourceColumns = new Array()
    let newobjectColumns = new Object()
    waterCancer.cancer.forEach((item: any, index: any) => {



      Object.assign(newobjectColumns, {
        title: item.name_cn,
        key: item.name_en,
        id: item.name_en,
        dataIndex: item.name_en,

        width: 150,
      })
      dataSourceColumns.push(newobjectColumns)
      newobjectColumns = {}


    });
    console.log("dataSourceColumns", dataSourceColumns);

    let dataColumns = new Array()
    let size = 9
    let [start, end, result] = [0, 0, []];
    let listForColumns = new Array()
    if (dataSourceColumns.length > 9) {
      for (let i = 0; i < Math.ceil(dataSourceColumns.length / size); i++) {
        start = i * size;
        end = start + size;
        dataColumns.push(Columns.concat(dataSourceColumns.slice(start, end)))



      }
    } else {
      dataColumns = Columns.concat(dataSourceColumns)
    }


    console.log("dataColumns", dataColumns);
    let headerArr = new Array()
    let headerObj = new Object()
    waterCancer.cancer[0].item.forEach((ite: any, ind: any) => {
      Object.assign(headerObj, {
        title: ite.name_en,
      })
      headerArr.push(headerObj)
      headerObj = {}
    })
    let sensitivityReplace = new Object()
    let sensitivityArray = new Array()
    const values = Object.values(waterCancer.cancer);
    values.forEach((val: any, index: any) => {
      for (const key in val.item) {
        let NumberKey = Number(key)
        for (let i = 0; i < val.item.length; i++) {
          if (i == NumberKey) {
            sensitivityReplace = JSON.parse(JSON.stringify(val.item[i]).replace(/sensitivity/g, `${val.name_en}`))
            sensitivityArray.push(sensitivityReplace)
            sensitivityReplace = {}
          }
        }
      }
    });
    let objectAss = new Object()
    let objectArray = new Array()
    headerArr.forEach((item: any) => {
      for (const key in sensitivityArray) {
        const NumberKey = Number(key)
        if (sensitivityArray[NumberKey].name_en == item.title) {
          objectAss = Object.assign(item, sensitivityArray[NumberKey])
        }
      }
      objectArray.push(objectAss)
    })
    waterCancer.water_cancer_sensitivityColumns = dataColumns
    waterCancer.water_cancer_sensitivity = objectArray

    this.setState({
      water_cancer_sensitivityColumns: dataColumns,
      water_cancer_sensitivity: objectArray

    })
  }
  handleWaterNotCancer = (waterNotCancer: any) => {
    let Columns = [

      {
        title: '参数名称',
        dataIndex: 'name_en',
        key: 'name_en',
        fixed: 'left',
        width: 100,
      },
      {
        title: '参数含义',
        dataIndex: 'name_cn',
        key: 'name_cn',
        fixed: 'left',
        width: 100,
      },
    ]
    let dataSourceColumns = new Array()
    let newobjectColumns = new Object()
    waterNotCancer.not_cancer.forEach((item: any, index: any) => {


      Object.assign(newobjectColumns, {
        title: item.name_cn,
        key: item.name_en,
        id: item.name_en,
        dataIndex: item.name_en,

        width: 150,
      })
      dataSourceColumns.push(newobjectColumns)
      newobjectColumns = {}


    });
    let headerArr = new Array()
    let headerObj = new Object()
    waterNotCancer.not_cancer[0].item.forEach((ite: any, ind: any) => {
      Object.assign(headerObj, {
        title: ite.name_en,
      })
      headerArr.push(headerObj)
      headerObj = {}
    })
    let dataColumns = new Array()
    let size = 9
    let [start, end, result] = [0, 0, []];
    let listForColumns = new Array()
    if (dataSourceColumns.length > 9) {
      for (let i = 0; i < Math.ceil(dataSourceColumns.length / size); i++) {
        start = i * size;
        end = start + size;
        dataColumns.push(Columns.concat(dataSourceColumns.slice(start, end)))

        console.log("start", start, end, size, dataColumns);

      }
    } else {
      dataColumns = Columns.concat(dataSourceColumns)
    }

    let sensitivityReplace = new Object()
    let sensitivityArray = new Array()
    const values = Object.values(waterNotCancer.not_cancer);
    values.forEach((val: any, index: any) => {
      for (const key in val.item) {
        let NumberKey = Number(key)
        for (let i = 0; i < val.item.length; i++) {
          if (i == NumberKey) {
            sensitivityReplace = JSON.parse(JSON.stringify(val.item[i]).replace(/sensitivity/g, `${val.name_en}`))
            sensitivityArray.push(sensitivityReplace)
            sensitivityReplace = {}
          }
        }
      }
    });
    let objectAss = new Object()
    let objectArray = new Array()
    headerArr.forEach((item: any) => {
      for (const key in sensitivityArray) {
        const NumberKey = Number(key)
        if (sensitivityArray[NumberKey].name_en == item.title) {
          objectAss = Object.assign(item, sensitivityArray[NumberKey])
        }
      }
      objectArray.push(objectAss)
    })
    waterNotCancer.water_not_cancer_sensitivityColumns = dataColumns
    waterNotCancer.water_not_cancer_sensitivity = objectArray
    console.log("waterNotCancer", waterNotCancer);
    this.setState({
      water_not_cancer_sensitivityColumns: dataColumns,
      water_not_cancer_sensitivity: objectArray

    })
  }
  handleSoilCancer = (soilCancer: any) => {
    console.log("soilCancer", soilCancer.cancer);
    let Columns = [

      {
        title: '参数名称',
        dataIndex: 'name_en',
        key: 'name_en',
        fixed: 'left',
        width: 100,
      },
      {
        title: '参数含义',
        dataIndex: 'name_cn',
        key: 'name_cn',
        fixed: 'left',
        width: 100,
      },
    ]
    let dataSourceColumns = new Array()
    let newobjectColumns = new Object()
    soilCancer.cancer.forEach((item: any, index: any) => {


      Object.assign(newobjectColumns, {
        title: item.name_cn,
        key: item.name_en,
        id: item.name_en,
        dataIndex: item.name_en,

        width: 150,
      })
      dataSourceColumns.push(newobjectColumns)
      newobjectColumns = {}


    });
    console.log("dataSourceColumns", dataSourceColumns);

    let headerArr = new Array()
    let headerObj = new Object()
    soilCancer.cancer[0].item.forEach((ite: any, ind: any) => {
      Object.assign(headerObj, {
        title: ite.name_en,
      })
      headerArr.push(headerObj)
      headerObj = {}
    })
    let dataColumns = new Array()
    let size = 9
    let [start, end, result] = [0, 0, []];
    let listForColumns = new Array()
    if (dataSourceColumns.length > 9) {
      for (let i = 0; i < Math.ceil(dataSourceColumns.length / size); i++) {
        start = i * size;
        end = start + size;
        dataColumns.push(Columns.concat(dataSourceColumns.slice(start, end)))

        console.log("start", start, end, size, dataColumns);

      }
    } else {
      dataColumns = Columns.concat(dataSourceColumns)
    }
    let sensitivityReplace = new Object()
    let sensitivityArray = new Array()
    const values = Object.values(soilCancer.cancer);
    values.forEach((val: any, index: any) => {
      for (const key in val.item) {
        let NumberKey = Number(key)
        for (let i = 0; i < val.item.length; i++) {
          if (i == NumberKey) {
            sensitivityReplace = JSON.parse(JSON.stringify(val.item[i]).replace(/sensitivity/g, `${val.name_en}`))
            sensitivityArray.push(sensitivityReplace)
            sensitivityReplace = {}
          }
        }
      }
    });
    let objectAss = new Object()
    let objectArray = new Array()
    headerArr.forEach((item: any) => {
      for (const key in sensitivityArray) {
        const NumberKey = Number(key)
        if (sensitivityArray[NumberKey].name_en == item.title) {
          objectAss = Object.assign(item, sensitivityArray[NumberKey])
        }
      }
      objectArray.push(objectAss)
    })
    soilCancer.soil_cancer_sensitivityColumns = dataColumns
    soilCancer.soil_cancer_sensitivity = objectArray
    console.log("soilCancer", soilCancer);
    this.setState({
      soil_cancer_sensitivityColumns: dataColumns,
      soil_cancer_sensitivity: objectArray

    })
  }
  handleSoilNotCancer = (soilNotCancer: any) => {
    console.log("soilNotCancer", soilNotCancer);

    let Columns = [

      {
        title: '参数名称',
        dataIndex: 'name_en',
        key: 'name_en',
        fixed: 'left',
        width: 100,
      },
      {
        title: '参数含义',
        dataIndex: 'name_cn',
        key: 'name_cn',
        fixed: 'left',
        width: 100,
      },
    ]
    let dataSourceColumns = new Array()
    let newobjectColumns = new Object()
    soilNotCancer.not_cancer.forEach((item: any, index: any) => {


      Object.assign(newobjectColumns, {
        title: item.name_cn,
        key: item.name_en,
        id: item.name_en,
        dataIndex: item.name_en,

        width: 150,
      })
      dataSourceColumns.push(newobjectColumns)
      newobjectColumns = {}


    });
    let headerArr = new Array()
    let headerObj = new Object()
    soilNotCancer.not_cancer[0].item.forEach((ite: any, ind: any) => {
      Object.assign(headerObj, {
        title: ite.name_en,
      })
      headerArr.push(headerObj)
      headerObj = {}
    })
    let dataColumns = new Array()
    let size = 9
    let [start, end, result] = [0, 0, []];
    let listForColumns = new Array()
    if (dataSourceColumns.length > 9) {
      for (let i = 0; i < Math.ceil(dataSourceColumns.length / size); i++) {
        start = i * size;
        end = start + size;
        dataColumns.push(Columns.concat(dataSourceColumns.slice(start, end)))

        console.log("start", start, end, size, dataColumns);

      }
    } else {
      dataColumns = Columns.concat(dataSourceColumns)
    }

    let sensitivityReplace = new Object()
    let sensitivityArray = new Array()
    const values = Object.values(soilNotCancer.not_cancer);
    values.forEach((val: any, index: any) => {
      for (const key in val.item) {
        let NumberKey = Number(key)
        for (let i = 0; i < val.item.length; i++) {
          if (i == NumberKey) {
            sensitivityReplace = JSON.parse(JSON.stringify(val.item[i]).replace(/sensitivity/g, `${val.name_en}`))
            sensitivityArray.push(sensitivityReplace)
            sensitivityReplace = {}
          }
        }
      }
    });
    let objectAss = new Object()
    let objectArray = new Array()
    headerArr.forEach((item: any) => {
      for (const key in sensitivityArray) {
        const NumberKey = Number(key)
        if (sensitivityArray[NumberKey].name_en == item.title) {
          objectAss = Object.assign(item, sensitivityArray[NumberKey])
        }
      }
      objectArray.push(objectAss)
    })
    soilNotCancer.soil_not_cancer_sensitivityColumns = dataColumns
    soilNotCancer.soil_not_cancer_sensitivity = objectArray
    console.log("soilNotCancer", soilNotCancer);
    this.setState({
      soil_not_cancer_sensitivityColumns: dataColumns,
      soil_not_cancer_sensitivity: objectArray

    })
  }
  handleRisk = (risk: any, index: any) => {

    let {
      control: {
        soil: soilControl,
        water: waterControl
      },
      risk: {
        report_water,
        soil: {
          cancer: soilCancer,
          not_cancer: soilNotCancer
        },
      },
      exposure: {
        soil: {
          cancer: exsoilCancer,
          not_cancer: exsoilNotCancer
        },
        water: {
          cancer: exwaterCancer,
          not_cancer: exwaterNotCancer
        }
      },
      contribution: {
        soil: {
          cancer: contributionsoil,
          not_cancer: contributionsoilNot
        },
        water: {
          cancer: contributionwater,
          not_cancer: contributionwaterNot
        },
        graphic: {
          soil: soilgraphic,
          water: watergraphic
        },
      },
    } = risk;
    let soilControlArr = [
      'HCVSdcs',
      'HCVSiiv1',
      'HCVSiov1',
      'HCVSiov2',
      'HCVSois',
      'HCVSpis',
      'RCVSdcs',
      'RCVSiiv1',
      'RCVSiov1',
      'RCVSiov2',
      'RCVSois',
      'RCVSpis',
      'feiZhiAiControlValue',
      'total',
      'zhiAiControlValue'
    ]
    let waterControlArr = [
      'HCVScgw',
      'HCVSiiv2',
      'HCVSiov3',
      'RCVScgw',
      'RCVSiiv2',
      'RCVSiov3',
      'feiZhiAiDXControlValue',
      'total',
      'zhiAiDXControlValue'
    ]
    let report_waterArr = [
      'CRcgw',
      'CRiiv2',
      'CRiov3',
      'HQcgw',
      'HQiiv2',
      'HQiov3',
      'feiZhiAiDXValue',
      'zhiAiDXValue'
    ]
    let soilCancerArr = [
      'CRdcs',
      'CRiiv1',
      'CRiov1',
      'CRiov2',
      'CRois',
      'CRpis',
      'zhiAiValue'
    ]
    let soilNotCancerArr = [
      'HQdcs',
      'HQiiv1',
      'HQiov1',
      'HQiov2',
      'HQois',
      'HQpis',
      'feiZhiAiValue'
    ]
    let exsoilCancerArr = [
      'OISERca',
      'DCSERca',
      'PIERca',
      'IOVERca1',
      'IOVERca2',
      'IIVERca1',
    ]
    let exsoilNotCancerArr = [
      'OISERnc',
      'DCSERnc',
      'PIERnc',
      'IOVERnc1',
      'IOVERnc2',
      'IIVERnc1',
    ]
    let exwaterCancerArr = [
      'IOVERca3',
      'IIVERca2',
      'CGWERca',
    ]
    let exwaterNotCancerArr = [
      'IOVERnc3',
      'IIVERnc2',
      'CGWERnc',
    ]
    let contributionsoilArr = [
      'DCS',
      'IIV1',
      'IOV1',
      'IOV2',
      'OIS',
      'PIS',
    ]
    let contributionsoilNotArr = [
      'OIS',
      'DCS',
      'PIS',
      'IOV1',
      'IOV2',
      'IIV1',
    ]
    let contributionwaterArr = [
      'CGW',
      'IIV2',
      'IOV3',
    ]
    let contributionwaterNotArr = [
      'CGW',
      'IIV2',
      'IOV3',
    ]

    let graphicArrcolumns = [
      { id: 1, cn: '径口摄入土壤', en: 'OIS' },
      { id: 2, cn: '皮肤接触土壤颗粒物', en: 'DCS' },
      { id: 3, cn: '吸入土壤颗粒物', en: 'PIS' },
      { id: 4, cn: '吸入室外表层挥发性污染物', en: 'IOV1' },
      { id: 5, cn: '吸入室外下层挥发性污染物', en: 'IOV2' },
      { id: 6, cn: '吸入室内下层挥发性污染物', en: 'IIV1' },
      { id: 7, cn: '饮用地下水', en: 'CGW' },
      { id: 8, cn: '吸入室外地下水挥发性污染物蒸气', en: 'IOV3' },
      { id: 9, cn: '吸入室内地下水挥发性污染物蒸气', en: 'IIV2' },
    ]
    soilControl.map((columns: any, index: any) => {
      soilControlArr.map((str: any, index: any) => {
        if (columns[str] || columns[str] == '0') {
          columns[str] = columns[str].toExponential(2)
        } else {
          columns[str] = '-'
        }
      })
    })
    waterControl.map((columns: any, index: any) => {
      waterControlArr.map((str: any, index: any) => {
        if (columns[str] || columns[str] == '0') {
          return columns[str] = columns[str].toExponential(2)
        } else {
          return columns[str] = '-'
        }
      })
    })
    report_water.map((columns: any, index: any) => {
      report_waterArr.map((str: any, index: any) => {
        if (columns[str] || columns[str] == '0') {
          return columns[str] = columns[str].toExponential(2)
        } else {
          return columns[str] = '-'
        }
      })
    })
    soilCancer.map((columns: any, index: any) => {
      soilCancerArr.map((str: any, index: any) => {
        if (columns[str] || columns[str] == '0') {
          return columns[str] = columns[str].toExponential(2)
        } else {
          return columns[str] = '-'
        }
      })
    })
    soilNotCancer.map((columns: any, index: any) => {
      soilNotCancerArr.map((str: any, index: any) => {
        if (columns[str] || columns[str] == '0') {
          return columns[str] = columns[str].toExponential(2)
        } else {
          return columns[str] = '-'
        }
      })
    })
    exsoilCancer.map((columns: any, index: any) => {
      exsoilCancerArr.map((str: any, index: any) => {
        if (columns[str] || columns[str] == '0') {
          return columns[str] = columns[str].toExponential(2)
        } else {
          return columns[str] = '-'
        }
      })
    })
    exsoilNotCancer.map((columns: any, index: any) => {
      exsoilNotCancerArr.map((str: any, index: any) => {
        if (columns[str] || columns[str] == '0') {
          return columns[str] = columns[str].toExponential(2)
        } else {
          return columns[str] = '-'
        }
      })
    })
    exwaterCancer.map((columns: any, index: any) => {
      exwaterCancerArr.map((str: any, index: any) => {
        if (columns[str] || columns[str] == '0') {
          return columns[str] = columns[str].toExponential(2)
        } else {
          return columns[str] = '-'
        }
      })
    })
    exwaterNotCancer.map((columns: any, index: any) => {

      exwaterNotCancerArr.map((str: any, index: any) => {
        if (columns[str] || columns[str] == '0') {
          return columns[str] = columns[str].toExponential(2)
        } else {
          return columns[str] = '-'
        }
      })
    })
    for (let i = 0; i < contributionsoil.length; i++) {
      let arr = Object.keys(contributionsoil[i].data);
      if (arr.length == 0) {
        contributionsoil.splice(i--, 1)
      }
      contributionsoil.map((columns: any, index: any) => {
        let obj3 = Object.assign(columns, columns.data);
        obj3.total = "100%"
        contributionsoilArr.map((str: any, index: any) => {
          columns[str] = ((columns[str] * 100).toFixed(2) + "%")
          if (columns[str] == '0.00%') {
            return columns[str] = "0%"
          } if (columns[str] == '100.00%') {
            return columns[str] = "100%"
          }
        })
      })
    }
    for (let i = 0; i < contributionsoilNot.length; i++) {
      let arr = Object.keys(contributionsoilNot[i].data);
      if (arr.length == 0) {
        contributionsoilNot.splice(i--, 1)
      }
      contributionsoilNot.map((columns: any, index: any) => {
        let obj3 = Object.assign(columns, columns.data);
        obj3.total = "100%"
        contributionsoilNotArr.map((str: any, index: any) => {
          columns[str] = ((columns[str] * 100).toFixed(2) + "%")
          if (columns[str] == '0.00%') {
            return columns[str] = "0%"
          } if (columns[str] == '100.00%') {
            return columns[str] = "100%"
          }
        })
      })
    }
    for (let i = 0; i < contributionwater.length; i++) {
      let arr = Object.keys(contributionwater[i].data);
      if (arr.length == 0) {
        contributionwater.splice(i--, 1)
      }
      contributionwater.map((columns: any, index: any) => {
        let obj3 = Object.assign(columns, columns.data);
        obj3.total = "100%"
        contributionwaterArr.map((str: any, index: any) => {
          columns[str] = ((columns[str] * 100).toFixed(2) + "%")
          if (columns[str] == '0.00%') {
            return columns[str] = "0%"
          }
          if (columns[str] == '100.00%') {
            return columns[str] = "100%"
          }
          if (columns[str] == '0') {
            return columns[str] = "0%"
          }
          if (columns[str] == '1') {
            return columns[str] = "100%"
          }
        })
      })


    }
    for (let i = 0; i < contributionwaterNot.length; i++) {
      let arr = Object.keys(contributionwaterNot[i].data);
      if (arr.length == 0) {
        contributionwaterNot.splice(i--, 1)
      }
      contributionwaterNot.map((columns: any, index: any) => {
        let obj3 = Object.assign(columns, columns.data);
        obj3.total = "100%"
        contributionwaterNotArr.map((str: any, index: any) => {
          columns[str] = ((columns[str] * 100).toFixed(2) + "%")
          if (columns[str] == '0.00%') {
            return columns[str] = "0%"
          } if (columns[str] == '100.00%') {
            return columns[str] = "100%"
          }
        })
      })
    }
    const grap = <CheckOutlined />
    console.log("grap", grap, JSON.stringify(grap));

    let graphicArr = Object.assign(soilgraphic, watergraphic);

    graphicArrcolumns.map((item: any, index: any) => {

      for (const i in graphicArr) {
        if (graphicArr[i] == true) {


          graphicArr[i] = <div className="graphic_Outlined">
            <CheckOutlined />
          </div>
        }
        // if (item.en == i) {
        //   return Object.assign(item, { url: graphicArr[i] });
        // }


      }
    })

    graphicArr = graphicArrcolumns
    console.log("graphicArrcolumns", graphicArrcolumns, graphicArr,);

    const token = getToken()
    const SessionProductId = getSessionProductId()
    const User = getUser()
    const SessionLevel = getSessionLevel()


    this.setState({
      soilControl,
      waterControl,
      soilCancer,
      soilNotCancer,
      exsoilCancer,
      exsoilNotCancer,
      exwaterCancer,
      exwaterNotCancer,
      contributionsoil,
      contributionsoilNot,
      contributionwater,
      contributionwaterNot,
      // graphicArr,
      report_water,
      token,
      SessionProductId,
      User,

    })
  }
  filtterType = (type: any) => {
    console.log("type", type);

    if (type == '1') {
      return '第一类用地'
    } else if (type == '2') {
      return '第二类用地'
    } else {
      return ''
    }
  }
  getPdf() {
    console.log("Generating PDF...");
  
    html2canvas(this.state.pdfs.current, {
      scale: 0.9,  // 提高图像清晰度
      useCORS: true,  // 允许跨域图像加载
      backgroundColor: '#ffffff',  // 设置背景色为白色
      logging: true,  // 启用日志记录，调试时很有用
    }).then((canvas) => {
      const contentWidth = canvas.width;
      const contentHeight = canvas.height;
      const imgWidth = 595.28; // A4宽度
      const imgHeight = 592.28 / contentWidth * contentHeight; // 图像高度比例
      const pageData = canvas.toDataURL('image/png'); // 调低清晰度避免内存溢出
  
      const pageHeight = contentWidth / 592.28 * 841.89; // A4页面高度
      let leftHeight = contentHeight; // 剩余高度
      let position = 0; // 图像开始位置
      const PDF = new jsPDF('p', 'pt', 'a4'); // 创建A4纸张的PDF
  
      while (leftHeight > 0) {
        const imgY = (contentHeight / 2 * 0.75); // 调整图像位置
  
        // 每页添加图像
        PDF.addImage(pageData, 'jpeg', 0, position, imgWidth, imgHeight);
  
        leftHeight -= pageHeight;
        position -= 840;
  
        // 如果页面剩余的内容还需要更多页，添加新页面
        if (leftHeight > 0) {
          PDF.addPage();
        }
      }
  
      // 最终保存PDF
      PDF.save('report.pdf');
    }).catch((error) => {
      console.error("Error generating PDF:", error);
    });
  }

  
  nextStep = (value: any) => {
    switch (value) {
      case 'previous':
        this.props.history.goBack()
        break;
      case 'analysis':

        break;
      case 'pdf':
        this.getPdf()
        break;
      case 'charge':
        this.gitCharge()
        break;
      case 'consult':
        this.getConsult()
        break;
      case 'word':

        break;
      default:
        break;
    }
  }
  gitCharge() {
    window.open("http://www.dsenv.cn/?userToken=" + this.state.token + '&id=' + JSON.stringify(this.state.SessionProductId) + '&acc=' + this.state.User.account + '#/MyOrder');

  }
  getConsult() {
    this.usercenter(true)

  }
  confirmSubmit = async () => {
    console.log("this.props", this.props);

    await saveExpertConsultation({
      token: this.state.token,
      question: this.state.content,
      phone: this.state.phone,
      project_id: this.state.project_id,
      user_id: 5
    }).then((res: any) => {
      this.usercenter(false)

    }).catch((err: any) => {

    })

  }
  onChangephone = (e: any) => {
    let value = e.target.value;

    this.setState({
      phone: value
    })
  };
  usercenter = (value: boolean) => {
    console.log("this.props", this.props);

    this.setState({
      consultData: value
    })
  }
  onChangeArea = (e: any) => {
    let value = e.target.value;

    this.setState({
      content: value
    })
  };

  toChineseNumber = (n: any) => {
    if (!Number.isInteger(n) && n < 0) {
      throw Error('请输入自然数');
    }
    const digits = ['零', '一', '二', '三', '四', '五', '六', '七', '八', ' 九'];
    const positions = ['', '十', '百', '千', '万', '十万', '百万', '千万', '亿', '十亿', '百亿', '千亿'];
    const charArray = String(n).split('');
    let result = '';
    let prevIsZero = false;
    //处理0  deal zero
    for (let i = 0; i < charArray.length; i++) {
      const ch = charArray[i];
      if (ch !== '0' && !prevIsZero) {
        result += digits[parseInt(ch)] + positions[charArray.length - i - 1];
      } else if (ch === '0') {
        prevIsZero = true;
      } else if (ch !== '0' && prevIsZero) {
        result += '零' + digits[parseInt(ch)] + positions[charArray.length - i - 1];
      }
    }
    //处理十 deal ten
    if (n < 100) {
      result = result.replace('一十', '十');
    }
    return result;
  }
  render() {
    const titColor = {
      color: "#105EBF"
    }
    return (

      <div className="report">
        <Spin spinning={this.state.loading}>

          {

            this.state.GetSessionLevel ? (

              <div>
                {
                  this.state.level == '1' ? (
                    <FirstLevel project_id={this.state.project_id} base_info={this.state.base_info} soil_pollutant={this.state.soil_pollutant} water_pollutant={this.state.water_pollutant} props={this.props}></FirstLevel>
                  ) : ''
                }
                {
                  this.state.level == '2' ?
                    (
                      <div>
                        <TwoLevel
                          report_water={this.state.report_water}
                          soilNotCancer={this.state.soilNotCancer}
                          waterControl={this.state.waterControl}
                          soilControl={this.state.soilControl}
                          soilCancer={this.state.soilCancer}
                          toxicity={this.state.toxicity}
                          physical={this.state.physical}
                          project_model={this.state.project_model}
                          base_info={this.state.base_info}
                          soil_pollutant={this.state.soil_pollutant}
                          water_pollutant={this.state.water_pollutant}
                          all_param_value={this.state.all_param_value}
                          exsoilCancer={this.state.exsoilCancer}
                          exsoilNotCancer={this.state.exsoilNotCancer}
                          exwaterCancer={this.state.exwaterCancer}
                          exwaterNotCancer={this.state.exwaterNotCancer}
                          contributionsoil={this.state.contributionsoil}
                          contributionsoilNot={this.state.contributionsoilNot}
                          contributionwater={this.state.contributionwater}
                          contributionwaterNot={this.state.contributionwaterNot}


                          soil_cancer_sensitivity={this.state.soil_cancer_sensitivity}
                          soil_not_cancer_sensitivity={this.state.soil_not_cancer_sensitivity}
                          water_cancer_sensitivity={this.state.water_cancer_sensitivity}
                          water_not_cancer_sensitivity={this.state.water_not_cancer_sensitivity}
                          soil_cancer_sensitivityColumns={this.state.soil_cancer_sensitivityColumns}
                          soil_not_cancer_sensitivityColumns={this.state.soil_not_cancer_sensitivityColumns}
                          water_cancer_sensitivityColumns={this.state.water_cancer_sensitivityColumns}
                          water_not_cancer_sensitivityColumns={this.state.water_not_cancer_sensitivityColumns}


                          graphicArr={this.state.graphicArr}
                          project_id={this.state.project_id}
                          props={this.props}
                          token={this.state.token}
                          SessionProductId={this.state.SessionProductId}
                          User={this.state.User}
                        >


                        </TwoLevel>
                      </div>


                    ) : ''
                }
              </div>
            ) : (
              <div>
                <div ref={this.state.pdfs} >
                  <div className="reportPdfContent">
                    <div className="report">
                      <div className="reportMarign">
                        <img src={require('../../../assets/images/report/logo.png').default} className="report-img"></img>
                        <Divider></Divider>
                      </div>
                      <div className="report-title" style={titColor}>项目分析报告 </div>
                      <div className="report-time">生成报告日期：{date("Y-m-d H:i:s", parseInt(this.state.base_info.report_time))}</div>
                      <div className="report-content">
                        <div className="report-area">
                          <div className="area-title">
                            <div className="area-top">项目基本信息</div>
                            <div className="area-btm">ESSENTIAL INFORMATION</div>
                          </div>
                          <table id="tfhover" className="tfhead">
                            <thead>
                              <tr>
                                <th>项目名称</th>
                                <td>{this.state.base_info.project_name}</td>
                                <th>评估时间</th>
                                <td>{date("Y-m-d", parseInt(this.state.base_info.project_time))}</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>用地方式</th>
                                <td>{this.filtterType(this.state.base_info.project_type)}</td>
                                <th>项目地址</th>
                                <td>{this.state.base_info.project_address}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    {
                      this.state.resDataList.map((item: any, index: any) => {
                        console.log("item", item);
                        console.log(" .project_type", this.state.base_info.project_type,);


                        const title = `第 ${this.toChineseNumber(index + 1)} 层`
                        const ListLength = this.state.resDataList.length
                        return <div>
                          -
                          <div className="orientation_Margin">
                            <Divider orientation="left" className="orientationMargin">
                              {title}

                            </Divider>
                          </div>

                          {/* <ThreeLevel
                          report_water={item.risk.risk.report_water}
                          soilNotCancer={item.risk.risk.soil.not_cancer}
                          waterControl={item.risk.control.water}
                          soilControl={item.risk.control.soil}
                          soilCancer={item.risk.risk.soil.cancer}
                          toxicity={item.toxicity}
                          toxicityItem={item.toxicity}
                          physical={item.physical}
                          project_model={this.state.project_model}
                          base_info={this.state.base_info}
                          soil_pollutant={item.soil_pollutant}
                          water_pollutant={item.water_pollutant}
                          all_param_value={item.all_param_value}
                          exsoilCancer={item.risk.exposure.soil.cancer}
                          exsoilNotCancer={item.risk.exposure.soil.not_cancer}
                          exwaterCancer={item.risk.exposure.water.cancer}
                          exwaterNotCancer={item.risk.exposure.water.not_cancer}
                          contributionsoil={item.risk.contribution.soil.cancer}
                          contributionsoilNot={item.risk.contribution.soil.not_cancer}
                          contributionwater={item.risk.contribution.water.cancer}
                          contributionwaterNot={item.risk.contribution.water.not_cancer}

                          soil_cancer_sensitivity={item.sensitivity.risk.soil.soil_cancer_sensitivity}
                          soil_not_cancer_sensitivity={item.sensitivity.risk.soil.soil_not_cancer_sensitivity}
                          water_cancer_sensitivity={item.sensitivity.risk.water.water_cancer_sensitivity}
                          water_not_cancer_sensitivity={item.sensitivity.risk.water.water_not_cancer_sensitivity}
                          soil_cancer_sensitivityColumns={item.sensitivity.risk.soil.soil_cancer_sensitivityColumns}
                          soil_not_cancer_sensitivityColumns={item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns}
                          water_cancer_sensitivityColumns={item.sensitivity.risk.water.water_cancer_sensitivityColumns}
                          water_not_cancer_sensitivityColumns={item.sensitivity.risk.water.water_not_cancer_sensitivityColumns}

                          graphicArr={item.graphicdataArr}
                          project_id={this.state.project_id}
                          props={this.props}
                          token={this.state.token}
                          SessionProductId={this.state.SessionProductId}
                          User={this.state.User}
                          title={title}
                          index={index + 1}
                          ListLength={ListLength}
                        >
                        </ThreeLevel> */}





                          {item.soil_pollutant.length == 0 ? (
                            <div></div>
                          ) : (
                            <div> <div className="report-content">
                              <div className="report-area">
                                <div className="area-title">
                                  <div className="area-top">项目污染物信息(土壤)</div>
                                  <div className="area-btm">ENVIRONMENTAL PROJECT POLLUTANT INFORMATION(SOIL)</div>
                                </div>
                                <ReportTable
                                  columns={this.state.soil_columns}
                                  dataSource={item.soil_pollutant}
                                ></ReportTable>
                              </div>
                            </div></div>
                          )}

                          {item.water_pollutant.length == 0 ? (
                            <div></div>
                          ) : (
                            <div> <div className="report-content">
                              <div className="report-area">
                                <div className="area-title">
                                  <div className="area-top">项目污染物信息(地下水)</div>
                                  <div className="area-btm">ENVIRONMENTAL PROJECT POLLUTANT INFORMATION(GROUNDWATER)</div>
                                </div>
                                <ReportTable
                                  columns={this.state.water_columns}
                                  dataSource={item.water_pollutant}
                                ></ReportTable>
                              </div>
                            </div></div>
                          )}

                          {item.graphicdataArr.length == 0 ? (
                            <div></div>
                          ) : (
                            <div><div className="report-content">
                              <div className="report-area dr">
                                <div className="area-title">
                                  <div className="area-top">{this.filtterType(item.base_info.project_type)}方式下暴露途径</div>
                                  <div className="area-btm">Exposure routes under the second type of land use</div>
                                </div>
                                <ReportTableCheck
                                  columns={[
                                    {
                                      title: '序号',
                                      dataIndex: 'id',
                                      className: 'column-money'
                                    },
                                    {
                                      title: '暴露途径',
                                      dataIndex: 'cn',
                                      className: 'column-money'
                                    },
                                    {
                                      title: `${this.filtterType(item.base_info.project_type)}`,
                                      dataIndex: 'url',
                                      className: 'column-money'
                                    },

                                  ]}
                                  dataSource={item.graphicdataArr}
                                ></ReportTableCheck>
                              </div>
                            </div></div>
                          )}

                          {item.all_param_value.length == 0 ? (
                            <div></div>
                          ) : (
                            <div>  <div className="report-content">
                              <div className="report-area">
                                <div className="area-title">
                                  <div className="area-top">全部参数取值</div>
                                  <div className="area-btm">All parameter values</div>
                                </div>
                                <ReportTable
                                  columns={this.state.parameter}
                                  dataSource={item.all_param_value}
                                ></ReportTable>
                              </div>
                            </div></div>
                          )}

                          <div className="LineChart-content">
                            <div className="report-area">
                              <div className="LineChart-title">
                                <div className="area-top">概念模型-流程图</div>
                                <div className="area-btm">conceptual model-flow chart</div>
                              </div>
                              <LineImg></LineImg>
                            </div>
                          </div>
                          <div className="report-content">
                            <div className="report-area">
                              <div className="area-title">
                                <div className="area-top">概念模型-示意图</div>
                                <div className="area-btm">conceptual model-Sketch Map</div>
                              </div>
                              <Model></Model>
                            </div>
                          </div>
                          {item.risk.exposure.soil.cancer.length == 0 ? (
                            <div></div>
                          ) : (
                            <div> <div className="report-content">
                              <div className="report-area">
                                <div className="area-title">
                                  <div className="area-top">土壤不同暴露途径暴露量（致癌）</div>
                                  <div className="area-btm">Soil exposure by different exposure routes (carcinogenic)</div>
                                </div>
                                <ReportTable
                                  columns={this.state.soilCancerexposure}
                                  dataSource={item.risk.exposure.soil.cancer}
                                ></ReportTable>
                              </div>
                            </div></div>
                          )}
                          {/* 这个导出pdf有问题 */}
                          {item.risk.exposure.soil.not_cancer.length == 0 ? (
                            <div></div>
                          ) : (
                            <div> <div className="report-content">
                              <div className="report-area">
                                <div className="area-title">
                                  <div className="area-top">土壤不同暴露途径暴露量（非致癌）</div>
                                  <div className="area-btm">Soil exposure by different exposure routes (non-carcinogenic)</div>
                                </div>
                                <ReportTable
                                  columns={this.state.soilNotCancerexposure}
                                  dataSource={item.risk.exposure.soil.not_cancer}
                                ></ReportTable>
                              </div>
                            </div></div>
                          )}

                          {item.risk.exposure.water.cancer.length == 0 ? (
                            <div></div>
                          ) : (
                            <div> <div className="report-content">
                              <div className="report-area">
                                <div className="area-title">
                                  <div className="area-top">地下水不同暴露途径暴露量（致癌）</div>
                                  <div className="area-btm">Groundwater exposure by different routes (carcinogenic)</div>
                                </div>
                                <ReportTable
                                  columns={this.state.waterCancerexposure}
                                  dataSource={item.risk.exposure.water.cancer}
                                ></ReportTable>
                              </div>
                            </div></div>
                          )}
                          {/* 这个导出pdf有问题 */}
                          {item.risk.exposure.water.not_cancer.length == 0 ? (
                            <div></div>
                          ) : (
                            <div>  <div className="report-content">
                              <div className="report-area">
                                <div className="area-title">
                                  <div className="area-top">地下水不同暴露途径暴露量（非致癌）</div>
                                  <div className="area-btm">Groundwater exposure by different routes (non-carcinogenic)</div>
                                </div>
                                <ReportTable
                                  columns={this.state.waterNotCancerexposure}
                                  dataSource={item.risk.exposure.water.not_cancer}
                                ></ReportTable>
                              </div>
                            </div></div>
                          )}
                          {/* 这个导出pdf有问题 */}

                          {item.toxicity.length == 0 ? (
                            <div></div>
                          ) : (
                            <div> <div className="report-content">
                              <div className="report-area">
                                <div className="area-title">
                                  <div className="area-top">污染物毒性参数表</div>
                                  <div className="area-btm">Table of toxicity parameters of pollutants</div>
                                </div>
                                <ReportTable
                                  columns={this.state.toxicity_columns}
                                  dataSource={item.toxicity}
                                ></ReportTable>
                              </div>
                            </div></div>
                          )}

                          {/* 这个导出pdf有问题 */}

                          {item.physical.length == 0 ? (
                            <div></div>
                          ) : (
                            <div><div className="report-content">
                              <div className="report-area">
                                <div className="area-title">
                                  <div className="area-top">污染物理化参数表</div>
                                  <div className="area-btm">Pollution physicochemical parameter table</div>
                                </div>
                                <ReportTable
                                  columns={this.state.physical_columns}
                                  dataSource={item.physical}
                                ></ReportTable>
                              </div>
                            </div></div>
                          )}
                          {/* 这个导出pdf有问题 */}
                          {item.risk.exposure.soil.cancer.length == 0 ? (
                            <div></div>
                          ) : (
                            <div> <div className="report-content">
                              <div className="report-area">
                                <div className="area-title">
                                  <div className="area-top">致癌风险水平（土壤）</div>
                                  <div className="area-btm">Carcinogenic risk level (soil)</div>
                                </div>
                                <ReportTable
                                  columns={this.state.cancerRiskSoil}
                                  dataSource={item.risk.risk.soil.cancer}
                                ></ReportTable>
                              </div>
                            </div></div>
                          )}
                          {/* 这个导出pdf有问题 */}
                          {item.risk.risk.soil.not_cancer.length == 0 ? (
                            <div></div>
                          ) : (
                            <div><div className="report-content">
                              <div className="report-area">
                                <div className="area-title">
                                  <div className="area-top">非致癌危害指数（土壤）</div>
                                  <div className="area-btm">Non carcinogenic hazard index (soil)</div>
                                </div>
                                <ReportTable
                                  columns={this.state.soilNotCancer_columns}
                                  dataSource={item.risk.risk.soil.not_cancer}
                                ></ReportTable>
                              </div>
                            </div></div>
                          )}
                          {/* 这个导出pdf有问题 */}
                          {item.risk.risk.report_water.length == 0 ? (
                            <div></div>
                          ) : (
                            <div>  <div className="report-content">
                              <div className="report-area">
                                <div className="area-title">
                                  <div className="area-top">污染物风险(地下水)</div>
                                  <div className="area-btm">Pollutant risk (groundwater)</div>
                                </div>
                                <ReportTable
                                  columns={this.state.report_water_columns}
                                  dataSource={item.risk.risk.report_water}
                                ></ReportTable>
                              </div>
                            </div></div>
                          )}
                          {/* 这个导出pdf有问题 */}
                          {item.risk.contribution.soil.cancer.length == 0 ? (
                            <div></div>
                          ) : (
                            <div> <div className="report-content">
                              <div className="report-area">
                                <div className="area-title">
                                  <div className="area-top">不同暴露途径的致癌风险贡献率（土壤）</div>
                                  <div className="area-btm">Contribution to carcinogenic risk by different exposure pathways (soil)</div>
                                </div>
                                <ReportTable
                                  columns={this.state.contributionsoil_columns}
                                  dataSource={item.risk.contribution.soil.cancer}
                                ></ReportTable>
                              </div>
                            </div></div>
                          )}
                          {/* 这个导出pdf有问题 */}
                          {item.risk.contribution.soil.not_cancer.length == 0 ? (
                            <div></div>
                          ) : (
                            <div>  <div className="report-content">
                              <div className="report-area">
                                <div className="area-title">
                                  <div className="area-top">不同暴露途径的危害商贡献率（土壤）</div>
                                  <div className="area-btm">Contribution rate of hazard quotient of different exposure pathways (soil)</div>
                                </div>
                                <ReportTable
                                  columns={this.state.contributionsoilNot_columns}
                                  dataSource={item.risk.contribution.soil.not_cancer}
                                ></ReportTable>
                              </div>
                            </div></div>
                          )}
                          {/* 这个导出pdf有问题 */}
                          {item.risk.contribution.water.cancer.length == 0 ? (
                            <div></div>
                          ) : (
                            <div>  <div className="report-content">
                              <div className="report-area">
                                <div className="area-title">
                                  <div className="area-top">不同暴露途径的致癌风险贡献率（地下水）</div>
                                  <div className="area-btm">Contribution rates of carcinogenic risk by different exposure pathways (groundwater)</div>
                                </div>
                                <ReportTable
                                  columns={this.state.contributionwater_columns}
                                  dataSource={item.risk.contribution.water.cancer}
                                ></ReportTable>
                              </div>
                            </div></div>
                          )}
                          {/* 这个导出pdf有问题 */}

                          {item.risk.contribution.water.not_cancer.length == 0 ? (
                            <div></div>
                          ) : (
                            <div>  <div className="report-content">
                              <div className="report-area">
                                <div className="area-title">
                                  <div className="area-top">不同暴露途径的危害商贡献率（地下水）</div>
                                  <div className="area-btm">Contribution rate of hazard quotient of different exposure pathways (groundwater)</div>
                                </div>
                                <ReportTable
                                  columns={this.state.contributionwaterNot_columns}
                                  dataSource={item.risk.contribution.water.not_cancer}
                                ></ReportTable>
                              </div>
                            </div></div>
                          )}
                          {/* 这个导出pdf有问题 */}
                          {item.risk.control.soil.length == 0 ? (
                            <div></div>
                          ) : (
                            <div> <div className="report-content">
                              <div className="report-area">
                                <div className="area-title">
                                  <div className="area-top">致癌风险控制值(土壤)</div>
                                  <div className="area-btm">Carcinogenic risk control value (soil)</div>
                                </div>
                                <ReportTable
                                  columns={this.state.soilCancerControl}
                                  dataSource={item.risk.control.soil}
                                ></ReportTable>
                              </div>
                            </div></div>
                          )}
                          {/* 这个地方数据不对 */}
                          {item.risk.control.soil.length == 0 ? (
                            <div></div>
                          ) : (
                            <div> <div className="report-content">
                              <div className="report-area">
                                <div className="area-title">
                                  <div className="area-top">非致癌风险控制值(土壤)</div>
                                  <div className="area-btm">Non carcinogenic risk control value (soil)</div>
                                </div>
                                <ReportTable
                                  columns={this.state.soilNotCancerControl}
                                  dataSource={item.risk.control.soil}
                                ></ReportTable>
                              </div>
                            </div></div>
                          )}
                          {/* 这个导出pdf有问题 */}
                          {item.risk.control.water.length == 0 ? (
                            <div></div>
                          ) : (
                            <div> <div className="report-content">
                              <div className="report-area">
                                <div className="area-title">
                                  <div className="area-top">污染物风险控制值(地下水)</div>
                                  <div className="area-btm">Pollutant risk control value (groundwater)</div>
                                </div>
                                <ReportTable
                                  columns={this.state.waterControl_columns}
                                  dataSource={item.risk.control.water}
                                ></ReportTable>
                              </div>
                            </div></div>
                          )}
                          {/* 这个导出pdf有问题 */}

                          {item.sensitivity.risk.soil.soil_cancer_sensitivity.length == 0 ? (
                            <div></div>
                          ) : (
                            <div> <div className="report-content">
                              <div className="report-area">
                                <div className="area-title">
                                  <div className="area-top">土壤参数敏感性比例（致癌）</div>
                                  <div className="area-btm">Soil Parameter sensitivity ratio (carcinogenic)</div>
                                </div>

                                {!Array.isArray(item.sensitivity.risk.soil.soil_cancer_sensitivityColumns[0]) ? (
                                  <ReportTable
                                    columns={item.sensitivity.risk.soil.soil_cancer_sensitivityColumns}
                                    dataSource={item.sensitivity.risk.soil.soil_cancer_sensitivity}
                                  ></ReportTable>
                                ) : (
                                  <div className="ReportTable_marght">
                                    {item.sensitivity.risk.soil.soil_cancer_sensitivityColumns.length > 0 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_cancer_sensitivityColumns[0]}
                                        dataSource={item.sensitivity.risk.soil.soil_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>

                                    )}

                                    {item.sensitivity.risk.soil.soil_cancer_sensitivityColumns.length > 1 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_cancer_sensitivityColumns[1]}
                                        dataSource={item.sensitivity.risk.soil.soil_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>

                                    )}
                                    {item.sensitivity.risk.soil.soil_cancer_sensitivityColumns.length > 2 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_cancer_sensitivityColumns[2]}
                                        dataSource={item.sensitivity.risk.soil.soil_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>

                                    )}
                                    {item.sensitivity.risk.soil.soil_cancer_sensitivityColumns.length > 3 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_cancer_sensitivityColumns[3]}
                                        dataSource={item.sensitivity.risk.soil.soil_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>

                                    )}

                                    {item.sensitivity.risk.soil.soil_cancer_sensitivityColumns.length > 4 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_cancer_sensitivityColumns[4]}
                                        dataSource={item.sensitivity.risk.soil.soil_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}
                                    {item.sensitivity.risk.soil.soil_cancer_sensitivityColumns.length > 5 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_cancer_sensitivityColumns[5]}
                                        dataSource={item.sensitivity.risk.soil.soil_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}

                                    {item.sensitivity.risk.soil.soil_cancer_sensitivityColumns.length > 6 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_cancer_sensitivityColumns[6]}
                                        dataSource={item.sensitivity.risk.soil.soil_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}

                                    {item.sensitivity.risk.soil.soil_cancer_sensitivityColumns.length > 7 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_cancer_sensitivityColumns[7]}
                                        dataSource={item.sensitivity.risk.soil.soil_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}

                                    {item.sensitivity.risk.soil.soil_cancer_sensitivityColumns.length > 8 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_cancer_sensitivityColumns[8]}
                                        dataSource={item.sensitivity.risk.soil.soil_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}

                                    {item.sensitivity.risk.soil.soil_cancer_sensitivityColumns.length > 9 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_cancer_sensitivityColumns[9]}
                                        dataSource={item.sensitivity.risk.soil.soil_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}
                                    {item.sensitivity.risk.soil.soil_cancer_sensitivityColumns.length > 10 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_cancer_sensitivityColumns[10]}
                                        dataSource={item.sensitivity.risk.soil.soil_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}

                                    {item.sensitivity.risk.soil.soil_cancer_sensitivityColumns.length > 11 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_cancer_sensitivityColumns[12]}
                                        dataSource={item.sensitivity.risk.soil.soil_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}

                                    {item.sensitivity.risk.soil.soil_cancer_sensitivityColumns.length > 13 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_cancer_sensitivityColumns[13]}
                                        dataSource={item.sensitivity.risk.soil.soil_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}

                                    {item.sensitivity.risk.soil.soil_cancer_sensitivityColumns.length > 14 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_cancer_sensitivityColumns[14]}
                                        dataSource={item.sensitivity.risk.soil.soil_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}

                                  </div>

                                )}

                              </div>
                            </div></div>
                          )}

                          {item.sensitivity.risk.soil.soil_not_cancer_sensitivity.length == 0 ? (
                            <div></div>
                          ) : (
                            <div> <div className="report-content">
                              <div className="report-area">
                                <div className="area-title">
                                  <div className="area-top">土壤参数敏感性比例（非致癌）</div>
                                  <div className="area-btm">Soil Parameter sensitivity ratio (noncarcinogenic)</div>
                                </div>
                                {!Array.isArray(item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns[0]) ? (
                                  <ReportTable
                                    columns={item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns}
                                    dataSource={item.sensitivity.risk.soil.soil_not_cancer_sensitivity}
                                  ></ReportTable>
                                ) : (
                                  <div className="ReportTable_marght">
                                    {item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns.length > 0 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns[0]}
                                        dataSource={item.sensitivity.risk.soil.soil_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>

                                    )}

                                    {item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns.length > 1 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns[1]}
                                        dataSource={item.sensitivity.risk.soil.soil_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>

                                    )}
                                    {item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns.length > 2 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns[2]}
                                        dataSource={item.sensitivity.risk.soil.soil_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>

                                    )}
                                    {item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns.length > 3 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns[3]}
                                        dataSource={item.sensitivity.risk.soil.soil_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>

                                    )}

                                    {item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns.length > 4 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns[4]}
                                        dataSource={item.sensitivity.risk.soil.soil_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}
                                    {item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns.length > 5 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns[5]}
                                        dataSource={item.sensitivity.risk.soil.soil_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}
                                    {item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns.length > 6 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns[6]}
                                        dataSource={item.sensitivity.risk.soil.soil_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}  {item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns.length > 7 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns[7]}
                                        dataSource={item.sensitivity.risk.soil.soil_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}  {item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns.length > 8 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns[8]}
                                        dataSource={item.sensitivity.risk.soil.soil_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}  {item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns.length > 9 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns[9]}
                                        dataSource={item.sensitivity.risk.soil.soil_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}  {item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns.length > 10 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns[10]}
                                        dataSource={item.sensitivity.risk.soil.soil_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}  {item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns.length > 11 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns[11]}
                                        dataSource={item.sensitivity.risk.soil.soil_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}  {item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns.length > 12 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns[12]}
                                        dataSource={item.sensitivity.risk.soil.soil_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}  {item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns.length > 13 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns[13]}
                                        dataSource={item.sensitivity.risk.soil.soil_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}  {item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns.length > 14 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns[14]}
                                        dataSource={item.sensitivity.risk.soil.soil_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}  {item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns.length > 15 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns[15]}
                                        dataSource={item.sensitivity.risk.soil.soil_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}  {item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns.length > 16 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.soil.soil_not_cancer_sensitivityColumns[16]}
                                        dataSource={item.sensitivity.risk.soil.soil_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}
                                  </div>

                                )}

                              </div>
                            </div></div>
                          )}

                          {item.sensitivity.risk.water.water_cancer_sensitivity.length == 0 ? (
                            <div></div>
                          ) : (
                            <div>
                              <div className="report-content">
                                <div className="report-area">
                                  <div className="area-title">
                                    <div className="area-top">地下水参数敏感性比例（致癌）</div>
                                    <div className="area-btm">water Parameter sensitivity ratio (carcinogenic)</div>
                                  </div>


                                  {!Array.isArray(item.sensitivity.risk.water.water_cancer_sensitivityColumns[0]) ? (
                                    <ReportTable
                                      columns={item.sensitivity.risk.water.water_cancer_sensitivityColumns}
                                      dataSource={item.sensitivity.risk.water.water_cancer_sensitivity}
                                    ></ReportTable>
                                  ) : (
                                    <div className="ReportTable_marght">
                                      {item.sensitivity.risk.water.water_cancer_sensitivityColumns.length > 0 ? (
                                        <ReportTable
                                          columns={item.sensitivity.risk.water.water_cancer_sensitivityColumns[0]}
                                          dataSource={item.sensitivity.risk.water.water_cancer_sensitivity}
                                        ></ReportTable>
                                      ) : (
                                        <div></div>

                                      )}


                                      {item.sensitivity.risk.water.water_cancer_sensitivityColumns.length > 1 ? (
                                        <ReportTable
                                          columns={item.sensitivity.risk.water.water_cancer_sensitivityColumns[1]}
                                          dataSource={item.sensitivity.risk.water.water_cancer_sensitivity}
                                        ></ReportTable>
                                      ) : (
                                        <div></div>

                                      )}
                                      {item.sensitivity.risk.water.water_cancer_sensitivityColumns.length > 2 ? (
                                        <ReportTable
                                          columns={item.sensitivity.risk.water.water_cancer_sensitivityColumns[2]}
                                          dataSource={item.sensitivity.risk.water.water_cancer_sensitivity}
                                        ></ReportTable>
                                      ) : (
                                        <div></div>

                                      )}
                                      {item.sensitivity.risk.water.water_cancer_sensitivityColumns.length > 3 ? (
                                        <ReportTable
                                          columns={item.sensitivity.risk.water.water_cancer_sensitivityColumns[3]}
                                          dataSource={item.sensitivity.risk.water.water_cancer_sensitivity}
                                        ></ReportTable>
                                      ) : (
                                        <div></div>

                                      )}

                                      {item.sensitivity.risk.water.water_cancer_sensitivityColumns.length > 4 ? (
                                        <ReportTable
                                          columns={item.sensitivity.risk.water.water_cancer_sensitivityColumns[4]}
                                          dataSource={item.sensitivity.risk.water.water_cancer_sensitivity}
                                        ></ReportTable>
                                      ) : (
                                        <div></div>
                                      )}
                                      {item.sensitivity.risk.water.water_cancer_sensitivityColumns.length > 5 ? (
                                        <ReportTable
                                          columns={item.sensitivity.risk.water.water_cancer_sensitivityColumns[5]}
                                          dataSource={item.sensitivity.risk.water.water_cancer_sensitivity}
                                        ></ReportTable>
                                      ) : (
                                        <div></div>
                                      )}
                                      {item.sensitivity.risk.water.water_cancer_sensitivityColumns.length > 6 ? (
                                        <ReportTable
                                          columns={item.sensitivity.risk.water.water_cancer_sensitivityColumns[6]}
                                          dataSource={item.sensitivity.risk.water.water_cancer_sensitivity}
                                        ></ReportTable>
                                      ) : (
                                        <div></div>
                                      )}  {item.sensitivity.risk.water.water_cancer_sensitivityColumns.length > 7 ? (
                                        <ReportTable
                                          columns={item.sensitivity.risk.water.water_cancer_sensitivityColumns[7]}
                                          dataSource={item.sensitivity.risk.water.water_cancer_sensitivity}
                                        ></ReportTable>
                                      ) : (
                                        <div></div>
                                      )}  {item.sensitivity.risk.water.water_cancer_sensitivityColumns.length > 8 ? (
                                        <ReportTable
                                          columns={item.sensitivity.risk.water.water_cancer_sensitivityColumns[8]}
                                          dataSource={item.sensitivity.risk.water.water_cancer_sensitivity}
                                        ></ReportTable>
                                      ) : (
                                        <div></div>
                                      )}  {item.sensitivity.risk.water.water_cancer_sensitivityColumns.length > 9 ? (
                                        <ReportTable
                                          columns={item.sensitivity.risk.water.water_cancer_sensitivityColumns[9]}
                                          dataSource={item.sensitivity.risk.water.water_cancer_sensitivity}
                                        ></ReportTable>
                                      ) : (
                                        <div></div>
                                      )}  {item.sensitivity.risk.water.water_cancer_sensitivityColumns.length > 10 ? (
                                        <ReportTable
                                          columns={item.sensitivity.risk.water.water_cancer_sensitivityColumns[10]}
                                          dataSource={item.sensitivity.risk.water.water_cancer_sensitivity}
                                        ></ReportTable>
                                      ) : (
                                        <div></div>
                                      )}  {item.sensitivity.risk.water.water_cancer_sensitivityColumns.length > 11 ? (
                                        <ReportTable
                                          columns={item.sensitivity.risk.water.water_cancer_sensitivityColumns[11]}
                                          dataSource={item.sensitivity.risk.water.water_cancer_sensitivity}
                                        ></ReportTable>
                                      ) : (
                                        <div></div>
                                      )}  {item.sensitivity.risk.water.water_cancer_sensitivityColumns.length > 12 ? (
                                        <ReportTable
                                          columns={item.sensitivity.risk.water.water_cancer_sensitivityColumns[12]}
                                          dataSource={item.sensitivity.risk.water.water_cancer_sensitivity}
                                        ></ReportTable>
                                      ) : (
                                        <div></div>
                                      )}  {item.sensitivity.risk.water.water_cancer_sensitivityColumns.length > 13 ? (
                                        <ReportTable
                                          columns={item.sensitivity.risk.water.water_cancer_sensitivityColumns[13]}
                                          dataSource={item.sensitivity.risk.water.water_cancer_sensitivity}
                                        ></ReportTable>
                                      ) : (
                                        <div></div>
                                      )}  {item.sensitivity.risk.water.water_cancer_sensitivityColumns.length > 14 ? (
                                        <ReportTable
                                          columns={item.sensitivity.risk.water.water_cancer_sensitivityColumns[14]}
                                          dataSource={item.sensitivity.risk.water.water_cancer_sensitivity}
                                        ></ReportTable>
                                      ) : (
                                        <div></div>
                                      )}  {item.sensitivity.risk.water.water_cancer_sensitivityColumns.length > 15 ? (
                                        <ReportTable
                                          columns={item.sensitivity.risk.water.water_cancer_sensitivityColumns[15]}
                                          dataSource={item.sensitivity.risk.water.water_cancer_sensitivity}
                                        ></ReportTable>
                                      ) : (
                                        <div></div>
                                      )}  {item.sensitivity.risk.water.water_cancer_sensitivityColumns.length > 16 ? (
                                        <ReportTable
                                          columns={item.sensitivity.risk.water.water_cancer_sensitivityColumns[16]}
                                          dataSource={item.sensitivity.risk.water.water_cancer_sensitivity}
                                        ></ReportTable>
                                      ) : (
                                        <div></div>
                                      )}  {item.sensitivity.risk.water.water_cancer_sensitivityColumns.length > 17 ? (
                                        <ReportTable
                                          columns={item.sensitivity.risk.water.water_cancer_sensitivityColumns[17]}
                                          dataSource={item.sensitivity.risk.water.water_cancer_sensitivity}
                                        ></ReportTable>
                                      ) : (
                                        <div></div>
                                      )}  {item.sensitivity.risk.water.water_cancer_sensitivityColumns.length > 18 ? (
                                        <ReportTable
                                          columns={item.sensitivity.risk.water.water_cancer_sensitivityColumns[18]}
                                          dataSource={item.sensitivity.risk.water.water_cancer_sensitivity}
                                        ></ReportTable>
                                      ) : (
                                        <div></div>
                                      )}
                                    </div>

                                  )}
                                </div>
                              </div>
                            </div>
                          )}



                          {item.sensitivity.risk.water.water_not_cancer_sensitivity.length == 0 ? (
                            <div></div>
                          ) : (
                            <div> <div className="report-content">
                              <div className="report-area">
                                <div className="area-title">
                                  <div className="area-top">地下水参数敏感性比例（非致癌）</div>
                                  <div className="area-btm">water Parameter sensitivity ratio (noncarcinogenic)</div>
                                </div>
                                {!Array.isArray(item.sensitivity.risk.water.water_not_cancer_sensitivityColumns[0]) ? (

                                  <ReportTable
                                    columns={item.sensitivity.risk.water.water_not_cancer_sensitivityColumns}
                                    dataSource={item.sensitivity.risk.water.water_not_cancer_sensitivity}
                                  ></ReportTable>

                                ) : (
                                  <div className="ReportTable_marght">
                                    {item.sensitivity.risk.water.water_not_cancer_sensitivityColumns.length > 0 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.water.water_not_cancer_sensitivityColumns[0]}
                                        dataSource={item.sensitivity.risk.water.water_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>

                                    )}

                                    {item.sensitivity.risk.water.water_not_cancer_sensitivityColumns.length > 1 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.water.water_not_cancer_sensitivityColumns[1]}
                                        dataSource={item.sensitivity.risk.water.water_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>

                                    )}
                                    {item.sensitivity.risk.water.water_not_cancer_sensitivityColumns.length > 2 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.water.water_not_cancer_sensitivityColumns[2]}
                                        dataSource={item.sensitivity.risk.water.water_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>

                                    )}
                                    {item.sensitivity.risk.water.water_not_cancer_sensitivityColumns.length > 3 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.water.water_not_cancer_sensitivityColumns[3]}
                                        dataSource={item.sensitivity.risk.water.water_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>

                                    )}

                                    {item.sensitivity.risk.water.water_not_cancer_sensitivityColumns.length > 4 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.water.water_not_cancer_sensitivityColumns[4]}
                                        dataSource={item.sensitivity.risk.water.water_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}
                                    {item.sensitivity.risk.water.water_not_cancer_sensitivityColumns.length > 5 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.water.water_not_cancer_sensitivityColumns[5]}
                                        dataSource={item.sensitivity.risk.water.water_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}
                                    {item.sensitivity.risk.water.water_not_cancer_sensitivityColumns.length > 6 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.water.water_not_cancer_sensitivityColumns[6]}
                                        dataSource={item.sensitivity.risk.water.water_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )} {item.sensitivity.risk.water.water_not_cancer_sensitivityColumns.length > 7 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.water.water_not_cancer_sensitivityColumns[7]}
                                        dataSource={item.sensitivity.risk.water.water_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )} {item.sensitivity.risk.water.water_not_cancer_sensitivityColumns.length > 8 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.water.water_not_cancer_sensitivityColumns[8]}
                                        dataSource={item.sensitivity.risk.water.water_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )} {item.sensitivity.risk.water.water_not_cancer_sensitivityColumns.length > 9 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.water.water_not_cancer_sensitivityColumns[9]}
                                        dataSource={item.sensitivity.risk.water.water_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )} {item.sensitivity.risk.water.water_not_cancer_sensitivityColumns.length > 10 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.water.water_not_cancer_sensitivityColumns[10]}
                                        dataSource={item.sensitivity.risk.water.water_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )} {item.sensitivity.risk.water.water_not_cancer_sensitivityColumns.length > 11 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.water.water_not_cancer_sensitivityColumns[11]}
                                        dataSource={item.sensitivity.risk.water.water_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )} {item.sensitivity.risk.water.water_not_cancer_sensitivityColumns.length > 12 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.water.water_not_cancer_sensitivityColumns[12]}
                                        dataSource={item.sensitivity.risk.water.water_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )} {item.sensitivity.risk.water.water_not_cancer_sensitivityColumns.length > 13 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.water.water_not_cancer_sensitivityColumns[13]}
                                        dataSource={item.sensitivity.risk.water.water_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )} {item.sensitivity.risk.water.water_not_cancer_sensitivityColumns.length > 14 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.water.water_not_cancer_sensitivityColumns[14]}
                                        dataSource={item.sensitivity.risk.water.water_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )} {item.sensitivity.risk.water.water_not_cancer_sensitivityColumns.length > 15 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.water.water_not_cancer_sensitivityColumns[15]}
                                        dataSource={item.sensitivity.risk.water.water_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )} {item.sensitivity.risk.water.water_not_cancer_sensitivityColumns.length > 16 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.water.water_not_cancer_sensitivityColumns[16]}
                                        dataSource={item.sensitivity.risk.water.water_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )} {item.sensitivity.risk.water.water_not_cancer_sensitivityColumns.length > 17 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.water.water_not_cancer_sensitivityColumns[17]}
                                        dataSource={item.sensitivity.risk.water.water_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )} {item.sensitivity.risk.water.water_not_cancer_sensitivityColumns.length > 18 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.water.water_not_cancer_sensitivityColumns[18]}
                                        dataSource={item.sensitivity.risk.water.water_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )} {item.sensitivity.risk.water.water_not_cancer_sensitivityColumns.length > 19 ? (
                                      <ReportTable
                                        columns={item.sensitivity.risk.water.water_not_cancer_sensitivityColumns[19]}
                                        dataSource={item.sensitivity.risk.water.water_not_cancer_sensitivity}
                                      ></ReportTable>
                                    ) : (
                                      <div></div>
                                    )}
                                  </div>

                                )}

                              </div>
                            </div></div>
                          )}




                        </div>


                      })

                    }

                  </div>
                </div>
                <Modal
                  title="专家咨询"
                  centered
                  visible={this.state.consultData}
                  onOk={() => this.confirmSubmit()}
                  onCancel={() => this.usercenter(false)}
                  width={800}
                  okText="提交"
                  cancelText="关闭"
                  destroyOnClose={true}
                >
                  <p className="consultP ">如果您对报告产生任何疑问，可以留下您的问题和联系方式，我们将派专家跟您联系！</p>
                  <p className="consultP consultP_txt">请输入您想咨询的问题</p>
                  <TextArea showCount maxLength={300} allowClear onChange={this.onChangeArea} style={{ width: '100%', resize: 'none' }} rows={5} />
                  <p className="consultP consultP_txt">请输入您的联系方式</p>
                  <Input onChange={this.onChangephone} />
                </Modal>
                <div className="report-btn">
                  <div className="laststep" onClick={this.nextStep.bind(this, 'previous')}>
                    上一步
                  </div>
                  <div className="pdf" onClick={this.nextStep.bind(this, 'pdf')}>
                    <img src={require('../../../assets/images/report/report_pdf.png').default}></img>
                    <span>导出PDF</span>
                  </div>
                  <div className="consult" onClick={this.nextStep.bind(this, 'consult')}>
                    <img src={require('../../../assets/images/report/consult.png').default}></img>
                    <span>专家咨询</span>
                  </div>
                  <div className="charge" onClick={this.nextStep.bind(this, 'charge')}>
                    <img src={require('../../../assets/images/report/report_word.png').default}></img>
                    <span>生成正式报告</span>
                    <div className="pay">付费</div>
                  </div>


                  {/* <div className="word" onClick={this.nextStep.bind(this,'word')}>
              <img src={require('../../../assets/images/report/word.png').default}></img>
              <span>导出WORD</span>
            </div> */}
                </div>

              </div>
            )
          }

        </Spin>
      </div>
    );
  }
}

export default generalReport;
