import * as React from "react";
import { withRouter } from "react-router-dom";
import { getCarcinogenicTable, getProjectInfo } from "../../../apis/common";
import { checkProductd, checkDepthid } from '../../../configs/checkProductId'
import { getDepthid, getSessionNumberPlies, getSessionLevel, setSelectedKey, setDepthid, getSelectedKey } from '../../../utils/token'

import Header from './head';
import { Tabs, Row, Col, Form, Input, Divider, Tag, Table, Switch, Space, Spin, message } from "antd";
import './sensitivity.less'
import type { ColumnsType } from 'antd/es/table';
import strict from "assert/strict";
const { TabPane } = Tabs;
const { Column, ColumnGroup } = Table;
interface IProps {
  login: any;
  register: any;
  history: any;
  retrieve: any;
  location: any;
}
interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}
interface IState {
  depth_id: any,
  project_id: any,
  loading: boolean,
  CarcinogenicColumns: any,
  NotCarcinogenicColumns: any,
  cancolumns: any,
  Nocancolumns: any,
  candata: any,
  Nocandata: any,
  Number_Plies: any,
  waterCancerColumns: any;
  waterCancer: any;
  waterNotCancerColumns: any,
  waterNotCancer: any,
  soilCancerColumns: any,
  soilCancer: any,
  soilNotCancerColumns: any,
  soilNotCancer: any,
  headers: any,
  searchList: any,
  hasSearchList: boolean,
  adjustment: string;
  Level: any,
}


let soilCancerColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '中文名',
    dataIndex: 'name_cn',
    key: 'name_cn',
  },
  {
    title: '英文名',
    dataIndex: 'name_en',
    key: 'name_en',
    width: 200,
  },
  {
    title: 'CAS编号',
    dataIndex: 'cas_code',
    key: 'cas_code',
  },
  {
    title: '经口摄入土壤',
    dataIndex: 'OISERca',
    key: 'OISERca',
    children: [
      {
        title: 'OISERca',
        dataIndex: 'OISERca',
        key: 'IOVERca1',
      },
    ]
  },
  {
    title: '皮肤接触土壤',
    dataIndex: 'DCSERca',
    key: 'DCSERca',
    children: [
      {
        title: 'DCSERca',
        dataIndex: 'DCSERca',
        key: 'DCSERca',
      },
    ]
  },
  {
    title: '吸入土壤颗粒物',
    dataIndex: 'PIERca',
    key: 'PIERca',
    children: [
      {
        title: 'PIERca',
        dataIndex: 'PIERca',
        key: 'PIERca',
      },
    ]
  },
  {
    title: '吸入室外空气中来自表层土壤的气态污染物',
    dataIndex: 'IOVERca1',
    key: 'IOVERca1',
    children: [
      {
        title: 'IOVERca1',
        dataIndex: 'IOVERca1',
        key: 'IOVERca1',
      },
    ]
  },
  {
    title: '吸入室外空气中来自下层土壤的气态污染物',
    dataIndex: 'IOVERca2',
    key: 'IOVERca2',
    children: [
      {
        title: 'IOVERca2',
        dataIndex: 'IOVERca2',
        key: 'IOVERca2',
      },
    ]
  },
  {
    title: '吸入室内空气中来自下层土壤的气态污染物',
    dataIndex: 'IIVERca1',
    children: [
      {
        title: 'IIVERca1',
        dataIndex: 'IIVERca1',
        key: 'IIVERca1',
      },
    ]
  },
]
let soilNotCancerColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '中文名',
    dataIndex: 'name_cn'
  },
  {
    title: '英文名',
    dataIndex: 'name_en',
    width: 200,
  },
  {
    title: 'CAS编号',
    dataIndex: 'cas_code',
  },
  {
    title: '经口摄入土壤',
    dataIndex: 'OISERnc',
    children: [
      {
        title: 'OISERnc',
        dataIndex: 'OISERnc',
        key: 'OISERnc',
      },
    ]
  },
  {
    title: '皮肤接触土壤',
    dataIndex: 'DCSERnc',
    children: [
      {
        title: 'DCSERnc',
        dataIndex: 'DCSERnc',
        key: 'DCSERnc',
      },
    ]
  },
  {
    title: '吸入土壤颗粒物',
    dataIndex: 'PIERnc',
    children: [
      {
        title: 'PIERnc',
        dataIndex: 'PIERnc',
        key: 'PIERnc',
      },
    ]
  },
  {
    title: '吸入室外空气中来自表层土壤的气态污染物',
    dataIndex: 'IOVERnc1',
    children: [
      {
        title: 'IOVERnc1',
        dataIndex: 'IOVERnc1',
        key: 'IOVERnc1',
      },
    ]
  },
  {
    title: '吸入室外空气中来自下层土壤的气态污染物',
    dataIndex: 'IOVERnc2',
    children: [
      {
        title: 'IOVERnc2',
        dataIndex: 'IOVERnc2',
        key: 'IOVERnc2',
      },
    ]
  },
  {
    title: '吸入室内空气中来自下层土壤的气态污染物',
    dataIndex: 'IIVERnc1',
    children: [
      {
        title: 'IIVERnc1',
        dataIndex: 'IIVERnc1',
        key: 'IIVERnc1',
      },
    ]
  },
];
let waterCancerColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '中文名',
    dataIndex: 'name_cn'
  },
  {
    title: '英文名',
    dataIndex: 'name_en',
  },
  {
    title: 'CAS编号',
    dataIndex: 'cas_code',
  },
  {
    title: '吸入室外空气中来自地下水的气态污染物',
    dataIndex: 'IOVERca3',
    children: [
      {
        title: 'IOVERca3',
        dataIndex: 'IOVERca3',
      },
    ]
  },
  {
    title: '吸入室内空气中来自地下水的气态污染物',
    dataIndex: 'IIVERca2',
    children: [
      {
        title: 'IIVERca2',
        dataIndex: 'IIVERca2',
      },
    ]
  },
  {
    title: '饮用地下水',
    dataIndex: 'CGWERca',
    children: [
      {
        title: 'CGWERca',
        dataIndex: 'CGWERca',
      },
    ]
  },
];
let waterNotCancerColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '中文名',
    dataIndex: 'name_cn'
  },
  {
    title: '英文名',
    dataIndex: 'name_en',
  },
  {
    title: 'CAS编号',
    dataIndex: 'cas_code',
  },
  {
    title: '吸入室外空气中来自地下水的气态污染物',
    dataIndex: 'IOVERnc3',
    children: [
      {
        title: 'IOVERnc3',
        dataIndex: 'IOVERnc3',
      },
    ]
  },
  {
    title: '吸入室内空气中来自地下水的气态污染物',
    dataIndex: 'IIVERnc2',
    children: [
      {
        title: 'IIVERnc2',
        dataIndex: 'IIVERnc2',
      },
    ]
  },
  {
    title: '饮用地下水',
    dataIndex: 'CGWERnc 0',
    children: [
      {
        title: 'CGWERnc',
        dataIndex: 'CGWERnc',
      },
    ]
  },
];
class sensitivity extends React.PureComponent<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      project_id: '',
      depth_id: '',
      Level: "",
      loading: true,
      CarcinogenicColumns: [],
      NotCarcinogenicColumns: [],
      candata: [],
      cancolumns: [],
      Nocandata: [],
      Nocancolumns: [],
      Number_Plies: [],
      waterCancerColumns: [],
      waterCancer: [],
      waterNotCancerColumns: [],
      waterNotCancer: [],
      soilCancerColumns: [],
      soilCancer: [],
      soilNotCancerColumns: [],
      soilNotCancer: [],
      searchList: [],//table数据源
      hasSearchList: false,
      adjustment: '20%',
      headers: [{
        dataIndex: 'columnHead',
        title: '参数名称',
        fixed: 'left',
        width: 100
      }, {
        dataIndex: 'whitespace',
        title: "参数含义",
        width: 100,
        fixed: 'left',

      }]//初始化headers


    };
  }
  gotoTap = async (step: string) => {
    if (step == 'back') {

      this.props.history.push({


        pathname: "/project/getlnitParamsValue",


      })


    }
    if (step == 'next') {
      let productId = checkProductd();
      console.log("SessionLevelSessionNumberPlies", this.state.depth_id,);

      console.log("this.state.Number_Plies", this.state.Number_Plies);
      getProjectInfo({
        project_id: productId
      }).then((res: any) => {
        console.log("res", res.data[0].depthRow);
        let depthRowArrays = res.data[0].depthRow
        let tier = 0
        let tierone = 0
        let depthId = 0
        let depthRowArraysdepthId = 0
        let indexs = 0
        let depthIdlength = depthRowArrays[depthRowArrays.length - 1].depth_id
        let Numberstr = Number(this.state.Number_Plies) + 1
        let depth_idstr = Number(this.state.depth_id) + 1
        let depth_idtoString = JSON.stringify(depth_idstr)


        depthRowArrays.forEach((items: any, indexs: any) => {
          // console.log("items", items.depth_id, items.tier, indexs);
          tierone = Number(items.tier) + 1
          tier = Number(items.tier)
          depthId = Number(items.depth_id)
          indexs = Number(indexs)




        })
        if (res.data[0].depthRow.length==1) {
       
          setSelectedKey('100')

          setTimeout(() => {
            this.props.history.push({


              pathname: "/project/generalReport",
              setSelect: '100',


            })
            window.location.reload()
          }, 200)
          
        }else{
          for (let index = 0; index < depthRowArrays.length; index++) {
            if (depthRowArrays[index].depth_id == this.state.depth_id) {
              console.log("depthRowArrays[index].depth_id1111111", depthRowArrays[index + 1].depth_id, this.state.depth_id, depthId);
              depthRowArraysdepthId = depthRowArrays[index + 1].depth_id
  
              console.log("index", this.state.depth_id, depthRowArraysdepthId, depthId);
  
              if (depthRowArraysdepthId == depthId) {
                console.log("depthRowArraysdepthId1111111", depthRowArraysdepthId, depthId);
               
                if (this.state.depth_id == depthId) {
                  setSelectedKey('100')
                  setTimeout(() => {
                    this.props.history.push({
  
  
                      pathname: "/project/generalReport",
                      setSelect: '100',
  
  
                    })
                    window.location.reload()
                  }, 200)
                }else{
                  let Number = this.state.Number_Plies + 1
                  setDepthid(JSON.stringify(depthId))
                  setSelectedKey(`${Numberstr + '-10'}`)
    
                  setTimeout(() => {
                    this.props.history.push({
    
                      pathname: "/project/followPollute",
                      val: `${Numberstr}`,
                      Depth: `${depthId}`,
    
                    })
                    window.location.reload()
                  }, 200)
                  return
                }
  
                return;
              } else {
                console.log("depthRowArraysdepthId2222222", depthRowArraysdepthId, depthId);
                let Number = this.state.Number_Plies + 1
                setDepthid(JSON.stringify(depthRowArraysdepthId))
                setSelectedKey(`${Numberstr + '-10'}`)
  
                setTimeout(() => {
                  this.props.history.push({
  
                    pathname: "/project/followPollute",
                    val: `${Numberstr}`,
                    Depth: `${depthRowArraysdepthId}`,
  
                  })
                  window.location.reload()
                }, 200)
                return
              }
  
            } else {
              console.log("depthRowArrays[index].depth_id22222222", depthRowArrays[index + 1].depth_id, this.state.depth_id, depthId);
              setSelectedKey('100')
              setTimeout(() => {
                this.props.history.push({
  
  
                  pathname: "/project/generalReport",
                  setSelect: '100',
  
  
                })
                window.location.reload()
              }, 200)
            }
  
  
          }

        }
    



        // if (this.state.depth_id == depthIdlength) {
        //   console.log("是", this.state.depth_id, depthIdlength);


        // } else {

        //   let Number = this.state.Number_Plies + 1
        //   // setDepthid(depth_idtoString)
        //   setSelectedKey(`${Numberstr + '-10'}`)

        //   setTimeout(() => {
        //     // this.props.history.push({

        //     //   pathname: "/project/followPollute",
        //     //   val: `${Numberstr}`,
        //     //   Depth: `${depth_idstr}`,

        //     // })
        //     // window.location.reload()
        //   }, 200)

        // }


      }).catch((err: any) => {
        // message.warn('服务器异常，请稍后再试')
      })


    }
  }
  componentWillMount() {
    let route = this.props.history.location;
    console.log("this.props", route.adjustment);

    if (route.adjustment) {
      console.log("11111");

      this.setState({
        adjustment: route.adjustment
      })
    }

    let productId = checkProductd();
    if (productId) {
      this.setState({
        project_id: productId
      })
    }
    let Depthid = checkDepthid();
    if (Depthid) {
      this.setState({
        depth_id: Depthid,
      })
    }
    let NumberPlies = getSessionNumberPlies();
    if (NumberPlies) {
      this.setState({
        Number_Plies: NumberPlies,
      })
    }
    let Level = getSessionLevel()
    if (Level) {
      this.setState({
        Level: Level,
      })
    }
  }
  componentDidMount() {
    this.exposedQuantityTable()

  }
  exposedQuantityTable = async () => {
    let params = {
      project_id: this.state.project_id,
      depth_id: this.state.depth_id,
      // adjustment_coefficient: '1%'
      adjustment_coefficient: this.state.adjustment
    }
    try {
      let res = await getCarcinogenicTable(params);
      console.log("res", res);
      let Waterexposure = res.data.risk.water;
      let Soilexposure = res.data.risk.soil;

      this.setState({
        loading: false
      })
      this.handlewater(Waterexposure)
      this.handlesoil(Soilexposure)
    } catch (error) {
      message.warn('服务器异常，请稍后再试')
      console.log("error", error);

      this.props.history.push({


        pathname: "/project/getlnitParamsValue",


      })
    }
  }
  handlewater = (exposure: any) => {
    console.log("exposure", exposure);

    let {

      cancer: waterCancer,
      not_cancer: waterNotCancer

    } = exposure;
    this.handleWaterCancer(waterCancer)
    this.handleWaterNotCancer(waterNotCancer)
  }
  handleWaterCancer = (waterCancer: any) => {
    console.log("waterCancer", waterCancer);

    let Columns = [

      {
        title: '参数名称',
        dataIndex: 'name_en',
        key: 'name_en',
        fixed: 'left',
        width: 100,
      },
      {
        title: '参数含义',
        dataIndex: 'name_cn',
        key: 'name_cn',
        fixed: 'left',
        width: 100,
      },
    ]
    let dataSourceColumns = new Array()
    let newobjectColumns = new Object()
    let dataSource = new Array()
    let newobject = new Object()
    waterCancer.forEach((item: any, index: any) => {


      Object.assign(newobjectColumns, {
        title: item.name_cn,
        key: item.name_en,
        id: item.name_en,
        dataIndex: item.name_en,

        width: 150,
      })
      dataSourceColumns.push(newobjectColumns)
      newobjectColumns = {}


    });
    let headerArr = new Array()
    let headerObj = new Object()
    waterCancer[0].item.forEach((ite: any, ind: any) => {
      console.log("ite", ite, ind, ite.name_en);
      Object.assign(headerObj, {
        title: ite.name_en,

      })
      headerArr.push(headerObj)
      headerObj = {}
    })
    console.log("headerArr", headerArr);

    let ssss = Columns.concat(dataSourceColumns)
    //获取后端返回对象数据的键值对
    const keys = Object.values(waterCancer).map((item: any) => item.name_cn);//name对应键值对的键名
    const values = Object.values(waterCancer);
    console.log("values", values, waterCancer);

    //重新设置表头
    this.setState({
      headers: [...this.state.headers, ...keys.map((item, index) => {

        return {
          dataIndex: item,
          title: item,
          width: 100
        };
      })]
    });
    let sensitivityReplace = new Object()
    let sensitivityArray = new Array()

    values.forEach((val: any, index: any) => {

      for (const key in val.item) {
        let NumberKey = Number(key)
        // val.item[NumberKey].sensitivity = val.item[NumberKey].sensitivity.toExponential(2)
        // console.log("val.item", val.item[NumberKey],val.name_cn);
        // cList.forEach((item: any, i: any) => {
        //   console.log("item",item,i);

        // })
        for (let i = 0; i < val.item.length; i++) {
          // console.log("i",i);
          if (i == NumberKey) {

            // console.log('是', NumberKey, i);
            // console.log(" val.item[NumberKey]", val.item[i], val.name_cn, i);
            sensitivityReplace = JSON.parse(JSON.stringify(val.item[i]).replace(/sensitivity/g, `${val.name_en}`))

            sensitivityArray.push(sensitivityReplace)
            sensitivityReplace = {}


          }

        }


      }



    });
    let objectAss = new Object()
    let objectArray = new Array()
    headerArr.forEach((item: any) => {
      for (const key in sensitivityArray) {
        const NumberKey = Number(key)

        if (sensitivityArray[NumberKey].name_en == item.title) {
          objectAss = Object.assign(item, sensitivityArray[NumberKey])


        }

      }


      objectArray.push(objectAss)

    })

    this.setState({
      waterCancerColumns: ssss,
      waterCancer: objectArray,
      loading: false

    })
  }
  handleWaterNotCancer = (waterNotCancer: any) => {
    console.log("waterNotCancer", waterNotCancer);

    let Columns = [

      {
        title: '参数名称',
        dataIndex: 'name_en',
        key: 'name_en',
        fixed: 'left',
        width: 100,
      },
      {
        title: '参数含义',
        dataIndex: 'name_cn',
        key: 'name_cn',
        fixed: 'left',
        width: 100,
      },
    ]
    let dataSourceColumns = new Array()
    let newobjectColumns = new Object()
    let dataSource = new Array()
    let newobject = new Object()
    waterNotCancer.forEach((item: any, index: any) => {


      Object.assign(newobjectColumns, {
        title: item.name_cn,
        key: item.name_en,
        id: item.name_en,
        dataIndex: item.name_en,

        width: 150,
      })
      dataSourceColumns.push(newobjectColumns)
      newobjectColumns = {}


    });
    let headerArr = new Array()
    let headerObj = new Object()
    waterNotCancer[0].item.forEach((ite: any, ind: any) => {
      console.log("ite", ite, ind, ite.name_en);
      Object.assign(headerObj, {
        title: ite.name_en,

      })
      headerArr.push(headerObj)
      headerObj = {}
    })
    let ssss = Columns.concat(dataSourceColumns)
    //获取后端返回对象数据的键值对
    const keys = Object.values(waterNotCancer).map((item: any) => item.name_cn);//name对应键值对的键名
    const values = Object.values(waterNotCancer);

    //重新设置表头
    this.setState({
      headers: [...this.state.headers, ...keys.map((item, index) => {

        return {
          dataIndex: item,
          title: item,
          width: 100
        };
      })]
    });
    //设置table数据源
    const cList = this.state.searchList;
    let sensitivityReplace = new Object()
    let sensitivityArray = new Array()
    let Numberindex = 0

    let dataObj = new Object()
    let dataArr = new Array()

    values.forEach((val: any, index: any) => {

      for (const key in val.item) {
        let NumberKey = Number(key)
        // val.item[NumberKey].sensitivity = val.item[NumberKey].sensitivity.toExponential(2)
        // console.log("val.item", val.item[NumberKey],val.name_cn);
        // cList.forEach((item: any, i: any) => {
        //   console.log("item",item,i);

        // })
        for (let i = 0; i < val.item.length; i++) {
          // console.log("i",i);
          if (i == NumberKey) {

            // console.log('是', NumberKey, i);
            // console.log(" val.item[NumberKey]", val.item[i], val.name_cn, i);
            sensitivityReplace = JSON.parse(JSON.stringify(val.item[i]).replace(/sensitivity/g, `${val.name_en}`))

            sensitivityArray.push(sensitivityReplace)
            sensitivityReplace = {}


          }

        }


        // if (val.item[NumberKey].name_cn==val.item[NumberKey+1].name_cn) {
        //   console.log('是');

        // }else{
        //   console.log('不是');

        // }



      }




    });
    let objectAss = new Object()
    let objectArray = new Array()

    headerArr.forEach((item: any) => {
      for (const key in sensitivityArray) {
        const NumberKey = Number(key)

        if (sensitivityArray[NumberKey].name_en == item.title) {
          objectAss = Object.assign(item, sensitivityArray[NumberKey])


        }

      }




      objectArray.push(objectAss)


    })


    this.setState({
      waterNotCancerColumns: ssss,
      waterNotCancer: objectArray,
      loading: false

    })
  }
  handlesoil = (exposure: any) => {

    let {

      cancer: soilCancer,
      not_cancer: soilNotCancer

    } = exposure;

    this.handleSoilCancer(soilCancer)
    this.handleSoilNotCancer(soilNotCancer)
  }
  handleSoilCancer = (soilCancer: any) => {
    console.log("soilCancer", soilCancer);

    let Columns = [

      {
        title: '参数名称',
        dataIndex: 'name_en',
        key: 'name_en',
        fixed: 'left',
        width: 100,
      },
      {
        title: '参数含义',
        dataIndex: 'name_cn',
        key: 'name_cn',
        fixed: 'left',
        width: 100,
      },
    ]
    let dataSourceColumns = new Array()
    let newobjectColumns = new Object()
    let dataSource = new Array()
    let newobject = new Object()

    soilCancer.forEach((item: any, index: any) => {


      Object.assign(newobjectColumns, {
        title: item.name_cn,
        key: item.name_cn,
        id: item.name_cn,
        dataIndex: item.name_cn,
        width: 150,
      })
      dataSourceColumns.push(newobjectColumns)
      newobjectColumns = {}


    });
    let headerArr = new Array()
    let headerObj = new Object()
    soilCancer[0].item.forEach((ite: any, ind: any) => {
      Object.assign(headerObj, {
        title: ite.name_en,
      })
      headerArr.push(headerObj)
      headerObj = {}
    })
    console.log("headerArr", headerArr);


    let ssss = Columns.concat(dataSourceColumns)
    //获取后端返回对象数据的键值对
    const keys = Object.values(soilCancer).map((item: any) => item.name_cn);//name对应键值对的键名
    const values = Object.values(soilCancer);


    //重新设置表头
    this.setState({
      headers: [...this.state.headers, ...keys.map((item, index) => {

        return {
          dataIndex: item,
          title: item,
          width: 100
        };
      })]
    });
    //设置table数据源
    const cList = this.state.searchList;
    let sensitivityReplace = new Object()
    let sensitivityArray = new Array()
    let Numberindex = 0

    let dataObj = new Object()
    let dataArr = new Array()

    values.forEach((val: any, index: any) => {

      for (const key in val.item) {
        let NumberKey = Number(key)
        // console.log("val.item[NumberKey].sensitivity.toExponential(5)", val.item[key].sensitivity.toExponential(5), val.item[key].sensitivity);
        // val.item[NumberKey].sensitivity = val.item[NumberKey].sensitivity.toExponential(2)


        for (let i = 0; i < val.item.length; i++) {

          if (i == NumberKey) {
            sensitivityReplace = JSON.parse(JSON.stringify(val.item[NumberKey]).replace(/sensitivity/g, `${val.name_cn}`))


            sensitivityArray.push(sensitivityReplace)
            sensitivityReplace = {}


          }

        }
      }




    });
    let objectAss = new Array()
    let objectArray = new Array()
    console.log("sensitivityArray", sensitivityArray, headerArr);

    headerArr.forEach((item: any) => {
      console.log("item", item);

      for (const key in sensitivityArray) {
        const NumberKey = Number(key)

        if (sensitivityArray[NumberKey].name_en == item.title) {
          objectAss = Object.assign(item, sensitivityArray[NumberKey])




        }



      }
      objectArray.push(objectAss)


    })

    console.log("objectArray", objectAss, objectArray);


    this.setState({
      soilCancerColumns: ssss,
      soilCancer: objectArray,
      loading: false
    })
  }
  handleSoilNotCancer = (soilNotCancer: any) => {
    console.log("soilNotCancer", soilNotCancer);

    let Columns = [

      {
        title: '参数名称',
        dataIndex: 'name_en',
        key: 'name_en',
        fixed: 'left',
        width: 100,
      },
      {
        title: '参数含义',
        dataIndex: 'name_cn',
        key: 'name_cn',
        fixed: 'left',
        width: 100,
      },
    ]
    let dataSourceColumns = new Array()
    let newobjectColumns = new Object()
    let dataSource = new Array()
    let newobject = new Object()

    soilNotCancer.forEach((item: any, index: any) => {
      console.log("item", item);


      Object.assign(newobjectColumns, {
        title: item.name_cn,
        key: item.name_cn,
        id: item.name_cn,
        dataIndex: item.name_cn,
        width: 150,
      })
      dataSourceColumns.push(newobjectColumns)
      newobjectColumns = {}


    });
    console.log("dataSourceColumns", dataSourceColumns,);
    let headerArr = new Array()
    let headerObj = new Object()
    soilNotCancer[0].item.forEach((ite: any, ind: any) => {
      console.log("ite", ite, ind, ite.name_en);
      Object.assign(headerObj, {
        title: ite.name_en,

      })
      headerArr.push(headerObj)
      headerObj = {}
    })
    console.log("headerArr", headerArr);


    let ssss = Columns.concat(dataSourceColumns)
    //获取后端返回对象数据的键值对
    const keys = Object.values(soilNotCancer).map((item: any) => item.name_cn);//name对应键值对的键名
    const values = Object.values(soilNotCancer);


    //重新设置表头
    this.setState({
      headers: [...this.state.headers, ...keys.map((item, index) => {

        return {
          dataIndex: item,
          title: item,
          width: 100
        };
      })]
    });
    //设置table数据源
    const cList = this.state.searchList;
    let sensitivityReplace = new Object()
    let sensitivityArray = new Array()
    let Numberindex = 0

    let dataObj = new Object()
    let dataArr = new Array()

    values.forEach((val: any, index: any) => {

      for (const key in val.item) {
        let NumberKey = Number(key)
        // console.log("val.item[NumberKey].sensitivity.toExponential(5)", val.item[key].sensitivity.toExponential(5), val.item[key].sensitivity);
        // val.item[NumberKey].sensitivity = val.item[NumberKey].sensitivity.toExponential(2)


        for (let i = 0; i < val.item.length; i++) {

          if (i == NumberKey) {
            sensitivityReplace = JSON.parse(JSON.stringify(val.item[NumberKey]).replace(/sensitivity/g, `${val.name_cn}`))


            sensitivityArray.push(sensitivityReplace)
            sensitivityReplace = {}


          }

        }
      }




    });
    let objectAss = new Array()
    let objectArray = new Array()
    console.log("sensitivityArray", sensitivityArray);

    headerArr.forEach((item: any) => {
      console.log("item", item);

      for (const key in sensitivityArray) {
        const NumberKey = Number(key)

        if (sensitivityArray[NumberKey].name_en == item.title) {
          objectAss = Object.assign(item, sensitivityArray[NumberKey])




        }



      }
      objectArray.push(objectAss)


    })

    console.log("objectArray", objectAss, objectArray);


    this.setState({
      soilNotCancerColumns: ssss,
      soilNotCancer: objectArray,
      loading: false
    })
  }
  render() {


    return (
      <div className="content">
        <Spin spinning={this.state.loading}>

          <Tabs
            type="card"
            defaultActiveKey='1'
            onChange={(key) => {
              console.log(key);
            }}
          >
            <TabPane tab="土壤" key="1">
              <div className="setting">
                <div className="modeliconleft">土壤敏感性比（致癌）</div>
              </div>
              <Divider></Divider>

              <Table
                columns={this.state.soilCancerColumns}
                dataSource={this.state.soilCancer}
                rowKey={record => record.id}
                scroll={{ x: 1500 }}
                bordered
                loading={false}
              >
              </Table>
              <div className="setting">
                <div className="modeliconleft">土壤敏感性比（非致癌效应）</div>
              </div>
              <Divider></Divider>
              <Table
                columns={this.state.soilNotCancerColumns}
                dataSource={this.state.soilNotCancer}
                rowKey={record => record.id}
                scroll={{ x: 1500 }}
                bordered
              >
              </Table>
            </TabPane>

            <TabPane tab="地下水" key="2">
              <div className="setting">
                <div className="modeliconleft">地下水敏感性比（致癌）</div>
              </div>
              <Divider></Divider>
              <Table
                columns={this.state.waterCancerColumns}
                dataSource={this.state.waterCancer}
                rowKey={record => record.id}
                scroll={{ x: 1500 }}
                bordered
              >
              </Table>
              <div className="setting">
                <div className="modeliconleft">地下水敏感性比（非致癌效应）</div>
              </div>
              <Divider></Divider>
              <Table
                columns={this.state.waterNotCancerColumns}
                dataSource={this.state.waterNotCancer}
                rowKey={record => record.id}
                bordered
                scroll={{ x: 1500 }}
              >
              </Table>
            </TabPane>
          </Tabs>

          <div className="exposebtn">
            <button className="formbtnleft" onClick={this.gotoTap.bind(this, 'back')}>上一步</button>
            <button className="formbtnright" onClick={this.gotoTap.bind(this, 'next')}>下一步</button>
          </div>
        </Spin>
      </div >
    );
  }
}

export default withRouter(sensitivity);
