import * as React from "react";
import { withRouter } from "react-router-dom";
import { fetchJson } from 'fetch-json'
import { message } from "antd";
import {getQRcode,queryStatus,bindWechat} from '../../apis/common'
import axios from 'axios'

interface IProps {
    history: any;
}

interface IState {
    scanImg: string
    msg: string
    uuid: string
}
// let backInterval
let backInterval:any;
class ScanQRCode extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            scanImg: "",
            msg:"请使用微信扫描二维码登录污染场地风险评估软件测试版",
            uuid: ""
        }
    }
    

    handleRegister = () =>{
        const {history} = this.props
        history.push("/register")
    }


    handleLogin = () =>{
        const {history} = this.props
        history.push("/login")
    }


    componentDidMount(){
        this.getQRcodeData();
    }
    getQRcodeData(){
        axios.get(getQRcode).then((res)=>{
            this.setState({scanImg:res.data.data.img, uuid:res.data.data.uuid})
            // backInterval = setInterval(()=>{
            //     this.pollingUUid()
            // },5000)
        })
    }
    pollingUUid(){
        axios.get(queryStatus,{
            params: {
                uuid:this.state.uuid
            }
          }).then((res)=>{
              console.log(res.data)
            // this.setState({scanImg:res.data.data.img, uuid:res.data.data.uuid})

        })
    }
    componentWillMount() {
        clearInterval(backInterval)
    }
    render() {
        return (
        <div className="register-container">
            <div className="register-header">
            <div className="register-header-button-container">
                <div className="register-space"></div>
                <img
                src={require("../../assets/images/login/min_button.png").default}
                alt=""
                />
                <img
                src={require("../../assets/images/login/close_button.png").default}
                alt=""
                />
            </div>
            <div className="register-header-title">扫码登录</div>
            </div>
            <div style={{display:"flex", flexDirection:"row", paddingTop:"64px"}}>
                <div style={{flex:"1 1 0"}}></div>
                <div id="wxlogin">
                    <img src={this.state.scanImg}></img>
                </div>
                <div style={{flex:"1 1 0"}}></div>
            </div>
            <div>{this.state.msg}</div>
            <div style={{}}>
                <span style={{height: "22px", fontSize: "16px", fontFamily: "Regular", fontWeight: 400, lineHeight: "22px", color: "#3F59AE", marginRight:"92px"}} onClick={this.handleLogin}>账号密码登录</span>
                <span style={{height: "22px", fontSize: "16px", fontFamily: "Regular", fontWeight: 400, lineHeight: "22px", color: "#3F59AE"}} onClick={this.handleRegister}>新用户注册</span>
            </div>
        </div>
        );
    }
}

export default withRouter(ScanQRCode);
