import React, { Component } from 'react';
import './conceptModel.less';
import Model from '../../../components/controlModel/controlModel'
import LineChart from '../../../components/lineChart/lineChart'
import LineImg from '../../../components/lineImg/lineImg'
import {  getSessionLevel } from '../../../utils/token'

class Demo extends Component {
    constructor(props) {
        super(props);
        this.state = {
        
        }
    }
 
    componentDidMount() {
        // this.renderDag();     
    }
    gotoTap= async(step)=>{
    const level = getSessionLevel()

      if(step == 'back'){
       
      if (level) {
        this.props.history.push({
          pathname: "/project/parmas",
        })
      } else {
        this.props.history.push({
          pathname: "/project/exposeRoad",
        })
      }
      }
      if(step == 'next'){
       
        if (level) {

          if (level == '1') {
            this.props.history.push({
              pathname: "/project/generalReport"
            })
          } else if (level == '2') {
            this.props.history.push({
              pathname: "/project/polluteParamsTable"
            })
          }
        } else {
          this.props.history.push({
            pathname:"/project/parmas"
          })
  
        }
      }
    }
    render() {
        return (
            <div>
              <Model></Model>
              <div className="modelbackground">
                <LineImg></LineImg>
              </div>
              <div className="exposebtn">
                  <button className="formbtnleft" onClick={this.gotoTap.bind(this,'back')}>上一步</button>
                  <button className="formbtnright" onClick={this.gotoTap.bind(this,'next')}>下一步</button>
              </div>
            </div>
         
        )
    }
}
 
export default Demo;