import * as React from "react";
import { Component } from "react";
import { Tabs, Row, Col, Form, Input, Divider,Spin, message } from "antd";
import {getProjectParamsValue,saveProjectParamsValue} from '../../../apis/common'
import {checkProductd,checkDepthid} from '../../../configs/checkProductId.js'
import {  getSessionLevel } from '../../../utils/token'

import "./paramSetting.less";
import "./paramSetting.css"
const { TabPane } = Tabs;
interface leaching{
  base:{
    DF:string,
    I:string,
    Ugw:string,
    δgw:string
  }
}
let leaching={
  base:{
    DF:'',
    I:'',
    Ugw:'',
    δgw:''
  }
}
interface common{
  area:{
    LS: string
    d: string
    dsub: string
    lgw:string
  },
  exposure:{
    ABSo:string
    ACR:string
    AHQ: string
    ATca:string
    ATnc: string
    BWa: string
    BWc:string
    DAIRa: string
    DAIRc:string
    EDa: string
    EDc: string
    EFIa: string
    EFIc:string
    EFOa: string
    EFOc: string
    EFa: string
    EFc: string
    SAF: string
    SAFVOCS: string
    WAF:string
    WAFVOCS: string
  },
  soil:{
    Pws: string
    fom: string
    hcap: string
    hv: string
    θacap:string
    ρs:string
    θwcap: string
    ρb:string

  }
}
let common={
  area:{
    LS: '',
    d:  '',
    dsub:  '',
    lgw: '',
  },
  exposure:{
    ABSo: '',
    ACR: '',
    AHQ:  '',
    ATca: '',
    ATnc:  '',
    BWa:  '',
    BWc: '',
    DAIRa:  '',
    DAIRc: '',
    EDa:  '',
    EDc:  '',
    EFIa:  '',
    EFIc: '',
    EFOa:  '',
    EFOc:  '',
    EFa:  '',
    EFc:  '',
    SAF:  '',
    SAFVOCS:  '',
    WAF: '',
    WAFVOCS:  '',
  },
  soil:{
    Pws:  '',
    fom:  '',
    hcap:  '',
    hv:  '',
    θacap: '',
    ρs:'',
    θwcap:  '',
    ρb: '',

  }
}
interface model{
  'inhalationIndoor':{
    Ab: string
    ER: string
    Kv:string
    LB: string
    Lcrack:string
    Xcrack: string
    Zcrack:string
    dP:string
    η: string
    θacrack: string
    θwcrack: string
    τ:string
  },
  'inhalationOutdoor':{
    A: string
    Uair: string
    W: string
    δair: string
  },
  'particulateMatter':{
    PIAF: string
    PM10: string
    fspi:string
    fspo: string
  },
  'throughMouth':{
    OSIRa: string
    OSIRc: string
  },
  'throughSkin':{
    Ev: string,
    Ha: string
    Hc: string
    SERa: string
    SERc:string
    SSARa: string
    SSARc: string
  },
  'water':{
    GWCRa: string
    GWCRc: string
  }

}
let model={
  'inhalationIndoor':{
    Ab: '',
    ER: '',
    Kv:'',
    LB: '',
    Lcrack:'',
    Xcrack: '',
    Zcrack:'',
    dP:'',
    η: '',
    θacrack: '',
    θwcrack: '',
    τ:'',
  },
  'inhalationOutdoor':{
    A: '',
    Uair: '',
    W: '',
    δair: '',
  },
  'particulateMatter':{
    PIAF: '',
    PM10: '',
    fspi:'',
    fspo: '',
  },
  'throughMouth':{
    OSIRa: '',
    OSIRc: '',
  },
  'throughSkin':{
    Ev: '',
    Ha: '',
    Hc: '',
    SERa: '',
    SERc:'',
    SSARa: '',
    SSARc: ''
  },
  'water':{
    GWCRa: '',
    GWCRc: '',
  }

}
export interface ParamsProps {
  history:any
}

export interface ParamsState {
  formLayout: any;
  project_id:string,
  depth_id:string,
  common:common,
  leaching:leaching,
  model:model,
  formOne:any,
  formTwo:any,
  formThree:any,
  formFour:any,
  formFive:any,
  formSix:any,
  formSeven:any,
  formEight:any,
  formNine:any,
  formTen:any,
  setformdiable:boolean,
  setformFive:boolean,
  setformSix:boolean,
  setformSeven:boolean,
  setformEight:boolean,
  setformNine:boolean,
  setformTen:boolean,
  loading:boolean,
  activeClass:any
  level: any;

}

class ParamsSetting extends React.Component<ParamsProps, ParamsState> {
  // formOne = React.createRef();
  constructor(props: ParamsProps) {
    super(props);
    this.state = {
      formOne:React.createRef(),
      formTwo:React.createRef(),
      formThree:React.createRef(),
      formFour:React.createRef(),
      formFive:React.createRef(),
      formSix:React.createRef(),
      formSeven:React.createRef(),
      formEight:React.createRef(),
      formNine:React.createRef(),
      formTen:React.createRef(),
      setformdiable:true,
      setformFive:false,
      setformSix:false,
      setformSeven:false,
      setformEight:false,
      setformNine:false,
      setformTen:false,
      loading:true,
      project_id:'',
      depth_id:'',
      level: '',
      
      common:common,
      leaching:leaching,
      model:model,
      activeClass:{
        area:[],
        soil:[],
        base:[],
        inhalationIndoor:[],
        inhalationOutdoor:[],
        particulateMatter:[],
        throughMouth:[],
        throughSkin:[],
        water:[]
      },
      formLayout: {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
      },
    };
  }
  getSystem = async()=>{
    let params ={
      project_id:this.state.project_id,
      depth_id:this.state. depth_id,
    }
    try {
      let res =await getProjectParamsValue(params); 
      let {
        common,
        leaching,
        model
      }=res.data;
    
      // this.state.activeClass.area[3]
      let activeClass= this.state.activeClass
      if(common.area['lgw']){
        activeClass.area[3]=true
      }else{
        activeClass.area[3]=false
      }
      this.setState({
        activeClass,
       

      })
      //通用参数
      this.state.formOne.current.setFieldsValue(common.area);
      this.state.formTwo.current.setFieldsValue(common.soil);
      this.state.formThree.current.setFieldsValue(common.exposure);
      //土壤淋溶至地下水
      this.state.formFour.current.setFieldsValue(leaching.base)
      //模型参数
      this.state.formFive.current.setFieldsValue(model.inhalationIndoor)
      this.state.formSix.current.setFieldsValue(model.inhalationOutdoor)
      this.state.formSeven.current.setFieldsValue(model.throughMouth)
      this.state.formEight.current.setFieldsValue(model.throughSkin)
      this.state.formNine.current.setFieldsValue(model.particulateMatter)
      this.state.formTen.current.setFieldsValue(model.water)
      this.setState({
        common,
        leaching,
        model,
        loading:false
      })
    } catch (error) {
      
    }
  }
  componentWillMount(){
    let productId=checkProductd();
    if(productId){
      this.setState({
        project_id:productId,
        loading:false
      })
    }
    let Depthid=checkDepthid();
    if (Depthid) {
      this.setState({
        depth_id:Depthid,
      })
    }
  }
  // 页面初始化
  componentDidMount(){
    this.getSystem()
  }
  async gotoTap(step:string){
    const level = getSessionLevel()

    if(step == 'back'){
      if (level) {
        this.props.history.push({
          pathname: "/project/exposeRoad",
        })
      } else {
        this.props.history.push({
          pathname: "/project/conceptModel",
        })
      }
    }
    if(step == 'next'){
      let area = this.state.formOne.current.getFieldsValue();
      let soil =  this.state.formTwo.current.getFieldsValue();
      let exposure=  this.state.formThree.current.getFieldsValue();
        //土壤淋溶至地下水
      let base=  this.state.formFour.current.getFieldsValue()
        //模型参数
      let inhalationIndoor= this.state.formFive.current.getFieldsValue()
      let inhalationOutdoor= this.state.formSix.current.getFieldsValue()
      let throughMouth= this.state.formSeven.current.getFieldsValue()
      let throughSkin= this.state.formEight.current.getFieldsValue()
      let particulateMatter= this.state.formNine.current.getFieldsValue()
      let water= this.state.formTen.current.getFieldsValue()
      let data = {
        common:{
          area,
          exposure,
          soil
        },
        leaching:{
          base
        },
        model:{
          inhalationIndoor,
          inhalationOutdoor,
          particulateMatter,
          throughMouth,
          throughSkin,
          water
        }
      }
      let params ={
        project_id:this.state.project_id,
        depth_id :this.state.depth_id,
        data:JSON.stringify(data),
      }
      // let hcapValue = this.state.formTwo.current.getFieldValue()['hcap']
      // soil
      

      let activeClass = this.state.activeClass
      let areaClass = activeClass.area
      let lgwValue = area['lgw'];
      let hcapValue = soil['hcap'];
      if(!area['lgw']){
        message.info('lgw地下水埋深必填！')
        areaClass[3] = true
        activeClass['area'] =  areaClass
        this.setState({
          activeClass
        })
        return
      }else{
        if(lgwValue-hcapValue<0){
          areaClass[3] = true
          activeClass['area'] =  areaClass
          this.setState({
            activeClass
          })
          message.info('lgw水埋深度需要大于hcap毛管厚度！')
          return
        }
      }
      let res = await saveProjectParamsValue(params);
      if(res.code =='0'){
      console.log();
      
        if (level) {

          if (level == '1') {
            this.props.history.push({
              pathname: "/project/generalReport"
            })
          } else if (level == '2') {
            this.props.history.push({
              pathname: "/project/conceptModel"
            })
          }
        } else {
          this.props.history.push({
            pathname: "/project/exposedQuantityTable"
          })
  
        }
       
       
      }
      return
    }
  }
  setEdit=()=>{
    this.setState({
      setformdiable:!this.state.setformdiable
    })
  }
  retrunlast=(id:any)=>{
    let ids = parseInt(id);
    let activeClass = this.state.activeClass
    switch (ids) {
      case 1:
        this.state.formOne.current.setFieldsValue(this.state.common.area)
        activeClass.area = []
        break;
      case 2:
        this.state.formTwo.current.setFieldsValue(this.state.common.soil)
        activeClass.soil = []
        break;
      case 3:
        this.state.formThree.current.setFieldsValue(this.state.common.exposure)
        break;
      case 4:
        this.state.formFour.current.setFieldsValue(this.state.leaching.base)
        activeClass.base = []
        break;
      case 5:
        this.state.formFive.current.setFieldsValue(this.state.model.inhalationIndoor)
        activeClass.inhalationIndoor = []
        break;
      case 6:
        this.state.formSix.current.setFieldsValue(this.state.model.inhalationOutdoor)
        activeClass.inhalationOutdoor = []
        break;
      case 7:
        this.state.formSeven.current.setFieldsValue(this.state.model.throughMouth)
        activeClass.throughMouth = []
        break;
      case 8:
        this.state.formEight.current.setFieldsValue(this.state.model.throughSkin)
        activeClass.throughSkin = []
        break;
      case 9:
        this.state.formNine.current.setFieldsValue(this.state.model.particulateMatter)
        activeClass.particulateMatter = []
        break;
      case 10:
        this.state.formTen.current.setFieldsValue(this.state.model.water)
        activeClass.water = []
        break;
      default:
        break;
    }
    this.setState({
      activeClass
    })
  }
  checkIsChange = (values:any)=>{
    let {
      target:{
        value,
        id
      }
    }= values

    let areaArr = ['d','dsub','LS','lgw']
    let soilArr = ['fom','hcap','ρb','hv','Pws','θacap','ρs','θwcap']
    let baseArr = ['Ugw','δgw','I','DF']
    let inhalationIndoorArr =  ['θacrack','τ','θwcrack','dP','Lcrack','Kv','LB','Zcrack','ER','Xcrack','η','Ab']
    let inhalationOutdoorArr =  ['W','Uair','A','δair']
    let throughMouthArr =  ['OSIRa','OSIRc']
    let throughSkinArr =  ['Ha','SERc','Hc','SSARa','Ev','SSARc','SERa']
    let particulateMatterArr =  ['PM10','fspo','fspi','PIAF']
    let waterArr =  ['GWCRa','GWCRc']

    let activeClass = this.state.activeClass
    let areaClass = activeClass.area
    let soilClass = activeClass.soil
    let baseClass =  activeClass.base
    let inhalationIndoorClass =  activeClass.inhalationIndoor
    let inhalationOutdoorClass =  activeClass.inhalationOutdoor
    let throughMouthClass =  activeClass.throughMouth
    let throughSkinClass =  activeClass.throughSkin
    let particulateMatterClass =  activeClass.particulateMatter
    let waterClass =  activeClass.water

    let area = this.state.common.area
    let soil = this.state.common.soil
    let base = this.state.leaching.base

    let {
      d,
      dsub,
      LS,
      lgw
    } =area;
    let {
      fom,
      hcap,
      ρb,
      hv,
      Pws,
      θacap,
      ρs,
      θwcap
    }=soil
    let {
      Ugw,
      δgw,
      I,
      DF
    } =base;
    let {
      inhalationIndoor:{
        Ab,
        ER,
        Kv,
        LB,
        Lcrack,
        Xcrack,
        Zcrack,
        dP,
        η,
        θacrack,
        θwcrack,
        τ,    
      },
      inhalationOutdoor:{
        A,
        Uair,
        W,
        δair,
      },
      throughMouth:{
        OSIRa,
        OSIRc,
      },
      throughSkin:{
        Ev,
        Ha,
        Hc,
        SERa,
        SERc,
        SSARa,
        SSARc
      },
      particulateMatter:{
        PIAF,
        PM10,
        fspi,
        fspo,
      },
      water:{
        GWCRa,
        GWCRc,
      }
    }= this.state.model
    switch (id) {
      case areaArr[0]:
        if(value != d){
          areaClass[0] = true
        }else{
          areaClass[0] = false
        }
        break;
      case areaArr[1]:
        if(value != dsub){
          areaClass[1] = true
        }else{
          areaClass[1] = false
        }
        break;
      case areaArr[2]:
        if(value != LS){
          areaClass[2] = true
        }else{
          areaClass[2] = false
        }
        break;
      case areaArr[3]:
        if(value !=lgw&&!lgw){
          areaClass[3] = true
        }else{
          // areaClass[3] = false
        }
         let hcapValue = this.state.formTwo.current.getFieldValue()['hcap']
         if(value>=0){
          let sss = value - hcapValue
          console.log("sss", sss);

          let hvs = JSON.stringify(sss)
          console.log("hvs", hvs,);
          this.state.formTwo.current.setFieldsValue({
            hv: hvs
          })
          console.log("hv", this.state.formTwo.current.getFieldsValue({}),);

         }else{
          this.state.formOne.current.setFieldsValue({
            lgw:0
          })
         }
        break;
      case soilArr[0]:
        if(value != fom){
          soilClass[0] = true
        }else{
          soilClass[0] = false
        }
        break;
      case soilArr[1]:
        let lgwValue = this.state.formOne.current.getFieldValue()['lgw']
        if(value>=0){
          let sss = lgwValue - value
          console.log("sss", sss);

          let hvs = JSON.stringify(sss)
          console.log("hvs", hvs);

          this.state.formTwo.current.setFieldsValue({
            hv: hvs
          })
          console.log("hv", this.state.formTwo.current.getFieldsValue({}),);

        }else{
          this.state.formTwo.current.setFieldsValue({
            hcap:0
          })
        }
        if(value != hcap){
          soilClass[1] = true
        }else{
          soilClass[1] = false
        }
        break;
      case soilArr[2]:
        if(value != ρb){
          soilClass[2] = true
        }else{
          soilClass[2] = false
        }
        break;
      case soilArr[3]:
        if(value != hv){
          soilClass[3] = true
        }else{
          soilClass[3] = false
        }
        break;
      case soilArr[4]:
        if(value != Pws){
          soilClass[4] = true
        }else{
          soilClass[4] = false
        }
        break;
      case soilArr[5]:
        if(value != θacap){
          soilClass[5] = true
        }else{
          soilClass[5] = false
        }
        break;
      case soilArr[6]:
        if(value != ρs){
          soilClass[6] = true
        }else{
          soilClass[6] = false
        }
        break;
      case soilArr[7]:
        if(value != θwcap){
          soilClass[7] = true
        }else{
          soilClass[7] = false
        }
        break;
      case baseArr[0]:
        if(value != Ugw){
          baseClass[0] = true
        }else{
          baseClass[0] = false
        }
        break;
      case baseArr[1]:
        if(value != δgw){
          baseClass[1] = true
        }else{
          baseClass[1] = false
        }
        break;
      case baseArr[2]:
        if(value != I){
          baseClass[2] = true
        }else{
          baseClass[2] = false
        }
        break;
      case baseArr[3]:
        if(value != DF){
          baseClass[3] = true
        }else{
          baseClass[3] = false
        }
        break;
      case inhalationIndoorArr[0]:
        if(value != θacrack){
          inhalationIndoorClass[0] = true
        }else{
          inhalationIndoorClass[0] = false
        }
        break;
      case inhalationIndoorArr[1]:
        if(value != τ){
          inhalationIndoorClass[1] = true
        }else{
          inhalationIndoorClass[1] = false
        }
        break; 
      case inhalationIndoorArr[2]:
        if(value != θwcrack){
          inhalationIndoorClass[2] = true
        }else{
          inhalationIndoorClass[2] = false
        }
        break; 
      case inhalationIndoorArr[3]:
        if(value != dP){
          inhalationIndoorClass[3] = true
        }else{
          inhalationIndoorClass[3] = false
        }
        break; 
      case inhalationIndoorArr[4]:
        if(value != Lcrack){
          inhalationIndoorClass[4] = true
        }else{
          inhalationIndoorClass[4] = false
        }
        break; 
      case inhalationIndoorArr[5]:
        if(value != Kv){
          inhalationIndoorClass[5] = true
        }else{
          inhalationIndoorClass[5] = false
        }
        break; 
      case inhalationIndoorArr[6]:
        if(value != LB){
          inhalationIndoorClass[6] = true
        }else{
          inhalationIndoorClass[6] = false
        }
        break; 
      case inhalationIndoorArr[7]:
        if(value != Zcrack){
          inhalationIndoorClass[7] = true
        }else{
          inhalationIndoorClass[7] = false
        }
        break; 
      case inhalationIndoorArr[8]:
        if(value != ER){
          inhalationIndoorClass[8] = true
        }else{
          inhalationIndoorClass[8] = false
        }
        break; 
      case inhalationIndoorArr[9]:
        if(value != Xcrack){
          inhalationIndoorClass[9] = true
        }else{
          inhalationIndoorClass[9] = false
        }
        break; 
      case inhalationIndoorArr[10]:
        if(value != η){
          inhalationIndoorClass[10] = true
        }else{
          inhalationIndoorClass[10] = false
        }
        break; 
      case inhalationIndoorArr[11]:
        if(value != Ab){
          inhalationIndoorClass[11] = true
        }else{
          inhalationIndoorClass[11] = false
        }
        break;
      case inhalationOutdoorArr[0]:
        if(value != W){
          inhalationOutdoorClass[0] = true
        }else{
          inhalationOutdoorClass[0] = false
        }
        break;
      case inhalationOutdoorArr[1]:
        if(value != Uair){
          inhalationOutdoorClass[1] = true
        }else{
          inhalationOutdoorClass[1] = false
        }
        break;
      case inhalationOutdoorArr[2]:
        if(value != A){
          inhalationOutdoorClass[2] = true
        }else{
          inhalationOutdoorClass[2] = false
        }
        break;
      case inhalationOutdoorArr[3]:
        if(value != δair){
          inhalationOutdoorClass[3] = true
        }else{
          inhalationOutdoorClass[3] = false
        }
        break;
      case throughMouthArr[0]:
        if(value != OSIRa){
          throughMouthClass[0] = true
        }else{
          throughMouthClass[0] = false
        }
        break;
      case throughMouthArr[1]:
        if(value != OSIRc){
          throughMouthClass[1] = true
        }else{
          throughMouthClass[1] = false
        }
        break;
      case throughSkinArr[0]:
        if(value != Ha){
          throughSkinClass[0] = true
        }else{
          throughSkinClass[0] = false
        }
        break;
      case throughSkinArr[1]:
        if(value != SERc){
          throughSkinClass[1] = true
        }else{
          throughSkinClass[1] = false
        }
        break;
      case throughSkinArr[2]:
        if(value != Hc){
          throughSkinClass[2] = true
        }else{
          throughSkinClass[2] = false
        }
        break;
      case throughSkinArr[3]:
        if(value != SSARa){
          throughSkinClass[3] = true
        }else{
          throughSkinClass[3] = false
        }
        break;
      case throughSkinArr[4]:
        if(value != Ev){
          throughSkinClass[4] = true
        }else{
          throughSkinClass[4] = false
        }
        break;
      case throughSkinArr[5]:
        if(value != SSARc){
          throughSkinClass[5] = true
        }else{
          throughSkinClass[5] = false
        }
        break;
      case throughSkinArr[6]:
        if(value != SERa){
          throughSkinClass[6] = true
        }else{
          throughSkinClass[6] = false
        }
        break;
      case particulateMatterArr[0]:
        if(value != PM10){
          particulateMatterClass[0] = true
        }else{
          particulateMatterClass[0] = false
        }
        break;
      case particulateMatterArr[1]:
        if(value != fspo){
          particulateMatterClass[1] = true
        }else{
          particulateMatterClass[1] = false
        }
        break;
      case particulateMatterArr[2]:
        if(value != fspi){
          particulateMatterClass[2] = true
        }else{
          particulateMatterClass[2] = false
        }
        break;
      case particulateMatterArr[3]:
        if(value != PIAF){
          particulateMatterClass[3] = true
        }else{
          particulateMatterClass[3] = false
        }
        break;
      case waterArr[0]:
        if(value != GWCRa){
          waterClass[0] = true
        }else{
          waterClass[0] = false
        }
        break;
      case waterArr[1]:
        if(value != GWCRc){
          waterClass[1] = true
        }else{
          waterClass[1] = false
        }
        break;           
      default:
        break;
    }
    activeClass['area'] =  areaClass
    activeClass['soil'] =  soilClass
    activeClass['base'] =  baseClass
    activeClass['inhalationIndoor'] =  inhalationIndoorClass
    activeClass['inhalationOutdoor'] = inhalationOutdoorClass
    activeClass['throughMouth'] = throughMouthClass
    activeClass['throughSkin'] = throughSkinClass
    activeClass['particulateMatter'] = particulateMatterClass
    activeClass['water'] = waterClass
    this.setState({
      activeClass
    })

    // let baseArr = ['Ugw','δgw','I','DF']
  }
  render() {
    return (
      <div className="ps-content">
        <Spin spinning={this.state.loading}>
        <Tabs
          type="card"
          defaultActiveKey='1'
        >
          <TabPane tab="通用参数" key="1" forceRender={true}>
            <div className="setting">
              <div className="modeliconleft">污染区参数</div>
              <div className="modeliconright">
                <div className="edit" onClick={()=>this.retrunlast(1)}>
                  <img src={require('../../../assets/images/paramSetting/return.png').default} className="editimg"></img>
                  <span className="editext">恢复默认</span>
                </div>
              </div>
            </div>
            <Divider></Divider>
            <Form ref={this.state.formOne} layout="vertical" preserve={false} wrapperCol={{ span: 18 }}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="d表层污染土壤层厚度:(cm)" name="d" className={`${this.state.activeClass.area[0] ?'blueClass' :'' }`}>
                    <Input type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="dsub下层污染土壤层厚度:(cm)" name="dsub" className={`${this.state.activeClass.area[1] ?'blueClass' :'' }`}>
                    <Input type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="LS下层污染土壤层埋深:(cm)" name="LS" className={`${this.state.activeClass.area[2] ?'blueClass' :'' }`}>
                    <Input type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="lgw地下水埋深:(cm)" name="lgw" className={`${this.state.activeClass.area[3] ?'blueClass' :'redClass' }`} rules={[{ required: true, message: '必须输入一个参数' }]}>
                    <Input type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <div className="setting">
              <div className="modeliconleft">土壤参数</div>
              <div className="modeliconright">
                <div className="edit" onClick={()=>this.retrunlast(2)}>
                  <img src={require('../../../assets/images/paramSetting/return.png').default} className="editimg"></img>
                  <span className="editext">恢复默认</span>
                </div>
              </div>
            </div>
            <Divider></Divider>
            <Form ref={this.state.formTwo} layout="vertical" wrapperCol={{ span: 18 }}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="fom土壤有机质含量:(g/kg)" name="fom" className={`${this.state.activeClass.soil[0] ?'blueClass' :'' }`}>
                    <Input type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="hcap土壤地下水交界处毛管层厚度:(cm)"
                    name="hcap"
                    className={`${this.state.activeClass.soil[1] ?'blueClass' :'' }`}
                  >
                    <Input type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="ρb土壤容重:(kg/dm³)" name="ρb" className={`${this.state.activeClass.soil[2] ?'blueClass' :'' }`}>
                    <Input type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="hv非饱和土壤厚度:(cm)" name="hv">
                    <Input disabled type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Pws土壤含水率:(kg/kg)" name="Pws" className={`${this.state.activeClass.soil[4] ?'blueClass' :'' }`}>
                    <Input type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="θacap毛细管层孔隙空气体积比:" name="θacap" className={`${this.state.activeClass.soil[5] ?'blueClass' :'' }`}>
                    <Input type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="ρs土壤颗粒密度:(kg/dm³)" name="ρs" className={`${this.state.activeClass.soil[6] ?'blueClass' :'' }`}>
                    <Input type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="θwcap毛细管层孔隙水体积比:" name="θwcap" className={`${this.state.activeClass.soil[7] ?'blueClass' :'' }`}>
                    <Input type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <div className="setting">
              <div className="modeliconleft">暴露参数</div>
              <div className="modeliconright">
                <div className="edit" onClick={()=>this.setEdit()}>
                  <img src={require('../../../assets/images/paramSetting/edit.png').default} className="editimg"></img>
                  <span className="editext">{this.state.setformdiable?'编辑':'保存'}</span>
                </div>
                <div className="edit" onClick={()=>this.retrunlast(3)}>
                  <img src={require('../../../assets/images/paramSetting/return.png').default} className="editimg"></img>
                  <span className="editext">恢复默认</span>
                </div>
              </div>
            </div>
            <Divider></Divider>
            <Form ref={this.state.formThree} layout="vertical" wrapperCol={{ span: 18 }}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="EDa成人暴露期:(a)" name="EDa">
                    <Input disabled={this.state.setformdiable} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="DAIRc儿童每日空气呼吸量:(m³/d)"
                    name="DAIRc"
                  >
                    <Input disabled={this.state.setformdiable} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="EDc儿童暴露期:(a)" name="EDc">
                    <Input disabled={this.state.setformdiable} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    label="SAF暴露于土壤的参考剂量分配比例(SVOCs和重金属):"
                    name="SAF"
                  >
                    <Input disabled={this.state.setformdiable} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="EFa成人暴露频率:(d/a)" name="EFa">
                    <Input disabled={this.state.setformdiable} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="WAF暴露于地下水的参考剂量分配比例(SVOCs和重金属):"
                    name="WAF"
                  >
                    <Input disabled={this.state.setformdiable} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="EFc儿童暴露频率:(d/a)" name="EFc">
                    <Input disabled={this.state.setformdiable} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="SAF暴露于土壤的参考剂量分配比例(VOCs):"
                    name="SAFVOCS"
                  >
                    <Input disabled={this.state.setformdiable} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="EFIa成人室内暴露频率:(d/a)" name="EFIa">
                    <Input disabled={this.state.setformdiable} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    label="WAF暴露于地下水的参考剂量分配比例(VOCS):"
                    name="WAFVOCS"
                  >
                    <Input disabled={this.state.setformdiable} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="EFIc儿童室内暴露频率:(d/a)" name="EFIc">
                    <Input disabled={this.state.setformdiable} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="ABSo经口摄入吸收因子" name="ABSo">
                    <Input disabled={this.state.setformdiable} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="EFOa成人室外暴露频率:(d/a)" name="EFOa">
                    <Input disabled={this.state.setformdiable} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="ACR单一污染物可接受致癌风险:" name="ACR">
                    <Input disabled={this.state.setformdiable} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="EFOc儿童室外暴露频率:(d/a)" name="EFOc">
                    <Input disabled={this.state.setformdiable} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="AHQ单一污染物可接受危害熵:" name="AHQ">
                    <Input disabled={this.state.setformdiable} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="BWa成人平均体重:(kg)" name="BWa">
                    <Input disabled={this.state.setformdiable} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="ATca致癌效应平均时间:(d)" name="ATca">
                    <Input disabled={this.state.setformdiable} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="BWc儿童平均体重:(kg)" name="BWc">
                    <Input disabled={this.state.setformdiable} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="ATnc非致癌效应平均时间:(d)" name="ATnc">
                    <Input disabled={this.state.setformdiable} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="DAIRa成人每日空气呼吸量:(m³/d)"
                    name="DAIRa"
                  >
                    <Input disabled={this.state.setformdiable} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </TabPane>
          <TabPane tab="土壤淋溶至地下水" key="2"  forceRender={true}>
          <div className="setting">
              <div></div>
              <div className="modeliconright">
                <div className="edit" onClick={()=>this.retrunlast(4)}>
                  <img src={require('../../../assets/images/paramSetting/return.png').default} className="editimg"></img>
                  <span className="editext">恢复默认</span>
                </div>
              </div>
            </div>
            <Divider></Divider>
            <Form ref={this.state.formFour} layout="vertical" wrapperCol={{ span: 18 }}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="Ugw地下水(Darcy)速率:(cm/a)" name="Ugw" className={`${this.state.activeClass.base[0] ?'blueClass' :'' }`}>
                    <Input type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="地下水混合区厚度:(cm)"
                    name="δgw"
                    className={`${this.state.activeClass.base[1] ?'blueClass' :'' }`}
                  >
                    <Input  type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="土壤中水的入渗速率:(cm/a)" name="I" className={`${this.state.activeClass.base[2] ?'blueClass' :'' }`}>
                    <Input type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="地下水迁移稀释因子" name="DF" className={`${this.state.activeClass.base[3] ?'blueClass' :'' }`}>
                    <Input type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </TabPane>
          <TabPane tab="模型参数" key="3"  forceRender={true}>
            <div className="setting">
              <div className="modeliconleft">吸入室内蒸汽HJ25.3模型参数</div>
              {
                !this.state.setformFive?(
                  <div className="modeliconright">
                    <div className="edit" onClick={()=>this.retrunlast(5)}>
                      <img src={require('../../../assets/images/paramSetting/return.png').default} className="editimg"></img>
                      <span className="editext">恢复默认</span>
                    </div>
                  </div>
                ):''
              }
            </div>
            <Divider></Divider>
            <Form ref={this.state.formFive} layout="vertical" wrapperCol={{ span: 18 }}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    label="θacrack地基裂隙中空气体积比:"
                    name="θacrack"
                    className={`${this.state.activeClass.inhalationIndoor[0] ?'blueClass' :'' }`}
                  >
                    <Input disabled={this.state.setformFive} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="τ气态污染物入侵持续时间:(a)" name="τ"
                    className={`${this.state.activeClass.inhalationIndoor[1] ?'blueClass' :'' }`}>
                    <Input disabled={this.state.setformFive} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="θwcrack地基裂隙中水体积比:(cm)"
                    name="θwcrack"
                    className={`${this.state.activeClass.inhalationIndoor[2] ?'blueClass' :'' }`}
                  >
                    <Input disabled={this.state.setformFive} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="dP室内室外气压差:(g/cm.s-2)" name="dP"
                    className={`${this.state.activeClass.inhalationIndoor[3] ?'blueClass' :'' }`}>
                    <Input disabled={this.state.setformFive} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Lcrack室内地基厚度:(cm)" name="Lcrack"
                    className={`${this.state.activeClass.inhalationIndoor[4] ?'blueClass' :'' }`}>
                    <Input disabled={this.state.setformFive} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Kv土壤透性系数:(cm²)" name="Kv"
                    className={`${this.state.activeClass.inhalationIndoor[5] ?'blueClass' :'' }`}>
                    <Input disabled={this.state.setformFive} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    label="LB室内空间体积与气态污染物入渗面积之比:(cm)"
                    name="LB"
                    className={`${this.state.activeClass.inhalationIndoor[6] ?'blueClass' :'' }`}
                  >
                    <Input disabled={this.state.setformFive} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Zcrack室内地面到地板底部厚度:(cm)"
                    name="Zcrack"
                    className={`${this.state.activeClass.inhalationIndoor[7] ?'blueClass' :'' }`}
                  >
                    <Input disabled={this.state.setformFive} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="ER室内空气交换速率:(次/d)" name="ER"
                  className={`${this.state.activeClass.inhalationIndoor[8] ?'blueClass' :'' }`}>
                    <Input disabled={this.state.setformFive} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="Xcrack室内地板周长:(cm)" name="Xcrack"
                  className={`${this.state.activeClass.inhalationIndoor[9] ?'blueClass' :'' }`}>
                    <Input disabled={this.state.setformFive} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="η地基和墙体裂隙表面积所占面积:" name="η"
                  className={`${this.state.activeClass.inhalationIndoor[10] ?'blueClass' :'' }`}>
                    <Input disabled={this.state.setformFive} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Ab室内地板面积:(cm²)" name="Ab"
                  className={`${this.state.activeClass.inhalationIndoor[11] ?'blueClass' :'' }`}>
                    <Input disabled={this.state.setformFive} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <div className="setting">
              <div className="modeliconleft">吸入室外蒸汽HJ25.3模型参数</div>
              {
                !this.state.setformSix?(
                  <div className="modeliconright">
                    <div className="edit" onClick={()=>this.retrunlast(6)}>
                      <img src={require('../../../assets/images/paramSetting/return.png').default} className="editimg"></img>
                      <span className="editext">恢复默认</span>
                    </div>
                  </div>
                ):''
              }
            </div>
            <Divider></Divider>
            <Form ref={this.state.formSix} layout="vertical" wrapperCol={{ span: 18 }}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item 
                    className={`${this.state.activeClass.inhalationOutdoor[0] ?'blueClass' :'' }`}
                    label="W污染源区宽度:(cm)" name="W">
                    <Input disabled={this.state.setformSix} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item 
                    className={`${this.state.activeClass.inhalationOutdoor[1] ?'blueClass' :'' }`}
                    label="Uair混合区大气流速风速:(cm/s)" name="Uair">
                    <Input disabled={this.state.setformSix} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item 
                    className={`${this.state.activeClass.inhalationOutdoor[2] ?'blueClass' :'' }`}
                    label="A污染源区面积:(cm²)" name="A">
                    <Input disabled={this.state.setformSix} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item 
                    className={`${this.state.activeClass.inhalationOutdoor[3] ?'blueClass' :'' }`}
                    label="δair混合区高度:(cm)" name="δair">
                    <Input disabled={this.state.setformSix} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <div className="setting">
              <div className="modeliconleft">土壤经口摄入HJ25.3模型参数</div>
              {
                !this.state.setformSeven?(
                  <div className="modeliconright">
                    <div className="edit" onClick={()=>this.retrunlast(7)}>
                      <img src={require('../../../assets/images/paramSetting/return.png').default} className="editimg"></img>
                      <span className="editext">恢复默认</span>
                    </div>
                  </div>
                ):''
              }
            </div>
            <Divider></Divider>
            <Form ref={this.state.formSeven} layout="vertical" wrapperCol={{ span: 18 }}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    label="OSIRa成人每日摄入土壤量:(mg/d)"
                    name="OSIRa"
                    className={`${this.state.activeClass.throughMouth[0] ?'blueClass' :'' }`}
                  >
                    <Input disabled={this.state.setformSeven} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="OSIRc儿童每日摄入土壤量:(mg/d)"
                    name="OSIRc"
                    className={`${this.state.activeClass.throughMouth[1] ?'blueClass' :'' }`}
                  >
                    <Input disabled={this.state.setformSeven} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <div className="setting">
              <div className="modeliconleft">土壤皮肤摄入HJ25.3模型参数</div>
              {
                !this.state.setformEight?(
                  <div className="modeliconright">
                    <div className="edit" onClick={()=>this.retrunlast(8)}>
                      <img src={require('../../../assets/images/paramSetting/return.png').default} className="editimg"></img>
                      <span className="editext">恢复默认</span>
                    </div>
                  </div>
                ):''
              }
            </div>
            <Divider></Divider>
            <Form ref={this.state.formEight} layout="vertical" wrapperCol={{ span: 18 }}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item 
                    className={`${this.state.activeClass.throughSkin[0] ?'blueClass' :'' }`}
                    label="Ha成人平均身高:(cm)" name="Ha">
                    <Input disabled={this.state.setformEight} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="SERc儿童暴露皮肤所占体表面积比:"
                    name="SERc"
                    className={`${this.state.activeClass.throughSkin[1] ?'blueClass' :'' }`}
                  >
                    <Input disabled={this.state.setformEight} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item 
                    className={`${this.state.activeClass.throughSkin[2] ?'blueClass' :'' }`}
                    label="Hc儿童平均身高:(cm)" name="Hc">
                    <Input disabled={this.state.setformEight} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    label="SSARa成人皮肤表面土壤粘附系数:(mg/cm²)"
                    name="SSARa"
                    className={`${this.state.activeClass.throughSkin[3] ?'blueClass' :'' }`}
                  >
                    <Input disabled={this.state.setformEight} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className={`${this.state.activeClass.throughSkin[4] ?'blueClass' :'' }`} 
                    label="Ev每日皮肤接触事件频率:(次/d)"
                    name="Ev">
                    <Input disabled={this.state.setformEight} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="SSARc儿童皮肤表面土壤粘附系数:(mg/cm²)"
                    name="SSARc"
                    className={`${this.state.activeClass.throughSkin[5] ?'blueClass' :'' }`}
                  >
                    <Input disabled={this.state.setformEight} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    label="SERa成人暴露皮肤所占体表面积比:"
                    name="SERa"
                    className={`${this.state.activeClass.throughSkin[6] ?'blueClass' :'' }`}
                  >
                    <Input disabled={this.state.setformEight} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <div className="setting">
              <div className="modeliconleft">土壤吸入颗粒物HJ25.3模型参数</div>
              {
                !this.state.setformNine?(
                  <div className="modeliconright">
                    <div className="edit" onClick={()=>this.retrunlast(9)}>
                      <img src={require('../../../assets/images/paramSetting/return.png').default} className="editimg"></img>
                      <span className="editext">恢复默认</span>
                    </div>
                  </div>
                ):''
              }
            </div>
            <Divider></Divider>
            <Form ref={this.state.formNine} layout="vertical" wrapperCol={{ span: 18 }}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    label="PM10空气中可吸入颗粒物含量:(mg/m³)"
                    name="PM10"
                    className={`${this.state.activeClass.particulateMatter[0] ?'blueClass' :'' }`}
                  >
                    <Input disabled={this.state.setformNine} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="fspo室外空气中来自土壤的颗粒物比例:"
                    name="fspo"
                    className={`${this.state.activeClass.particulateMatter[1] ?'blueClass' :'' }`}
                  >
                    <Input disabled={this.state.setformNine} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="fspi室内空气中来自土壤的颗粒物所占比例:"
                    name="fspi"
                    className={`${this.state.activeClass.particulateMatter[2] ?'blueClass' :'' }`}
                  >
                    <Input disabled={this.state.setformNine} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    label="PIAF吸入土壤颗粒物在体内滞留比例:"
                    name="PIAF"
                    className={`${this.state.activeClass.particulateMatter[3] ?'blueClass' :'' }`}
                  >
                    <Input disabled={this.state.setformNine} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <div className="setting">
              <div className="modeliconleft">地下水饮用HJ25.3模型参数</div>
              {
                !this.state.setformTen?(
                  <div className="modeliconright">
                    <div className="edit" onClick={()=>this.retrunlast(10)}>
                      <img src={require('../../../assets/images/paramSetting/return.png').default} className="editimg"></img>
                      <span className="editext">恢复默认</span>
                    </div>
                  </div>
                ):''
              }
            </div>
            <Divider></Divider>
            <Form ref={this.state.formTen} layout="vertical" wrapperCol={{ span: 18 }}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item 
                    className={`${this.state.activeClass.water[0] ?'blueClass' :'' }`}
                    label="GWCRa成人每日饮用水量:(L/d)" name="GWCRa">
                    <Input disabled={this.state.setformTen} type="number" onChange={this.checkIsChange}></Input>
                    
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item 
                    className={`${this.state.activeClass.water[1] ?'blueClass' :'' }`}
                    label="GWCRc儿童每日饮用水量:(L/d)" name="GWCRc">
                    <Input disabled={this.state.setformTen} type="number" onChange={this.checkIsChange}></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </TabPane>
        </Tabs>
        <div className="exposebtn">
          <button className="formbtnleft" onClick={this.gotoTap.bind(this,'back')}>上一步</button>
          <button className="formbtnright" onClick={this.gotoTap.bind(this,'next')}>下一步</button>
        </div>
        </Spin>
      </div>
    );
  }
}

export default ParamsSetting;
