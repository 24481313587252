import React, {useEffect,Component,Suspense} from 'react';
import { HashRouter as Router, Switch, Route, Redirect} from 'react-router-dom'
import { systemRouteList } from './router/utils';
import { IRoute } from './router/config';
import { Provider } from 'react-redux';
import { store } from './reducers';
import { Spin } from 'antd';

class App extends Component {
  render(){
    return(
      <Suspense fallback={<Spin size="large" className="layout__loading" />}>
        <Router>
          <Switch>
              {
                systemRouteList.map((item) => {
                  return <Route exact={item.exact} key={item.path} path={item.path} component={item.component}></Route>
                })  
              }
              <Redirect from="/" to="/login" exact></Redirect>
          </Switch>
        </Router>
      </Suspense>
    )
  }
}

export default App;

