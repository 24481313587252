
import {setSessionProductId,getSessionProductId,removeSessionProductId,getDepthid,setDepthid} from '../utils/token'
import config from '../configs/index';
export const saveProductId = (productId) => {
    setSessionProductId(productId)  

}
export const saveDepthId = (Depth_id) => {
    setDepthid(Depth_id)  
   

}


export const checkProductd = () => {
     let productId = getSessionProductId();
     console.log("productId1111",productId);
     if(productId){
         return productId
     }else{
        window.location.href = `${
            window.location.origin
          }${config.BASENAME}/project/info`;
        return false
     }
}


export const checkDepthid = () => {
    let Depthid = getDepthid();
    if(Depthid){
        return Depthid
    }else{
       window.location.href = `${
           window.location.origin
         }${config.BASENAME}/project/info`;
       return false
    }
}

