import * as React from "react";
import { Layout, Menu, Tree, Spin } from "antd";
import { withRouter, Switch } from "react-router-dom";
import BreadCrumb from "../components/common/breadCrumb"
import HeaderTop from '../components/headerTop/headerTop'
import "../assets/styles/homepage.less";
import MainRoutes from '../layout/MainRoutes';
import BasicInfoForm from '../components/basicInfoForm';
import { getSessionLevel, getSessionNumberPlies, setSessionNumberPlies, removeDepthid, setSelectedKey, getSelectedKey, setTreeData, getTreeData } from '../utils/token'

import eventBus from '../configs/events';
import { getProjectInfo, } from "../apis/common"

import { showWindow } from '../configs/electronHandle'
import { checkProductd, saveDepthId, checkDepthid } from '../configs/checkProductId.js'
import { number } from "echarts";
import { strict } from "assert";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { DirectoryTree } = Tree;



interface HomePageProps {
  location: any,
  history: any
}

interface HomePageState {
  reset: boolean,
  selectedKeys: any,
  expandedKeys: any,
  expandedKey: any,
  autoExpandParent: boolean,
  treeData: any,
  defaultExpandAll: boolean,
  level: string
  project_id: string
  depthRowArray: any,
  OneTreeDataList: any,
  sensafList: any,
}


const fileMenu = (
  <Menu>
    <Menu.Item>
      <img src={require("../assets/images/homePage/addFile.png").default}></img>
      新建
    </Menu.Item>
    <Menu.Item>
      <img src={require("../assets/images/homePage/openFile.png").default}></img>
      打开
    </Menu.Item>
    <Menu.Item>
      <img src={require("../assets/images/homePage/saveFile.png").default}></img>
      保存
    </Menu.Item>
    <Menu.Item>
      <img src={require("../assets/images/homePage/saveFileAs.png").default}></img>
      另存为
    </Menu.Item>
    <Menu.Item>
      <img src={require("../assets/images/homePage/exportFile.png").default}></img>
      导入
    </Menu.Item>
  </Menu>
)

const dataBaseMenu = (
  <Menu>
    <Menu.Item>
      土壤筛选值数据库查询
    </Menu.Item>
    <Menu.Item>
      地下水筛选值数据库查询
    </Menu.Item>
    <Menu.Item>
      标准污染物数据库查询
    </Menu.Item>
    <Menu.Item>
      自定义污染物数据库查询
    </Menu.Item>
    <Menu.Item>
      更新数据库
    </Menu.Item>
  </Menu>
)
const urlpatterns = [
  {
    url: '/project/info',
    keys: '0',
    breadtitle: '项目信息',
  },
  {
    url: '/project/analysis',
    keys: '1',
    breadtitle: '层次分析',
  },
  {
    url: '/project/followPollute',
    keys: '2-1',
    breadtitle: '项目输入/关注污染物',
  },
  {
    url: '/project/exposeRoad',
    keys: '2-2',
    breadtitle: '项目输入/暴露途径及模型',
  },
  {
    url: '/project/parmas',
    keys: '2-3',
    breadtitle: '项目输入/参数设置',
  },
  {
    url: '/project/conceptModel',
    keys: '3-1',
    breadtitle: '项目输出/概念模型',
  },
  {
    url: '/project/polluteParamsTable',
    keys: '3-2',
    breadtitle: '项目输出/污染物参数表',
  },
  {
    url: '/project/modelParamsTable',
    keys: '3-3',
    breadtitle: '项目输出/模型及参数表',
  },
  {
    url: '/project/exposedQuantityTable',
    keys: '3-4',
    breadtitle: '项目输出/暴露量计算',
  },
  {
    url: '/project/riskCalculation',
    keys: '3-5',
    breadtitle: '项目输出/风险计算',
  },
  {
    url: '/project/ContributionRateCalculation',
    keys: '3-6',
    breadtitle: '项目输出/贡献率计算',
  },
  {
    url: '/project/controlValueCalculation',
    keys: '3-7',
    breadtitle: '项目输出/控制值计算',
  },
  {
    url: '/project/getlnitParamsValue',
    keys: '3-8',
    breadtitle: '项目输出/敏感性分析',
  },
  {
    url: '/project/sensitivity',
    keys: '3-9',
    breadtitle: '项目输出/敏感性分析结果',
  },
  {
    url: '/project/generalReport',
    keys: '3-10',
    breadtitle: '项目输出/总报告',
  }


]
const morenTreeData = [
  {
    title: '项目信息',
    key: '0',
  },
  {
    title: '分析层次',
    key: '1',
  },
  {
    title: '项目输入',
    key: '2',
  },
  {
    title: '项目输出',
    key: '3'

  },
];
const firstTreeData = [
  {
    title: '项目信息',
    key: '0',
  },
  {
    title: '分析层次',
    key: '1',
  },
  {
    title: '项目输入',
    key: '2',
    children: [
      { title: '关注污染物', key: '2-1' }
    ],
  },
  {
    title: '项目输出',
    key: '3',
    children: [
      { title: '总报告', key: '3-6' },
    ],

  },
];
const twoTreeData = [
  {
    title: '项目信息',
    key: '0',
  },
  {
    title: '分析层次',
    key: '1',
  },
  {
    title: '项目输入',
    key: '2',
    children: [
      { title: '关注污染物', key: '2-1' },
      { title: '暴露途径及模型', key: '2-2' },
      { title: '参数设置', key: '2-3' },
    ],
  },
  {
    title: '项目输出',
    key: '3',
    children: [
      { title: '概念模型', key: '3-1' },
      { title: '污染物参数表', key: '3-2' },
      { title: '模型及参数表', key: '3-3' },
      { title: '暴露量计算', key: '3-4' },
      { title: '风险计算', key: '3-5' },
      { title: '贡献率计算', key: '3-6' },
      { title: '控制值计算', key: '3-7' },
      { title: '敏感性分析', key: '3-8', },
      { title: '敏感性分析结果', key: '3-9'},
      { title: '总报告', key: '3-10' },
    ],

  },
];
const OneTreeData = [
  {
    url: '/project/info',
    keys: '0',
    breadtitle: '项目信息',
  },
  {
    url: '/project/analysis',
    keys: '1',
    breadtitle: '层次分析',
  },
  {
    url: '/project/followPollute',
    keys: '2-1',
    breadtitle: '项目输入/关注污染物',

  },
  {
    url: '/project/exposeRoad',
    keys: '2-2',
    breadtitle: '项目输入/暴露途径及模型',
  },
  {
    url: '/project/parmas',
    keys: '2-3',
    breadtitle: '项目输入/参数设置',
  },
  {
    url: '/project/conceptModel',
    keys: '3-1',
    breadtitle: '项目输出/概念模型',
  },
  {
    url: '/project/polluteParamsTable',
    keys: '3-2',
    breadtitle: '项目输出/污染物参数表',
  },
  {
    url: '/project/modelParamsTable',
    keys: '3-3',
    breadtitle: '项目输出/模型及参数表',
  },
  {
    url: '/project/exposedQuantityTable',
    keys: '3-4',
    breadtitle: '项目输出/暴露量计算',
  },
  {
    url: '/project/riskCalculation',
    keys: '3-5',
    breadtitle: '项目输出/风险计算',
  },
  {
    url: '/project/ContributionRateCalculation',
    keys: '3-6',
    breadtitle: '项目输出/贡献率计算',
  },
  {
    url: '/project/controlValueCalculation',
    keys: '3-7',
    breadtitle: '项目输出/控制值计算',
  },
  {
    url: '/project/generalReport',
    keys: '3-8',
    breadtitle: '项目输出/总报告',
  }


]

class HomePage extends React.PureComponent<HomePageProps, HomePageState> {
  constructor(props: HomePageProps) {
    super(props);
    this.state = {
      reset: false,
      selectedKeys: [],
      expandedKeys: [],
      expandedKey: [],
      autoExpandParent: true,
      treeData: null,
      defaultExpandAll: false,
      level: '',
      project_id: '',
      depthRowArray: [],
      OneTreeDataList: [],
      sensafList: []

    };
    this.props.history.listen((route: any) => {
      this.handleSelectTab(route, ' 路由监听')
    });
  }
  componentWillMount() {
    let route = this.props.history.location;
    console.log("this.props", this.props.history, route.pathname);
    let NumberPlies = getSessionNumberPlies();

    let productId = checkProductd();
    if (productId) {
      this.setState({
        project_id: productId,
      })
    }
    console.log("productId", productId, route);

    this.handleSelectTab(route, ' 传参')

    eventBus.on('changeTabSelect', this.handleSayHelloListener);
    this.getStatusLevel(productId, route, NumberPlies)
  }
  componentDidMount() {

    // maximizeWindow()
    showWindow()
  }
  handleSelectTab = (route: any, itm: any) => {

    let NumberPlies = getSessionNumberPlies();
    let SelectedKey = getSelectedKey();
    let Level = getSessionLevel();
    let expandedobject = new Array()
    expandedobject.push(NumberPlies)
    console.log("route", route, itm, NumberPlies, this.state.sensafList);
    if (route.val) {
      let sss = route.val + `${-1}`
      console.log("不空", route, route.Depth);

      this.setState({
        selectedKeys: [sss],
        expandedKeys: [sss],

        defaultExpandAll: true
      })
      setSessionNumberPlies(route.val)
      saveDepthId(route.Depth)

    } else {
      if (Level == undefined || Level == null || Level) {
        console.log("NumberPlies11111111111111111112333333不分层", NumberPlies, Level, Level == undefined, Level == null);
        urlpatterns.map((value, key) => {
          if (value.url == route.pathname) {
            console.log("urlpatterns", urlpatterns, value, key, urlpatterns[key], value.keys);
            this.setState({
              selectedKeys: [value.keys],
              expandedKeys: [value.keys],

              defaultExpandAll: true
            })
          }
        })
      } else {
        console.log("NumberPlies11111111111111111112333333分层", NumberPlies,);
        let NumberKeys = NumberPlies + `${-1}`
        console.log(" this.state.OneTreeDataList[NumberPlies]", this.state.OneTreeDataList[NumberPlies], SelectedKey);
        if (this.state.OneTreeDataList[NumberPlies] == undefined || this.state.OneTreeDataList[NumberPlies] == null) {
          this.setState({
            expandedKeys: [SelectedKey],
            selectedKeys: [SelectedKey],
            defaultExpandAll: true,

          })
        }
        for (const key in this.state.OneTreeDataList[NumberPlies]) {
          console.log("forIN", key, this.state.OneTreeDataList[NumberPlies][key]);
          if (key == 'children') {
            console.log("this.state.OneTreeDataList[1][key]", this.state.OneTreeDataList[NumberPlies][key]);
            this.state.OneTreeDataList[NumberPlies][key].map((value: any, index: any) => {
              console.log("this.state.OneTreeDataList[1][key]", value, index);


              if (value.url == route.pathname) {
                console.log("value.url", value.url, route.pathname, value);
                let expandedobject = new Array()
                expandedobject.push(value.key)
                console.log("expandedobject", expandedobject, value.key);
                setSelectedKey(value.key)
                this.setState({
                  selectedKeys: [value.key],

                  defaultExpandAll: true,
                  expandedKeys: expandedobject,
                  expandedKey: expandedobject
                })
              }
            })
          }
        }

      }

    }







  }
  getStatusLevel = async (productId: any, route: any, NumberPlies: any) => {

    let Level = getSessionLevel();

    let oneArray = new Array()
    let oneArrayList = new Array()

    let childrenArray = new Object()
    let newobject = new Object()

    if (productId) {
      console.log("productId", productId, route, NumberPlies);
      if (Level == '1' || Level == "2") {
        console.log("是", Level);
        // removeDepthid()

        if (Level == '1' || Level == '2') {
          console.log(
            "我选择了分层并且lever是1火葬2", Level, Level == '1', Level == '2'
          );
          if (Level == '1') {
            this.setState({
              treeData: firstTreeData,
            })
          } else if (Level == '2') {
            this.setState({
              treeData: twoTreeData,
            })
          } else {
            this.setState({
              treeData: morenTreeData,
            })
          }
        }

      } else {
        console.log("bu是", Level);
        await getProjectInfo({
          project_id: productId
        }).then((res: any) => {
          console.log("res", res);
          let depthRowArrays = new Array()
          depthRowArrays = res.data[0].depthRow

          console.log("res777777777777777777777777", depthRowArrays);

          const sssTreeData = [
            {
              title: '项目信息',
              key: '0',
              url: '/project/info',
            },




          ];
          childrenArray = {
            title: '总报告',
            key: '100',
            url: '/project/generalReport',
          }
          const toChineseNumber = (n: any) => {
            if (!Number.isInteger(n) && n < 0) {
              throw Error('请输入自然数');
            }
            const digits = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
            const positions = ['', '十', '百', '千', '万', '十万', '百万', '千万', '亿', '十亿', '百亿', '千亿'];
            const charArray = String(n).split('');
            let result = '';
            let prevIsZero = false;
            //处理0  deal zero
            for (let i = 0; i < charArray.length; i++) {
              const ch = charArray[i];
              if (ch !== '0' && !prevIsZero) {
                result += digits[parseInt(ch)] + positions[charArray.length - i - 1];
              } else if (ch === '0') {
                prevIsZero = true;
              } else if (ch !== '0' && prevIsZero) {
                result += '零' + digits[parseInt(ch)] + positions[charArray.length - i - 1];
              }
            }
            //处理十 deal ten
            if (n < 100) {
              result = result.replace('一十', '十');
            }
            return result;
          }
          depthRowArrays.forEach((items: any, indexs: any) => {
            console.log("items", items, indexs, items.depth_id);
            const title = `第 ${toChineseNumber(indexs + 1)} 层`
            Object.assign(newobject, {
              title: title,
              key: indexs + 1,
              children: [
                { title: '关注污染物', key: `${indexs + 1}-1`, depth_id: `${items.depth_id}`, url: '/project/followPollute', },
                { title: '暴露途径及模型', key: `${indexs + 1}-2`, depth_id: `${items.depth_id}`, url: '/project/exposeRoad', },
                { title: '概念模型', key: `${indexs + 1}-3`, depth_id: `${items.depth_id}`, url: '/project/conceptModel', },
                { title: '参数设置', key: `${indexs + 1}-4`, depth_id: `${items.depth_id}`, url: '/project/parmas', },
                { title: '暴露量计算', key: `${indexs + 1}-5`, depth_id: `${items.depth_id}`, url: '/project/exposedQuantityTable', },
                { title: '风险计算', key: `${indexs + 1}-6`, depth_id: `${items.depth_id}`, url: '/project/riskCalculation', },
                { title: '贡献率计算', key: `${indexs + 1}-7`, depth_id: `${items.depth_id}`, url: '/project/ContributionRateCalculation', },
                { title: '控制值计算', key: `${indexs + 1}-8`, depth_id: `${items.depth_id}`, url: '/project/controlValueCalculation', },
                { title: '敏感性分析', key: `${indexs + 1}-9`, depth_id: `${items.depth_id}`, url: '/project/getlnitParamsValue', },
                { title: '敏感性分析结果', key: `${indexs + 1}-10`, depth_id: `${items.depth_id}`, url: '/project/sensitivity', },

              ],
            },

            )
            oneArray.push(newobject)
            newobject = {}


          })

          oneArray.push(childrenArray)

          oneArrayList = sssTreeData.concat(oneArray)

          let stringJSON = JSON.stringify(oneArrayList)
          setTreeData(stringJSON)

          console.log("getTreeData", getTreeData(), JSON.parse(stringJSON), JSON.parse(getTreeData()));

          this.setState({
            depthRowArray: depthRowArrays,
            OneTreeDataList: JSON.parse(getTreeData()),
            sensafList: oneArrayList,
            // selectedKeys: ['1-1']

          })

          let NumberPlies = getSessionNumberPlies();

          console.log("oneArra11111111y", oneArrayList, route, NumberPlies);
          oneArrayList.forEach((items: any, indexs: any) => {
            if (indexs == NumberPlies) {
              console.log("items", items, indexs, items);
              for (const key in items) {
                if (key == 'children') {
                  console.log("items", items, key);
                  items[key].map((val: any, ind: any) => {
                    // console.log("route",route.pathname,val);

                    if (val.url == route.pathname) {
                      console.log("val", val.url, route.pathname, val.key);
                      this.setState({
                        selectedKeys: [val.key],
                        defaultExpandAll: true,
                        expandedKeys: [val.key],


                      })

                    }

                  })



                }
              }



            }
          })




          if (NumberPlies || NumberPlies == undefined || NumberPlies == null || NumberPlies == '<empty string>' || checkDepthid() == null) {

            if (Level == '1' || Level == '2') {

              if (Level == '1') {
                this.setState({
                  treeData: firstTreeData,
                  expandedKeys: ''

                })
              } else if (Level == '2') {
                this.setState({
                  treeData: twoTreeData,

                })
              } else {
                this.setState({
                  treeData: morenTreeData,

                })
              }

            } else {
              this.setState({
                treeData: JSON.parse(getTreeData()),

              })
            }

          } else {
            console.log("执行的是不分层的", NumberPlies, Level, "他现在是1的状态");
            if (Level == '1') {
              this.setState({
                treeData: firstTreeData,
              })
            } else if (Level == '2') {
              this.setState({
                treeData: twoTreeData,
              })
            } else {
              console.log("productId", productId, route, NumberPlies);

              this.setState({
                treeData: morenTreeData,
              })
            }

          }

        }).catch((err: any) => {
          console.log(err)

        })

      }

    } else {
      this.setState({
        treeData: morenTreeData,
      })
    }

  }
  handleSayHelloListener = (level: any) => {
    console.log("level", level)
    let {
      clicktwoActive,
      clickoneActive
    } = level
    if (clickoneActive && !clicktwoActive) {
      this.setState({
        treeData: firstTreeData,
        defaultExpandAll: true,
        level
      })
    }
    if (clicktwoActive && !clickoneActive) {
      this.setState({
        treeData: twoTreeData,
        defaultExpandAll: false,
        level
      })
    }
    if (!clickoneActive && !clicktwoActive) {
      this.setState({
        treeData: morenTreeData,
        defaultExpandAll: false
      })
    }
  }
  handelScreen = () => {
    this.setState({ reset: !this.state.reset })
  }

  render() {
    const onOpenChange = (keys: any) => {
      console.log("keys", keys, this.state.expandedKeys,);
      let expandedobject = new Array()
      let depthIdobject = new Array()
      let selectedKeysobject = new Array()
      expandedobject.push(keys[0])
      let str = keys[0] + ''
      let str1 = str.slice(0, 1)
      console.log("str1", str1, this.state.OneTreeDataList[str1]);
      for (const key in this.state.OneTreeDataList[str1]) {
        if (key == 'children') {
          console.log("str1", key, this.state.OneTreeDataList[str1][key][0]);
          depthIdobject = this.state.OneTreeDataList[str1][key][0].depth_id
          selectedKeysobject.push(this.state.OneTreeDataList[str1][key][0].key)
          console.log("depthIdobject", depthIdobject, selectedKeysobject);


          // this.setState({
          //   selectedKeys:[this.state.OneTreeDataList[str1][key][0].key]
          // })
        }
      }
      this.props.history.push({
        pathname: "/project/followPollute"
      })

      setSessionNumberPlies(str1)
      saveDepthId(depthIdobject)
      this.setState({
        expandedKeys: expandedobject,
        selectedKeys: selectedKeysobject,
        expandedKey: expandedobject

      })


    };
    // 树节点展开/收缩
    const onExpand = (expandedKeys: any) => {
      console.log(22, expandedKeys)

      this.setState({
        // autoExpandParent: true,
        // expandedKeys: expandedKeys
      })
    }
    return (
      <div>


        <Layout className="dashboard">
          <Header className="db-header" style={{ position: 'fixed', zIndex: 3, width: '100%' }}>
            <HeaderTop props={this.props}></HeaderTop>
          </Header>
          <Layout style={{ marginTop: 70 }} hasSider={true} >
            <Sider
              style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                backgroundColor: '#fff',
                minWidth: 200,
                paddingTop: 20,
                width: 200
              }}
              className="site-layout-background win-no-drag"
              breakpoint="lg"
              collapsedWidth="200">
              <Tree.DirectoryTree
                treeData={this.state.treeData}
                defaultSelectedKeys={['0']}
                defaultExpandedKeys={['1-1']}
                defaultExpandAll={this.state.defaultExpandAll}
                selectedKeys={this.state.selectedKeys}
                expandAction={false}
                // onSelect={onOpenChange}
                defaultExpandParent={true}
                onExpand={onExpand}
                expandedKeys={this.state.expandedKeys}
                autoExpandParent={true}//是否自动展开父节点
                style={{ height: '100%' }}

              //   multiple//支持点选多个节点（节点本身）
              //   // treeData={this.firstTreeData}//treeNodes 数据，如果设置则不需要手动构造 TreeNode 节点（key 在整个树范围内唯一）
              // treeData={this.state.treeData}

              //   defaultSelectedKeys={['0']}//默认选中的树节点
              //   defaultExpandAll={this.state.defaultExpandAll}//默认展开所有树节点
              //   selectedKeys={this.state.expandedKeys}//受控）设置选中的树节点
              //   expandAction={false}//（受控）展开指定的树节点
              //   onSelect={this.handleSelectTab}
              //   autoExpandParent={true}//	是否自动展开父节点
              />
            </Sider>
            <Layout style={{
              marginLeft: 224,
              marginTop: 20,
              marginRight: 24,
              minWidth: 800,
              minHeight: '100vh',
            }}>
              <BreadCrumb />
              <Content
                className="site-layout-background win-no-drag"
                style={{
                  padding: '24px',
                  margin: 0,
                  minHeight: 280,
                }}
              >
                <Switch>
                  <MainRoutes />
                </Switch>
              </Content>
            </Layout>
          </Layout>
        </Layout>


      </div>
    );
  }
}

export default withRouter(HomePage);
