import * as React from "react";
import { Component } from "react";
import { Tabs, Row, Col, Form, Input, Divider, Spin, message, Checkbox } from "antd";
import { getProjectParamsValue, saveProjectParamsValueSensitivity, getInitMatch } from '../../../apis/common'
import { checkProductd, checkDepthid } from '../../../configs/checkProductId.js'
import { getSessionLevel } from '../../../utils/token'

import "./getlnitParamsValue.less";
import "./getlnitParamsValue.css"
import { log } from "console";
const { TabPane } = Tabs;
interface leaching {
  base: {
    DF: string,
    I: string,
    Ugw: string,
    δgw: string
  }
}
let leaching = {
  base: {
    DF: '',
    I: '',
    Ugw: '',
    δgw: ''
  }
}
interface common {
  area: {
    LS: string
    d: string
    dsub: string
    lgw: string
  },
  exposure: {
    ABSo: string
    ACR: string
    AHQ: string
    ATca: string
    ATnc: string
    BWa: string
    BWc: string
    DAIRa: string
    DAIRc: string
    EDa: string
    EDc: string
    EFIa: string
    EFIc: string
    EFOa: string
    EFOc: string
    EFa: string
    EFc: string
    SAF: string
    SAFVOCS: string
    WAF: string
    WAFVOCS: string
  },
  soil: {
    Pws: string
    fom: string
    hcap: string
    hv: string
    θacap: string
    ρs: string
    θwcap: string
    ρb: string

  }
}
let areaArrListArr = {


  "common": {
    "area": {
      "d": "False",
      "dsub": "False",
      "LS": "False",
      "lgw": "False"
    },
    "exposure": {
      "EDa": "False",
      "DAIRc": "False",
      "EDc": "False",
      "SAF": "False",
      "EFa": "False",
      "WAF": "False",
      "EFc": "False",
      "SAFVOCS": "False",
      "EFIa": "False",
      "WAFVOCS": "False",
      "EFIc": "False",
      "ABSo": "False",
      "EFOa": "False",
      "ACR": "False",
      "EFOc": "False",
      "AHQ": "False",
      "BWa": "False",
      "ATca": "False",
      "BWc": "False",
      "ATnc": "False",
      "DAIRa": "False"
    },
    "soil": {
      "fom": "False",
      "hcap": "False",
      "ρb": "False",
      "hv": "False",
      "Pws": "False",
      "θacap": "False",
      "ρs": "False",
      "θwcap": "False"
    }
  },
  "leaching": {
    "base": {
      "Ugw": "False",
      "δgw": "False",
      "I": "False",
      "DF": "False"
    }
  },
  "model": {
    "inhalationIndoor": {
      "θacrack": "False",
      "τ": "False",
      "θwcrack": "False",
      "dP": "False",
      "Lcrack": "False",
      "Kv": "False",
      "LB": "False",
      "Zcrack": "False",
      "ER": "False",
      "Xcrack": "False",
      "η": "False",
      "Ab": "False"
    },
    "inhalationOutdoor": {
      "W": "False",
      "Uair": "False",
      "A": "False",
      "δair": "False"
    },
    "particulateMatter": {
      "PM10": "False",
      "fspo": "False",
      "fspi": "False",
      "PIAF": "False"
    },
    "throughMouth": {
      "OSIRa": "False",
      "OSIRc": "False"
    },
    "throughSkin": {
      "Ha": "False",
      "SERc": "False",
      "Hc": "False",
      "SSARa": "False",
      "Ev": "False",
      "SSARc": "False",
      "SERa": "False"
    },
    "water": {
      "GWCRa": "False",
      "GWCRc": "False"
    }
  }


}
let common = {
  area: {
    LS: '',
    d: '',
    dsub: '',
    lgw: '',
  },
  exposure: {
    ABSo: '',
    ACR: '',
    AHQ: '',
    ATca: '',
    ATnc: '',
    BWa: '',
    BWc: '',
    DAIRa: '',
    DAIRc: '',
    EDa: '',
    EDc: '',
    EFIa: '',
    EFIc: '',
    EFOa: '',
    EFOc: '',
    EFa: '',
    EFc: '',
    SAF: '',
    SAFVOCS: '',
    WAF: '',
    WAFVOCS: '',
  },
  soil: {
    Pws: '',
    fom: '',
    hcap: '',
    hv: '',
    θacap: '',
    ρs: '',
    θwcap: '',
    ρb: '',

  }
}
interface model {
  'inhalationIndoor': {
    Ab: string
    ER: string
    Kv: string
    LB: string
    Lcrack: string
    Xcrack: string
    Zcrack: string
    dP: string
    η: string
    θacrack: string
    θwcrack: string
    τ: string
  },
  'inhalationOutdoor': {
    A: string
    Uair: string
    W: string
    δair: string
  },
  'particulateMatter': {
    PIAF: string
    PM10: string
    fspi: string
    fspo: string
  },
  'throughMouth': {
    OSIRa: string
    OSIRc: string
  },
  'throughSkin': {
    Ev: string,
    Ha: string
    Hc: string
    SERa: string
    SERc: string
    SSARa: string
    SSARc: string
  },
  'water': {
    GWCRa: string
    GWCRc: string
  }

}
let model = {
  'inhalationIndoor': {
    Ab: '',
    ER: '',
    Kv: '',
    LB: '',
    Lcrack: '',
    Xcrack: '',
    Zcrack: '',
    dP: '',
    η: '',
    θacrack: '',
    θwcrack: '',
    τ: '',
  },
  'inhalationOutdoor': {
    A: '',
    Uair: '',
    W: '',
    δair: '',
  },
  'particulateMatter': {
    PIAF: '',
    PM10: '',
    fspi: '',
    fspo: '',
  },
  'throughMouth': {
    OSIRa: '',
    OSIRc: '',
  },
  'throughSkin': {
    Ev: '',
    Ha: '',
    Hc: '',
    SERa: '',
    SERc: '',
    SSARa: '',
    SSARc: ''
  },
  'water': {
    GWCRa: '',
    GWCRc: '',
  }

}
export interface ParamsProps {
  history: any
}

export interface ParamsState {
  formLayout: any;
  project_id: string,
  depth_id: string,
  common: common,
  leaching: leaching,
  model: model,
  formActive: any,
  formOne: any,
  formTwo: any,
  formThree: any,
  formFour: any,
  formFive: any,
  formSix: any,
  formSeven: any,
  formEight: any,
  formNine: any,
  formTen: any,
  setformdiable: boolean,
  setformFive: boolean,
  setformSix: boolean,
  setformSeven: boolean,
  setformEight: boolean,
  setformNine: boolean,
  setformTen: boolean,
  loading: boolean,
  activeClass: any,
  areaArrList: any,
  checkList: any,
  adjustment: any,
  ss: any,
}

class getlnitParamsValue extends React.Component<ParamsProps, ParamsState> {
  constructor(props: ParamsProps) {
    super(props);
    this.state = {

      ss: [],
      formActive: React.createRef(),

      formOne: React.createRef(),
      formTwo: React.createRef(),
      formThree: React.createRef(),
      formFour: React.createRef(),
      formFive: React.createRef(),
      formSix: React.createRef(),
      formSeven: React.createRef(),
      formEight: React.createRef(),
      formNine: React.createRef(),
      formTen: React.createRef(),
      setformdiable: true,
      setformFive: false,
      setformSix: false,
      setformSeven: false,
      setformEight: false,
      setformNine: false,
      setformTen: false,
      loading: true,
      project_id: '',
      depth_id: '',
      common: common,
      leaching: leaching,
      model: model,
      adjustment: [],
      activeClass: {
        area: [],
        soil: [],
        base: [],
        inhalationIndoor: [],
        inhalationOutdoor: [],
        particulateMatter: [],
        throughMouth: [],
        throughSkin: [],
        water: []
      },
      formLayout: {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
      },
      checkList: [],
      areaArrList: {


        "common": {
          "area": {
            "d": "False",
            "dsub": "False",
            "LS": "False",
            "lgw": "False"
          },
          "exposure": {
            "EDa": "False",
            "DAIRc": "False",
            "EDc": "False",
            "SAF": "False",
            "EFa": "False",
            "WAF": "False",
            "EFc": "False",
            "SAFVOCS": "False",
            "EFIa": "False",
            "WAFVOCS": "False",
            "EFIc": "False",
            "ABSo": "False",
            "EFOa": "False",
            "ACR": "False",
            "EFOc": "False",
            "AHQ": "False",
            "BWa": "False",
            "ATca": "False",
            "BWc": "False",
            "ATnc": "False",
            "DAIRa": "False"
          },
          "soil": {
            "fom": "False",
            "hcap": "False",
            "ρb": "False",
            "hv": "False",
            "Pws": "False",
            "θacap": "False",
            "ρs": "False",
            "θwcap": "False"
          }
        },
        "leaching": {
          "base": {
            "Ugw": "False",
            "δgw": "False",
            "I": "False",
            "DF": "False"
          }
        },
        "model": {
          "inhalationIndoor": {
            "θacrack": "False",
            "τ": "False",
            "θwcrack": "False",
            "dP": "False",
            "Lcrack": "False",
            "Kv": "False",
            "LB": "False",
            "Zcrack": "False",
            "ER": "False",
            "Xcrack": "False",
            "η": "False",
            "Ab": "False"
          },
          "inhalationOutdoor": {
            "W": "False",
            "Uair": "False",
            "A": "False",
            "δair": "False"
          },
          "particulateMatter": {
            "PM10": "False",
            "fspo": "False",
            "fspi": "False",
            "PIAF": "False"
          },
          "throughMouth": {
            "OSIRa": "False",
            "OSIRc": "False"
          },
          "throughSkin": {
            "Ha": "False",
            "SERc": "False",
            "Hc": "False",
            "SSARa": "False",
            "Ev": "False",
            "SSARc": "False",
            "SERa": "False"
          },
          "water": {
            "GWCRa": "False",
            "GWCRc": "False"
          }
        }


      }

    };
  }
  getSystem = async () => {
    // getInitMatch


    let params = {
      project_id: this.state.project_id,
      depth_id: this.state.depth_id,
    }
    try {
      let res = await getInitMatch(params);
      let dataMatch = { adjustment: Number(res.data) * 100 }

      console.log("dataMatch", res, dataMatch);

      if (res.data == null) {
        this.state.formActive.current.setFieldsValue('')

      } else {

        this.state.formActive.current.setFieldsValue(dataMatch)

      }




      this.setState({
        adjustment: JSON.stringify(dataMatch.adjustment),
      })
    } catch (error) {

      console.log(error)
    }
    try {
      let res = await getProjectParamsValue(params);
      console.log("res", res.data, res.data.sensitivity,);

      let {
        common,
        leaching,
        model,
        sensitivity
      } = res.data;
      // console.log("00000000000",sensitivity,sensitivity.length,Object.keys(sensitivity).length,JSON.stringify(sensitivity));

      this.state.areaArrList.sensitivity = sensitivity
      if (sensitivity == undefined || Object.keys(sensitivity).length == 0) {
        console.log("111111111", areaArrListArr);
        let route = this.props.history.location;
        console.log("route", route);
    
        if (route.adjustment) {
          console.log("1111111111", route.adjustment);
          window.location.reload()
    
        } else {
          console.log("22222222", route.adjustment);
    
        }
        this.setState({

          areaArrList: areaArrListArr,
          loading: false,
        })
      } else {
        console.log("22222222222222222",);

        this.setState({

          areaArrList: sensitivity,
          loading: false,
        })

      }

      console.log("this.state.areaArrList.model.inhalationIndoor.θacrack", this.state.areaArrList);
      console.log(" checked={JSON.parse(this.state.areaArrList.model.inhalationIndoor.Lcrack.toLowerCase())}", JSON.parse(this.state.areaArrList.model.inhalationIndoor.θacrack.toLowerCase()));



      // this.state.activeClass.area[3]
      let activeClass = this.state.activeClass
      if (common.area['lgw']) {
        activeClass.area[3] = true
      } else {
        activeClass.area[3] = false
      }
      this.setState({
        activeClass,


      })
      //通用参数
      this.state.formOne.current.setFieldsValue(common.area);
      this.state.formTwo.current.setFieldsValue(common.soil);
      this.state.formThree.current.setFieldsValue(common.exposure);
      //土壤淋溶至地下水
      this.state.formFour.current.setFieldsValue(leaching.base)
      //模型参数
      this.state.formFive.current.setFieldsValue(model.inhalationIndoor)
      this.state.formSix.current.setFieldsValue(model.inhalationOutdoor)
      this.state.formSeven.current.setFieldsValue(model.throughMouth)
      this.state.formEight.current.setFieldsValue(model.throughSkin)
      this.state.formNine.current.setFieldsValue(model.particulateMatter)
      this.state.formTen.current.setFieldsValue(model.water)


    } catch (error) {

    }
  }
  componentWillMount() {
    let route = this.props.history.location;
    console.log("route", route);

    // if (route.adjustment) {
    //   console.log("1111111111", route.adjustment);
    //   window.location.reload()

    // } else {
    //   console.log("22222222", route.adjustment);

    // }
    let productId = checkProductd();
    if (productId) {
      this.setState({
        project_id: productId,
      })
    }
    let Depthid = checkDepthid();
    if (Depthid) {
      this.setState({
        depth_id: Depthid,
      })
    }
  }
  // 页面初始化
  componentDidMount() {
    this.getSystem()
  }
  async gotoTap(step: string) {
    let getLevel = getSessionLevel()
    console.log("getLevel", getLevel);

    if (step == 'back') {

      if (getLevel) {
        console.log("1111111", getLevel);
        this.props.history.push({
          pathname: "/project/controlValueCalculation",
        })
      } else {
        console.log("222222222", getLevel);
        this.props.history.push({
          pathname: "/project/controlValueCalculation",
        })
      }

    }
    if (step == 'next') {

      let area = this.state.formOne.current.getFieldsValue();
      let soil = this.state.formTwo.current.getFieldsValue();
      let exposure = this.state.formThree.current.getFieldsValue();
      //土壤淋溶至地下水
      let base = this.state.formFour.current.getFieldsValue()
      //模型参数
      let inhalationIndoor = this.state.formFive.current.getFieldsValue()
      let inhalationOutdoor = this.state.formSix.current.getFieldsValue()
      let throughMouth = this.state.formSeven.current.getFieldsValue()
      let throughSkin = this.state.formEight.current.getFieldsValue()
      let particulateMatter = this.state.formNine.current.getFieldsValue()
      let water = this.state.formTen.current.getFieldsValue()





      let areaArrList = this.state.areaArrList

      let areaCheck = areaArrList.common.area
      let soilCheck = areaArrList.common.soil
      let exposureCheck = areaArrList.common.exposure
      //土壤淋溶至地下水
      let baseCheck = areaArrList.leaching.base
      //模型参数
      let inhalationIndoorCheck = areaArrList.model.inhalationIndoor
      let inhalationOutdoorCheck = areaArrList.model.inhalationOutdoor
      let throughMouthCheck = areaArrList.model.throughMouth
      let throughSkinCheck = areaArrList.model.throughSkin
      let particulateMatterCheck = areaArrList.model.particulateMatter
      let waterCheck = areaArrList.model.water
      console.log("areaArrList", areaArrList, soilCheck);

      let data = {
        common: {
          area,
          exposure,
          soil
        },
        leaching: {
          base
        },
        model: {
          inhalationIndoor,
          inhalationOutdoor,
          particulateMatter,
          throughMouth,
          throughSkin,
          water
        },
        sensitivity: {
          common: {
            area: areaCheck,
            exposure: exposureCheck,
            soil: soilCheck
          },
          leaching: {
            base: baseCheck
          },
          model: {
            inhalationIndoor: inhalationIndoorCheck,
            inhalationOutdoor: inhalationOutdoorCheck,
            particulateMatter: particulateMatterCheck,
            throughMouth: throughMouthCheck,
            throughSkin: throughSkinCheck,
            water: waterCheck
          },
        }
      }
      console.log("data", this.state.formOne.current.getFieldValue()['lgw']);
      if (this.state.adjustment.length == 0) {


        message.info('敏感度分析调整系数必填！')
        return
      } else {

      }

      let params = {
        project_id: this.state.project_id,
        depth_id: this.state.depth_id,
        data: JSON.stringify(data),
        adjustment_coefficient: this.state.adjustment + '%'
      }

      let activeClass = this.state.activeClass
      let areaClass = activeClass.area
      let lgwValue = area['lgw'];
      let hcapValue = soil['hcap'];
      if (!area['lgw']) {
        message.info('lgw地下水埋深必填！')
        areaClass[3] = true
        activeClass['area'] = areaClass
        this.setState({
          activeClass
        })
        return
      } else {
        if (lgwValue - hcapValue < 0) {
          areaClass[3] = true
          activeClass['area'] = areaClass
          this.setState({
            activeClass
          })
          message.info('lgw水埋深度需要大于hcap毛管厚度！')
          return
        }
      }
      let res = await saveProjectParamsValueSensitivity(params);
      if (res.code == '0') {



        this.props.history.push({
          pathname: "/project/sensitivity",
          adjustment: `${this.state.adjustment}`
        })

      }
      return
    }
  }
  setEdit = () => {
    this.setState({
      setformdiable: !this.state.setformdiable
    })
  }
  retrunlast = (id: any) => {
    let ids = parseInt(id);
    let activeClass = this.state.activeClass
    switch (ids) {
      case 1:
        this.state.formOne.current.setFieldsValue(this.state.common.area)
        activeClass.area = []
        break;
      case 2:
        this.state.formTwo.current.setFieldsValue(this.state.common.soil)
        activeClass.soil = []
        break;
      case 3:
        this.state.formThree.current.setFieldsValue(this.state.common.exposure)
        break;
      case 4:
        this.state.formFour.current.setFieldsValue(this.state.leaching.base)
        activeClass.base = []
        break;
      case 5:
        this.state.formFive.current.setFieldsValue(this.state.model.inhalationIndoor)
        activeClass.inhalationIndoor = []
        break;
      case 6:
        this.state.formSix.current.setFieldsValue(this.state.model.inhalationOutdoor)
        activeClass.inhalationOutdoor = []
        break;
      case 7:
        this.state.formSeven.current.setFieldsValue(this.state.model.throughMouth)
        activeClass.throughMouth = []
        break;
      case 8:
        this.state.formEight.current.setFieldsValue(this.state.model.throughSkin)
        activeClass.throughSkin = []
        break;
      case 9:
        this.state.formNine.current.setFieldsValue(this.state.model.particulateMatter)
        activeClass.particulateMatter = []
        break;
      case 10:
        this.state.formTen.current.setFieldsValue(this.state.model.water)
        activeClass.water = []
        break;
      default:
        break;
    }
    this.setState({
      activeClass
    })
  }
  adjustmentChange = (values: any) => {
    console.log("values", values.target.value);
    this.setState({
      adjustment: values.target.value
    })

  }
  checkIsChangeCheck = (values: any) => {

    let {
      target: {
        checked,
        name
      }
    } = values
    console.log("11111111111111111111111111111111111checked", checked, name, values);

    let toUpperCaseChecked = JSON.stringify(checked).slice(0, 1).toUpperCase() + JSON.stringify(checked).slice(1).toLowerCase()
    console.log("toUpperCaseChecked", toUpperCaseChecked);

    let areaArr = ['d', 'dsub', 'LS', 'lgw']
    let soilArr = ['fom', 'hcap', 'ρb', 'hv', 'Pws', 'θacap', 'ρs', 'θwcap']
    let exposureArr = ['EDa', 'DAIRc', 'EDc', 'SAF', 'EFa', 'WAF', 'EFc', 'SAFVOCS', 'EFIa', 'WAFVOCS', 'EFIc', 'ABSo', 'EFOa', 'ACR', 'EFOc', 'AHQ', 'BWa', 'BWc', 'ATnc', 'DAIRa']
    let baseArr = ['Ugw', 'δgw', 'I', 'DF']

    let inhalationIndoorArr = ['θacrack', 'τ', 'θwcrack', 'dP', 'Lcrack', 'Kv', 'LB', 'Zcrack', 'ER', 'Xcrack', 'η', 'Ab']
    let inhalationOutdoorArr = ['W', 'Uair', 'A', 'δair']
    let throughMouthArr = ['OSIRa', 'OSIRc']
    let throughSkinArr = ['Ha', 'SERc', 'Hc', 'SSARa', 'Ev', 'SSARc', 'SERa']
    let particulateMatterArr = ['PM10', 'fspo', 'fspi', 'PIAF']

    let waterArr = ['GWCRa', 'GWCRc']


    let areaArrList = this.state.areaArrList

    let areaClass = areaArrList.common.area

    let soilClass = areaArrList.common.soil
    let exposureClass = areaArrList.common.exposure
    let baseClass = areaArrList.leaching.base
    let inhalationIndoorClass = areaArrList.model.inhalationIndoor
    console.log(" areaArrList.model.inhalationIndoor", areaArrList.model.inhalationIndoor);

    let inhalationOutdoorClass = areaArrList.model.inhalationOutdoor
    let particulateMatterClass = areaArrList.model.particulateMatter
    let throughMouthClass = areaArrList.model.throughMouth
    let throughSkinClass = areaArrList.model.throughSkin
    let waterClass = areaArrList.model.water


    let oneArrayList = new Array()
    let newobject = new Object()

    const commonObject = {
      area: {
        d: "False",
        dsub: "False",
        LS: "False",
        lgw: "False"
      },
      exposure: {
        EDa: "False",
        DAIRc: "False",
        EDc: "False",
        SAF: "False",
        EFa: "False",
        WAF: "False",
        EFc: "False",
        SAFVOCS: "False",
        EFIa: "False",
        WAFVOCS: "False",
        EFIc: "False",
        ABSo: "False",
        EFOa: "False",
        ACR: "False",
        EFOc: "False",
        AHQ: "False",
        BWa: "False",
        ATca: "False",
        BWc: "False",
        ATnc: "False",
        DAIRa: "False"
      },
      soil: {
        fom: "False",
        hcap: "False",
        ρb: "False",
        hv: "False",
        Pws: "False",
        θacap: "False",
        ρs: "False",
        θwcap: "False"
      }
    }
    const leachingObject = {
      base: {
        Ugw: "False",
        δgw: "False",
        I: "False",
        DF: "False"
      }
    }
    const modelObject = {
      inhalationIndoor: {
        θacrack: "False",
        τ: "False",
        θwcrack: "False",
        dP: "False",
        Lcrack: "False",
        Kv: "False",
        LB: "False",
        Zcrack: "False",
        ER: "False",
        Xcrack: "False",
        η: "False",
        Ab: "False"
      },
      inhalationOutdoor: {
        W: "False",
        Uair: "False",
        A: "False",
        δair: "False"
      },
      particulateMatter: {
        PM10: "False",
        fspo: "False",
        fspi: "False",
        PIAF: "False"
      },
      throughMouth: {
        OSIRa: "False",
        OSIRc: "False"
      },
      throughSkin: {
        Ha: "False",
        SERc: "False",
        Hc: "False",
        SSARa: "False",
        Ev: "False",
        SSARc: "False",
        SERa: "False"
      },
      water: {
        GWCRa: "False",
        GWCRc: "False"
      }
    }
    let area = commonObject.area
    let exposure = commonObject.exposure
    let soil = commonObject.soil


    let {
      d,
      dsub,
      LS,
      lgw
    } = area;
    let {
      fom,
      hcap,
      ρb,
      hv,
      Pws,
      θacap,
      ρs,
      θwcap
    } = soil
    let {
      EDa,
      DAIRc,
      EDc,
      SAF,
      EFa,
      WAF,
      EFc,
      SAFVOCS,
      EFIa,
      WAFVOCS,
      EFIc,
      ABSo,
      EFOa,
      ACR,
      EFOc,
      AHQ,
      BWa,
      ATca,
      BWc,
      ATnc,
      DAIRa
    } = exposure;
    const {
      base: {
        Ugw,
        δgw,
        I,
        DF
      }
    } = leachingObject
    let {
      inhalationIndoor: {
        Ab,
        ER,
        Kv,
        LB,
        Lcrack,
        Xcrack,
        Zcrack,
        dP,
        η,
        θacrack,
        θwcrack,
        τ,
      },
      inhalationOutdoor: {
        A,
        Uair,
        W,
        δair,
      },
      throughMouth: {
        OSIRa,
        OSIRc,
      },
      throughSkin: {
        Ev,
        Ha,
        Hc,
        SERa,
        SERc,
        SSARa,
        SSARc
      },
      particulateMatter: {
        PIAF,
        PM10,
        fspi,
        fspo,
      },
      water: {
        GWCRa,
        GWCRc,
      }
    } = this.state.model

    switch (name) {
      case areaArr[0]:

        if (checked != d) {
          areaClass.d = toUpperCaseChecked

        } else {

        }
        break;
      case areaArr[1]:
        if (checked != dsub) {
          areaClass.dsub = toUpperCaseChecked
        } else {
        }
        break;
      case areaArr[2]:
        if (checked != LS) {
          areaClass.LS = toUpperCaseChecked
        } else {
        }
        break;
      case areaArr[3]:

        if (checked != lgw) {
          areaClass.lgw = toUpperCaseChecked
        } else {
        }
        break;

      case soilArr[0]:

        if (checked != fom) {
          soilClass.fom = toUpperCaseChecked
        } else {
        }
        break;
      case soilArr[1]:
        if (checked != hcap) {
          soilClass.hcap = toUpperCaseChecked
        } else {
        }
        break;
      case soilArr[2]:
        if (checked != ρb) {
          soilClass.ρb = toUpperCaseChecked
        } else {
        }
        break;
      case soilArr[3]:
        if (checked != hv) {
          soilClass.hv = toUpperCaseChecked
        } else {
        }
        break;
      case soilArr[4]:
        if (checked != Pws) {
          soilClass.Pws = toUpperCaseChecked
        } else {
        }
        break;
      case soilArr[5]:
        if (checked != θacap) {
          soilClass.θacap = toUpperCaseChecked
        } else {
        }
        break;
      case soilArr[6]:
        if (checked != ρs) {
          soilClass.ρs = toUpperCaseChecked
        } else {
        }
        break;
      case soilArr[7]:
        if (checked != θwcap) {
          soilClass.θwcap = toUpperCaseChecked
        } else {
        }
        break;
      case exposureArr[0]:
        if (checked != EDa) {
          exposureClass.EDa = toUpperCaseChecked
        } else {
        }
        break;
      case exposureArr[1]:
        if (checked != DAIRc) {
          exposureClass.DAIRc = toUpperCaseChecked
        } else {
        }
        break;
      case exposureArr[2]:
        if (checked != EDc) {
          exposureClass.EDc = toUpperCaseChecked
        } else {
        }
        break;
      case exposureArr[3]:
        if (checked != SAF) {
          exposureClass.SAF = toUpperCaseChecked
        } else {
        }
        break;
      case exposureArr[4]:
        if (checked != EFa) {
          exposureClass.EFa = toUpperCaseChecked
        } else {
        }
        break;
      case exposureArr[5]:
        if (checked != WAF) {
          exposureClass.WAF = toUpperCaseChecked
        } else {
        }
        break;
      case exposureArr[6]:
        if (checked != EFc) {
          exposureClass.EFc = toUpperCaseChecked
        } else {
        }
        break;
      case exposureArr[7]:
        if (checked != SAFVOCS) {
          exposureClass.SAFVOCS = toUpperCaseChecked
        } else {
        }
        break;
      case exposureArr[8]:
        if (checked != EFIa) {
          exposureClass.EFIa = toUpperCaseChecked
        } else {
        }
        break;
      case exposureArr[9]:
        if (checked != WAFVOCS) {
          exposureClass.WAFVOCS = toUpperCaseChecked
        } else {
        }
        break;
      case exposureArr[10]:
        if (checked != EFIc) {
          exposureClass.EFIc = toUpperCaseChecked
        } else {
        }
        break;
      case exposureArr[11]:
        if (checked != ABSo) {
          exposureClass.ABSo = toUpperCaseChecked
        } else {
        }
        break;
      case exposureArr[12]:
        if (checked != EFOa) {
          exposureClass.EFOa = toUpperCaseChecked
        } else {
        }
        break;
      case exposureArr[13]:
        if (checked != ACR) {
          exposureClass.ACR = toUpperCaseChecked
        } else {
        }
        break;
      case exposureArr[14]:
        if (checked != EFOc) {
          exposureClass.EFOc = toUpperCaseChecked
        } else {
        }
        break;
      case exposureArr[15]:
        if (checked != AHQ) {
          exposureClass.AHQ = toUpperCaseChecked
        } else {
        }
        break;
      case exposureArr[16]:
        if (checked != BWa) {
          exposureClass.BWa = toUpperCaseChecked
        } else {
        }
        break;
      case exposureArr[17]:
        if (checked != BWc) {
          exposureClass.BWc = toUpperCaseChecked
        } else {
        }
        break;
      case exposureArr[18]:
        if (checked != ATnc) {
          exposureClass.ATnc = toUpperCaseChecked
        } else {
        }
        break;
      case exposureArr[19]:
        if (checked != DAIRa) {
          exposureClass.DAIRa = toUpperCaseChecked
        } else {
        }
        break;

      case baseArr[0]:
        if (checked != Ugw) {
          baseClass.Ugw = toUpperCaseChecked
        } else {
        }
        break;
      case baseArr[1]:
        if (checked != δgw) {
          baseClass.δgw = toUpperCaseChecked
        } else {
        }
        break;
      case baseArr[2]:
        if (checked != I) {
          baseClass.I = toUpperCaseChecked
        } else {
        }
        break;
      case baseArr[3]:
        if (checked != DF) {
          baseClass.DF = toUpperCaseChecked
        } else {
        }
        break;
      case inhalationIndoorArr[0]:



        inhalationIndoorClass.θacrack = toUpperCaseChecked

        break;
      case inhalationIndoorArr[1]:

        inhalationIndoorClass.τ = toUpperCaseChecked

        break;
      case inhalationIndoorArr[2]:

        inhalationIndoorClass.θwcrack = toUpperCaseChecked

        break;
      case inhalationIndoorArr[3]:
        inhalationIndoorClass.dP = toUpperCaseChecked

        break;
      case inhalationIndoorArr[4]:

        inhalationIndoorClass.Lcrack = toUpperCaseChecked

        break;
      case inhalationIndoorArr[5]:

        inhalationIndoorClass.Kv = toUpperCaseChecked

        break;
      case inhalationIndoorArr[6]:

        inhalationIndoorClass.LB = toUpperCaseChecked

        break;
      case inhalationIndoorArr[7]:

        inhalationIndoorClass.Zcrack = toUpperCaseChecked

        break;
      case inhalationIndoorArr[8]:

        inhalationIndoorClass.ER = toUpperCaseChecked

        break;
      case inhalationIndoorArr[9]:

        inhalationIndoorClass.Xcrack = toUpperCaseChecked

        break;
      case inhalationIndoorArr[10]:

        inhalationIndoorClass.η = toUpperCaseChecked

        break;
      case inhalationIndoorArr[11]:

        inhalationIndoorClass.Ab = toUpperCaseChecked


        break;
      case inhalationOutdoorArr[0]:

        inhalationOutdoorClass.W = toUpperCaseChecked

        break;
      case inhalationOutdoorArr[1]:

        inhalationOutdoorClass.Uair = toUpperCaseChecked

        break;
      case inhalationOutdoorArr[2]:

        inhalationOutdoorClass.A = toUpperCaseChecked

        break;
      case inhalationOutdoorArr[3]:

        inhalationOutdoorClass.δair = toUpperCaseChecked

        break;
      case throughMouthArr[0]:

        throughMouthClass.OSIRa = toUpperCaseChecked

        break;
      case throughMouthArr[1]:

        throughMouthClass.OSIRc = toUpperCaseChecked

        break;
      case throughSkinArr[0]:

        throughSkinClass.Ha = toUpperCaseChecked

        break;
      case throughSkinArr[1]:

        throughSkinClass.SERc = toUpperCaseChecked

        break;
      case throughSkinArr[2]:

        throughSkinClass.Hc = toUpperCaseChecked

        break;
      case throughSkinArr[3]:

        throughSkinClass.SSARa = toUpperCaseChecked

        break;
      case throughSkinArr[4]:

        throughSkinClass.Ev = toUpperCaseChecked

        break;
      case throughSkinArr[5]:

        throughSkinClass.SSARc = toUpperCaseChecked

        break;
      case throughSkinArr[6]:

        throughSkinClass.SERa = toUpperCaseChecked

        break;
      case particulateMatterArr[0]:

        particulateMatterClass.PM10 = toUpperCaseChecked

        break;
      case particulateMatterArr[1]:

        particulateMatterClass.fspo = toUpperCaseChecked

        break;
      case particulateMatterArr[2]:

        particulateMatterClass.fspi = toUpperCaseChecked

        break;
      case particulateMatterArr[3]:

        particulateMatterClass.PIAF = toUpperCaseChecked

        break;
      case waterArr[0]:



        waterClass.GWCRa = toUpperCaseChecked



        break;
      case waterArr[1]:

        waterClass.GWCRc = toUpperCaseChecked



        break;
      default:
        break;
    }
    console.log("areaClass", areaClass, areaArrList, this.state.areaArrList);

    areaArrList['area'] = areaClass
    areaArrList['soil'] = soilClass
    areaArrList['exposure'] = exposureClass
    areaArrList['base'] = baseClass
    areaArrList['inhalationIndoor'] = inhalationIndoorClass
    areaArrList['inhalationOutdoor'] = inhalationOutdoorClass
    areaArrList['throughMouth'] = throughMouthClass
    areaArrList['throughSkin'] = throughSkinClass
    areaArrList['particulateMatter'] = particulateMatterClass
    areaArrList['water'] = waterClass
    this.setState({
      areaArrList
    })
    console.log("area", areaArrList, this.state.areaArrList);


  }

  checkIsChange = (values: any) => {
    console.log("values", values);

    let {
      target: {
        value,
        id
      }
    } = values
    console.log("value", values, value, id);


    let areaArr = ['d', 'dsub', 'LS', 'lgw']
    let soilArr = ['fom', 'hcap', 'ρb', 'hv', 'Pws', 'θacap', 'ρs', 'θwcap']
    let baseArr = ['Ugw', 'δgw', 'I', 'DF']
    let inhalationIndoorArr = ['θacrack', 'τ', 'θwcrack', 'dP', 'Lcrack', 'Kv', 'LB', 'Zcrack', 'ER', 'Xcrack', 'η', 'Ab']
    let inhalationOutdoorArr = ['W', 'Uair', 'A', 'δair']
    let throughMouthArr = ['OSIRa', 'OSIRc']
    let throughSkinArr = ['Ha', 'SERc', 'Hc', 'SSARa', 'Ev', 'SSARc', 'SERa']
    let particulateMatterArr = ['PM10', 'fspo', 'fspi', 'PIAF']
    let waterArr = ['GWCRa', 'GWCRc']

    let activeClass = this.state.activeClass

    let areaClass = activeClass.area
    let soilClass = activeClass.soil
    let baseClass = activeClass.base
    let inhalationIndoorClass = activeClass.inhalationIndoor
    let inhalationOutdoorClass = activeClass.inhalationOutdoor
    let throughMouthClass = activeClass.throughMouth
    let throughSkinClass = activeClass.throughSkin
    let particulateMatterClass = activeClass.particulateMatter
    let waterClass = activeClass.water

    let area = this.state.common.area
    let soil = this.state.common.soil
    let base = this.state.leaching.base

    let {
      d,
      dsub,
      LS,
      lgw
    } = area;
    let {
      fom,
      hcap,
      ρb,
      hv,
      Pws,
      θacap,
      ρs,
      θwcap
    } = soil
    let {
      Ugw,
      δgw,
      I,
      DF
    } = base;
    let {
      inhalationIndoor: {
        Ab,
        ER,
        Kv,
        LB,
        Lcrack,
        Xcrack,
        Zcrack,
        dP,
        η,
        θacrack,
        θwcrack,
        τ,
      },
      inhalationOutdoor: {
        A,
        Uair,
        W,
        δair,
      },
      throughMouth: {
        OSIRa,
        OSIRc,
      },
      throughSkin: {
        Ev,
        Ha,
        Hc,
        SERa,
        SERc,
        SSARa,
        SSARc
      },
      particulateMatter: {
        PIAF,
        PM10,
        fspi,
        fspo,
      },
      water: {
        GWCRa,
        GWCRc,
      }
    } = this.state.model
    console.log("inhalationOutdoorArr2222222222222222222222", inhalationOutdoorArr, inhalationOutdoorClass);

    switch (id) {
      case areaArr[0]:
        if (value != d) {
          areaClass[0] = true
        } else {
          areaClass[0] = false
        }
        break;
      case areaArr[1]:
        if (value != dsub) {
          areaClass[1] = true
        } else {
          areaClass[1] = false
        }
        break;
      case areaArr[2]:
        if (value != LS) {
          areaClass[2] = true
        } else {
          areaClass[2] = false
        }
        break;
      case areaArr[3]:
        if (value != lgw && !lgw) {
          areaClass[3] = true
        } else {
          // areaClass[3] = false
        }
        let hcapValue = this.state.formTwo.current.getFieldValue()['hcap']
        if (value >= 0) {
          let sss = value - hcapValue
          console.log("sss", sss);

          let hvs = JSON.stringify(sss)
          console.log("hvs", hvs,);
          this.state.formTwo.current.setFieldsValue({
            hv: hvs
          })
          console.log("hv", this.state.formTwo.current.getFieldsValue({}),);

        } else {
          this.state.formOne.current.setFieldsValue({
            lgw: '0'
          })
        }
        break;
      case soilArr[0]:
        if (value != fom) {
          soilClass[0] = true
        } else {
          soilClass[0] = false
        }
        break;
      case soilArr[1]:
        let lgwValue = this.state.formOne.current.getFieldValue()['lgw']
        if (value >= 0) {
          let sss = lgwValue - value
          console.log("sss", sss);

          let hvs = JSON.stringify(sss)
          console.log("hvs", hvs);

          this.state.formTwo.current.setFieldsValue({
            hv: hvs
          })
          console.log("hv", this.state.formTwo.current.getFieldsValue({}),);

        } else {
          this.state.formTwo.current.setFieldsValue({
            hcap: 0
          })
        }
        if (value != hcap) {
          soilClass[1] = true
        } else {
          soilClass[1] = false
        }
        break;
      case soilArr[2]:
        if (value != ρb) {
          soilClass[2] = true
        } else {
          soilClass[2] = false
        }
        break;
      case soilArr[3]:
        if (value != hv) {
          soilClass[3] = true
        } else {
          soilClass[3] = false
        }
        break;
      case soilArr[4]:
        if (value != Pws) {
          soilClass[4] = true
        } else {
          soilClass[4] = false
        }
        break;
      case soilArr[5]:
        if (value != θacap) {
          soilClass[5] = true
        } else {
          soilClass[5] = false
        }
        break;
      case soilArr[6]:
        if (value != ρs) {
          soilClass[6] = true
        } else {
          soilClass[6] = false
        }
        break;
      case soilArr[7]:
        if (value != θwcap) {
          soilClass[7] = true
        } else {
          soilClass[7] = false
        }
        break;
      case baseArr[0]:
        if (value != Ugw) {
          baseClass[0] = true
        } else {
          baseClass[0] = false
        }
        break;
      case baseArr[1]:
        if (value != δgw) {
          baseClass[1] = true
        } else {
          baseClass[1] = false
        }
        break;
      case baseArr[2]:
        if (value != I) {
          baseClass[2] = true
        } else {
          baseClass[2] = false
        }
        break;
      case baseArr[3]:
        if (value != DF) {
          baseClass[3] = true
        } else {
          baseClass[3] = false
        }
        break;
      case inhalationIndoorArr[0]:
        if (value != θacrack) {
          inhalationIndoorClass[0] = true
        } else {
          inhalationIndoorClass[0] = false
        }
        break;
      case inhalationIndoorArr[1]:
        if (value != τ) {
          inhalationIndoorClass[1] = true
        } else {
          inhalationIndoorClass[1] = false
        }
        break;
      case inhalationIndoorArr[2]:
        if (value != θwcrack) {
          inhalationIndoorClass[2] = true
        } else {
          inhalationIndoorClass[2] = false
        }
        break;
      case inhalationIndoorArr[3]:
        if (value != dP) {
          inhalationIndoorClass[3] = true
        } else {
          inhalationIndoorClass[3] = false
        }
        break;
      case inhalationIndoorArr[4]:
        if (value != Lcrack) {
          inhalationIndoorClass[4] = true
        } else {
          inhalationIndoorClass[4] = false
        }
        break;
      case inhalationIndoorArr[5]:
        if (value != Kv) {
          inhalationIndoorClass[5] = true
        } else {
          inhalationIndoorClass[5] = false
        }
        break;
      case inhalationIndoorArr[6]:
        if (value != LB) {
          inhalationIndoorClass[6] = true
        } else {
          inhalationIndoorClass[6] = false
        }
        break;
      case inhalationIndoorArr[7]:
        if (value != Zcrack) {
          inhalationIndoorClass[7] = true
        } else {
          inhalationIndoorClass[7] = false
        }
        break;
      case inhalationIndoorArr[8]:
        if (value != ER) {
          inhalationIndoorClass[8] = true
        } else {
          inhalationIndoorClass[8] = false
        }
        break;
      case inhalationIndoorArr[9]:
        if (value != Xcrack) {
          inhalationIndoorClass[9] = true
        } else {
          inhalationIndoorClass[9] = false
        }
        break;
      case inhalationIndoorArr[10]:
        if (value != η) {
          inhalationIndoorClass[10] = true
        } else {
          inhalationIndoorClass[10] = false
        }
        break;
      case inhalationIndoorArr[11]:
        if (value != Ab) {
          inhalationIndoorClass[11] = true
        } else {
          inhalationIndoorClass[11] = false
        }
        break;
      case inhalationOutdoorArr[0]:
        if (value != W) {
          inhalationOutdoorClass[0] = true
        } else {
          inhalationOutdoorClass[0] = false
        }
        break;
      case inhalationOutdoorArr[1]:
        if (value != Uair) {
          inhalationOutdoorClass[1] = true
        } else {
          inhalationOutdoorClass[1] = false
        }
        break;
      case inhalationOutdoorArr[2]:
        if (value != A) {
          inhalationOutdoorClass[2] = true
        } else {
          inhalationOutdoorClass[2] = false
        }
        break;
      case inhalationOutdoorArr[3]:
        if (value != δair) {
          inhalationOutdoorClass[3] = true
        } else {
          inhalationOutdoorClass[3] = false
        }
        break;
      case throughMouthArr[0]:
        if (value != OSIRa) {
          throughMouthClass[0] = true
        } else {
          throughMouthClass[0] = false
        }
        break;
      case throughMouthArr[1]:
        if (value != OSIRc) {
          throughMouthClass[1] = true
        } else {
          throughMouthClass[1] = false
        }
        break;
      case throughSkinArr[0]:
        if (value != Ha) {
          throughSkinClass[0] = true
        } else {
          throughSkinClass[0] = false
        }
        break;
      case throughSkinArr[1]:
        if (value != SERc) {
          throughSkinClass[1] = true
        } else {
          throughSkinClass[1] = false
        }
        break;
      case throughSkinArr[2]:
        if (value != Hc) {
          throughSkinClass[2] = true
        } else {
          throughSkinClass[2] = false
        }
        break;
      case throughSkinArr[3]:
        if (value != SSARa) {
          throughSkinClass[3] = true
        } else {
          throughSkinClass[3] = false
        }
        break;
      case throughSkinArr[4]:
        if (value != Ev) {
          throughSkinClass[4] = true
        } else {
          throughSkinClass[4] = false
        }
        break;
      case throughSkinArr[5]:
        if (value != SSARc) {
          throughSkinClass[5] = true
        } else {
          throughSkinClass[5] = false
        }
        break;
      case throughSkinArr[6]:
        if (value != SERa) {
          throughSkinClass[6] = true
        } else {
          throughSkinClass[6] = false
        }
        break;
      case particulateMatterArr[0]:
        if (value != PM10) {
          particulateMatterClass[0] = true
        } else {
          particulateMatterClass[0] = false
        }
        break;
      case particulateMatterArr[1]:
        if (value != fspo) {
          particulateMatterClass[1] = true
        } else {
          particulateMatterClass[1] = false
        }
        break;
      case particulateMatterArr[2]:
        if (value != fspi) {
          particulateMatterClass[2] = true
        } else {
          particulateMatterClass[2] = false
        }
        break;
      case particulateMatterArr[3]:
        if (value != PIAF) {
          particulateMatterClass[3] = true
        } else {
          particulateMatterClass[3] = false
        }
        break;
      case waterArr[0]:
        if (value != GWCRa) {
          waterClass[0] = true
        } else {
          waterClass[0] = false
        }
        break;
      case waterArr[1]:
        if (value != GWCRc) {
          waterClass[1] = true
        } else {
          waterClass[1] = false
        }
        break;
      default:
        break;
    }

    activeClass['area'] = areaClass
    activeClass['soil'] = soilClass
    activeClass['base'] = baseClass
    activeClass['inhalationIndoor'] = inhalationIndoorClass
    activeClass['inhalationOutdoor'] = inhalationOutdoorClass
    activeClass['throughMouth'] = throughMouthClass
    activeClass['throughSkin'] = throughSkinClass
    activeClass['particulateMatter'] = particulateMatterClass
    activeClass['water'] = waterClass
    this.setState({
      activeClass
    })
    console.log("activeClass", activeClass);

    // let baseArr = ['Ugw','δgw','I','DF']
  }
  render() {
    return (
      <div className="ps-content">
        <Spin spinning={this.state.loading}>
          <Tabs
            type="card"
            defaultActiveKey='1'
          >
            <TabPane tab="通用参数" key="1" forceRender={true} className="tabPane_padd">
              {/* <div>

                <Row gutter={24}>
                  <Col span={8} >
                    <Form.Item
                      label="敏感度分析调整系数：(%)"
                      name="adjustment"
                      rules={[{ required: true, message: '必须输入一个参数' }]}

                    >
                      <Input type="number"
                        defaultValue={this.state.adjustment}
                        onChange={this.adjustmentChange}></Input>
                    </Form.Item>

                  </Col>
                </Row>
              
              </div> */}
              <Form ref={this.state.formActive} layout="vertical" preserve={false} wrapperCol={{ span: 18 }}>
                <Row gutter={24}>

                  <Col span={8} className="relative_active">

                    <Form.Item label="敏感度分析调整系数：(%)"
                      name="adjustment" rules={[{ required: true, message: '必须输入一个参数' }]}>
                      <Input type="number" onChange={this.adjustmentChange}></Input>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <div className="setting">
                <div className="modeliconleft">污染区参数</div>
                <div className="modeliconright">
                  <div className="edit" onClick={() => this.retrunlast(1)}>
                    <img src={require('../../../assets/images/paramSetting/return.png').default} className="editimg"></img>
                    <span className="editext">恢复默认</span>
                  </div>
                </div>
              </div>
              <Divider></Divider>
              <Form ref={this.state.formOne} layout="vertical" preserve={false} wrapperCol={{ span: 18 }}>
                <Row gutter={24}>

                  <Col span={8} className="relative_active">

                    <Checkbox className="Checkbox_active"    checked={JSON.parse(this.state.areaArrList.common.area.d.toLowerCase())} name="d" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="d表层污染土壤层厚度:(cm)" name="d" className={`${this.state.activeClass.area[0] ? 'blueClass' : ''}`}>
                      <Input type="number" disabled={true} onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>

                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active"  name="dsub" checked={JSON.parse(this.state.areaArrList.common.area.dsub.toLowerCase())} onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="dsub下层污染土壤层厚度:(cm)" name="dsub" className={`${this.state.activeClass.area[1] ? 'blueClass' : ''}`}>
                      <Input type="number" disabled={true} onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>

                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active"  checked={JSON.parse(this.state.areaArrList.common.area.LS.toLowerCase())} name="LS" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="LS下层污染土壤层埋深:(cm)" name="LS" className={`${this.state.activeClass.area[2] ? 'blueClass' : ''}`}>
                      <Input type="number" disabled={true} onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>

                </Row>
                <Row gutter={24}>

                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" name="lgw" checked={JSON.parse(this.state.areaArrList.common.area.lgw.toLowerCase())} onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="lgw地下水埋深:(cm)" name="lgw"
                    // className={`${this.state.activeClass.area[3] ? 'blueClass' : 'redClass'}`} 
                    >
                      <Input type="number" disabled={true} onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <div className="setting">
                <div className="modeliconleft">土壤参数</div>
                <div className="modeliconright">
                  <div className="edit" onClick={() => this.retrunlast(2)}>
                    <img src={require('../../../assets/images/paramSetting/return.png').default} className="editimg"></img>
                    <span className="editext">恢复默认</span>
                  </div>
                </div>
              </div>
              <Divider></Divider>
              <Form ref={this.state.formTwo} layout="vertical" wrapperCol={{ span: 18 }}>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.soil.fom.toLowerCase())} name="fom" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="fom土壤有机质含量:(g/kg)" name="fom" className={`${this.state.activeClass.soil[0] ? 'blueClass' : ''}`}>
                      <Input type="number" disabled={true} onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.soil.hcap.toLowerCase())} name="hcap" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      label="hcap土壤地下水交界处毛管层厚度:(cm)"
                      name="hcap"
                      className={`${this.state.activeClass.soil[1] ? 'blueClass' : ''}`}
                    >
                      <Input type="number" disabled={true} onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.soil.ρb.toLowerCase())} name="ρb" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="ρb土壤容重:(kg/dm³)" name="ρb" className={`${this.state.activeClass.soil[2] ? 'blueClass' : ''}`}>
                      <Input type="number" disabled={true} onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.soil.hv.toLowerCase())} name="hv" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="hv非饱和土壤厚度:(cm)" name="hv">
                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.soil.Pws.toLowerCase())} name="Pws" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="Pws土壤含水率:(kg/kg)" name="Pws" className={`${this.state.activeClass.soil[4] ? 'blueClass' : ''}`}>
                      <Input type="number" disabled={true} onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.soil.θacap.toLowerCase())} name="θacap" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="θacap毛细管层孔隙空气体积比:" name="θacap" className={`${this.state.activeClass.soil[5] ? 'blueClass' : ''}`}>
                      <Input type="number" disabled={true} onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.soil.ρs.toLowerCase())} name="ρs" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="ρs土壤颗粒密度:(kg/dm³)" name="ρs" className={`${this.state.activeClass.soil[6] ? 'blueClass' : ''}`}>
                      <Input type="number" disabled={true} onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.soil.θwcap.toLowerCase())} name="θwcap" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="θwcap毛细管层孔隙水体积比:" name="θwcap" className={`${this.state.activeClass.soil[7] ? 'blueClass' : ''}`}>
                      <Input type="number" disabled={true} onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

              <div className="setting">
                <div className="modeliconleft">暴露参数</div>
                <div className="modeliconright">
                  <div className="edit" onClick={() => this.setEdit()}>
                    <img src={require('../../../assets/images/paramSetting/edit.png').default} className="editimg"></img>
                    <span className="editext">{this.state.setformdiable ? '编辑' : '保存'}</span>
                  </div>
                  <div className="edit" onClick={() => this.retrunlast(3)}>
                    <img src={require('../../../assets/images/paramSetting/return.png').default} className="editimg"></img>
                    <span className="editext">恢复默认</span>
                  </div>
                </div>
              </div>
              <Divider></Divider>
              <Form ref={this.state.formThree} layout="vertical" wrapperCol={{ span: 18 }}>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.exposure.EDa.toLowerCase())} name="EDa" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="EDa成人暴露期:(a)" name="EDa">
                      <Input disabled={true} type="number"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.exposure.DAIRc.toLowerCase())} name="DAIRc" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      label="DAIRc儿童每日空气呼吸量:(m³/d)"
                      name="DAIRc"
                    >
                      <Input disabled={true} type="number"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.exposure.EDc.toLowerCase())} name="EDc" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="EDc儿童暴露期:(a)" name="EDc">
                      <Input disabled={true} type="number"></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.exposure.SAF.toLowerCase())} name="SAF" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      label="SAF暴露于土壤的参考剂量分配比例(SVOCs和重金属):"
                      name="SAF"
                    >
                      <Input disabled={true} type="number"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.exposure.EFa.toLowerCase())} name="EFa" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="EFa成人暴露频率:(d/a)" name="EFa">
                      <Input disabled={true} type="number"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.exposure.WAF.toLowerCase())} name="WAF" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      label="WAF暴露于地下水的参考剂量分配比例(SVOCs和重金属):"
                      name="WAF"
                    >
                      <Input disabled={true} type="number"></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.exposure.EFc.toLowerCase())} name="EFc" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="EFc儿童暴露频率:(d/a)" name="EFc">
                      <Input disabled={true} type="number"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.exposure.SAFVOCS.toLowerCase())} name="SAFVOCS" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      label="SAF暴露于土壤的参考剂量分配比例(VOCs):"
                      name="SAFVOCS"
                    >
                      <Input disabled={true} type="number"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.exposure.EFIa.toLowerCase())} name="EFIa" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="EFIa成人室内暴露频率:(d/a)" name="EFIa">
                      <Input disabled={true} type="number"></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.exposure.WAFVOCS.toLowerCase())} name="WAFVOCS" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      label="WAF暴露于地下水的参考剂量分配比例(VOCS):"
                      name="WAFVOCS"
                    >
                      <Input disabled={true} type="number"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.exposure.EFIc.toLowerCase())} name="EFIc" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="EFIc儿童室内暴露频率:(d/a)" name="EFIc">
                      <Input disabled={true} type="number"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.exposure.ABSo.toLowerCase())} name="ABSo" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="ABSo经口摄入吸收因子" name="ABSo">
                      <Input disabled={true} type="number"></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.exposure.EFOa.toLowerCase())} name="EFOa" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="EFOa成人室外暴露频率:(d/a)" name="EFOa">
                      <Input disabled={true} type="number"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.exposure.ACR.toLowerCase())} name="ACR" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="ACR单一污染物可接受致癌风险:" name="ACR">
                      <Input disabled={true} type="number"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.exposure.EFOc.toLowerCase())} name="EFOc" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="EFOc儿童室外暴露频率:(d/a)" name="EFOc">
                      <Input disabled={true} type="number"></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.exposure.AHQ.toLowerCase())} name="AHQ" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="AHQ单一污染物可接受危害熵:" name="AHQ">
                      <Input disabled={true} type="number"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.exposure.BWa.toLowerCase())} name="BWa" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="BWa成人平均体重:(kg)" name="BWa">
                      <Input disabled={true} type="number"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.exposure.ATca.toLowerCase())} name="ATca" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="ATca致癌效应平均时间:(d)" name="ATca">
                      <Input disabled={true} type="number"></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.exposure.BWc.toLowerCase())} name="BWc" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="BWc儿童平均体重:(kg)" name="BWc">
                      <Input disabled={true} type="number"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.exposure.ATnc.toLowerCase())} name="ATnc" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="ATnc非致癌效应平均时间:(d)" name="ATnc">
                      <Input disabled={true} type="number"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.common.exposure.DAIRa.toLowerCase())} name="DAIRa" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      label="DAIRa成人每日空气呼吸量:(m³/d)"
                      name="DAIRa"
                    >
                      <Input disabled={true} type="number"></Input>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </TabPane>
            <TabPane tab="土壤淋溶至地下水" key="2" forceRender={true} className="tabPane_padd">
              <div className="setting">
                <div></div>
                <div className="modeliconright">
                  <div className="edit" onClick={() => this.retrunlast(4)}>
                    <img src={require('../../../assets/images/paramSetting/return.png').default} className="editimg"></img>
                    <span className="editext">恢复默认</span>
                  </div>
                </div>
              </div>
              <Divider></Divider>
              <Form ref={this.state.formFour} layout="vertical" wrapperCol={{ span: 18 }}>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.leaching.base.Ugw.toLowerCase())} name="Ugw" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="Ugw地下水(Darcy)速率:(cm/a)" name="Ugw" className={`${this.state.activeClass.base[0] ? 'blueClass' : ''}`}>
                      <Input type="number" disabled={true} onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.leaching.base.δgw.toLowerCase())} name="δgw" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      label="地下水混合区厚度:(cm)"
                      name="δgw"
                      className={`${this.state.activeClass.base[1] ? 'blueClass' : ''}`}
                    >
                      <Input type="number" disabled={true} onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.leaching.base.I.toLowerCase())} name="I" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="土壤中水的入渗速率:(cm/a)" name="I" className={`${this.state.activeClass.base[2] ? 'blueClass' : ''}`}>
                      <Input type="number" disabled={true} onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.leaching.base.DF.toLowerCase())} name="DF" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="地下水迁移稀释因子" name="DF" className={`${this.state.activeClass.base[3] ? 'blueClass' : ''}`}>
                      <Input type="number" disabled={true} onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </TabPane>
            <TabPane tab="模型参数" key="3" forceRender={true} className="tabPane_padd">
              <div className="setting">

                <div className="modeliconleft">吸入室内蒸汽HJ25.3模型参数</div>
                {
                  !this.state.setformFive ? (
                    <div className="modeliconright">
                      <div className="edit" onClick={() => this.retrunlast(5)}>
                        <img src={require('../../../assets/images/paramSetting/return.png').default} className="editimg"></img>
                        <span className="editext">恢复默认</span>
                      </div>
                    </div>
                  ) : ''
                }
              </div>
              <Divider></Divider>
              <Form ref={this.state.formFive} layout="vertical" wrapperCol={{ span: 18 }}>
                <Row gutter={24}>


                  <Col span={8} className="relative_active">

                    <Checkbox className="Checkbox_active"
                      checked={JSON.parse(this.state.areaArrList.model.inhalationIndoor.θacrack.toLowerCase())}
                      name="θacrack" onChange={this.checkIsChangeCheck}></Checkbox>


                    <Form.Item
                      label="θacrack地基裂隙中空气体积比:"
                      name="θacrack"
                      className={`${this.state.activeClass.inhalationIndoor[0] ? 'blueClass' : ''}`}
                    >

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>

                  </Col>

                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.inhalationIndoor.τ.toLowerCase())} name="τ" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="τ气态污染物入侵持续时间:(a)" name="τ"
                      className={`${this.state.activeClass.inhalationIndoor[1] ? 'blueClass' : ''}`}>

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.inhalationIndoor.θwcrack.toLowerCase())} name="θwcrack" onChange={this.checkIsChangeCheck}></Checkbox>

                    <Form.Item
                      label="θwcrack地基裂隙中水体积比:(cm)"
                      name="θwcrack"
                      className={`${this.state.activeClass.inhalationIndoor[2] ? 'blueClass' : ''}`}
                    >

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.inhalationIndoor.dP.toLowerCase())} name="dP" onChange={this.checkIsChangeCheck}></Checkbox>

                    <Form.Item label="dP室内室外气压差:(g/cm.s-2)" name="dP"
                      className={`${this.state.activeClass.inhalationIndoor[3] ? 'blueClass' : ''}`}>


                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.inhalationIndoor.Lcrack.toLowerCase())} name="Lcrack" onChange={this.checkIsChangeCheck}></Checkbox>

                    <Form.Item label="Lcrack室内地基厚度:(cm)" name="Lcrack"
                      className={`${this.state.activeClass.inhalationIndoor[4] ? 'blueClass' : ''}`}>


                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.inhalationIndoor.Kv.toLowerCase())} name="Kv" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="Kv土壤透性系数:(cm²)" name="Kv"
                      className={`${this.state.activeClass.inhalationIndoor[5] ? 'blueClass' : ''}`}>


                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.inhalationIndoor.LB.toLowerCase())} name="LB" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      label="LB室内空间体积与气态污染物入渗面积之比:(cm)"
                      name="LB"
                      className={`${this.state.activeClass.inhalationIndoor[6] ? 'blueClass' : ''}`}
                    >


                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.inhalationIndoor.Zcrack.toLowerCase())} name="Zcrack" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      label="Zcrack室内地面到地板底部厚度:(cm)"
                      name="Zcrack"
                      className={`${this.state.activeClass.inhalationIndoor[7] ? 'blueClass' : ''}`}
                    >


                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.inhalationIndoor.ER.toLowerCase())} name="ER" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="ER室内空气交换速率:(次/d)" name="ER"
                      className={`${this.state.activeClass.inhalationIndoor[8] ? 'blueClass' : ''}`}>


                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.inhalationIndoor.Xcrack.toLowerCase())} name="Xcrack" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="Xcrack室内地板周长:(cm)" name="Xcrack"
                      className={`${this.state.activeClass.inhalationIndoor[9] ? 'blueClass' : ''}`}>


                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.inhalationIndoor.η.toLowerCase())} name="η" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="η地基和墙体裂隙表面积所占面积:" name="η"
                      className={`${this.state.activeClass.inhalationIndoor[10] ? 'blueClass' : ''}`}>

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.inhalationIndoor.Ab.toLowerCase())} name="Ab" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item label="Ab室内地板面积:(cm²)" name="Ab"
                      className={`${this.state.activeClass.inhalationIndoor[11] ? 'blueClass' : ''}`}>

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <div className="setting">
                <div className="modeliconleft">吸入室外蒸汽HJ25.3模型参数</div>
                {
                  !this.state.setformSix ? (
                    <div className="modeliconright">
                      <div className="edit" onClick={() => this.retrunlast(6)}>
                        <img src={require('../../../assets/images/paramSetting/return.png').default} className="editimg"></img>
                        <span className="editext">恢复默认</span>
                      </div>
                    </div>
                  ) : ''
                }
              </div>
              <Divider></Divider>
              <Form ref={this.state.formSix} layout="vertical" wrapperCol={{ span: 18 }}>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.inhalationOutdoor.W.toLowerCase())} name="W" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      className={`${this.state.activeClass.inhalationOutdoor[0] ? 'blueClass' : ''}`}
                      label="W污染源区宽度:(cm)" name="W">

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.inhalationOutdoor.Uair.toLowerCase())} name="Uair" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      className={`${this.state.activeClass.inhalationOutdoor[1] ? 'blueClass' : ''}`}
                      label="Uair混合区大气流速风速:(cm/s)" name="Uair">

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.inhalationOutdoor.A.toLowerCase())} name="A" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      className={`${this.state.activeClass.inhalationOutdoor[2] ? 'blueClass' : ''}`}
                      label="A污染源区面积:(cm²)" name="A">

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.inhalationOutdoor.δair.toLowerCase())} name="δair" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      className={`${this.state.activeClass.inhalationOutdoor[3] ? 'blueClass' : ''}`}
                      label="δair混合区高度:(cm)" name="δair">

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <div className="setting">
                <div className="modeliconleft">土壤经口摄入HJ25.3模型参数</div>
                {
                  !this.state.setformSeven ? (
                    <div className="modeliconright">
                      <div className="edit" onClick={() => this.retrunlast(7)}>
                        <img src={require('../../../assets/images/paramSetting/return.png').default} className="editimg"></img>
                        <span className="editext">恢复默认</span>
                      </div>
                    </div>
                  ) : ''
                }
              </div>
              <Divider></Divider>
              <Form ref={this.state.formSeven} layout="vertical" wrapperCol={{ span: 18 }}>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.throughMouth.OSIRa.toLowerCase())} name="OSIRa" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      label="OSIRa成人每日摄入土壤量:(mg/d)"
                      name="OSIRa"
                      className={`${this.state.activeClass.throughMouth[0] ? 'blueClass' : ''}`}
                    >

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.throughMouth.OSIRc.toLowerCase())} name="OSIRc" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      label="OSIRc儿童每日摄入土壤量:(mg/d)"
                      name="OSIRc"
                      className={`${this.state.activeClass.throughMouth[1] ? 'blueClass' : ''}`}
                    >

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <div className="setting">
                <div className="modeliconleft">土壤皮肤摄入HJ25.3模型参数</div>
                {
                  !this.state.setformEight ? (
                    <div className="modeliconright">
                      <div className="edit" onClick={() => this.retrunlast(8)}>
                        <img src={require('../../../assets/images/paramSetting/return.png').default} className="editimg"></img>
                        <span className="editext">恢复默认</span>
                      </div>
                    </div>
                  ) : ''
                }
              </div>
              <Divider></Divider>
              <Form ref={this.state.formEight} layout="vertical" wrapperCol={{ span: 18 }}>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.throughSkin.Ha.toLowerCase())} name="Ha" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      className={`${this.state.activeClass.throughSkin[0] ? 'blueClass' : ''}`}
                      label="Ha成人平均身高:(cm)" name="Ha">

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.throughSkin.SERc.toLowerCase())} name="SERc" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      label="SERc儿童暴露皮肤所占体表面积比:"
                      name="SERc"
                      className={`${this.state.activeClass.throughSkin[1] ? 'blueClass' : ''}`}
                    >
                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.throughSkin.Hc.toLowerCase())} name="Hc" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      className={`${this.state.activeClass.throughSkin[2] ? 'blueClass' : ''}`}
                      label="Hc儿童平均身高:(cm)" name="Hc">

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.throughSkin.SSARa.toLowerCase())} name="SSARa" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      label="SSARa成人皮肤表面土壤粘附系数:(mg/cm²)"
                      name="SSARa"
                      className={`${this.state.activeClass.throughSkin[3] ? 'blueClass' : ''}`}
                    >

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.throughSkin.Ev.toLowerCase())} name="Ev" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      className={`${this.state.activeClass.throughSkin[4] ? 'blueClass' : ''}`}
                      label="Ev每日皮肤接触事件频率:(次/d)"
                      name="Ev">

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.throughSkin.SSARc.toLowerCase())} name="SSARc" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      label="SSARc儿童皮肤表面土壤粘附系数:(mg/cm²)"
                      name="SSARc"
                      className={`${this.state.activeClass.throughSkin[5] ? 'blueClass' : ''}`}
                    >

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.throughSkin.SERa.toLowerCase())} name="SERa" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      label="SERa成人暴露皮肤所占体表面积比:"
                      name="SERa"
                      className={`${this.state.activeClass.throughSkin[6] ? 'blueClass' : ''}`}
                    >

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <div className="setting">
                <div className="modeliconleft">土壤吸入颗粒物HJ25.3模型参数</div>
                {
                  !this.state.setformNine ? (
                    <div className="modeliconright">
                      <div className="edit" onClick={() => this.retrunlast(9)}>
                        <img src={require('../../../assets/images/paramSetting/return.png').default} className="editimg"></img>
                        <span className="editext">恢复默认</span>
                      </div>
                    </div>
                  ) : ''
                }
              </div>
              <Divider></Divider>
              <Form ref={this.state.formNine} layout="vertical" wrapperCol={{ span: 18 }}>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.particulateMatter.PM10.toLowerCase())} name="PM10" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      label="PM10空气中可吸入颗粒物含量:(mg/m³)"
                      name="PM10"
                      className={`${this.state.activeClass.particulateMatter[0] ? 'blueClass' : ''}`}
                    >

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.particulateMatter.fspo.toLowerCase())} name="fspo" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      label="fspo室外空气中来自土壤的颗粒物比例:"
                      name="fspo"
                      className={`${this.state.activeClass.particulateMatter[1] ? 'blueClass' : ''}`}
                    >

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.particulateMatter.fspi.toLowerCase())} name="fspi" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      label="fspi室内空气中来自土壤的颗粒物所占比例:"
                      name="fspi"
                      className={`${this.state.activeClass.particulateMatter[2] ? 'blueClass' : ''}`}
                    >

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.particulateMatter.PIAF.toLowerCase())} name="PIAF" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      label="PIAF吸入土壤颗粒物在体内滞留比例:"
                      name="PIAF"
                      className={`${this.state.activeClass.particulateMatter[3] ? 'blueClass' : ''}`}
                    >

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <div className="setting">
                <div className="modeliconleft">地下水饮用HJ25.3模型参数</div>
                {
                  !this.state.setformTen ? (
                    <div className="modeliconright">
                      <div className="edit" onClick={() => this.retrunlast(10)}>
                        <img src={require('../../../assets/images/paramSetting/return.png').default} className="editimg"></img>
                        <span className="editext">恢复默认</span>
                      </div>
                    </div>
                  ) : ''
                }
              </div>
              <Divider></Divider>
              <Form ref={this.state.formTen} layout="vertical" wrapperCol={{ span: 18 }}>
                <Row gutter={24}>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.water.GWCRa.toLowerCase())} name="GWCRa" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      className={`${this.state.activeClass.water[0] ? 'blueClass' : ''}`}
                      label="GWCRa成人每日饮用水量:(L/d)" name="GWCRa">

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>

                    </Form.Item>
                  </Col>
                  <Col span={8} className="relative_active">
                    <Checkbox className="Checkbox_active" checked={JSON.parse(this.state.areaArrList.model.water.GWCRc.toLowerCase())} name="GWCRc" onChange={this.checkIsChangeCheck}></Checkbox>
                    <Form.Item
                      className={`${this.state.activeClass.water[1] ? 'blueClass' : ''}`}
                      label="GWCRc儿童每日饮用水量:(L/d)" name="GWCRc">

                      <Input disabled={true} type="number" onChange={this.checkIsChange}></Input>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </TabPane>
          </Tabs>
          <div className="exposebtn">
            <button className="formbtnleft" onClick={this.gotoTap.bind(this, 'back')}>上一步</button>
            <button className="formbtnright" onClick={this.gotoTap.bind(this, 'next')}>下一步</button>
          </div>
        </Spin>
      </div>
    );
  }
}

export default getlnitParamsValue;
