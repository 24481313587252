import React, { Component } from 'react';
import './lineImg.less'
import {getModelStatus} from '../../apis/common';
import {checkProductd,checkDepthid} from '../../configs/checkProductId.js'
class Model extends Component{
    constructor(props){
        super(props)
        this.state={
            project_id:'',
            depth_id:'',
            selectArr:[false,false,false,false,false,false,false,false,false]
        }
    }
    componentDidMount(){
        this.getModelStatus()
    }
 
    componentWillMount(){
        let productId=checkProductd();
        if(productId){
          this.setState({
            project_id:productId
          })
        }
        let Depthid = checkDepthid();
        if (Depthid) {
          this.setState({
            depth_id: Depthid,
          })
        }
    }
    getModelStatus=async()=>{
        let selectArr= [];
        await getModelStatus({
          project_id:this.state.project_id,
          depth_id: this.state.depth_id,
        }).then((res)=>{
            let soil = res.data.soil;
            let water = res.data.water;
            soil.forEach(element => {
                selectArr.push(Boolean(element.selected))
            });
            water.forEach(element => {
                selectArr.push(Boolean(element.selected))
            });
            this.setState({
                selectArr
            })
        }).catch((err)=>{
            console.log(err)
        })
    }
    render(){
        return (
            <div className="linecontent">
                <div className="lineImg">
                    {/* 第一列 */}
                    <img  style={{visibility:(this.state.selectArr[0]|| this.state.selectArr[1])?'visible':'hidden'}} src={require('../../assets/images/lineImg/one.png').default} className="oneClass"/>
                    <img  style={{visibility:(this.state.selectArr[0]|| this.state.selectArr[1])?'hidden':'visible'}} src={require('../../assets/images/lineImg/dot/onedot.png').default} className="oneClass"/>

                    <img  style={{visibility:this.state.selectArr[2]?'visible':'hidden'}} src={require('../../assets/images/lineImg/two.png').default} className="twoClass"/>
                    <img  style={{visibility:this.state.selectArr[2]?'hidden':'visible'}} src={require('../../assets/images/lineImg/dot/twodot.png').default} className="twoClass"/>

                    <img  style={{visibility:this.state.selectArr[3]?'visible':'hidden'}} src={require('../../assets/images/lineImg/three.png').default} className="threeClass"/>
                    <img  style={{visibility:this.state.selectArr[3]?'hidden':'visible'}} src={require('../../assets/images/lineImg/dot/threedot.png').default} className="threeClass"/>

                    <img  style={{visibility:(this.state.selectArr[4]||this.state.selectArr[5])?'visible':'hidden'}} src={require('../../assets/images/lineImg/four.png').default} className="fourClass"/>
                    <img  style={{visibility:(this.state.selectArr[4]||this.state.selectArr[5])?'hidden':'visible'}} src={require('../../assets/images/lineImg/dot/fourdot.png').default} className="fourClass"/>

                    <img  style={{visibility:(this.state.selectArr[6]||this.state.selectArr[7])?'visible':'hidden'}} src={require('../../assets/images/lineImg/five.png').default} className="fiveClass"/>
                    <img  style={{visibility:(this.state.selectArr[6]||this.state.selectArr[7])?'hidden':'visible'}} src={require('../../assets/images/lineImg/dot/fivedot.png').default} className="fiveClass"/>

                    <img  style={{visibility:this.state.selectArr[8]?'visible':'hidden'}} src={require('../../assets/images/lineImg/six.png').default} className="sixClass"/>
                    <img  style={{visibility:this.state.selectArr[8]?'hidden':'visible'}} src={require('../../assets/images/lineImg/dot/sixdot.png').default} className="sixClass"/>
                    {/* 第一列 */}
                    {/* 第二列 */}
                    <img  style={{visibility:this.state.selectArr[0]?'visible':'hidden'}} src={require('../../assets/images/lineImg/seven.png').default} className="sevenClass"/>
                    <img  style={{visibility:this.state.selectArr[0]?'hidden':'visible'}} src={require('../../assets/images/lineImg/dot/sevendot.png').default} className="sevenClass"/>

                    <img  style={{visibility:this.state.selectArr[1]?'visible':'hidden'}} src={require('../../assets/images/lineImg/eight.png').default} className="eightClass"/>
                    <img  style={{visibility:this.state.selectArr[1]?'hidden':'visible'}} src={require('../../assets/images/lineImg/dot/eightdot.png').default} className="eightClass"/>

                    <img  style={{visibility:this.state.selectArr[2]?'visible':'hidden'}} src={require('../../assets/images/lineImg/nine.png').default} className="nineClass"/>
                    <img  style={{visibility:this.state.selectArr[2]?'hidden':'visible'}} src={require('../../assets/images/lineImg/dot/ninedot.png').default} className="nineClass"/>

                    <img  style={{visibility:(this.state.selectArr[3])?'visible':'hidden'}} src={require('../../assets/images/lineImg/ten.png').default} className="tenClass"/>
                    <img  style={{visibility:(this.state.selectArr[3])?'hidden':'visible'}} src={require('../../assets/images/lineImg/dot/tendot.png').default} className=" tenClassdot"/>

                    <img  style={{visibility:(this.state.selectArr[4]||this.state.selectArr[5])?'visible':'hidden'}} src={require('../../assets/images/lineImg/eleven.png').default} className="elevenClass"/>
                    <img  style={{visibility:(this.state.selectArr[4]||this.state.selectArr[5])?'hidden':'visible'}} src={require('../../assets/images/lineImg/dot/elevendot.png').default} className="elevenClass"/>

                    <img  style={{visibility:(this.state.selectArr[6]||this.state.selectArr[7])?'visible':'hidden'}} src={require('../../assets/images/lineImg/twelve.png').default} className="twelveClass"/>
                    <img  style={{visibility:(this.state.selectArr[6]||this.state.selectArr[7])?'hidden':'visible'}} src={require('../../assets/images/lineImg/dot/twelvedot.png').default} className="twelveClass"/>

                    <img  style={{visibility:(this.state.selectArr[8])?'visible':'hidden'}} src={require('../../assets/images/lineImg/thirteen.png').default} className="thirteenClass"/>
                    <img  style={{visibility:(this.state.selectArr[8])?'hidden':'visible'}} src={require('../../assets/images/lineImg/dot/thirteendot.png').default} className="thirteenClass"/>
                    {/* 第二列 */}


                    <img  style={{visibility:this.state.selectArr[0]?'visible':'hidden'}} src={require('../../assets/images/lineImg/fourteen.png').default} className="fourteenClass"/>
                    <img  style={{visibility:this.state.selectArr[0]?'hidden':'visible'}} src={require('../../assets/images/lineImg/dot/fourteendot.png').default} className="fourteenClass"/>

                    <img  style={{visibility:this.state.selectArr[1]?'visible':'hidden'}} src={require('../../assets/images/lineImg/fourteen.png').default} className="fifteenClass"/>
                    <img  style={{visibility:this.state.selectArr[1]?'hidden':'visible'}} src={require('../../assets/images/lineImg/dot/fourteendot.png').default} className="fifteenClass"/>
                    
                    <img  style={{visibility:this.state.selectArr[2]?'visible':'hidden'}} src={require('../../assets/images/lineImg/fourteen.png').default} className="sixteenClass"/>
                    <img  style={{visibility:this.state.selectArr[2]?'hidden':'visible'}} src={require('../../assets/images/lineImg/dot/fourteendot.png').default} className="sixteenClass"/>

                    <img  style={{visibility:this.state.selectArr[3]?'visible':'hidden'}} src={require('../../assets/images/lineImg/seventeen.png').default} className="seventeenClass"/>
                    <img  style={{visibility:this.state.selectArr[3]?'hidden':'visible'}} src={require('../../assets/images/lineImg/dot/seventeendot.png').default} className="seventeenClass"/>

                    <img  style={{visibility:this.state.selectArr[4]?'visible':'hidden'}} src={require('../../assets/images/lineImg/eighteen.png').default} className="eighteenClass"/>
                    <img  style={{visibility:this.state.selectArr[4]?'hidden':'visible'}} src={require('../../assets/images/lineImg/dot/eighteendot.png').default} className="eighteenClass"/>

                    <img  style={{visibility:this.state.selectArr[5]?'visible':'hidden'}} src={require('../../assets/images/lineImg/nineteen.png').default} className="nineteenClass"/>
                    <img  style={{visibility:this.state.selectArr[5]?'hidden':'visible'}} src={require('../../assets/images/lineImg/dot/nineteendot.png').default} className="nineteenClass"/>

                    <img  style={{visibility:this.state.selectArr[6]?'visible':'hidden'}} src={require('../../assets/images/lineImg/twenty.png').default} className="twentyClass"/>
                    <img  style={{visibility:this.state.selectArr[6]?'hidden':'visible'}} src={require('../../assets/images/lineImg/dot/twentydot.png').default} className="twentyClass"/>

                    <img  style={{visibility:this.state.selectArr[7]?'visible':'hidden'}} src={require('../../assets/images/lineImg/twentyone.png').default} className="twentyoneClass"/>
                    <img  style={{visibility:this.state.selectArr[7]?'hidden':'visible'}} src={require('../../assets/images/lineImg/dot/twentyonedot.png').default} className="twentyoneClass"/>

                    <img  style={{visibility:this.state.selectArr[8]?'visible':'hidden'}} src={require('../../assets/images/lineImg/fourteen.png').default} className="twentytwoClass"/>
                    <img  style={{visibility:this.state.selectArr[8]?'hidden':'visible'}} src={require('../../assets/images/lineImg/dot/fourteendot.png').default} className="twentytwoClass"/>
                </div>
            </div>
        )
    }
}
export default Model