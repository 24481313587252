
import axios from 'axios'
// import { hashHistory } from 'react-router'
import { timeout, baseURL } from './config'
import { message } from 'antd'
import {store} from '../reducers/index';
import { getToken } from '../utils/token';
import config from '../configs/index';
let baseConfig = {
  url: '/',
  method: 'post', // default
  baseURL: '',
  params: {
  },
  data: {
  },
  timeout: '',
  withCredentials: true, // default
  responseType: 'json', // default
  maxContentLength: 2000,
  validateStatus(status) {
    return status >= 200 && status < 300 // default
  },
}
baseConfig = { ...baseConfig, timeout: timeout, baseURL: baseURL }

axios.interceptors.request.use(function(config){

  const token = getToken();
  console.log("携带token", token, config);
  if (token) {
    console.log("有",token);
    config.headers.token = token

  }
  return config;
},function(error){
  console.log("请求拦截",error);
  return Promise.reject(error)
 

})
export const oftenFetchByPost = (api, options) => {
  // 当api参数为createApi创建的返回值
  if (typeof api === 'function') return api
  return (...rest) => { 
    // 参数分析
    const data = rest[0] || {}
    return new Promise ((resolve,reject)=>{
      axios({
        ...baseConfig, ...options, url: api, data,
        headers: {
          'Content-Type': 'application/json',
          'token':getToken()
        },
      })
        .then(response => response.data)
        .then((response) => {
          switch (response.code) {
            case 0: { 
              resolve(response);
              break 
            }
            case 20001: { 
              store.dispatch({type: 'SET_USER_LOGOUT', plalod: {}});
              window.location.href = `${
                window.location.origin
              }${config.BASENAME}/login?redirectURL=${encodeURIComponent(window.location.href)}`;
              break 
            }
            default: {
              reject()
              message.warning(response.msg)
              break
            }
          }
        })
        .catch((e) => {
          console.log(e,'response')
          reject(e)
        })
    })
  }
}

// 创建发起api的启动器
export const createApi = function (api, options) {
  return oftenFetchByPost(`${api}`, options)
}

