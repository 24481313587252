import React, { Component } from 'react';
import './lineChart.less'
import {getModelStatus} from '../../apis/common';
import {checkProductd,checkDepthid} from '../../configs/checkProductId.js'
class Model extends Component{
    constructor(props){
        super(props)
        this.state={
            project_id:'',
            depth_id:'',
            selectArr:[false,false,false,false,false,false,false,false,false]
        }
    }
    componentDidMount(){
        this.getModelStatus()
    }
 
    componentWillMount(){
        let productId=checkProductd();
        if(productId){
          this.setState({
            project_id:productId
          })
        }
        let Depthid = checkDepthid();
        if (Depthid) {
          this.setState({
            depth_id: Depthid,
          })
        }
    }
    getModelStatus=async()=>{
        let selectArr= [];
        await getModelStatus({
          project_id:this.state.project_id,
          depth_id: this.state.depth_id,

        }).then((res)=>{
            let soil = res.data.soil;
            let water = res.data.water;
            soil.forEach(element => {
                selectArr.push(Boolean(element.selected))
            });
            water.forEach(element => {
                selectArr.push(Boolean(element.selected))
            });
            this.setState({
                selectArr
            })
        }).catch((err)=>{
            console.log(err)
        })
    }
    render(){
        return (
            <div className="lineChart" style={{display:(this.state.selectArr[0]||this.state.selectArr[1]||this.state.selectArr[2]||this.state.selectArr[3]||this.state.selectArr[4]||this.state.selectArr[5]||this.state.selectArr[6]||this.state.selectArr[7]||this.state.selectArr[8])?'':'none'}}> 
                <div className="linelist">
                    <div className="lineblue">污染源</div>
                    <div className="toplineopcity" style={{visibility:(this.state.selectArr[0]||this.state.selectArr[1]||this.state.selectArr[2]||this.state.selectArr[3])?'visible':'hidden'}}>表层土中污染物</div>
                    <div className="lineopcity" style={{visibility:(this.state.selectArr[4]||this.state.selectArr[5])?'visible':'hidden'}}>下层土中污染物</div>
                    <div className="lineopcity" style={{visibility:(this.state.selectArr[6]||this.state.selectArr[7]||this.state.selectArr[8])?'visible':'hidden'}}>地下水中污染物</div>
                    <img style={{visibility:(this.state.selectArr[0]|| this.state.selectArr[1])?'visible':'hidden'}} src={require('../../assets/images/lineChart/zhijie.svg').default} className="zhijie"/>
                    <img style={{visibility:this.state.selectArr[2]?'visible':'hidden'}} src={require('../../assets/images/lineChart/yangchen.svg').default} className="yangchen"/>
                    <img style={{visibility:this.state.selectArr[3]?'visible':'hidden'}} src={require('../../assets/images/lineChart/chart.svg').default} className="chart"/>
                    <img style={{visibility:(this.state.selectArr[4]||this.state.selectArr[5])?'visible':'hidden'}} src={require('../../assets/images/lineChart/kuosan.svg').default} className="kuosan1"/>

                    <img style={{visibility:(this.state.selectArr[6]||this.state.selectArr[7])?'visible':'hidden'}} src={require('../../assets/images/lineChart/huifa.svg').default} className="huifa"/>

                    <img style={{visibility:this.state.selectArr[8]?'visible':'hidden'}} src={require('../../assets/images/lineChart/kuosan.svg').default} className="kuosan2"/>
                   
                    
                </div>
                <div className="linelist">
                    <div className="lineblue">路径</div>
                </div>
                <div className="linelist">
                    <div className="lineblue">暴露介质</div>
                    <div style={{visibility:(this.state.selectArr[0]|| this.state.selectArr[1])?'visible':'hidden'}} className="toplineopcity">土壤</div>
                    <div className="lineopcity" style={{visibility:(this.state.selectArr[2]||this.state.selectArr[3]||this.state.selectArr[4]||this.state.selectArr[5]||this.state.selectArr[6]||this.state.selectArr[7])?'visible':'hidden'}}>空气</div>
                    <div className="lineopcity" style={{visibility:(this.state.selectArr[8])?'visible':'hidden'}}>地下水</div>
                    <div className="box turang1" style={{visibility:this.state.selectArr[0]?'visible':'hidden'}}>
                        <span className="triangle right"></span>
                    </div>
                    <div className="box turang2" style={{visibility:this.state.selectArr[1]?'visible':'hidden'}}>
                        <span className="triangle right"></span>
                    </div>
                    <div className="box turang3" style={{visibility:(this.state.selectArr[2])?'visible':'hidden'}}>
                        <span className="triangle right"></span>
                    </div>
                    <div className="box turang4" style={{visibility:(this.state.selectArr[3])?'visible':'hidden'}}>
                        <span className="triangle right"></span>
                    </div>
                    <div className="box turang5" style={{visibility:(this.state.selectArr[4]||this.state.selectArr[5])?'visible':'hidden'}}>
                        <span className="triangle right"></span>
                    </div>
                    <div className="box turang6" style={{visibility:(this.state.selectArr[6]||this.state.selectArr[7])?'visible':'hidden'}}>
                        <span className="triangle right"></span>
                    </div>
                    <div className="box turang7" style={{visibility:(this.state.selectArr[8])?'visible':'hidden'}}>
                        <span className="triangle right"></span>
                    </div>
                </div>
                <div className="linelist">
                    <div className="lineblue">途径</div>
                    <div className="linebody">
                        <div style={{visibility:this.state.selectArr[0]?'visible':'hidden'}} className="linebodybtn">经口</div>
                        <div style={{visibility:this.state.selectArr[1]?'visible':'hidden'}} className="linebodybtn">经皮肤</div>
                    </div>
                    <div className="linehuxi">
                        <div className="linebodybtn" style={{visibility:(this.state.selectArr[2])?'visible':'hidden'}}>吸入颗粒物</div>
                        <div className="linebodybtn" style={{visibility:(this.state.selectArr[3])?'visible':'hidden'}}>
                            吸入蒸气来自表层土壤
                        </div>
                        <div className="linebodybtn" style={{visibility:(this.state.selectArr[4]||this.state.selectArr[5])?'visible':'hidden'}}>
                            吸入蒸气来自下层土壤
                        </div>
                        <div className="linebodybtn" style={{visibility:(this.state.selectArr[6]||this.state.selectArr[7])?'visible':'hidden'}}>
                            吸入蒸气来自地下水
                        </div>
                    </div>
                    <div className="linewater" style={{visibility:(this.state.selectArr[8])?'visible':'hidden'}}>
                        <div className="linebodybtn">饮用</div>
                    </div>
                    <div className="box zongjie1" style={{visibility:this.state.selectArr[0]?'visible':'hidden'}}>
                        <span className="triangle right"></span>
                    </div>
                    <div className="box zongjie2" style={{visibility:this.state.selectArr[1]?'visible':'hidden'}}>
                        <span className="triangle right"></span>
                    </div>
                    <div className="box zongjie3" style={{visibility:this.state.selectArr[2]?'visible':'hidden'}}>
                        <span className="triangle right"></span>
                    </div>
                    <div className="box zongjie4" style={{visibility:this.state.selectArr[3]?'visible':'hidden'}}>
                        <span className="triangle right"></span>
                    </div>
                    <div className="box zongjie5" style={{visibility:this.state.selectArr[4]?'visible':'hidden'}}>
                        <span className="triangle right"></span>
                    </div>
                    <div className="box zongjie6" style={{visibility:this.state.selectArr[5]?'visible':'hidden'}}>
                        <span className="triangle right"></span>
                    </div>
                    <div className="box zongjie7" style={{visibility:this.state.selectArr[6]?'visible':'hidden'}}>
                        <span className="triangle right"></span>
                    </div>
                    <div className="box zongjie8" style={{visibility:this.state.selectArr[7]?'visible':'hidden'}}>
                        <span className="triangle right"></span>
                    </div>
                    <div className="box zongjie9" style={{visibility:(this.state.selectArr[8])?'visible':'hidden'}}>
                        <span className="triangle right"></span>
                    </div>
                </div>
                <div className="linelist" style={{visibility:(this.state.selectArr[0]||this.state.selectArr[1]||this.state.selectArr[2]||this.state.selectArr[3]||this.state.selectArr[4]||this.state.selectArr[5]||this.state.selectArr[6]||this.state.selectArr[7]||this.state.selectArr[8])?'visible':'hidden'}}>
                    <div className="lineblue">受体</div>
                    <div className="linecontaner">
                        <div className="lineno">地块上人群</div>
                        <div className="conbtn">
                            室外工作生活
                        </div>
                        <div className="conbtn">
                            室内工作生活
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Model