
export const set = 'set$'
export const brandName = 'React' // slogan

// 开发环境默认配置

// let _serverIp = 'http://120.26.84.237:9527/'
let _serverIp = 'https://doc.crisk.com.cn/' 
let _baseURL = `${_serverIp}`
if (process.env.NODE_ENV === 'testing') { // 测试环境
  _baseURL = `${_serverIp}`
}
if (process.env.NODE_ENV === 'production') { // 发布环境
  _baseURL = `${_serverIp}`
}

export const serverIp = _serverIp
export const path = '/mock'
export const timeout = '100000000' // 接口超时限制(ms)
export const baseURL = _baseURL
