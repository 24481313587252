import React, { memo } from 'react';
import { Redirect } from 'react-router-dom';
import { businessRouteList } from '../router/utils';
import { getToken } from '../utils/token';
import config from '../configs/index';


function checkAuth(props:any): boolean {
  // 判断当前访问路由是否在系统路由中, 不存在直接走最后默认的 404 路由
  const route = businessRouteList.find(child => child.path === props.location.pathname);
  if (!route) {
    return true;
  }

  if (route.redirect) {
    return true;
  }

  if (route.auth === false) {
    return true;
  }
  return false;
}

function Auth(props: any) {
  // 未登录
  if (!getToken()) {
    return (
      <Redirect
        to={`/login?redirectURL=${encodeURIComponent(
          window.location.origin +
            config.BASENAME+
            props.location.pathname+
            props.location.search
        )}`}
      />
    );
  }

  // 检查授权
  // if (!checkAuth(props)) {
  //   return <Redirect to="/403" push />;
  // }

  if (props.route.redirect) {
    return <Redirect to={props.route.redirect!} push />;
  }
  return <>{props.children}</>;
}

export default memo(Auth);
