import './dataTable.less'
import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Popconfirm, Form, Checkbox, Col, Row, Modal, message, Space, Radio } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { getSoilPollutant, listCustomPollutant, getSystemPollutantList, deleteCustomPollutant, updateCustomPollutant, getCriskPollutant40, getCriskPollutant45 } from "../../apis/common";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import LocalStore from '../../utils/store';
import { color } from 'echarts';

// const [modal, contextHolder] = Modal.useModal();
const EditableContext = React.createContext<FormInstance<any> | null>(null);
const { confirm } = Modal;
interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<Input>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  NAME_CN: string;
  NAME_EN: string;
  CAS_CODE: string;
  BIAOZHUN: string;
  TURANG: string;
}

interface EditableTableState {
  dataSource: any;
  selectedRowKeys: any;
  selectRowIds: any;
  isShowtable: boolean,
  viewvisible: boolean,
  editModal: boolean,
  record: any,
  ISKOC: any,
  resetrecord: any
}
interface props {
  type: any,
  upprops: any
}
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

class dataTable extends React.Component<props, EditableTableState> {
  columns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[];
  definition: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[];
  PopEditData: any;
  type: any;
  formRef: any;
  upprops: any
  constructor(props: props) {
    super(props);
    this.formRef = React.createRef();
    this.columns = [
      {
        title: '中文名',
        dataIndex: 'NAME_CN'
      },
      {
        title: '英文名',
        dataIndex: 'NAME_EN',
      },
      {
        title: 'CAS编号',
        dataIndex: 'CAS_CODE',
      }
    ];
    this.definition = [
      {
        title: '中文名',
        dataIndex: 'NAME_CN'
      },
      {
        title: '英文名',
        dataIndex: 'NAME_EN',
      },
      {
        title: 'CAS编号',
        dataIndex: 'CAS_CODE',
      },
      {
        title: '操作',
        dataIndex: 'CAS_CODE',
        render: (text, record, index) => {
          return <span>
            <a style={{ marginRight: 8 }} onClick={() => this.viewline(record)}>查看</a>
            <a style={{ marginRight: 8 }} onClick={() => this.editline(record)}>编辑</a>

            <Popconfirm
              title="确认删除此项？"
              onConfirm={this.deleteThisLine.bind(this, record)}
              okText='确定'
              cancelText='取消'
            >
              <a >删除</a>
            </Popconfirm>
          </span>;
        }
      }
    ];
    // this.popdata = React.createRef()
    this.state = {
      dataSource: [],
      selectedRowKeys: [],
      selectRowIds: '',
      isShowtable: true,
      viewvisible: false,
      editModal: false,
      ISKOC: true,
      record: {
        ABSD: '',
        ABSD_SOURCE: '',
        ABSGI: '',
        ABSGI_SOURCE: '',
        CARBON: '',
        CAS_CODE: '',
        CONTROL_I: '',
        CONTROL_II: '',
        DA: '',
        DA_SOURCE: '',
        DW: '',
        DW_SOURCE: '',
        HSTRIKE: '',
        HSTRIKE_SOURCE: '',
        HYDROGEN: '',
        ID: '',
        ISKOC: '',
        ISKOC_SOURCE: '',
        ISVOC: '',
        IUR: '',
        IUR_SOURCE: '',
        KD: '',
        KOBS: '',
        KOC: '',
        NAME_CN: '',
        NAME_EN: '',
        OXYGEN: '',
        RFC: '',
        RFC_SOURCE: '',
        RFDO: '',
        RFDO_SOURCE: '',
        S: '',
        SCREEN_I: '',
        SCREEN_II: '',
        SFO: '',
        SFO_SOURCE: '',
        STOICHIOMETRIC: '',
        S_SOURCE: '',
        WHETHER_VOC: '',
        is_delete: '',
        key: '',
        userid: ''
      },
      resetrecord: {
        ABSD: '',
        ABSD_SOURCE: '',
        ABSGI: '',
        ABSGI_SOURCE: '',
        CARBON: '',
        CAS_CODE: '',
        CONTROL_I: '',
        CONTROL_II: '',
        DA: '',
        DA_SOURCE: '',
        DW: '',
        DW_SOURCE: '',
        HSTRIKE: '',
        HSTRIKE_SOURCE: '',
        HYDROGEN: '',
        ID: '',
        ISKOC: '',
        ISKOC_SOURCE: '',
        ISVOC: '',
        IUR: '',
        IUR_SOURCE: '',
        KD: '',
        KOBS: '',
        KOC: '',
        NAME_CN: '',
        NAME_EN: '',
        OXYGEN: '',
        RFC: '',
        RFC_SOURCE: '',
        RFDO: '',
        RFDO_SOURCE: '',
        S: '',
        SCREEN_I: '',
        SCREEN_II: '',
        SFO: '',
        SFO_SOURCE: '',
        STOICHIOMETRIC: '',
        S_SOURCE: '',
        WHETHER_VOC: '',
        is_delete: '',
        key: '',
        userid: ''
      }
    };
  }
  async componentDidMount() {
    console.log("props", this.props)
    // this.props.savefn(this)
    let type = this.props.type;
    if (type == 'system') {
      this.systemPollutants('')
    } else if (type == 'definition') {
      this.definitionPollutants('')
    }
  }
  systemPollutants = async (keyword: any) => {
    this.setState({
      isShowtable: false
    })
    await getSystemPollutantList({
      page: 1,
      size: 10000,
      ...keyword
    }).then((res: any) => {
      let data = res.data;
      console.log("data", data);

      if (data.length > 0) {
        data.forEach((element: any, i: any) => {


          element.key = i;
          // console.log("element", element.ID,element.key, i);
        });
        this.setState({
          dataSource: data,
          isShowtable: true
        })
      } else {
        this.setState({
          isShowtable: true
        })
      }
    }).catch((err: any) => {
      // message.warn('服务器异常，请稍后再试')
    })
  }
  definitionPollutants = async (keyword: any) => {
    this.setState({
      isShowtable: false
    })
    await listCustomPollutant({
      page: 1,
      size: 10000,
      ...keyword
    }).then((res: any) => {
      let data = res.data;
      if (data.length > 0) {
        data.forEach((element: any, i: any) => {
          element.key = i;
        });
        console.log(data, 'data')
        this.setState({
          dataSource: data,
          isShowtable: true
        })
      } else {
        this.setState({
          dataSource: [],
          isShowtable: true
        })
      }
    }).catch((err: any) => {
      // message.warn('服务器异常，请稍后再试')
    })
  }
  handleDelete = async () => {
    let selectRowIds = this.state.selectRowIds;
    await getSoilPollutant({
      selectRowIds
    }).then((res: any) => {

    }).catch((err: any) => {
      // message.warn('服务器异常，请稍后再试')
    })
  };

  handleSave = (row: DataType) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
  };
  deleteline = () => {
    let that = this
    if (!this.state.selectRowIds) {
      message.info('至少选择一项')
      return
    }
    confirm({
      title: '删除',
      icon: <ExclamationCircleOutlined />,
      content: '是否删除选中的污染物？',
      okText: '确定',
      okType: 'primary',
      centered: true,
      okButtonProps: {

      },
      cancelText: '取消',
      onOk() {
        that.handleDelete()
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  onSelectChange = (selectedRowKeys: any, selectRowIds: any) => {
    let str = "";

    selectRowIds.forEach((element: any) => {
      str += element.ID + ','
    });
    if (str.length > 0) {
      // str = str.substr(0,str.length - 1);
    }
    this.setState({
      selectedRowKeys,
      selectRowIds: str
    });
  };

  viewline = (record: any) => {
    this.setState({
      viewvisible: true,
      record
    })
  }
  editline = (record: any) => {
    this.setState({
      record,
      editModal: true,
    })
  }
  deleteThisLine = (record: any) => {
    deleteCustomPollutant({
      id: record.ID
    }).then(() => {
      this.definitionPollutants('')
    })
  }
  setview = (value: any) => {
    this.setState({
      viewvisible: value
    })
  }
  setcancle = (value: any) => {
    this.setState({
      viewvisible: value
    })
  }
  editModalFn = (value: any) => {
    console.log('000')
    this.setState({
      editModal: value,
      record: this.state.resetrecord
    })
  }
  changeRadio = (i: any) => {
    console.log(i)
    if (i.target.value == '是') {
      this.setState({
        ISKOC: true
      })
    } else if (i.target.value == '否') {
      this.setState({
        ISKOC: false
      })
    }
  }
  // 注册登陆
  updateModalFn = async (record: any) => {
    updateCustomPollutant({
      ...record,
      id: record.ID
    }).then(() => {
      this.formRef.current.resetFields()
      this.setState({
        editModal: false,
        record: this.state.resetrecord
      })
      this.definitionPollutants('')
    }).catch(() => {
      message.warn('服务器异常，请稍后再试')
    })
  };

  updata = (key: any) => {
    let udkey = key.target.id;
    let value = key.target.value;
    let newobj = Object.assign(this.state.record, { [udkey]: value })
    // console.log(newobj)
    this.setState({
      record: newobj
    })
  }
  triggerEvevt = () => {
    this.props.upprops.addPollute(true)
  }
  Pollutant1 = (chked1: any) => {
    let str = "";
    let dataRowKeys = new Array();
    let selectedRowArr = new Array();
    getCriskPollutant40({
      page: 1,
      size: 10000,
    }).then((res: any) => {
      let data = res.data;
      data.forEach((element: any) => {
        str += element.ID + ','
        dataRowKeys.push(element.ID - 1)
      });
      selectedRowArr = dataRowKeys.concat(this.state.selectedRowKeys)
      for (var i = 0; i < selectedRowArr.length; i++) {
        var item1 = selectedRowArr[i];
        for (var j = i + 1; j < selectedRowArr.length; j++) {
          var item2 = selectedRowArr[j];
          if (item1 == item2) {
            selectedRowArr.splice(j, 1);
            j--; 
          }
        }
      }
      if (!chked1.target.checked) {
        for (let i = 0; i < dataRowKeys.length; i++) {
          for (let j = 0; j < selectedRowArr.length; j++) {
            if (selectedRowArr[j] === dataRowKeys[i]) {
              selectedRowArr.splice(j, 1);
              j = j - 1;
            }
          }
        }
      }
      this.setState({
        selectedRowKeys: selectedRowArr,
        selectRowIds: str
      })
    
    }).catch((err: any) => {
      // message.warn('服务器异常，请稍后再试')
    })
  };
  Pollutant2= (chked2: any) => {
    
    let str = "";
    let dataRowKeys = new Array();
    let selectedRowArr = new Array();
    getCriskPollutant45({
      page: 1,
      size: 10000,
    }).then((res: any) => {
      let data = res.data;
      data.forEach((element: any) => {
        str += element.ID + ','
        dataRowKeys.push(element.ID - 1)
      });
      
      selectedRowArr = dataRowKeys.concat(this.state.selectedRowKeys)
      for (var i = 0; i < selectedRowArr.length; i++) {
        var item1 = selectedRowArr[i];
        for (var j = i + 1; j < selectedRowArr.length; j++) {
          var item2 = selectedRowArr[j];
          if (item1 == item2) {
            selectedRowArr.splice(j, 1);
            j--; 
          }
        }
      }
      if (!chked2.target.checked) {
        for (let i = 0; i < dataRowKeys.length; i++) {
          for (let j = 0; j < selectedRowArr.length; j++) {
            if (selectedRowArr[j] === dataRowKeys[i]) {
              selectedRowArr.splice(j, 1);
              j = j - 1;
            }
          }
        }
      }
      
      this.setState({
        selectedRowKeys: selectedRowArr,
        selectRowIds: str
      })

    }).catch((err: any) => {
      // message.warn('服务器异常，请稍后再试')
    })
  };
  render() {
    const { dataSource, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,

    };
    console.log("dataSource", dataSource);//自定义数据
    console.log("selectedRowKeys", selectedRowKeys);//选中的数据
    console.log("rowSelection", rowSelection);
    console.log("this.props", this.props);

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    let columns;
    if (this.props.type == 'definition') {
      columns = this.definition
    } else {
      columns = this.columns;
    }
    let userMessage;
    if (this.props.type == 'system') {
      userMessage = (
        <div className="GB_checkbox">
          <Checkbox style={{color:'#264CC6'}} onChange={this.Pollutant1}>GB 36600表1 (40项)</Checkbox>
          <Checkbox  style={{color:'#264CC6'}} onChange={this.Pollutant2}>GB 36600表2 (45项)</Checkbox>
        </div >
      )
    } else {

    }
    return (
      <div>

        {true ? (
          <div>
            {userMessage}
            <Table
              components={components}//覆盖默认的 table 元素
              // rowClassName={() => 'editable-row'}
              scroll={{ y: 300 }}//表格是否可滚动，也可以指定滚动区域的宽、高，配置项
              rowSelection={rowSelection}//表格行是否可选择，配置项
              bordered//是否展示外边框和列边框
              loading={!this.state.isShowtable}//页面是否加载中
              dataSource={dataSource}//数据数组
              columns={columns as ColumnTypes}//表格列的配置描述，具体项见下表
            ></Table>

            <Modal
              title="查看自定义污染物详情"
              visible={this.state.viewvisible}//对话框是否可见
              onOk={() => this.setview(false)}
              onCancel={() => this.setcancle(false)}
              cancelText="取消"
              okText="关闭"
              width={1000}
              destroyOnClose={true}//关闭时销毁 Modal 里的子元素
            >
              <Form layout="vertical" onFinish={this.setview} preserve={false}>
                <Space size={20} direction="vertical">
                  <Row gutter={24}>
                    <Col span={8}>
                      <span>中文名：{this.state.record.NAME_CN}</span>
                    </Col>
                    <Col span={8}>
                      <span>英文名：{this.state.record.NAME_EN}</span>
                    </Col>
                    <Col span={8}>
                      <span>CAS编号:{this.state.record.CAS_CODE}</span>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={8}>
                      <span>亨利常数(无量纲)：{this.state.record.HSTRIKE}</span>
                    </Col>
                    <Col span={8}>
                      <span>亨利常数数据来源：{this.state.record.HSTRIKE_SOURCE}</span>
                    </Col>
                    <Col span={8}>
                      <span>Da(cm²/s)：{this.state.record.DA}</span>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={8}>
                      <span>Da数据来源：{this.state.record.DA_SOURCE}</span>
                    </Col>
                    <Col span={8}>
                      <span>Dw(cm²/s)：{this.state.record.DW}</span>
                    </Col>
                    <Col span={8}>
                      <span>Dw数据来源：{this.state.record.DW_SOURCE}</span>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={8}>
                      <span>Koc/Kd数据来源：{this.state.record.ISKOC_SOURCE}</span>
                    </Col>
                    <Col span={8}>
                      <span>溶解度(mg/L)：{this.state.record.S}</span>
                    </Col>
                    <Col span={8}>
                      <span>溶解度数据来源：{this.state.record.S_SOURCE}</span>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={8}>
                      <span>Sfo(mg/kg-d)-1：{this.state.record.SFO}</span>
                    </Col>
                    <Col span={8}>
                      <span>Sfo数据来源：{this.state.record.SFO_SOURCE}</span>
                    </Col>
                    <Col span={8}>
                      <span>IUR(mg/m³)-1：{this.state.record.IUR}</span>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={8}>
                      <span>IUR数据来源：{this.state.record.IUR_SOURCE}</span>
                    </Col>
                    <Col span={8}>
                      <span>RfDo(mg/kg-d)：{this.state.record.RFDO}</span>
                    </Col>
                    <Col span={8}>
                      <span>RfDo数据来源：{this.state.record.RFDO_SOURCE}</span>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={8}>
                      <span>RfC(mg/m³)：{this.state.record.RFC}</span>
                    </Col>
                    <Col span={8}>
                      <span>RfC数据来源：{this.state.record.RFC_SOURCE}</span>
                    </Col>
                    <Col span={8}>
                      <span>ABSgi(无量纲)：{this.state.record.ABSGI}</span>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={8}>
                      <span>ABSgi(无量纲)数据来源：{this.state.record.ABSGI_SOURCE}</span>
                    </Col>
                    <Col span={8}>
                      <span>ABSd(无量纲)：{this.state.record.ABSD}</span>
                    </Col>
                    <Col span={8}>
                      <span>ABSd(无量纲)数据来源：{this.state.record.ABSD_SOURCE}</span>
                    </Col>
                  </Row>
                  <Row className='atoms_Row_span' gutter={24}>
                    <Col span={8}>
                      <span>Carbon atoms per molecule(n)：{this.state.record.CARBON}</span>
                    </Col>
                    <Col span={8}>
                      <span>Hydrogen atoms per molecule(m)：{this.state.record.HYDROGEN}</span>
                    </Col>
                    <Col span={8}>
                      <span>Oxygen atoms per molecule(m)：{this.state.record.OXYGEN}</span>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={8}>
                      <span>Stoichiometric factor δ [g/g]：{this.state.record.STOICHIOMETRIC}</span>
                    </Col>
                    <Col span={8}>
                      <span>反应常数：{this.state.record.KOBS}</span>
                    </Col>
                    <Col span={8}>
                      <span>是否VOC：{this.state.record.ISVOC}</span>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    {
                      this.state.record.ISKOC == '是' ? (
                        <Col span={8}>
                          <span>Koc(cm³/g)：{this.state.record.KOC}</span>
                        </Col>
                      ) : (
                        <Col span={8}>
                          <span>Kd(cm³/g)：{this.state.record.KD}</span>
                        </Col>
                      )
                    }
                    <Col span={8}>
                      <span>是否基于Koc计算Kd：{this.state.record.ISKOC}</span>
                    </Col>
                  </Row>
                </Space>
              </Form>
            </Modal>
            <Modal
              title="编辑自定义污染物数据库"
              visible={this.state.editModal}
              onOk={() => this.updateModalFn(this.state.record)}
              onCancel={() => this.editModalFn(false)}
              cancelText="取消"
              okText="确定"
              width={1000}
              destroyOnClose={true}
            >
              <Form layout="vertical" ref={this.formRef} preserve={false}>
                <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item label="中文名：" name="NAME_CN" >
                      <Input defaultValue={this.state.record.NAME_CN} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="英文名：" name="NAME_EN">
                      <Input defaultValue={this.state.record.NAME_EN} value={this.state.record.NAME_EN} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="CAS编号：" name="CAS_CODE">
                      <Input defaultValue={this.state.record.CAS_CODE} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="亨利常数(无量纲)：" name="HSTRIKE">
                      <Input defaultValue={this.state.record.HSTRIKE} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item label="亨利常数数据来源：" name="HSTRIKE_SOURCE">
                      <Input defaultValue={this.state.record.HSTRIKE_SOURCE} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Da(cm²/s)：" name="DA">
                      <Input defaultValue={this.state.record.DA} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Da数据来源：" name="DA_SOURCE">
                      <Input defaultValue={this.state.record.DA_SOURCE} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Dw(cm²/s)：" name="DW">
                      <Input defaultValue={this.state.record.DW} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item label="Dw数据来源：" name="DW_SOURCE">
                      <Input defaultValue={this.state.record.DW_SOURCE} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Koc/Kd数据来源：" name="ISKOC_SOURCE">
                      <Input defaultValue={this.state.record.ISKOC_SOURCE} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="溶解度(mg/L)：" name="S">
                      <Input defaultValue={this.state.record.S} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="溶解度数据来源：" name="S_SOURCE">
                      <Input defaultValue={this.state.record.S_SOURCE} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item label="Sfo(mg/kg-d)-1：" name="SFO">
                      <Input defaultValue={this.state.record.SFO} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Sfo数据来源：" name="SFO_SOURCE">
                      <Input defaultValue={this.state.record.SFO_SOURCE} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="IUR(mg/m³)-1：" name="IUR">
                      <Input defaultValue={this.state.record.IUR} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="IUR数据来源：" name="IUR_SOURCE">
                      <Input defaultValue={this.state.record.IUR_SOURCE} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item label="RfDo(mg/kg-d)：" name="RFDO">
                      <Input defaultValue={this.state.record.RFDO} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="RfDo数据来源：" name="RFDO_SOURCE">
                      <Input defaultValue={this.state.record.RFDO_SOURCE} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="RfC(mg/m³)：" name="RFC">
                      <Input defaultValue={this.state.record.RFC} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="RfC数据来源：" name="RFC_SOURCE">
                      <Input defaultValue={this.state.record.RFC_SOURCE} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item label="ABSgi(无量纲)：：" name="ABSGI">
                      <Input defaultValue={this.state.record.ABSGI} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="ABSgi(无量纲)数据来源：" name="ABSGI_SOURCE">
                      <Input defaultValue={this.state.record.ABSGI_SOURCE} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="ABSd(无量纲)：" name="ABSD">
                      <Input defaultValue={this.state.record.ABSD} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="ABSd(无量纲)数据来源：" name="ABSD_SOURCE">
                      <Input defaultValue={this.state.record.ABSD_SOURCE} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className='atoms_Row_span' gutter={24}>
                  <Col span={6}>
                    <Form.Item label="Carbon atoms per molecule(n)：" name="CARBON">
                      <Input defaultValue={this.state.record.CARBON} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Hydrogen atoms per molecule(m)：" name="HYDROGEN">
                      <Input defaultValue={this.state.record.HYDROGEN} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Oxygen atoms per molecule(m)：" name="OXYGEN">
                      <Input defaultValue={this.state.record.OXYGEN} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Stoichiometric factor δ [g/g]：" name="STOICHIOMETRIC">
                      <Input defaultValue={this.state.record.STOICHIOMETRIC} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item label="反应常数：" name="KOBS">
                      <Input defaultValue={this.state.record.KOBS} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="是否VOC：" name="ISVOC" >
                      <Radio.Group defaultValue={'是'}>
                        <Radio value={'是'}>是</Radio>
                        <Radio value={'否'}>否</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  {
                    this.state.ISKOC ? (<Col span={6}>
                      <Form.Item label="Koc(cm³/g)：" name="KOC">
                        <Input defaultValue={this.state.record.KOC} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                      </Form.Item>
                    </Col>) : (<Col span={6}>
                      <Form.Item label="Kd(cm³/g)：" name="KD">
                        <Input defaultValue={this.state.record.KD} onChange={this.updata.bind(this)} autoComplete="off"></Input>
                      </Form.Item>
                    </Col>)
                  }
                  <Col span={6}>
                    <Form.Item label="是否基于Koc计算Kd：" name="ISKOC" >
                      <Radio.Group defaultValue={'是'} onChange={this.changeRadio.bind(this)}>
                        <Radio value={'是'}>是</Radio>
                        <Radio value={'否'}>否</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Modal>
          </div>) :
          (
            <div className="empty">
              <img src={require('../../assets/images/followPollute/empty.png').default} className="emptyimg"></img>
              <div className="emptytext">暂无关注污染物</div>
              <button className="addbtn" onClick={this.triggerEvevt}>+添加</button>
            </div>
          )}
      </div>
    );
  }
}
export default dataTable;