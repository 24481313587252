import * as React from "react";
import { Component } from "react";
import { Tabs, Spin, Row, Col, Form, Input, Divider } from "antd";
import {getProjectParamsValue,saveProjectParamsValue} from '../../../apis/common'
import "./modelParamsTable.less";
import {checkProductd,checkDepthid} from '../../../configs/checkProductId'
import { getSessionLevel } from '../../../utils/token'

const { TabPane } = Tabs;
interface leaching{
  base:{
    DF:string,
    I:string,
    Ugw:string,
    δgw:string
  }
}
let leaching={
  base:{
    DF:'',
    I:'',
    Ugw:'',
    δgw:''
  }
}
interface common{
  area:{
    LS: string
    d: string
    dsub: string
    lgw:string
  },
  exposure:{
    ABSo:string
    ACR:string
    AHQ: string
    ATca:string
    ATnc: string
    BWa: string
    BWc:string
    DAIRa: string
    DAIRc:string
    EDa: string
    EDc: string
    EFIa: string
    EFIc:string
    EFOa: string
    EFOc: string
    EFa: string
    EFc: string
    SAF: string
    SAFVOCS: string
    WAF:string
    WAFVOCS: string
  },
  soil:{
    Pws: string
    fom: string
    hcap: string
    hv: string
    θacap:string
    θwcap: string
    ρb:string

  }
}
let common={
  area:{
    LS: '',
    d:  '',
    dsub:  '',
    lgw: '',
  },
  exposure:{
    ABSo: '',
    ACR: '',
    AHQ:  '',
    ATca: '',
    ATnc:  '',
    BWa:  '',
    BWc: '',
    DAIRa:  '',
    DAIRc: '',
    EDa:  '',
    EDc:  '',
    EFIa:  '',
    EFIc: '',
    EFOa:  '',
    EFOc:  '',
    EFa:  '',
    EFc:  '',
    SAF:  '',
    SAFVOCS:  '',
    WAF: '',
    WAFVOCS:  '',
  },
  soil:{
    Pws:  '',
    fom:  '',
    hcap:  '',
    hv:  '',
    θacap: '',
    θwcap:  '',
    ρb: '',

  }
}
interface model{
  'inhalationIndoor':{
    Ab: string
    ER: string
    Kv:string
    LB: string
    Lcrack:string
    Xcrack: string
    Zcrack:string
    dP:string
    η: string
    θacrack: string
    θwcrack: string
    τ:string
  },
  'inhalationOutdoor':{
    A: string
    Uair: string
    W: string
    δair: string
  },
  'particulateMatter':{
    PIAF: string
    PM10: string
    fspi:string
    fspo: string
  },
  'throughMouth':{
    OSIRa: string
    OSIRc: string
  },
  'throughSkin':{
    Ev: string,
    Ha: string
    Hc: string
    SERa: string
    SERc:string
    SSARa: string
    SSARc: string
  },
  'water':{
    GWCRa: string
    GWCRc: string
  }

}
let model={
  'inhalationIndoor':{
    Ab: '',
    ER: '',
    Kv:'',
    LB: '',
    Lcrack:'',
    Xcrack: '',
    Zcrack:'',
    dP:'',
    η: '',
    θacrack: '',
    θwcrack: '',
    τ:'',
  },
  'inhalationOutdoor':{
    A: '',
    Uair: '',
    W: '',
    δair: '',
  },
  'particulateMatter':{
    PIAF: '',
    PM10: '',
    fspi:'',
    fspo: '',
  },
  'throughMouth':{
    OSIRa: '',
    OSIRc: '',
  },
  'throughSkin':{
    Ev: '',
    Ha: '',
    Hc: '',
    SERa: '',
    SERc:'',
    SSARa: '',
    SSARc: ''
  },
  'water':{
    GWCRa: '',
    GWCRc: '',
  }

}
export interface ParamsProps {
  history:any
}

export interface ParamsState {
  formLayout: any;
  loading:boolean;
  project_id:string,
  depth_id:string,
  formOne:any,
  formTwo:any,
  formThree:any,
  formFour:any,
  formFive:any,
  formSix:any,
  formSeven:any,
  formEight:any,
  formNine:any,
  formTen:any,
}

class ParamsSetting extends React.Component<ParamsProps, ParamsState> {
  // formOne = React.createRef();
  constructor(props: ParamsProps) {
    super(props);
    this.state = {
      formOne:React.createRef(),
      formTwo:React.createRef(),
      formThree:React.createRef(),
      formFour:React.createRef(),
      formFive:React.createRef(),
      formSix:React.createRef(),
      formSeven:React.createRef(),
      formEight:React.createRef(),
      formNine:React.createRef(),
      formTen:React.createRef(),
      project_id:'',
      formLayout: {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
      },
      loading:true,
      depth_id:'',

    };
  }
  getSystem = async(project_id:string,Depthid:any)=>{
    console.log("project_id",project_id,Depthid);
    
    let params ={
      project_id:project_id,
      depth_id:Depthid,
    }
    try {
      let res =await getProjectParamsValue(params); 
      let {
        common,
        leaching,
        model
      }=res.data;
     
      //通用参数
      this.state.formOne.current.setFieldsValue(common.area);
      this.state.formTwo.current.setFieldsValue(common.soil);
      this.state.formThree.current.setFieldsValue(common.exposure);
      //土壤淋溶至地下水
      this.state.formFour.current.setFieldsValue(leaching.base)
      //模型参数
      this.state.formFive.current.setFieldsValue(model.inhalationIndoor)
      this.state.formSix.current.setFieldsValue(model.inhalationOutdoor)
      this.state.formSeven.current.setFieldsValue(model.throughMouth)
      this.state.formEight.current.setFieldsValue(model.throughSkin)
      this.state.formNine.current.setFieldsValue(model.particulateMatter)
      this.state.formTen.current.setFieldsValue(model.water)
      this.setState({
        loading:false
      })
    } catch (error) {
      
    }
  }

  // 页面初始化
  componentDidMount(){
    let project_id = checkProductd();
    if(!project_id){
      return
    }
    let Depthid=checkDepthid();
    
    if (!Depthid) {
      return
    }
    this.getSystem(project_id,Depthid)
  }
  gotoTap= (step:string)=>{
    let level = getSessionLevel()
    if(step == 'back'){
      if (level) {
        this.props.history.push({
          pathname: "/project/polluteParamsTable",
        })
        return
      } else {
        this.props.history.push({
          pathname: "/project/parmas",
        })
      }
    }
    if(step == 'next'){
      this.props.history.push({
        pathname:"/project/exposedQuantityTable"
      })
    }
  }
  render() {
    return (
      <div className="ps-content">
        <Spin spinning={this.state.loading}>
        <Tabs
          type="card"
          defaultActiveKey='1'
        >
          <TabPane tab="通用参数" key="1" forceRender={true}>
            <div className="setting">
              <div className="modeliconleft">污染区参数</div>
            </div>
            <Divider></Divider>
            <Form ref={this.state.formOne} layout="vertical" preserve={false} wrapperCol={{ span: 18 }}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="d表层污染土壤层厚度:(cm)" name="d">
                    <Input type="number" disabled={true}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="dsub下层污染土壤层厚度:(cm)" name="dsub">
                    <Input type="number" disabled={true}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="LS下层污染土壤层埋深:(cm)" name="LS">
                    <Input type="number" disabled={true}></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="lgw地下水埋深:(cm)" name="lgw">
                    <Input type="number" disabled={true}></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <div className="setting">
              <div className="modeliconleft">土壤参数</div>
            </div>
            <Divider></Divider>
            <Form ref={this.state.formTwo} layout="vertical" wrapperCol={{ span: 18 }}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="fom土壤有机质含量:(g/kg)" name="fom">
                    <Input type="number" disabled={true}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="hcap土壤地下水交界处毛管层厚度:(cm)"
                    name="hcap"
                  >
                    <Input type="number" disabled={true}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="ρb土壤容重:(kg/dm³)" name="ρb">
                    <Input type="number" disabled={true}></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="hv非饱和土壤厚度:(cm)" name="hv">
                    <Input type="number" disabled={true}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Pws土壤含水率:(kg/kg)" name="Pws">
                    <Input type="number" disabled={true}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="θacap毛细管层孔隙空气体积比:" name="θacap">
                    <Input type="number" disabled={true}></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="ρs土壤颗粒密度:(kg/dm³)" name="ρs">
                    <Input type="number" disabled={true}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="θwcap毛细管层孔隙水体积比:" name="θwcap">
                    <Input type="number" disabled={true}></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <div className="setting">
              <div className="modeliconleft">暴露参数</div>
            </div>
            <Divider></Divider>
            <Form ref={this.state.formThree} layout="vertical" wrapperCol={{ span: 18 }}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="EDa成人暴露期:(a)" name="EDa">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="DAIRc儿童每日空气呼吸量:(m³/d)"
                    name="DAIRc"
                  >
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="EDc儿童暴露期:(a)" name="EDc">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    label="SAF暴露于土壤的参考剂量分配比例(SVOCs和重金属):"
                    name="SAF"
                  >
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="EFa成人暴露频率:(d/a)" name="EFa">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  {" "}
                  <Form.Item
                    label="WAF暴露于地下水的参考剂量分配比例(SVOCs和重金属):"
                    name="WAF"
                  >
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  {" "}
                  <Form.Item label="EFc儿童暴露频率:(d/a)" name="EFc">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="SAF暴露于土壤的参考剂量分配比例(VOCs):"
                    name="SAFVOCS"
                  >
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="EFIa成人室内暴露频率:(d/a)" name="EFIa">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    label="WAF暴露于地下水的参考剂量分配比例(VOCS):"
                    name="WAFVOCS"
                  >
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="EFIc儿童室内暴露频率:(d/a)" name="EFIc">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="ABSo经口摄入吸收因子" name="ABSo">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="EFOa成人室外暴露频率:(d/a)" name="EFOa">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="ACR单一污染物可接受致癌风险:" name="ACR">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="EFOc儿童室外暴露频率:(d/a)" name="EFOc">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="AHQ单一污染物可接受危害熵:" name="AHQ">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="BWa成人平均体重:(kg)" name="BWa">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="ATca致癌效应平均时间:(d)" name="ATca">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="BWc儿童平均体重:(kg)" name="BWc">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="ATnc非致癌效应平均时间:(d)" name="ATnc">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="DAIRa成人每日空气呼吸量:(m³/d)"
                    name="DAIRa"
                  >
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </TabPane>
          <TabPane tab="土壤淋溶至地下水" key="2"  forceRender={true}>
            <Form ref={this.state.formFour} layout="vertical" wrapperCol={{ span: 18 }}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="Ugw地下水(Darcy)速率:(cm/a)" name="Ugw">
                    <Input type="number" disabled={true}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="地下水混合区厚度:(cm)"
                    name="δgw"
                  >
                    <Input  type="number" disabled={true}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="土壤中水的入渗速率:(cm/a)" name="I">
                    <Input type="number" disabled={true}></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="地下水迁移稀释因子" name="DF">
                    <Input type="number" disabled={true}></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </TabPane>
          <TabPane tab="模型参数" key="3"  forceRender={true}>
            <div className="setting">
              <div className="modeliconleft">吸入室内蒸汽HJ25.3模型参数</div>
            </div>
            <Divider></Divider>
            <Form ref={this.state.formFive} layout="vertical" wrapperCol={{ span: 18 }}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    label="θacrack地基裂隙中空气体积比:"
                    name="θacrack"
                  >
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="τ气态污染物入侵持续时间:(a)" name="τ">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="θwcrack地基裂隙中水体积比:(cm)"
                    name="θwcrack"
                  >
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="dP室内室外气压差:(g/cm.s-2)" name="dP">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Lcrack室内地基厚度:(cm)" name="Lcrack">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Kv土壤透性系数:(cm²)" name="Kv">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    label="LB室内空间体积与气态污染物入渗面积之比:(cm)"
                    name="LB"
                  >
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Zcrack室内地面到地板底部厚度:(cm)"
                    name="Zcrack"
                  >
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="ER室内空气交换速率:(次/d)" name="ER">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="Xcrack室内地板周长:(cm)" name="Xcrack">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="η地基和墙体裂隙表面积所占面积:" name="η">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Ab室内地板面积:(cm²)" name="Ab">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <div className="setting">
              <div className="modeliconleft">吸入室外蒸汽HJ25.3模型参数</div>
            </div>
            <Divider></Divider>
            <Form ref={this.state.formSix} layout="vertical" wrapperCol={{ span: 18 }}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="W污染源区宽度:(cm)" name="W">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Uair混合区大气流速风速:(cm/s)" name="Uair">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="A污染源区面积:(cm²)" name="A">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="δair混合区高度:(cm)" name="δair">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <div className="setting">
              <div className="modeliconleft">土壤经口摄入HJ25.3模型参数</div>
            </div>
            <Divider></Divider>
            <Form ref={this.state.formSeven} layout="vertical" wrapperCol={{ span: 18 }}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    label="OSIRa成人每日摄入土壤量:(mg/d)"
                    name="OSIRa"
                  >
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="OSIRc儿童每日摄入土壤量:(mg/d)"
                    name="OSIRc"
                  >
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <div className="setting">
              <div className="modeliconleft">土壤皮肤摄入HJ25.3模型参数</div>
            </div>
            <Divider></Divider>
            <Form ref={this.state.formEight} layout="vertical" wrapperCol={{ span: 18 }}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="Ha成人平均身高:(cm)" name="Ha">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="SERc儿童暴露皮肤所占体表面积比:"
                    name="SERc"
                  >
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Hc儿童平均身高:(cm)" name="Hc">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    label="SSARa成人皮肤表面土壤粘附系数:(mg/cm²)"
                    name="SSARa"
                  >
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Ev每日皮肤接触事件频率:(次/d)" name="Ev">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="SSARc儿童皮肤表面土壤粘附系数:(mg/cm²)"
                    name="SSARc"
                  >
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    label="SERa成人暴露皮肤所占体表面积比:"
                    name="SERa"
                  >
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <div className="setting">
              <div className="modeliconleft">土壤吸入颗粒物HJ25.3模型参数</div>
            </div>
            <Divider></Divider>
            <Form ref={this.state.formNine} layout="vertical" wrapperCol={{ span: 18 }}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    label="PM10空气中可吸入颗粒物含量:(mg/m³)"
                    name="PM10"
                  >
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="fspo室外空气中来自土壤的颗粒物比例:"
                    name="fspo"
                  >
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="fspi室内空气中来自土壤的颗粒物所占比例:"
                    name="fspi"
                  >
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    label="PIAF吸入土壤颗粒物在体内滞留比例:"
                    name="PIAF"
                  >
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <div className="setting">
              <div className="modeliconleft">地下水饮用HJ25.3模型参数</div>
            </div>
            <Divider></Divider>
            <Form ref={this.state.formTen} layout="vertical" wrapperCol={{ span: 18 }}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="GWCRa成人每日饮用水量:(L/d)" name="GWCRa">
                    <Input disabled={true} type="number"></Input>
                    
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="GWCRc儿童每日饮用水量:(L/d)" name="GWCRc">
                    <Input disabled={true} type="number"></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </TabPane>
        </Tabs>
        <div className="exposebtn">
          <button className="formbtnleft" onClick={this.gotoTap.bind(this,'back')}>上一步</button>
          <button className="formbtnright" onClick={this.gotoTap.bind(this,'next')}>下一步</button>
        </div>
        </Spin>
      </div>
    );
  }
}

export default ParamsSetting;
