import React, { Component } from 'react';
import { Tabs, Row, Col, Form, Input, Divider, Tag, Table, Switch, Space, Spin } from "antd";
import { exposedQuantityTable } from '../../../apis/common'
import { checkProductd, checkDepthid } from '../../../configs/checkProductId.js'
import { getSessionLevel } from '../../../utils/token'

const { TabPane } = Tabs;
const { Column, ColumnGroup } = Table;


export interface Props {
  history: any
}
export interface State {
  project_id: string,
  depth_id: string,
  waterCancerColumns: any;
  waterCancer: any;
  waterNotCancerColumns: any,
  waterNotCancer: any,
  soilCancerColumns: any,
  soilCancer: any,
  soilNotCancerColumns: any,
  soilNotCancer: any,
  loading: boolean,
}
let soilCancerColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '中文名',
    dataIndex: 'name_cn',
    key: 'name_cn',
  },
  {
    title: '英文名',
    dataIndex: 'name_en',
    key: 'name_en',
    width: 200,
  },
  {
    title: 'CAS编号',
    dataIndex: 'cas_code',
    key: 'cas_code',
  },
  {
    title: '经口摄入土壤',
    dataIndex: 'OISERca',
    key: 'OISERca',
    children: [
      {
        title: 'OISERca',
        dataIndex: 'OISERca',
        key: 'IOVERca1',
      },
    ]
  },
  {
    title: '皮肤接触土壤',
    dataIndex: 'DCSERca',
    key: 'DCSERca',
    children: [
      {
        title: 'DCSERca',
        dataIndex: 'DCSERca',
        key: 'DCSERca',
      },
    ]
  },
  {
    title: '吸入土壤颗粒物',
    dataIndex: 'PIERca',
    key: 'PIERca',
    children: [
      {
        title: 'PIERca',
        dataIndex: 'PIERca',
        key: 'PIERca',
      },
    ]
  },
  {
    title: '吸入室外空气中来自表层土壤的气态污染物',
    dataIndex: 'IOVERca1',
    key: 'IOVERca1',
    children: [
      {
        title: 'IOVERca1',
        dataIndex: 'IOVERca1',
        key: 'IOVERca1',
      },
    ]
  },
  {
    title: '吸入室外空气中来自下层土壤的气态污染物',
    dataIndex: 'IOVERca2',
    key: 'IOVERca2',
    children: [
      {
        title: 'IOVERca2',
        dataIndex: 'IOVERca2',
        key: 'IOVERca2',
      },
    ]
  },
  {
    title: '吸入室内空气中来自下层土壤的气态污染物',
    dataIndex: 'IIVERca1',
    children: [
      {
        title: 'IIVERca1',
        dataIndex: 'IIVERca1',
        key: 'IIVERca1',
      },
    ]
  },
]
let soilNotCancerColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '中文名',
    dataIndex: 'name_cn'
  },
  {
    title: '英文名',
    dataIndex: 'name_en',
    width: 200,
  },
  {
    title: 'CAS编号',
    dataIndex: 'cas_code',
  },
  {
    title: '经口摄入土壤',
    dataIndex: 'OISERnc',
    children: [
      {
        title: 'OISERnc',
        dataIndex: 'OISERnc',
        key: 'OISERnc',
      },
    ]
  },
  {
    title: '皮肤接触土壤',
    dataIndex: 'DCSERnc',
    children: [
      {
        title: 'DCSERnc',
        dataIndex: 'DCSERnc',
        key: 'DCSERnc',
      },
    ]
  },
  {
    title: '吸入土壤颗粒物',
    dataIndex: 'PIERnc',
    children: [
      {
        title: 'PIERnc',
        dataIndex: 'PIERnc',
        key: 'PIERnc',
      },
    ]
  },
  {
    title: '吸入室外空气中来自表层土壤的气态污染物',
    dataIndex: 'IOVERnc1',
    children: [
      {
        title: 'IOVERnc1',
        dataIndex: 'IOVERnc1',
        key: 'IOVERnc1',
      },
    ]
  },
  {
    title: '吸入室外空气中来自下层土壤的气态污染物',
    dataIndex: 'IOVERnc2',
    children: [
      {
        title: 'IOVERnc2',
        dataIndex: 'IOVERnc2',
        key: 'IOVERnc2',
      },
    ]
  },
  {
    title: '吸入室内空气中来自下层土壤的气态污染物',
    dataIndex: 'IIVERnc1',
    children: [
      {
        title: 'IIVERnc1',
        dataIndex: 'IIVERnc1',
        key: 'IIVERnc1',
      },
    ]
  },
];
let waterCancerColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '中文名',
    dataIndex: 'name_cn'
  },
  {
    title: '英文名',
    dataIndex: 'name_en',
  },
  {
    title: 'CAS编号',
    dataIndex: 'cas_code',
  },
  {
    title: '吸入室外空气中来自地下水的气态污染物',
    dataIndex: 'IOVERca3',
    children: [
      {
        title: 'IOVERca3',
        dataIndex: 'IOVERca3',
      },
    ]
  },
  {
    title: '吸入室内空气中来自地下水的气态污染物',
    dataIndex: 'IIVERca2',
    children: [
      {
        title: 'IIVERca2',
        dataIndex: 'IIVERca2',
      },
    ]
  },
  {
    title: '饮用地下水',
    dataIndex: 'CGWERca',
    children: [
      {
        title: 'CGWERca',
        dataIndex: 'CGWERca',
      },
    ]
  },
];
let waterNotCancerColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '中文名',
    dataIndex: 'name_cn'
  },
  {
    title: '英文名',
    dataIndex: 'name_en',
  },
  {
    title: 'CAS编号',
    dataIndex: 'cas_code',
  },
  {
    title: '吸入室外空气中来自地下水的气态污染物',
    dataIndex: 'IOVERnc3',
    children: [
      {
        title: 'IOVERnc3',
        dataIndex: 'IOVERnc3',
      },
    ]
  },
  {
    title: '吸入室内空气中来自地下水的气态污染物',
    dataIndex: 'IIVERnc2',
    children: [
      {
        title: 'IIVERnc2',
        dataIndex: 'IIVERnc2',
      },
    ]
  },
  {
    title: '饮用地下水',
    dataIndex: 'CGWERnc 0',
    children: [
      {
        title: 'CGWERnc',
        dataIndex: 'CGWERnc',
      },
    ]
  },
];
class exposedQuantityTables extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    console.log("home", props)
    this.state = {
      project_id: '',
      depth_id: '',
      waterCancerColumns: [],
      waterCancer: [],
      waterNotCancerColumns: [],
      waterNotCancer: [],
      soilCancerColumns: [],
      soilCancer: [],
      soilNotCancerColumns: [],
      soilNotCancer: [],
      loading: true,
    };
  }
  componentWillMount() {
    let productId = checkProductd();
    if (productId) {
      this.setState({
        project_id: productId
      })
    }
    let Depthid = checkDepthid();
    if (Depthid) {
      this.setState({
        depth_id: Depthid,
      })
    }
  }
  componentDidMount() {
    this.exposedQuantityTable()
  }
  exposedQuantityTable = async () => {
    let params = {
      project_id: this.state.project_id,
      depth_id: this.state.depth_id,
    }
    try {
      let res = await exposedQuantityTable(params);
      let exposure = res.data.exposure;
      this.setState({
        loading: false
      })
      this.handlewater(exposure)
      this.handlesoil(exposure)
    } catch (error) {
      console.log(error);
    }
  }
  handlewater = (exposure: any) => {
    let {
      water: {
        cancer: waterCancer,
        not_cancer: waterNotCancer
      }
    } = exposure;
    this.handleWaterCancer(waterCancer)
    this.handleWaterNotCancer(waterNotCancer)
  }
  handleWaterCancer = (waterCancer: any) => {
    let dataSource = new Array()
    let newobject = new Object()
    waterCancer.map((key: any) => {
      Object.assign(newobject, {

        id: key.id,
        name_cn: key.name_cn,
        name_en: key.name_en,
        cas_code: key.cas_code,
        IOVERca3: (key.IOVERca3 !== "") ? key.IOVERca3.toExponential(2) : '-',
        IIVERca2: (key.IIVERca2 !== "") ? key.IIVERca2.toExponential(2) : '-',
        CGWERca: (key.CGWERca !== "") ? key.CGWERca.toExponential(2) : '-',
      })
      dataSource.push(newobject)
      newobject = {}
    })
    this.setState({
      waterCancerColumns,
      waterCancer: dataSource
    })
  }
  handleWaterNotCancer = (waterNotCancer: any) => {
    let dataSource = new Array()
    let newobject = new Object()
    waterNotCancer.map((key: any) => {
      Object.assign(newobject, {
        id: key.id,
        name_cn: key.name_cn,
        name_en: key.name_en,
        cas_code: key.cas_code,
        IOVERnc3: (key.IOVERnc3 !== "") ? key.IOVERnc3.toExponential(2) : '-',
        IIVERnc2: (key.IIVERnc2 !== "") ? key.IIVERnc2.toExponential(2) : '-',
        CGWERnc: (key.CGWERnc !== "") ? key.CGWERnc.toExponential(2) : '-',
      })

      dataSource.push(newobject)
      newobject = {}
    })
    this.setState({
      waterNotCancerColumns,
      waterNotCancer: dataSource,
    })
  }
  handlesoil = (exposure: any) => {
    let {
      soil: {
        cancer: soilCancer,
        not_cancer: soilNotCancer
      }
    } = exposure;

    this.handleSoilCancer(soilCancer)
    this.handleSoilNotCancer(soilNotCancer)
  }
  handleSoilCancer = (soilCancer: any) => {
    let dataSource = new Array()
    let newobject = new Object()
    soilCancer.map((key: any) => {
      Object.assign(newobject, {

        id: key.id,
        name_cn: key.name_cn,
        name_en: key.name_en,
        cas_code: key.cas_code,
        OISERca: (key.OISERca !== "") ? key.OISERca.toExponential(2) : '-',
        DCSERca: (key.DCSERca !== "") ? key.DCSERca.toExponential(2) : '-',
        PIERca: (key.PIERca !== "") ? key.PIERca.toExponential(2) : '-',
        IOVERca1: (key.IOVERca1 !== "") ? key.IOVERca1.toExponential(2) : '-',
        IOVERca2: (key.IOVERca2 !== "") ? key.IOVERca2.toExponential(2) : '-',
        IIVERca1: (key.IIVERca1 !== "") ? key.IIVERca1.toExponential(2) : '-',
      })
      dataSource.push(newobject)
      newobject = {}
    })
    this.setState({
      soilCancerColumns,
      soilCancer: dataSource
    })
  }
  handleSoilNotCancer = (soilNotCancer: any) => {
    let dataSource = new Array()
    let newobject = new Object()
    soilNotCancer.map((key: any) => {
      Object.assign(newobject, {
        id: key.id,
        name_cn: key.name_cn,
        name_en: key.name_en,
        cas_code: key.cas_code,
        OISERnc: (key.OISERnc !== "") ? key.OISERnc.toExponential(2) : '-',
        DCSERnc: (key.DCSERnc !== "") ? key.DCSERnc.toExponential(2) : '-',
        PIERnc: (key.PIERnc !== "") ? key.PIERnc.toExponential(2) : '-',
        IOVERnc1: (key.IOVERnc1 !== "") ? key.IOVERnc1.toExponential(2) : '-',
        IOVERnc2: (key.IOVERnc2 !== "") ? key.IOVERnc2.toExponential(2) : '-',
        IIVERnc1: (key.IIVERnc1 !== "") ? key.IIVERnc1.toExponential(2) : '-',
      })

      dataSource.push(newobject)
      newobject = {}
    })
    this.setState({
      soilNotCancerColumns,
      soilNotCancer: dataSource,
    })
  }
  gotoTap = (step: string) => {
    let level = getSessionLevel()

    if (step == 'back') {
      if (level) {
        this.props.history.push({
          pathname: "/project/modelParamsTable",
        })
        return
      } else {
        this.props.history.push({
          pathname: "/project/parmas",
        })
      }
    }
    if (step == 'next') {
      this.props.history.push({
        pathname: "/project/riskCalculation"
      })

    }
  }
  render() {

    return (
      <div className="ps-content">
        <Spin spinning={this.state.loading}>
          <Tabs
            type="card"
            defaultActiveKey='1'
            onChange={(key) => {
              console.log(key);
            }}
          >
            <TabPane tab="土壤" key="1">
              <div className="setting">
                <div className="modeliconleft">土壤不同暴露途径暴露量（致癌效应）</div>
              </div>
              <Divider></Divider>
              <Table
                columns={this.state.soilCancerColumns}
                dataSource={this.state.soilCancer}
                rowKey={record => record.id}
                bordered
              >
              </Table>
              <div className="setting">
                <div className="modeliconleft">土壤不同暴露途径暴露量（非致癌效应）</div>
              </div>
              <Divider></Divider>
              <Table
                columns={this.state.soilNotCancerColumns}
                dataSource={this.state.soilNotCancer}
                rowKey={record => record.id}
                bordered
              >
              </Table>
            </TabPane>

            <TabPane tab="地下水" key="2">
              <div className="setting">
                <div className="modeliconleft">地下水不同暴露途径暴露量（致癌效应）</div>
              </div>
              <Divider></Divider>
              <Table
                columns={this.state.waterCancerColumns}
                dataSource={this.state.waterCancer}
                rowKey={record => record.id}
                bordered
              >
              </Table>
              <div className="setting">
                <div className="modeliconleft">地下水不同暴露途径暴露量（非致癌效应）</div>
              </div>
              <Divider></Divider>
              <Table
                columns={this.state.waterNotCancerColumns}
                dataSource={this.state.waterNotCancer}
                rowKey={record => record.id}
                bordered
              >
              </Table>
            </TabPane>
          </Tabs>
          <div className="exposebtn">
            <button className="formbtnleft" onClick={this.gotoTap.bind(this, 'back')}>上一步</button>
            <button className="formbtnright" onClick={this.gotoTap.bind(this, 'next')}>下一步</button>
          </div>
        </Spin>
      </div>
    );
  }
}

export default exposedQuantityTables;
