import React, { useEffect, useState } from "react";
import { Form, Input, Radio, DatePicker, Button, Row, Col, Tooltip, Select, message, Spin, Checkbox, Option } from "antd";
import provinces from '../assets/database/provinces.json'
import cities from '../assets/database/province-city.json'
import { createProject, getProjectInfo, updateProject, levelAnalysis } from "../apis/common"
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import eventBus from '../configs/events';
import { Space } from 'antd';
import moment from 'moment'
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN'
import ProvinceAndCityCascader from "./common/ProvinceAndCity.jsx"
import { saveProductId, checkProductd, saveDepthId } from '../configs/checkProductId.js'
import '../../src/assets/styles/common.less'
import first from "../assets/images/init/first-inactive.png"
import second from "../assets/images/init/second-inactive.png"
import { getSessionProductId, setSessionNumberPlies, removeSessionNumberPlies, getDepthid, getSessionNumberPlies, removeDepthid, removeSessionLevel, setSelectedKey } from '../utils/token'
import firsthover from "../assets/images/init/first-hover.png"
import secondhover from "../assets/images/init/second-hover.png"

import firstactive from "../assets/images/init/first-active.png"
import secondactive from "../assets/images/init/second-active.png"
import { withRouter, useHistory } from "react-router-dom";
// const { Option } = Select;
const provinceData = provinces;
const cityData = cities;

const BasicInfoForm = () => {
  let history = useHistory();
  const [form] = Form.useForm();
  const [firstImage, setFirstImage] = useState(first)
  const [project_time, setProjectTime] = useState(new Date())

  const [twoImage, setTwoImage] = useState(second)
  const [isclick, setIsclick] = useState(false)
  const leftitle = '包括城市建设用地中的居住用地、公共管理与公共服务用地中的中小学用地、医疗卫生用地和社会福利设施用地以及公园绿地中的社区公园或公园工地等。'
  const righttitle = '包括城市建设用地中的工业用地、物流仓储用地、商业服务业设施用地、道路与交通设施用地、公用设施用地、公共管理与公共服务用地、以及绿地与广场等。'
  const [cities, setCities] = React.useState(cityData[provinceData[0]["name"]]);
  const [province, setProvince] = React.useState(provinceData[0]["name"]);
  const [secondCity, setSecondCity] = React.useState(cityData[provinceData[0]["name"]][0]);
  const [projectId, setProjectId] = React.useState('');
  const [loading, setLoading] = React.useState(true);

  const [costSaving, setcostSaving] = React.useState(false);
  const [gradation, setgradation] = React.useState([{ i: 1, w: 2 }, { q: 1 }, { e: 3 }]);

  // loading
  const handleProvinceChange = (value, city) => {
    console.log("value, city", value, city);
    setProvince(value)
    setCities(cityData[value]);
    setSecondCity(cityData[value][0]);
    if (typeof (city) == 'string') {
      form.setFieldsValue({
        city: city,

      });
    } else {
      console.log("cityData", cityData);
      form.setFieldsValue({
        city: cityData[value][0],


      });
    }
  };
  useEffect(() => {
    let project_id = getSessionProductId();
    let depth_id = getDepthid();
    let getNumberPlies = getSessionNumberPlies();
    console.log("project_id", project_id);
    console.log("depth_id", depth_id);
    console.log("getNumberPlies", getNumberPlies);
    console.log("getFieldsValue", form.getFieldsValue().isLamination);
    if (project_id) {
      setProjectId(project_id)
      getProjectInfoData(project_id)


    } else {
      console.log("不执行",);

      setLoading(false)
      form.setFieldsValue({

        isLamination: '1',
        hierarchyNumber: '0'

      });


    }
  }, []);

  const onSecondCityChange = value => {
    setSecondCity(value);
  };
  const onFinish = async (values) => {


    console.log("有Id没有", projectId, values.depth);

    if (projectId) {
      console.log("有id存储的项目", projectId, values);

      updatePro(values)
    } else {
      console.log("创建项目", projectId, values);

      createPro(values)
    }
  };
  //有id
  const updatePro = async (values) => {

    let {
      name,
      type,
      address,
      time,
      city,
      isLamination,
      province,
      depth,
      hierarchyNumber
    } = values;

    console.log("values", values);

    if (isLamination == 0) {
      console.log("这个是选择多个分层数的", isLamination);
      depth.forEach((item, index) => {
        item.tier = index
      })

      let newtime = parseInt((new Date(time._d).getTime()) / 1000)
      const project = {
        project_name: name,
        project_type: type,
        project_time: newtime,
        province: province,
        city: secondCity,
        address: address,
        is_lamination: isLamination,
        hierarchy_number: hierarchyNumber,
        array: depth,
        project_id: projectId
      }
      console.log("project", project);
      const projectIFY = JSON.stringify(project)
      await levelAnalysis({
        level: 2,
        project_id: projectId
      }).then((res) => {
        console.log("resssssssssssssss", res);
      }).catch((err) => {
        message.warn('服务器异常，请稍后再试')
      })
      await updateProject({

        json: projectIFY
      }).then((res) => {
        console.log("res", res);


        getProjectInfoData(projectId)
        setSelectedKey('1-1')
        setTimeout(() => {
          history.push({
            pathname: "/project/followPollute",
            state: `${'1-1'}`
          })
          window.location.reload()
        }, 200)
      }).catch((err) => {
        message.warn('服务器异常，请稍后再试')
      })
    } else {
      console.log("这个是原来项目", isLamination);
      // depth.forEach((item, index) => {
      //   console.log("item", item, index);
      //   item.tier = '0'
      //   item.depth1 = '0'
      //   item.depth2 = '0'

      // })
      let depth = [{
        tier: '0',
        depth1: '0',
        depth2: '0',
      }]
      console.log("depth", depth);

      let newtime = parseInt((new Date(time._d).getTime()) / 1000)
      const project = {
        project_name: name,
        project_type: type,
        project_time: newtime,
        province: province,
        city: secondCity,
        address: address,
        is_lamination: isLamination,
        hierarchy_number: '0',
        array: depth,
        project_id: projectId
      }
      console.log("project", project);
      const projectIFY = JSON.stringify(project)

      await updateProject({

        json: projectIFY
      }).then((res) => {
        console.log("res", res);
        getProjectInfo({
          project_id: projectId
        }).then((res) => {
          let depth_id = res.data[0].depthRow[0].depth_id;

          saveDepthId(depth_id)
          console.log("resssssssssssssss", res.data[0], depth_id,);

        }).catch((err) => {
          message.warn('服务器异常，请稍后再试')
        })


        setTimeout(() => {
          history.push({
            pathname: "/project/analysis",
          })
          // window.location.reload()
        }, 200)
      }).catch((err) => {
        message.warn('服务器异常，请稍后再试')
      })
    }


  }
  // 没有id
  const createPro = async (values) => {
    console.log("values", values);
    let {
      name,
      type,
      address,
      time,
      city,
      isLamination,
      province,
      depth,
      hierarchyNumber
    } = values;

    if (isLamination == 0) {
      depth.forEach((item, index) => {
        item.tier = index + 1
      })

      let newtime = parseInt((new Date(time._d).getTime()) / 1000)
      const project = {
        project_name: name,
        project_type: type,
        project_time: newtime,
        province: province,
        city: secondCity,
        address: address,
        is_lamination: isLamination,
        hierarchy_number: hierarchyNumber,

        array: depth
      }
      console.log("project", project);

      const projectIFY = JSON.stringify(project)

      await createProject({

        json: projectIFY
      }).then((res) => {
        console.log("res", res);
        saveProductId(res.data.project_id)


        levelAnalysis({
          level: 2,
          project_id: res.data.project_id
        }).then((res) => {
          console.log("resssssssssssssss", res);
        }).catch((err) => {
          message.warn('服务器异常，请稍后再试')
        })

        getProjectInfo({
          project_id: res.data.project_id
        }).then((res) => {
          let depth_id = res.data[0].depthRow[0].depth_id;
          let is_lamination = res.data[0].is_lamination;

          console.log("resssssssssssssss", res.data[0], depth_id, is_lamination);

          if (is_lamination == 0) {
            saveDepthId(depth_id)
            setSessionNumberPlies('1')
            setcostSaving(true)
            setSelectedKey('1-1')

            setTimeout(() => {
              history.push({
                pathname: "/project/followPollute",
                state: `${'1-1'}`
              })
            }, 200)
            setTimeout(() => {
              window.location.reload()


            }, 200)
          } else {
            removeDepthid()
            removeSessionNumberPlies()
            removeSessionLevel()
            setcostSaving(false)
          }
        }).catch((err) => {
          message.warn('服务器异常，请稍后再试')
        })

      }).catch((err) => {
        message.warn('服务器异常，请稍后再试')
      })
    } else {

      depth = [{}]
      depth.forEach((item, index) => {
        console.log("item", item, index);
        item.tier = '0'
        item.depth1 = '0'
        item.depth2 = '0'


      })

      let newtime = parseInt((new Date(time._d).getTime()) / 1000)
      const project = {
        project_name: name,
        project_type: type,
        project_time: newtime,
        province: province,
        city: secondCity,
        address: address,
        is_lamination: isLamination,
        hierarchy_number: '0',

        array: depth
      }
      console.log("project", project);

      const projectIFY = JSON.stringify(project)
      await createProject({

        json: projectIFY
      }).then((res) => {
        console.log("res", res);
        saveProductId(res.data.project_id)


        getProjectInfo({
          project_id: res.data.project_id
        }).then((res) => {
          let depth_id = res.data[0].depthRow[0].depth_id;

          saveDepthId(depth_id)
          console.log("resssssssssssssss", res.data[0], depth_id,);

        }).catch((err) => {
          message.warn('服务器异常，请稍后再试')
        })


        setTimeout(() => {
          history.push({
            pathname: "/project/analysis",
            state: `${'1-1'}`
          })
        }, 200)
        // setTimeout(() => {
        //   window.location.reload()


        //     }, 200)
      }).catch((err) => {
        message.warn('服务器异常，请稍后再试')
      })
    }

  }
  const getProjectInfoData = async (project_id) => {
    let res = await getProjectInfo({ project_id });
    let newobject = new Object()

    try {
      let data = res.data[0];
      let depthRowArray = data.depthRow;
      let depthRow = data.depthRow[0];
      let depthRowDepth_id = data.depthRow[0].depth_id;
      let depthRowtier = data.depthRow[0].tier;
      console.log("data", data);
      console.log("orm.setFieldsValue()", depthRowDepth_id, depthRowtier);
      let {
        address,
        city,
        project_name,
        project_time,
        project_type,
        province,
        hierarchy_number,
        is_lamination,
        depth_id,
      } = data;

      // depthRowArray.push(newobject)
      let time = moment(moment(parseInt(project_time) * 1000).format())
      form.setFieldsValue({
        name: project_name,
        type: project_type,
        time: time,
        address,
        province,
        city,
        isLamination: is_lamination,
        depth: depthRowArray,
        hierarchyNumber: hierarchy_number
      });
      if (is_lamination == 0) {
        saveDepthId(depthRowDepth_id)
        setSessionNumberPlies('1')
        setcostSaving(true)
      } else {
        removeDepthid()
        removeSessionNumberPlies()
        removeSessionLevel()
        setcostSaving(false)
      }


      handleProvinceChange(province, city)
      setLoading(false)
      let value = project_type == '1' ? 'one' : 'two'
      clickleft(value)
    } catch (error) {

    }
  }

  const disabledDate = (current) => {
    return current > moment().endOf('day')
  }
  const clickleft = (value) => {
    if (value == 'one') {
      setFirstImage(firstactive)
      setTwoImage(second);
      form.setFieldsValue({ type: '1' });
    } else {
      setFirstImage(first)
      setTwoImage(secondactive)
      form.setFieldsValue({ type: '2' });
    }
    setIsclick(true)
  }
  const mouse = (value, active) => {
    if (isclick) {
      return
    }
    if (active == 'enter') {
      if (value == 'one') {
        setFirstImage(firsthover)
        setTwoImage(second)
      } else {
        setFirstImage(first)
        setTwoImage(secondhover)
      }
    } else {
      setFirstImage(first)
      setTwoImage(second)
    }
  }

  const printInput = (i) => {
    if (i.target.value.length > 0) {
      form.setFieldsValue({ address: i.target.value });
    } else {
      form.setFieldsValue({ address: '' });
    }
  }




  const toChineseNumber = (n) => {
    if (!Number.isInteger(n) && n < 0) {
      throw Error('请输入自然数');
    }
    const digits = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
    const positions = ['', '十', '百', '千', '万', '十万', '百万', '千万', '亿', '十亿', '百亿', '千亿'];
    const charArray = String(n).split('');
    let result = '';
    let prevIsZero = false;
    //处理0  deal zero
    for (let i = 0; i < charArray.length; i++) {
      const ch = charArray[i];
      if (ch !== '0' && !prevIsZero) {
        result += digits[parseInt(ch)] + positions[charArray.length - i - 1];
      } else if (ch === '0') {
        prevIsZero = true;
      } else if (ch !== '0' && prevIsZero) {
        result += '零' + digits[parseInt(ch)] + positions[charArray.length - i - 1];
      }
    }
    //处理十 deal ten
    if (n < 100) {
      result = result.replace('一十', '十');
    }
    return result;
  }

  const addrr = (i) => {
    const depthobjecy = { depth1: '', depth2: '', }
    let getdepth = form.getFieldsValue({}).depth
    let depth = getdepth
    console.log("111111", i, depth.length);
    if (i <= getdepth.length) {
      console.log("111111111",i,getdepth.length);
      depth=[]
      for (let index = 0; index < i ; index++) {

        console.log("index",index,getdepth[index]);
        depth.push(getdepth[index])

      }
    } else {
      console.log("222222222",);
      for (let index = 0; index = i - depth.length; index++) {
        depth.push(depthobjecy)

      }
    }

    Object.assign(depth, {
      hierarchyNumber: i,

    })
    console.log("i", i, form.getFieldsValue({}).depth, depth);
    form.setFieldsValue({
      depth: depth,
      hierarchyNumber: i
    })


  }

  const clickCheck = (values,) => {
    // console.log("value", values.target.value, form.getFieldsValue({
    // }).depth);
    let project_id = getSessionProductId();

    getProjectInfo({
      project_id: project_id
    }).then((res) => {
      let depth_Row = res.data[0].depthRow
      let hierarchy_number = res.data[0].hierarchy_number
      console.log("resssssssssssssss",depth_Row,hierarchy_number);

      if (values.target.value == 0) {
        setcostSaving(true)
  
        
        form.setFieldsValue({
          depth:depth_Row,
          hierarchyNumber:hierarchy_number
        })
      } else {
        setcostSaving(false)
        form.setFieldsValue({
          depth: [],
          hierarchyNumber: 0
        })
      }
    }).catch((err) => {
      // message.warn('服务器异常，请稍后再试')
      if (values.target.value == 0) {
        setcostSaving(true)
        form.setFieldsValue({
          depth: [{ depth1: '', depth2: '', }],
          hierarchyNumber: 1
        })
      } else {
        setcostSaving(false)
        form.setFieldsValue({
          depth: [],
          hierarchyNumber: 0
        })
      }
    })
   
  };
  return (
    <div className="basic-info">
      <Spin spinning={loading}>
        <Row >
          {/* <Col xs={0} sm={0} md={7} lg={7} xl={7}></Col> */}
          <Col xs={24} sm={24} md={10} lg={10} xl={10} className="123">
            <Form name="basic-info" onFinish={onFinish} form={form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 20 }}
              initialValues={
                {
                  province: province,
                  city: secondCity
                }
              }
            >

              <Form.Item
                label="项目名称"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "请输入项目名称",
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>

              <Form.Item
                label="用地类型"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "请选择用地类型",
                  },
                ]}
              >
                <Row gutter={0}>
                  <Col span="12">
                    <Tooltip placement="top" title={leftitle}>
                      <img src={firstImage} onClick={() => clickleft('one')} onMouseEnter={() => mouse('one', 'enter')} onMouseLeave={() => mouse('one', 'leave')}></img>
                    </Tooltip>

                  </Col>
                  <Col span="12">
                    <Tooltip placement="top" title={righttitle}>
                      <img src={twoImage} onClick={() => clickleft('two')} onMouseEnter={() => mouse('two', 'enter')} onMouseLeave={() => mouse('two', 'leave')}></img>
                    </Tooltip>
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item label="评估时间" name="time" rules={[{
                required: true, message: "请选择评估时间"
              }]}>
                <DatePicker disabledDate={disabledDate} locale={locale} />
              </Form.Item>

              <Form.Item label="项目地点" name="address" className="projectform">
                <Row>
                  <Col span={11}>
                    <Form.Item name="province">
                      <Select onChange={handleProvinceChange}>
                        {provinceData.map(province => (
                          <Select.Option key={province["name"]}>{province["name"]}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={11} offset={2}>
                    <Form.Item name="city">
                      <Select value={secondCity} onChange={onSecondCityChange}>
                        {cities.map(city => (
                          <Select.Option key={city}>{city}</Select.Option>
                        ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item name="address" rules={[{
                  required: true, message: "请输入项目地点"
                }]}>
                  <Input onChange={printInput} onPressEnter={printInput} autoComplete="off" />
                </Form.Item>
              </Form.Item>




              <Form.Item className="Lamination" name="isLamination" label="是否分层分析：" rules={[{
                required: true,
                message: "请选择是否分层分析",
              },]}>
                <Radio.Group onChange={clickCheck}>
                  <Radio value="0">是</Radio>
                  <Radio value="1">否</Radio>
                </Radio.Group>
              </Form.Item>
              {/* <Form.Item name="isLamination" label="是否分层分析：" rules={[{
                required: true,
                message: "请选择是否分层分析",
              },]}>
               
                  <Checkbox value="0" onChange={clickCheck('是')}>是</Checkbox>
                <Checkbox value="1" onChange={clickCheck('否')}>否</Checkbox>
               
              </Form.Item> */}
              {

                costSaving ? (

                  <div>
                    <Form.Item
                      label=" 分层数"
                      name="hierarchyNumber"
                    >
                      <Select placeholder="请选择深度" onChange={addrr}>
                        <Select.Option key={1}>{1}</Select.Option>
                        <Select.Option key={2}>{2}</Select.Option>
                        <Select.Option key={3}>{3}</Select.Option>
                        <Select.Option key={4}>{4}</Select.Option>
                        <Select.Option key={5}>{5}</Select.Option>
                        <Select.Option key={6}>{6}</Select.Option>
                        <Select.Option key={7}>{7}</Select.Option>
                        <Select.Option key={8}>{8}</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.List name="depth"  >

                      {(fields, { add, remove }) => (

                        <>




                          {fields.map(({ key, name, ...restField }) => (




                            <Form.Item label={`第 ${toChineseNumber(key + 1)} 层(m)`}  >
                              <Row>

                                <Col span={11}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'depth1']}
                                  // rules={[{ required: true, message: 'Missing first name' }]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col span={2}>
                                  <Form.Item
                                    name={[name, 'tier']} >
                                    <div className="numberOfpiles" value='1'>
                                      ~
                                    </div>
                                  </Form.Item>
                                </Col>
                                <Col span={11} >
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'depth2']}
                                  // rules={[{ required: true, message: 'Missing last name' }]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>


                                {/* <MinusCircleOutlined onClick={() => remove(key, name => { console.log("key,name", key, name); })} /> */}

                              </Row>
                            </Form.Item>


                          ))}

                        </>
                      )}
                    </Form.List>
                  </div>





                ) : null


              }










              <expandedData></expandedData>


              <Row gutter={24}>



                <Col span="24" offset="24">
                  <Form.Item >
                    <button className="formbtn info_btn">下一步</button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          {/* <Col xs={0} sm={0} md={7} lg={7} xl={7}></Col> */}
        </Row>
      </Spin>
    </div>
  );
};

export default BasicInfoForm;
